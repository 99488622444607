import { useMediaQuery, useTheme } from '@mui/material'
import { Allotment } from 'allotment'
import { useEffect, useState } from 'react'
import { CustomAllotment } from 'src/components/CustomAllotment/CustomAllotment'
import { ManagementTableContext } from 'src/components/ManagementTable/ManagementTableContext'
import { TableMenuItem } from 'src/components/ManagementTable/TableMenuItem'
import { Modal } from 'src/components/Modal/Modal'
import { TeamMemberDetailsFragment, TeamMemberForm } from 'src/fragments/Settings'
import { membersSelectors } from 'src/store/adapters/members'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupMembers } from 'src/store/thunks/setupMembers'
import { Manager } from 'src/types/Manager'
import { StyledAllotmentPanel, StyledManagementTable, StyledPaper } from './StyledSettings'

export type SettingsTeamMembersProps = {
  //
}

export const SettingsTeamMembers: React.FC<SettingsTeamMembersProps> = () => {
  const [selectedId, setSelectedId] = useState<string | null>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const [isAddMemberFormOpen, setAddMemberFormOpen] = useState(false)
  const teamMembers = useAppSelector(state => membersSelectors.selectAll(state))
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'))
  const dispatch = useAppDispatch()

  const tableContextValue = {
    selectedId,
    setSelectedId,
    searchValue,
    setSearchValue,
  }

  const searchFilter = (member: Manager) => {
    const searchTerm = searchValue.toLowerCase()

    return (
      member.TEAM_MEMBER_ID.toLowerCase().includes(searchTerm) ||
      member.email.toLowerCase().includes(searchTerm) ||
      member.mobile.toLowerCase().includes(searchTerm) ||
      member.team_member_name.toLowerCase().includes(searchTerm)
    )
  }

  useEffect(() => {
    dispatch(setupMembers())
  }, [])

  return (
    <StyledPaper>
      <ManagementTableContext.Provider value={tableContextValue}>
        <CustomAllotment>
          <Allotment.Pane minSize={350}>
            <StyledAllotmentPanel>
              <StyledManagementTable
                title={'Team Members'}
                subtitle={`${teamMembers.length} members`}
                onAddClick={() => { setAddMemberFormOpen(true) }}
              >
                {teamMembers.filter(searchFilter).map((member) => (
                  <TableMenuItem
                    active={selectedId === member.TEAM_MEMBER_ID}
                    key={member.TEAM_MEMBER_ID}
                    onClick={() => {
                      setSelectedId((prevId) => prevId === member.TEAM_MEMBER_ID ? null : member.TEAM_MEMBER_ID)
                    }}
                  >
                    {member.team_member_name}
                  </TableMenuItem>
                ))}
              </StyledManagementTable>
            </StyledAllotmentPanel>
          </Allotment.Pane>
          {isTablet && selectedId && (
            <Allotment.Pane minSize={350}>
              <StyledAllotmentPanel>
                <TeamMemberDetailsFragment />
              </StyledAllotmentPanel>
            </Allotment.Pane>
          )}
        </CustomAllotment>
        <Modal title={'Add Team Member'} open={isAddMemberFormOpen} onClose={() => { setAddMemberFormOpen(false) }} disabledBackdropClose>
          <TeamMemberForm editable $isEditable onFormSubmitSuccess={() => { setAddMemberFormOpen(false) }} />
        </Modal>
        {!isTablet && (
          <Modal
            fullWidth
            open={!!selectedId}
          >
            <TeamMemberDetailsFragment />
          </Modal>
        )}
      </ManagementTableContext.Provider>
    </StyledPaper>
  )
}
