import { useCallback, useState } from 'react'
import { APIResponse } from 'src/apis/CarshareApiService'

export type RequestHandler<TResult> = () => Promise<APIResponse<TResult>>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SuccessHandler<TResult> = (data: APIResponse<TResult>['results']) => any

export const useApiRequest = () => {
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const request = useCallback(async <TResult>(
    handler: RequestHandler<TResult>,
    onSuccess?: SuccessHandler<TResult>,
    onError?: (errorMessage: string) => void,
  ) => {
    if (!setLoading || !setComplete || !setErrorMessage) return

    setLoading(true)
    setComplete(false)
    setErrorMessage(null)

    try {
      const response = await handler()

      if (response.status !== 'OK') {
        setErrorMessage(response.error || 'Server response with an error.')

        if (onError) {
          onError(response.error || 'Server response with an error.')
        }

        return response
      } else if (response.status === 'OK' && response.results.length >= 0) {
        if (onSuccess) {
          await onSuccess(response.results)
        }

        setLoading(false)
        setComplete(true)

        return response
      } else if (response.status === 'OK') {
        setLoading(false)
        setComplete(true)
      }
    } catch (err) {
      setErrorMessage('An error occurred while performing this request.')

      if (onError) {
        onError('An error occurred while performing this request.')
      }
    } finally {
      setLoading(false)
    }
  }, [setLoading, setComplete, setErrorMessage])

  return { loading, complete, errorMessage, request }
}