import { faXmark } from '@fortawesome/pro-light-svg-icons'
import { IconButton, Typography, styled } from '@mui/material'
import React, { useContext } from 'react'
import { Icon } from '../Icon/Icon'
import { MultiselectDropdownOption } from './MultiselectDropdown'
import { DropdownContext } from './MultiselectDropdownContext'


export type ItemTagProps = {
  children: string
  value: MultiselectDropdownOption
  fullWidth?: boolean
}

export const ItemTag: React.FC<ItemTagProps> = ({ children, value, fullWidth, ...props }) => {
  const dropdownContext = useContext(DropdownContext)

  const onTagClick: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    dropdownContext.onSelectItem(value)
  }

  return (
    <StyledTag sx={{ width: fullWidth ? '100%' : 'auto' }} {...props}>
      <StyledTagTypography variant="body2">
        {children}
      </StyledTagTypography>
      <IconButton onClick={onTagClick}>
        <StyledRemoveIcon icon={faXmark} />
      </IconButton>
    </StyledTag>
  )
}

const StyledTag = styled('span')`
  display: inline-flex;
  padding: ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(8)};
  border-radius: ${props => props.theme.typography.pxToRem(8)};
  background-color: rgba(0, 0, 0, 0.06);
  align-items: center;
  justify-content: center;
  margin-right: ${props => props.theme.typography.pxToRem(4)};
  border: 1px solid ${props => props.theme.palette.divider};
  padding-top: ${props => props.theme.typography.pxToRem(4)};
  padding-bottom: ${props => props.theme.typography.pxToRem(4)};
`

const StyledTagTypography = styled(Typography)`
  font-weight: 500;
  font-size: ${props => props.theme.typography.pxToRem(14)};
  color: ${props => props.theme.palette.text.primary};
  line-height: 1;
  max-width: ${props => props.theme.typography.pxToRem(200)};
  text-overflow: ellipsis;
  white-space: nowrap;
`

const StyledRemoveIcon = styled(Icon)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
`