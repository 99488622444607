import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Booking } from 'src/types/Booking'
import { addMultipleBookings } from '../reducers/bookings'

type SetupByIdPayload = {
  BOOKING_ID: string
}

export const setupBookingById = createAsyncThunk('bookings/setup', async ({ BOOKING_ID }: SetupByIdPayload, { dispatch }) => {
  const bookings = await CarshareApiService.post<Booking>('getBookings', {
    limit: 1000,
    BOOKING_ID,
  })

  if (bookings.results) {
    return dispatch(addMultipleBookings(bookings.results))
  }
})