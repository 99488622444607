import React, { ComponentProps } from 'react'

export type VehicleSummaryListItemProps = ComponentProps<'div'> & {
  children: React.ReactNode
}

export const VehicleSummaryListItem: React.FC<VehicleSummaryListItemProps> = ({ children, ...props }) => {
  return (
    <div {...props}>
      {children}
    </div>
  )
}