import { MenuItem, styled } from '@mui/material'
import { ComponentProps } from 'react'

export type TableMenuItemProps = ComponentProps<typeof MenuItem> & {
  active?: boolean
  actionText?: string
}

export const TableMenuItem: React.FC<TableMenuItemProps> = ({ active, actionText = 'View details', ...props }) => {
  return (
    <StyledMenuItem disableRipple disableGutters $active={active} $actionText={actionText} {...props} />
  )
}

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== '$active' && prop !== '$actionText',
}) <{ $active?: boolean, $actionText: string }>`
  min-height: ${(props) => props.theme.typography.pxToRem(48)};
  padding: ${(props) => props.theme.typography.pxToRem(12)} ${(props) => props.theme.typography.pxToRem(16)};
  margin-left: ${(props) => props.theme.typography.pxToRem(-16)};
  margin-right: ${(props) => props.theme.typography.pxToRem(-16)};
  position: relative;
  transition: background-color 0.2s ease-in-out;
  width: calc(100vw - ${(props) => props.theme.typography.pxToRem(16)});
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) => props.theme.breakpoints.up('sm')} {
    width: auto;
  }
  
  &:after {
    content: ${(props) => `'${props.$actionText}'`};
    position: absolute;
    right: ${(props) => props.theme.typography.pxToRem(16)};
    font-size: ${(props) => props.theme.typography.pxToRem(14)};
    color: ${(props) => props.theme.palette.text.primary};
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }

  &:active {
    background-color: ${(props) => props.theme.palette.action.hover};
  }

  ${(props) => props.$active && `
    background-color: ${props.theme.palette.action.hover};

    &:after {
      content: 'Close details';
    }
  `}
`
