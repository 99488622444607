import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { VehicleAttribute } from 'src/types/Vehicle'

type SetupPayload = {
  VEHICLE_ID: string
}

export const setupVehicleAttributes = createAsyncThunk('vehicles/setupAttributes', async ({ VEHICLE_ID }: SetupPayload) => {
  // Retrieve Vehicle Attributes
  const attributes = await CarshareApiService.post<VehicleAttribute>('getVehicleAttribute', {
    VEHICLE_ID,
  })

  return {
    VEHICLE_ID: VEHICLE_ID,
    results: attributes.results,
  }
})