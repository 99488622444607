import { AppBar, Toolbar, styled } from '@mui/material'
import { ComponentProps } from 'react'

export type NavHeaderProps = ComponentProps<typeof AppBar> & {
  children?: React.ReactNode
}

export const NavHeader: React.FC<NavHeaderProps> = ({ children, ...props }) => {
  return (
    <StyledAppBar elevation={0} {...props}>
      <StyledToolbar>
        {children}
      </StyledToolbar>
    </StyledAppBar>
  )
}

export const StyledAppBar = styled(AppBar)`
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  width: unset;
`

export const StyledToolbar = styled(Toolbar)`
  background-color: ${(props) => props.theme.palette.background.paper};

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding-left: ${(props) => props.theme.typography.pxToRem(36)};
    padding-right: ${(props) => props.theme.typography.pxToRem(36)};
  }
`
