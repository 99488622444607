import { createAsyncThunk } from '@reduxjs/toolkit'
import { setupCustomers } from './setupCustomers'
import { setupLocations } from './setupLocations'
import { setupPassengers } from './setupPassengers'
import { setupVehicles } from './setupVehicles'


export type SetupPayload = {
  ORG_UNIT_ID?: string
}

export const setupApp = createAsyncThunk('app/setup', async ({ ORG_UNIT_ID }: SetupPayload, { dispatch }) => {
  await Promise.allSettled([
    dispatch(setupVehicles({ ORG_UNIT_ID })),
    dispatch(setupCustomers({ ORG_UNIT_ID })),
    dispatch(setupLocations({ ORG_UNIT_ID })),
    dispatch(setupPassengers()),
  ])
})
