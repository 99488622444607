import { useMediaQuery, useTheme } from '@mui/material'
import { Allotment } from 'allotment'
import { useEffect, useState } from 'react'
import { CustomAllotment } from 'src/components/CustomAllotment/CustomAllotment'
import { ManagementTableContext } from 'src/components/ManagementTable/ManagementTableContext'
import { TableMenuItem } from 'src/components/ManagementTable/TableMenuItem'
import { Modal } from 'src/components/Modal/Modal'
import { CustomerDetailsFragment, CustomerForm } from 'src/fragments/Management'
import { customersSelectors } from 'src/store/adapters/customers'
import { useAppSelector } from 'src/store/store'
import { Customer } from 'src/types/Customer'
import { StyledAllotmentPanel, StyledManagementTable, StyledPaper } from './StyledManagement'

export type ManagementCustomersProps = {
  //
}

export const ManagementCustomers: React.FC<ManagementCustomersProps> = () => {
  const [selectedId, setSelectedId] = useState<string | null>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const [isAddCustomerFormOpen, setAddCustomerFormOpen] = useState(false)
  const activeOrgUnit = useAppSelector((state) => state.settings.activeOrgUnit)
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'))
  const customers = useAppSelector((state) => customersSelectors.selectAll(state))

  const tableContextValue = {
    selectedId,
    setSelectedId,
    searchValue,
    setSearchValue,
  }

  const customerSearchFilter = (customer: Customer) => {
    const searchTerm = searchValue.toLowerCase()

    return (
      customer.customer_name.toLowerCase().includes(searchTerm) ||
      customer.customer_birth_year.includes(searchTerm) ||
      customer.license_number.toLowerCase().includes(searchTerm)
    )
  }

  // Reset selected customer when org unit changes
  useEffect(() => {
    setSelectedId(null)
  }, [activeOrgUnit])

  return (
    <StyledPaper>
      <ManagementTableContext.Provider value={tableContextValue}>
        <CustomAllotment>
          <Allotment.Pane minSize={350}>
            <StyledAllotmentPanel>
              <StyledManagementTable
                title={'Customers'}
                subtitle={`${customers.length} customers`}
                onAddClick={() => { setAddCustomerFormOpen(true) }}
              >
                {customers.filter(customerSearchFilter).map((customer) => (
                  <TableMenuItem
                    active={selectedId === customer.CUSTOMER_ID}
                    key={customer.CUSTOMER_ID}
                    onClick={() => {
                      setSelectedId((prevId) => prevId === customer.CUSTOMER_ID ? null : customer.CUSTOMER_ID)
                    }}
                  >
                    {customer.customer_name}
                  </TableMenuItem>
                ))}
              </StyledManagementTable>
            </StyledAllotmentPanel>
          </Allotment.Pane>
          {isTablet && selectedId && (
            <Allotment.Pane minSize={350}>
              <StyledAllotmentPanel>
                <CustomerDetailsFragment />
              </StyledAllotmentPanel>
            </Allotment.Pane>
          )}
        </CustomAllotment>
        <Modal title={'Add Customer'} open={isAddCustomerFormOpen} onClose={() => { setAddCustomerFormOpen(false) }} disabledBackdropClose>
          <CustomerForm
            editable
            onFormSubmitSuccess={() => { setAddCustomerFormOpen(false) }}
          />
        </Modal>
        {!isTablet && (
          <Modal
            fullWidth
            open={!!selectedId}
          >
            <CustomerDetailsFragment />
          </Modal>
        )}
      </ManagementTableContext.Provider>
    </StyledPaper>
  )
}

