import { createEntityAdapter } from '@reduxjs/toolkit'
import { Passenger } from 'src/types/Passenger'
import { RootState } from '../store'

export const passengersAdapter = createEntityAdapter({
  selectId: (customer: Passenger) => customer.passenger_guid,
})

export const passengersSelectors = passengersAdapter.getSelectors<RootState>(
  (state) => state.passengers,
)
