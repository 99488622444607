import { Grid, styled } from '@mui/material'
import { scrollbarStyle } from '../../styles/themes'
import { IconButton } from '../IconButton/IconButton'

export const StyledNotificationContent = styled('div')`
  padding: ${(props) => props.theme.typography.pxToRem(16)};
  height: calc(100vh - ${(props) => props.theme.layout.navHeaderMobileHeight});
  overflow-y: auto;
  ${scrollbarStyle};
  
  > * {
    margin-bottom: ${(props) => props.theme.typography.pxToRem(16)};
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    height: calc(100vh - ${(props) => props.theme.layout.navHeaderHeight});
  }
`

export const StyledCloseButton = styled(IconButton)`
  margin-right: ${(props) => props.theme.typography.pxToRem(-12)};
`

export const StyledHeaderGrid = styled(Grid)`
  min-height: ${(props) => props.theme.layout.navHeaderMobileHeight};
  background-color: ${(props) => props.theme.palette.background.paper};
  padding-left: ${(props) => props.theme.typography.pxToRem(16)};
  padding-right: ${(props) => props.theme.typography.pxToRem(16)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: ${(props) => props.theme.layout.navHeaderHeight};
  }
`

export const StyledNotificationSidebar = styled('aside', {
  shouldForwardProp: (prop) => prop !== '$open',
}) <{ $open?: boolean }>`
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: ${(props) => props.theme.zIndex.appBar + 100};
  height: 100vh;
  background-color: ${(props) => props.theme.palette.background.default};
  overflow: hidden;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: visibility 250ms ease-in-out, opacity 250ms ease-in-out;

  ${(props) => props.$open && `
    visibility: visible;
    opacity: 1;
  `}

  ${(props) => props.theme.breakpoints.up('sm')} {
    visibility: visible;
    opacity: 1;
    width: ${(props) => props.theme.layout.notificationSidebarWidth};
    transform: translateX(${(props) => props.theme.layout.notificationSidebarWidth});
    transition: transform 250ms ease-in-out;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    
    ${(props) => props.$open && `
      transform: translateX(0);
    `}
  }
`
