/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Grid } from '@mui/material'
import { ComponentProps, useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { TextField } from 'src/components/Form/TextField'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { StyledForm, StyledFormControl, StyledAccountSubmitButton } from 'src/fragments/StyledForm'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupAccount } from 'src/store/thunks/setupAccount'

export type AccountFormProps = ComponentProps<typeof StyledForm> & {
  onFormSubmitSuccess?: () => void
}

export const AccountForm: React.FC<AccountFormProps> = ({ onFormSubmitSuccess, ...props }) => {
  const [company, setCompany] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const user = useAppSelector((state) => state.user)

  const { loading, complete, errorMessage, request } = useApiRequest()
  const { setSuccessMessage } = useAlertContext()
  const dispatch = useAppDispatch()

  const submitHandler = () => {
    const payload = {
      org_name: company,
      given_name: firstName,
      family_name: lastName,
      email: email,
      mobile: phoneNumber,
    }

    return CarshareApiService.post('updateAccount', payload)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    request(submitHandler)
  }

  useEffect(() => {
    if (user && user.profile) {
      setCompany(user?.profile?.org_name)
      setFirstName(user?.profile?.given_name || '')
      setLastName(user.profile.family_name || '')
      setEmail(user.profile.email || '')
      setPhoneNumber(user.profile.mobile || '')
    }
  }, [user])

  useEffect(() => {
    if (errorMessage) return

    if (complete && onFormSubmitSuccess) {
      onFormSubmitSuccess()
    }
  }, [errorMessage, complete, onFormSubmitSuccess])

  useEffect(() => {
    if (complete) {
      setSuccessMessage(`Account ${firstName} ${lastName} was updated successfully.`)
      dispatch(setupAccount())
    }
  }, [complete])

  return (
    <StyledForm onSubmit={onSubmit} $isEditable={false} {...props}>
      <Grid container spacing={2} justifyContent={'center'} flexDirection={'column'}>
        <Grid item>
          {errorMessage && (
            <Alert severity={'error'} icon={false}>
              {errorMessage}
            </Alert>
          )}
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel>Company</TextFieldLabel>
            <TextField
              disabled
              value={company || ''}
              onChange={(e) => {
                setCompany(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel>First Name</TextFieldLabel>
            <TextField
              disabled
              value={firstName || ''}
              onChange={(e) => {
                setFirstName(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel>Last Name</TextFieldLabel>
            <TextField
              disabled
              value={lastName || ''}
              onChange={(e) => {
                setLastName(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel>Email</TextFieldLabel>
            <TextField
              disabled
              value={email || ''}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel>Phone</TextFieldLabel>
            <TextField
              value={phoneNumber || ''}
              onChange={(e) => {
                setPhoneNumber(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledAccountSubmitButton
            primary
            type="submit"
            disabled={loading}
          >
            Update Profile
          </StyledAccountSubmitButton>
        </Grid>
      </Grid>
    </StyledForm >
  )
}
