/* eslint-disable import/no-named-as-default-member */
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

dayjs.extend(quarterOfYear)

const initializeDayJs = () => {
  dayjs.extend(quarterOfYear)
}

export default initializeDayJs