import { faAdd, faClose } from '@fortawesome/pro-regular-svg-icons'
import { faPen, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { Grid, ListItemText, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { Button } from 'src/components/Button/Button'
import { Icon } from 'src/components/Icon/Icon'
import { ImageInputField } from 'src/components/ImageInputField/ImageInputField'
import { useManagementTableContext } from 'src/components/ManagementTable/ManagementTableContext'
import { Modal } from 'src/components/Modal/Modal'
import { OutlinedMenuItem } from 'src/components/OutlinedMenuList/OutlinedMenuItem'
import { OutlinedMenuList } from 'src/components/OutlinedMenuList/OutlinedMenuList'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { vehiclesSelectors } from 'src/store/adapters/vehicles'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupVehicleAttributes } from 'src/store/thunks/setupVehicleAttributes'
import { setupVehicles } from 'src/store/thunks/setupVehicles'
import { pxToRem } from 'src/styles/themes'
import { VehicleAttribute } from 'src/types/Vehicle'
import { StyledAddButton, StyledCloseButton, StyledContainer, StyledEditButton, StyledEditIcon } from '../StyledDetailsFragment'
import { AddVehicleAttributeForm } from '../VehicleForm/AddVehicleAttributeForm'
import { VehicleForm } from '../VehicleForm/VehicleForm'


type VehicleDetailsFragmentProps = {
  //
}

export const VehicleDetailsFragment: React.FC<VehicleDetailsFragmentProps> = () => {
  const [isEditVehicleFormOpen, setEditVehicleFormOpen] = useState<boolean>(false)
  const [isAddVehicleAttributeFormOpen, setAddVehicleAttributeFormOpen] = useState<boolean>(false)
  const [isConfirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState<boolean>(false)
  const [selectedAttribute, setSelectedAttribute] = useState<VehicleAttribute>()
  const { selectedId, setSelectedId } = useManagementTableContext()
  const [memoSelectedId, setMemoSelectedId] = useState<string | null>(null)
  const { setSuccessMessage } = useAlertContext()
  const { loading, errorMessage, request } = useApiRequest()

  const activeVehicle = useAppSelector(state => vehiclesSelectors.selectById(state, memoSelectedId || ''))
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (selectedId) {
      dispatch(setupVehicleAttributes({ VEHICLE_ID: selectedId }))

      // Use to prevent flickering redux state data when closing details modal on mobile.
      setMemoSelectedId(selectedId)
    }
  }, [selectedId])

  const onConfirmDeleteAttribute = (attribute: VehicleAttribute) => {
    setSelectedAttribute(attribute)

    setConfirmDeleteDialogOpen(true)
  }

  const removeAttributeHandler = () => {
    const payload = {
      VEHICLE_ID: selectedId,
      ATTRIBUTE_ID: selectedAttribute?.ATTRIBUTE_ID,
      VEHICLE_ATTRIBUTE_ID: selectedAttribute?.VEHICLE_ATTRIBUTE_ID,
    }

    CarshareApiService.post('removeVehicleAttribute', payload).then(() => {
      dispatch(setupVehicleAttributes({ VEHICLE_ID: selectedId || '' }))
    }).then(() => {
      setConfirmDeleteDialogOpen(false)
    })
  }

  const onImageUpload: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    const file = ev.target?.files?.item(0)

    if (file) {
      const reader = new FileReader()

      reader.onload = (loadEvent) => {
        if (!loadEvent.target?.result) return

        const base64 = (loadEvent.target.result as string).split(',')[1]

        const uploadHandler = () => {
          return CarshareApiService.post('addVehicleImage', {
            VEHICLE_ID: activeVehicle?.VEHICLE_ID,
            VEHICLE_IMAGE_ID: 0,
            original_filename: file.name,
            filename: file.name,
            filetype: file.type,
            filesize: file.size.toString(),
            fileextension: file.name.split('.').pop(),
            base64,
          })
        }

        request(uploadHandler, () => {
          setSuccessMessage('Vehicle image is updated successfully.')
          dispatch(setupVehicles({}))
        })
      }

      reader.readAsDataURL(file)
    }
  }


  return (
    <StyledContainer>
      <Grid container justifyContent={'space-between'} sx={{ position: 'sticky' }}>
        <Grid item>
          <StyledEditButton text round onClick={() => { setEditVehicleFormOpen(true) }}>
            <Typography variant="h6">Details</Typography>
            <StyledEditIcon icon={faPen} />
          </StyledEditButton>
        </Grid>
        <Grid item>
          <StyledCloseButton onClick={() => setSelectedId(null)}>
            <Icon icon={faClose} />
          </StyledCloseButton>
        </Grid>
      </Grid>

      {activeVehicle?.file_image_src_data && <StyledImageContainer>
        <StyledImage
          src={activeVehicle?.file_image_src_data && activeVehicle?.file_image_src_data.substring(4, activeVehicle?.file_image_src_data.length - 1)}
          alt="Vehicle Image"
        />
      </StyledImageContainer>}

      <ImageInputField
        onChange={onImageUpload}
        accept="image/*"
        error={errorMessage}
        disabled={loading}
      >
        {loading ? 'Uploading...' : 'Choose Image to Upload'}
      </ImageInputField>
      <VehicleForm vehicleId={selectedId || ''} isEditForm editable={false} />
      <Grid container sx={{ marginTop: pxToRem(24) }} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} wrap={'nowrap'}>
        <Grid item>
          <Typography variant="h6">Vehicle Attributes</Typography>
        </Grid>
        <Grid item>
          <StyledAddButton text round startIcon={<Icon icon={faAdd} />} onClick={() => { setAddVehicleAttributeFormOpen(true) }}>
            Add {!isMobile && 'Attribute'}
          </StyledAddButton>
        </Grid>
      </Grid>

      <OutlinedMenuList>
        {activeVehicle?.vehicle_attributes?.map((attribute) => {
          return (
            <OutlinedMenuItem key={attribute.ATTRIBUTE_ID} onClick={() => { onConfirmDeleteAttribute(attribute) }}>
              <ListItemText>
                {attribute.attribute_name}
              </ListItemText>
              <Icon icon={faTrash} />
            </OutlinedMenuItem>
          )
        })}
        {(!activeVehicle?.vehicle_attributes || activeVehicle?.vehicle_attributes.length === 0) && (
          <OutlinedMenuItem disabled>
            <ListItemText>
              No attribute found.
            </ListItemText>
          </OutlinedMenuItem>
        )}
      </OutlinedMenuList>

      <Modal title={'Edit Vehicle Details'} open={isEditVehicleFormOpen} onClose={() => { setEditVehicleFormOpen(false) }} disabledBackdropClose>
        <VehicleForm
          vehicleId={selectedId || ''}
          isEditForm
          editable
          onFormSubmitSuccess={() => { setEditVehicleFormOpen(false) }}
        />
      </Modal>
      <Modal title={'Add Vehicle Attribute'} open={isAddVehicleAttributeFormOpen} onClose={() => { setAddVehicleAttributeFormOpen(false) }} disabledBackdropClose>
        <AddVehicleAttributeForm
          vehicleId={selectedId || ''}
          onFormSubmitSuccess={() => { setAddVehicleAttributeFormOpen(false) }}
        />
      </Modal>
      <Modal title={'Delete Confirmation'} open={isConfirmDeleteDialogOpen} onClose={() => { setConfirmDeleteDialogOpen(false) }} disabledBackdropClose>
        <Typography variant="body1">Are you sure you want to remove attribute <strong>{selectedAttribute?.attribute_name}</strong> from {activeVehicle?.vehicle_rego_number} {activeVehicle?.vehicle_rego_number && activeVehicle?.vehicle_identifier && '-'} {activeVehicle?.vehicle_identifier}?</Typography>
        <Grid container justifyContent={'flex-end'} sx={{ marginTop: pxToRem(16) }} spacing={1}>
          <Grid item>
            <Button text onClick={() => { setConfirmDeleteDialogOpen(false) }}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button primary onClick={() => removeAttributeHandler()}>Confirm</Button>
          </Grid>
        </Grid>
      </Modal>
    </StyledContainer>
  )
}

const StyledImageContainer = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 200px;
  overflow: hidden;
  margin-bottom: ${pxToRem(8)};
`

const StyledImage = styled('img')`
  width: 100%;
  height: auto;
  border-radius: ${pxToRem(8)};

  ${props => props.theme.breakpoints.up('sm')} {
    width: auto;
    height: 100%;
  }
`