import { CircularProgress, Grid, Typography, styled } from '@mui/material'
import React, { ComponentProps } from 'react'
import { pxToRem } from '../../styles/themes'
import { IconButton } from '../IconButton/IconButton'

type Pressure = {
  value: number
  unit: string
}

export type VehicleSummaryWrapperProps = ComponentProps<typeof StyledSummaryWrapper> & {
  children: React.ReactNode
  vehicleImage: string
  wheelsPressure?: {
    topLeft: Pressure
    topRight: Pressure
    bottomLeft: Pressure
    bottomRight: Pressure
  }
  onLockClick?: () => void
  onUnlockClick?: () => void
  loading?: boolean
  disableAction?: boolean
  disabled?: boolean
}

export const VehicleSummaryWrapper: React.FC<VehicleSummaryWrapperProps> = ({ wheelsPressure, children, onLockClick, onUnlockClick, loading, disableAction, disabled, ...props }) => {
  return (
    <StyledSummaryWrapper {...props}>
      <StyledGrid container>
        <Grid item xs={6} sx={{ position: 'relative', height: '38.5%', textAlign: 'start' }}>
          {wheelsPressure && <StyledPressureTopLeft>
            <StyledPressureTypography variant="body1">
              {wheelsPressure.topLeft.value}
            </StyledPressureTypography>
            <StyledSecondaryTypography variant="body1">
              {wheelsPressure.topLeft.unit}
            </StyledSecondaryTypography>
          </StyledPressureTopLeft>}
          <StyledUnlockButtonContainer>
            <StyledPrimaryButton disabled={disableAction} onClick={onUnlockClick}>
              <StyledLockImage src="/svgs/connect-unlocked.svg" alt="unlock car" />
            </StyledPrimaryButton>
            <StyledButtonTypography variant="body1">
              Unlock
            </StyledButtonTypography>
          </StyledUnlockButtonContainer>
        </Grid>
        <Grid item xs={6} sx={{ position: 'relative', height: '38.5%', textAlign: 'end' }}>
          {wheelsPressure && <StyledPressureTopRight>
            <StyledPressureTypography variant="body1">
              {wheelsPressure.topRight.value}
            </StyledPressureTypography>
            <StyledSecondaryTypography variant="body1">
              {wheelsPressure.topRight.unit}
            </StyledSecondaryTypography>
          </StyledPressureTopRight>}
          <StyledLockButtonContainer>
            <StyledPrimaryButton disabled={disableAction} onClick={onLockClick}>
              <StyledLockImage src="/svgs/connect-locked.svg" alt="lock car" />
            </StyledPrimaryButton>
            <StyledButtonTypography variant="body1">
              Lock
            </StyledButtonTypography>
          </StyledLockButtonContainer>
        </Grid>
        <Grid item xs={6} sx={{ position: 'relative', height: '38.5%', textAlign: 'start' }}>
          {wheelsPressure && <StyledPressureBottomLeft>
            <StyledPressureTypography variant="body1">
              {wheelsPressure.bottomLeft.value}
            </StyledPressureTypography>
            <StyledSecondaryTypography variant="body1">
              {wheelsPressure.bottomLeft.unit}
            </StyledSecondaryTypography>
          </StyledPressureBottomLeft>}
        </Grid>
        <Grid item xs={6} sx={{ position: 'relative', height: '38.5%', textAlign: 'end' }}>
          {wheelsPressure && <StyledPressureBottomRight>
            <StyledPressureTypography variant="body1">
              {wheelsPressure.bottomRight.value}
            </StyledPressureTypography>
            <StyledSecondaryTypography variant="body1">
              {wheelsPressure.bottomRight.unit}
            </StyledSecondaryTypography>
          </StyledPressureBottomRight>}
        </Grid>
      </StyledGrid>
      <StyledBottom>
        {children}
      </StyledBottom>
      <StyledLoadingBackdrop $open={loading || disabled}>
        {loading && <StyledCircularProgress size={pxToRem(48)} />}
      </StyledLoadingBackdrop>
    </StyledSummaryWrapper>
  )
}

export const StyledSummaryWrapper = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    
    background-image: url('/svgs/connect-car-birds-eye.svg');
    background-repeat: no-repeat;
    background-size: 80% 80%;
    background-position: center calc(50% - ${props => props.theme.typography.pxToRem(80)});

    ${({ theme }) => theme.breakpoints.up('sm')} {
      z-index: 0;
    }
  }
`

export const StyledPrimaryButton = styled(IconButton)`
  width: ${props => props.theme.typography.pxToRem(80)};
  height: ${props => props.theme.typography.pxToRem(80)};

  &.Mui-disabled {
    opacity: 0.5;
  }
`

export const StyledUnlockButtonContainer = styled('div')`
  position: absolute;
  left: ${props => props.theme.typography.pxToRem(20)};
  bottom: ${props => props.theme.typography.pxToRem(-150)};
  z-index: 10;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  wrap: nowrap;
  
  @media only screen and (min-width: 500px)  {
    left: unset;
    right: ${props => props.theme.typography.pxToRem(150)};
  }
  
  ${({ theme }) => theme.breakpoints.up('sm')} {
    left: unset;
    right: ${props => props.theme.typography.pxToRem(130)};
    bottom: ${props => props.theme.typography.pxToRem(-140)};
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    right: ${props => props.theme.typography.pxToRem(150)};
  }
`

export const StyledLockButtonContainer = styled('div')`
  position: absolute;
  right: ${props => props.theme.typography.pxToRem(20)};
  bottom: ${props => props.theme.typography.pxToRem(-150)};
  z-index: 10;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  wrap: nowrap;

  @media only screen and (min-width: 500px)  {
    right: unset;
    left: ${props => props.theme.typography.pxToRem(150)};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    right: unset;
    left: ${props => props.theme.typography.pxToRem(130)};
    bottom: ${props => props.theme.typography.pxToRem(-140)};
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    left: ${props => props.theme.typography.pxToRem(150)};
  }
`

export const StyledButtonTypography = styled(Typography)`
  margin-top: ${({ theme }) => theme.typography.pxToRem(4)};
  opacity: 0;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    opacity: 1;
  }
`

export const StyledLockImage = styled('img')`
  color: #7CD5A7;
`

export const StyledGrid = styled(Grid)`
  height: 75%;
  spacing: ${({ theme }) => theme.typography.pxToRem(32)};
`

export const StyledPressureTopLeft = styled('div')`
  position: absolute;
  display: flex;
  align-items: flex-end;
  right: ${props => props.theme.typography.pxToRem(115)};
  bottom: ${props => props.theme.typography.pxToRem(20)};
  z-index: 10;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    right: ${props => props.theme.typography.pxToRem(130)};
    bottom: ${props => props.theme.typography.pxToRem(30)};
  }
`

export const StyledPressureTopRight = styled('div')`
  position: absolute;
  display: flex;
  align-items: flex-end;
  left: ${props => props.theme.typography.pxToRem(115)};
  bottom: ${props => props.theme.typography.pxToRem(20)};
  z-index: 10;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    left: ${props => props.theme.typography.pxToRem(130)};
    bottom: ${props => props.theme.typography.pxToRem(30)};
  }
`

export const StyledPressureBottomLeft = styled('div')`
  position: absolute;
  display: flex;
  align-items: flex-end;
  right: ${props => props.theme.typography.pxToRem(115)};
  top: ${props => props.theme.typography.pxToRem(120)};
  z-index: 10;

  ${props => props.theme.breakpoints.up('sm')} {
    right: ${props => props.theme.typography.pxToRem(130)};
    top: ${props => props.theme.typography.pxToRem(115)};
  }
`

export const StyledPressureBottomRight = styled('div')`
  position: absolute;
  display: flex;
  align-items: flex-end;
  left: ${props => props.theme.typography.pxToRem(115)};
  top: ${props => props.theme.typography.pxToRem(120)};
  z-index: 10;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    left: ${props => props.theme.typography.pxToRem(130)};
    top: ${props => props.theme.typography.pxToRem(115)};
  }
`

export const StyledPressureTypography = styled(Typography)`
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  margin-right: ${({ theme }) => theme.typography.pxToRem(3)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  }
`

export const StyledSecondaryTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(2)};
`

const StyledBottom = styled('div')`
  position: relative;
  padding: ${({ theme }) => theme.typography.pxToRem(20)};
  max-width: ${({ theme }) => theme.typography.pxToRem(440)};
  margin: auto;
  z-index: 10;

  @media all and (display-mode: standalone) {
    padding-top: ${({ theme }) => theme.typography.pxToRem(4)};
  }
`

const StyledLoadingBackdrop = styled('div', { shouldForwardProp: (prop) => prop !== '$open' }) <{ $open?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: ${props => props.$open ? 100 : -1};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255,0.5);
  visbiility: ${props => props.$open ? 'visible' : 'hidden'};
  opacity: ${props => props.$open ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
`

const StyledCircularProgress = styled(CircularProgress)`
  z-index: 101;
  color: ${props => props.theme.palette.text.primary};
  margin-bottom: ${props => props.theme.typography.pxToRem(96)};
`