import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { VehicleDetail } from 'src/types/Vehicle'
import { replaceAllVehicles } from '../reducers/vehicles'

type SetupPayload = {
  ORG_UNIT_ID?: string
}

export const setupVehicles = createAsyncThunk('vehicles/setup', async ({ ORG_UNIT_ID }: SetupPayload, { dispatch }) => {
  // Retrieve Vehicles
  const vehicles = await CarshareApiService.post<VehicleDetail>('getVehiclesStrict', {
    limit: 1000,
    ORG_UNIT_ID,
  })

  if (vehicles.results) {
    dispatch(replaceAllVehicles(vehicles.results))
  }
})