/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react'

export type LayoutContextType = {
  selectedBookingId: string | null
  setSelectedBookingId: React.Dispatch<React.SetStateAction<string | null>>
}

export const LayoutContext = createContext<LayoutContextType>({
  selectedBookingId: '',
  setSelectedBookingId: () => { },
})

export const useLayoutContext = () => {
  const context = useContext(LayoutContext)

  if (context === undefined) {
    throw new Error(
      'useLayoutContext must be used within a LayoutContext.Provider',
    )
  }

  return context
}