/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { TextField } from 'src/components/Form/TextField'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { StyledForm, StyledFormControl, StyledSubmitButton } from 'src/fragments/StyledForm'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupLocationFacilities } from 'src/store/thunks/setupLocationFacilities'

export type AddLocationFacilityFormProps = {
  locationId: string
  onFormSubmitSuccess?: () => void
}

export const AddLocationFacilityForm: React.FC<AddLocationFacilityFormProps> = ({ locationId, onFormSubmitSuccess }) => {
  const activeOrgUnit = useAppSelector(state => state.settings.activeOrgUnit)
  const [facilityName, setFacilityName] = useState<string>('')
  const { loading, complete, errorMessage, request } = useApiRequest()
  const { setSuccessMessage } = useAlertContext()
  const dispatch = useAppDispatch()

  const createFacilityHandler = () => {
    const payload = {
      ORG_UNIT_ID: activeOrgUnit?.ORG_UNIT_ID,
      LOCATION_ID: locationId,
      location_facility_name: facilityName,
    }

    return CarshareApiService.post('addLocationFacility', payload)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    request(createFacilityHandler)
  }

  useEffect(() => {
    if (errorMessage) return

    if (complete && onFormSubmitSuccess) {
      onFormSubmitSuccess()
    }
  }, [errorMessage, complete, onFormSubmitSuccess])

  useEffect(() => {
    if (complete) {
      setSuccessMessage(`Facility ${facilityName} was added successfully.`)
      dispatch(setupLocationFacilities({ LOCATION_ID: locationId }))
    }
  }, [complete])

  return (
    <StyledForm $isEditable onSubmit={onSubmit} >
      <Grid container spacing={2} justifyContent={'center'} flexDirection={'column'}>
        <Grid item>
          {errorMessage && (
            <Alert severity={'error'} icon={false}>
              {errorMessage}
            </Alert>
          )}
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Facility Name</TextFieldLabel>
            <TextField
              required
              onChange={e => setFacilityName(e.target.value)}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledSubmitButton
            primary
            fullWidth
            type="submit"
            disabled={loading}
          >
            Add Facility
          </StyledSubmitButton>
        </Grid>
      </Grid>
    </StyledForm >
  )
}
