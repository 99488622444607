import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { Card, Grid, Typography } from '@mui/material'
import { ComponentProps } from 'react'
import { Button } from '../Button/Button'
import { Icon } from '../Icon/Icon'
import { StyledButtonContainer, StyledCard, StyledGrid, StyledMainContent, StyledPrimaryButton } from './StyledNotificationCard'

export type NotificationCardProps = ComponentProps<typeof Card> & {
  title: string
  time: string
  type: 'success' | 'error' | 'warning' | 'info'
  onSeeBookingClick?: () => void
  onMarkReadClick?: () => void
  children: React.ReactNode
}

export const NotificationCard: React.FC<NotificationCardProps> = ({ title, time, type, children, onMarkReadClick, onSeeBookingClick, ...props }) => {
  return (
    <StyledCard {...props}>
      <StyledGrid container $type={type} justifyContent={'space-between'}>
        <Grid>
          <Typography variant="body1">
            {title}
          </Typography>
        </Grid>
        <Grid>
          <Typography variant="body1">
            {time}
          </Typography>
        </Grid>
      </StyledGrid>
      <StyledMainContent>
        <Typography variant="body1">
          {children}
        </Typography>
        <StyledButtonContainer container justifyContent={'flex-end'} columnSpacing={1}>
          <Grid item>
            {onMarkReadClick && <Button text onClick={onMarkReadClick}>
              Mark as read
            </Button>}
          </Grid>
          {onSeeBookingClick && <Grid item>
            <StyledPrimaryButton primary endIcon={<Icon icon={faArrowRight} />} onClick={onSeeBookingClick}>
              See booking
            </StyledPrimaryButton>
          </Grid>}
        </StyledButtonContainer>
      </StyledMainContent>
    </StyledCard>
  )
}
