import axios, { AxiosInstance, AxiosResponse } from 'axios'
import axiosRetry from 'axios-retry'

const hereMapResources = {
  'geocode': 'v1/geocode',
}

export type HereMapResourcesEndpoint = keyof typeof hereMapResources

export const HERE_API_KEY = process.env.REACT_APP_HERE_API_KEY || ''
export const HERE_GEOCODE_API_KEY = process.env.REACT_APP_HERE_GEOCODE_API_KEY || ''

const hereMapApiClient = axios.create({
  baseURL: 'https://geocode.search.hereapi.com/',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
})

axiosRetry(hereMapApiClient, {
  retries: 2,
  retryDelay: (retryCount) => {
    return retryCount * 1000
  },
  retryCondition: (error) => {
    return error.response === undefined
  },
})


class HereMapApiServiceClass {
  private instance: AxiosInstance

  public resources = hereMapResources

  constructor() {
    this.instance = hereMapApiClient
  }

  async get(endpoint: HereMapResourcesEndpoint, params?: object) {
    const response: AxiosResponse = await this.instance.get(this.resources[endpoint], {
      params: {
        ...params,
        apiKey: HERE_GEOCODE_API_KEY,
        limit: 4,
      },
    })

    return response.data
  }
}

export const HereMapApiService = new HereMapApiServiceClass()
