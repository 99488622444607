import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Location } from 'src/types/Location'
import { replaceAllLocations } from '../reducers/locations'

type SetupPayload = {
  ORG_UNIT_ID?: string
}

export const setupLocations = createAsyncThunk('locations/setup', async ({ ORG_UNIT_ID }: SetupPayload, { dispatch }) => {
  // Retrieve Locations
  const locations = await CarshareApiService.post<Location>('getLocations', {
    limit: 1000,
    ORG_UNIT_ID,
  })

  if (locations.results) {
    dispatch(replaceAllLocations(locations.results))
  }
})