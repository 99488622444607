import { faSquare, faSquareCheck } from '@fortawesome/pro-light-svg-icons'
import { Grid, MenuItem, styled } from '@mui/material'
import React, { ComponentProps, useCallback, useContext, useEffect, useState } from 'react'
import { Icon } from '../Icon/Icon'
import { MultiselectDropdownOption } from './MultiselectDropdown'
import { DropdownContext } from './MultiselectDropdownContext'


export type MultiselectDropdownItemProps = Omit<ComponentProps<typeof StyledDropdownItem>, 'disabled' | 'value'> & {
  children: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: React.MouseEventHandler<HTMLLIElement>
  disabled?: boolean
  value?: MultiselectDropdownOption
}

export const MultiselectDropdownItem: React.FC<MultiselectDropdownItemProps> = ({ children, value, disabled, onSelect, ...props }) => {
  const [isSelected, setSelected] = useState(false)
  const dropdownContext = useContext(DropdownContext)

  const onDropdownClick: React.MouseEventHandler<HTMLLIElement> = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()

    if (onSelect) {
      onSelect(event)
    }

    if (dropdownContext.onSelectItem && value) {
      dropdownContext.onSelectItem(value)
    }

    if (dropdownContext.setDropdownOpen) {
      dropdownContext.setDropdownOpen(false)
    }
  }, [dropdownContext, onSelect])

  useEffect(() => {
    if (dropdownContext.values && value) {
      setSelected(dropdownContext.values.find(item => item.name === value.name) !== undefined)
    }
  }, [dropdownContext.values])

  return (
    <StyledDropdownItem
      disableRipple
      disableTouchRipple
      disableGutters
      onClick={onDropdownClick}
      $disabled={disabled}
      $active={isSelected}
      {...props}
    >
      <Grid container alignItems={'center'}>
        <Grid item sx={{ display: 'flex' }}>
          {isSelected ? <StyledIcon icon={faSquareCheck} /> : <StyledIcon icon={faSquare} />}
        </Grid>
        <Grid item>
          {children}
        </Grid>
      </Grid>
    </StyledDropdownItem>
  )
}

const StyledIcon = styled(Icon)`
  margin-right: ${props => props.theme.typography.pxToRem(6)};
  font-size: ${props => props.theme.typography.pxToRem(16)};
`

const StyledDropdownItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== '$disabled' && prop !== '$active',
}) <{ $disabled?: boolean, $active?: boolean }>`
  padding: 0;
  margin: 0;
  z-index: 2;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${props => props.theme.palette.text.primary};
  padding: 0 ${(props) => props.theme.typography.pxToRem(16)};
  margin-bottom: ${(props) => props.theme.typography.pxToRem(8)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding: ${props => props.theme.typography.pxToRem(8)} ${props => props.theme.typography.pxToRem(16)};
  }
  
  &:hover {
    background-color: transparent;
  }
  
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: ${props => props.theme.palette.custom.button.textButtonHoverBackground};
    opacity: 0;
    transition: opacity 0.2s ease;

    ${props => props.theme.breakpoints.up('sm')} {
      top: ${props => props.theme.typography.pxToRem(-8)};
      bottom: ${props => props.theme.typography.pxToRem(-8)};
    }
  }

  &:hover {
    background-color: none !important;

    &:after {
      opacity: 1;
    }
  }

  ${(props) => props.$active && `
    &:after {
      opacity: 1;
    }
  `}

  ${(props) => props.$disabled && `
    pointer-events: none;
    
    &:after {
      opacity: 1;
      background-color: ${props.theme.palette.action.disabledBackground};
    }
  `}
`
