import { faClose } from '@fortawesome/pro-regular-svg-icons'
import { faPen } from '@fortawesome/pro-solid-svg-icons'
import { Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { Icon } from 'src/components/Icon/Icon'
import { useManagementTableContext } from 'src/components/ManagementTable/ManagementTableContext'
import { Modal } from 'src/components/Modal/Modal'
import { StyledCloseButton, StyledContainer, StyledEditButton, StyledEditIcon } from '../StyledDetailsFragment'
import { ThirdPartyForm } from '../ThirdPartyForm/ThirdPartyForm'


type ThirdPartyDetailsFragmentProps = {
  //
}

export const ThirdPartyDetailsFragment: React.FC<ThirdPartyDetailsFragmentProps> = () => {
  const [isEditThirdPartyFormOpen, setEditThirdPartyFormOpen] = useState<boolean>(false)
  const { selectedId, setSelectedId } = useManagementTableContext()

  return (
    <StyledContainer>
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <StyledEditButton text round onClick={() => { setEditThirdPartyFormOpen(true) }}>
            <Typography variant="h6">Details</Typography>
            <StyledEditIcon icon={faPen} />
          </StyledEditButton>
        </Grid>
        <Grid item>
          <StyledCloseButton onClick={() => setSelectedId(null)}>
            <Icon icon={faClose} />
          </StyledCloseButton>
        </Grid>
      </Grid>
      <ThirdPartyForm thirdPartyId={selectedId || ''} isEditForm editable={false} />

      <Modal title={'Edit Third Party Details'} open={isEditThirdPartyFormOpen} onClose={() => { setEditThirdPartyFormOpen(false) }} disabledBackdropClose>
        <ThirdPartyForm thirdPartyId={selectedId || ''} isEditForm editable onFormSubmitSuccess={() => setEditThirdPartyFormOpen(false)} />
      </Modal>
    </StyledContainer>
  )
}
