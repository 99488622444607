/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react'

export type DashboardContextType = {
  selectedBookingId: string | null
  setSelectedBookingId: React.Dispatch<React.SetStateAction<string | null>>
}

export const DashboardContext = createContext<DashboardContextType>({
  selectedBookingId: '',
  setSelectedBookingId: () => { },
})

export const useDashboardContext = () => {
  const context = useContext(DashboardContext)

  if (context === undefined) {
    throw new Error(
      'useDashboardContext must be used within a DashboardContext.Provider',
    )
  }

  return context
}