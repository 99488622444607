import { faArrowTrendDown, faArrowTrendUp } from '@fortawesome/pro-solid-svg-icons'
import { Grid } from '@mui/material'
import { StyledPaper, StyledTitleTypography, StyledDescriptionTypography, StyledNumberTypography, StyledTrendGrid, StyledTrendIcon, StyledPercentageTypography } from './StyledTrendCard'

export type TrendCardProps = {
  title: string
  description: string
  number: string
  numberPercentage: string
  trend: 'up' | 'down'
}

export const TrendCard: React.FC<TrendCardProps> = ({ title, description, number, numberPercentage, trend }) => {
  return (
    <StyledPaper>
      <StyledTitleTypography variant="body1">{title}</StyledTitleTypography>
      <StyledDescriptionTypography variant="body1">{description}</StyledDescriptionTypography>
      <Grid container sx={{ margin: 'auto' }} justifyContent={'center'} alignItems={'center'}>
        <Grid item>
          <StyledNumberTypography variant="body1">{number}</StyledNumberTypography>
        </Grid>
        <StyledTrendGrid item>
          <StyledTrendIcon $trend={trend} icon={trend === 'up' ? faArrowTrendUp : faArrowTrendDown} />
          <StyledPercentageTypography variant="body1">{numberPercentage}</StyledPercentageTypography>
        </StyledTrendGrid>
      </Grid>
    </StyledPaper>
  )
}
