import { MenuItem, styled } from '@mui/material'
import { ComponentProps } from 'react'

export type OutlinedMenuItemProps = ComponentProps<typeof MenuItem> & {
  active?: boolean
}

export const OutlinedMenuItem: React.FC<OutlinedMenuItemProps> = ({ active, ...props }) => {
  return (
    <StyledMenuItem disableRipple disableGutters $noPointerEvent={!props.onClick} $active={active} {...props} />
  )
}

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== '$active' && prop !== '$noPointerEvent',
}) <{ $active?: boolean, $noPointerEvent?: boolean }>`
  border-radius: ${props => props.theme.typography.pxToRem(8)};
  padding: ${props => props.theme.typography.pxToRem(12.5)} ${props => props.theme.typography.pxToRem(12)};
  height: ${props => props.theme.typography.pxToRem(48)};
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:focus {
    box-shadow: 0 0 0 2px ${(props) => props.theme.palette.background.default}, 0 0 0 4px ${(props) => props.theme.palette.custom.button.focusedOutline};
  }

  &:hover {
    background-color: ${props => props.theme.palette.action.hover};
  }
  
  .MuiSvgIcon-root {
    font-size: ${props => props.theme.typography.pxToRem(16)};
    color: ${props => props.theme.palette.primary.dark};
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    .MuiSvgIcon-root {
      opacity: 0;
    }
  }

  &:hover {
    .MuiSvgIcon-root {
      opacity: 1;
    }
  }
  
  ${(props) => props.$active && `
    background-color: ${props.theme.palette.action.hover};
  `}

  ${(props) => props.$noPointerEvent && `
    color: ${props.theme.palette.text.disabled};
    pointer-events: none;
    cursor: default;
  `}
`
