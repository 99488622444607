import React from 'react'

import { ButtonProps as BaseButtonProps } from '@mui/material'
import { StyledButton } from './StyledButton'

export type ButtonProps = Omit<BaseButtonProps, 'disableRipple' | 'variant'> & {
  primary?: boolean
  outlined?: boolean
  round?: boolean
  text?: boolean
}

// es-lint-disable-next-line @typescript-eslint/no-unused-vars
export const Button: React.FC<ButtonProps> = ({ round, primary, outlined, text, ...props }) => {
  const buttonProps: BaseButtonProps = props

  if (primary) {
    buttonProps.color = 'primary'
  } else if (outlined) {
    buttonProps.variant = 'outlined'
  } else if (text) {
    buttonProps.variant = 'text'
  } else {
    buttonProps.color = 'primary'
  }

  buttonProps.disableRipple = true

  return <StyledButton $round={round} {...buttonProps} />
}
