import { createEntityAdapter } from '@reduxjs/toolkit'
import { Attribute } from 'src/types/Attribute'
import { RootState } from '../store'

export const attributesAdapter = createEntityAdapter<Attribute>({
  selectId: (item) => item.ATTRIBUTE_ID,
})

export const attributesSelector = attributesAdapter.getSelectors<RootState>(
  (state) => state.attributes,
)
