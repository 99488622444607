
import { faCalendar, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/pro-solid-svg-icons'
import { styled, useMediaQuery, useTheme } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { Allotment } from 'allotment'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Alert } from 'src/components/Alert/Alert'
import { AnimatedPage } from 'src/components/AnimatedPage/AnimatedPage'
import { CustomAllotment } from 'src/components/CustomAllotment/CustomAllotment'
import { CustomAllotmentContext } from 'src/components/CustomAllotment/CustomAllotmentContext'
import { Icon } from 'src/components/Icon/Icon'
import { IconButton } from 'src/components/IconButton/IconButton'
import { Modal } from 'src/components/Modal/Modal'
import { VehicleSearchContext } from 'src/components/OperationsCalander/VehicleSearchContext'
import { BookingForm, CalendarFragment, VehicleInfoFragment } from 'src/fragments/Operations'
import { vehiclesSelectors } from 'src/store/adapters/vehicles'
import { setOperationActiveVehicleId } from 'src/store/reducers/settings'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupBookings } from 'src/store/thunks/setupBookings'
import { pxToRem } from 'src/styles/themes'
import {
  StyledAddBookingButton, StyledAddBookingMobileButton, StyledAllotmentPanel, StyledCalIcon, StyledChevron,
  StyledDate, StyledDateGrid, StyledDateSelector, StyledDateSelectorButton, StyledGridItem,
  StyledPaper,
  StyledToolbar,
} from './StyledOperation'


export const Operations = () => {
  const [dateAnchorEl, setDateAnchorEl] = useState<HTMLElement | null>(null)
  const [currentDate, setCurrentDate] = useState(dayjs())
  const [allotmentSizes, setAllotmentSizes] = useState<number[]>([0, 0])
  const [isAddBookingFormOpen, setAddBookingFormOpen] = useState(false)
  const [isEditBookingFormOpen, setEditBookingFormOpen] = useState(false)
  const [selectedBookingId, setSelectedBookingId] = useState<string | null>(null)
  const [vehicleSearchString, setVehicleSearchString] = useState<string>('')
  const [rowsPerPage, setRowsPerPage] = useState(10) //Pagination
  const [currentPage, setCurrentPage] = useState(0) //Pagination
  const [calendarHeight, setCalendarHeight] = useState<number | string>('100%') //Update height on pagination change
  const vehicles = useAppSelector((state) => vehiclesSelectors.selectAll(state)).filter((vehicle) => {
    return vehicle.vehicle_hidden === '0' && (vehicle.vehicle_rego_number.toLowerCase().includes(vehicleSearchString.toLowerCase()) ||
      vehicle.make_name.toLowerCase().includes(vehicleSearchString.toLowerCase()) ||
      vehicle.model_name.toLowerCase().includes(vehicleSearchString.toLowerCase()) ||
      vehicle.VEHICLE_ID.toLowerCase().includes(vehicleSearchString.toLowerCase()))
  })
  const activeOrgUnit = useAppSelector((state) => state.settings.activeOrgUnit)
  const { operationActiveVehicleId } = useAppSelector(state => state.settings)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.up('md'))
  const dispatch = useAppDispatch()

  // Fetch required data for the operation page
  useEffect(() => {
    dispatch(setupBookings({
      currentDate: currentDate,
    }))
  }, [currentDate, activeOrgUnit])

  const onPreviousDay = () => {
    setCurrentDate(prevDate => prevDate.subtract(1, 'day'))
  }

  const onNextDay = () => {
    setCurrentDate(prevDate => prevDate.add(1, 'day'))
  }

  // Set Active Vehicle
  const onActiveVehicleChange = (id: string | null) => {
    dispatch(setOperationActiveVehicleId(id))
  }

  // Open Add Booking Form
  const onAddBookingClick = () => {
    setAddBookingFormOpen(true)
  }

  // Open Update Booking Form
  const onEditBookingClick = (id: string) => {
    if (id) {
      setEditBookingFormOpen(true)
      setSelectedBookingId(id)
    }
  }

  // Resize the Paper height on Desktop
  useEffect(() => {
    if (vehicles) {
      const padding = isMobile ? 0 : 69

      if (vehicles.length < rowsPerPage) {
        setCalendarHeight(pxToRem(62 * (10 + 1) + padding))

        return
      }

      setCalendarHeight(pxToRem(62 * (rowsPerPage + 1) + padding))
    }
  }, [rowsPerPage, vehicles, isMobile])

  return (
    <AnimatedPage>
      <StyledContainer>
        <Alert />
        <StyledToolbar container justifyContent={'space-between'}>
          <StyledGridItem item xs={0} sm={4} />
          <StyledDateGrid item xs={12} sm={4}>
            <StyledDateSelector>
              <IconButton onClick={onPreviousDay}>
                <StyledChevron icon={faChevronCircleLeft} />
              </IconButton>
              <StyledDateSelectorButton text round onClick={(ev) => setDateAnchorEl(ev.currentTarget)}>
                <StyledDate variant="body1">
                  {currentDate.format('DD/MM/YYYY')}
                </StyledDate>
                <StyledCalIcon icon={faCalendar} />
              </StyledDateSelectorButton>
              <DatePicker
                open={Boolean(dateAnchorEl)}
                onClose={() => setDateAnchorEl(null)}
                defaultValue={null}
                value={dayjs(currentDate)}
                slotProps={{
                  popper: {
                    anchorEl: dateAnchorEl,
                  },
                }}
                onAccept={(date) => {
                  if (!date) return

                  setCurrentDate(date)
                }}
                slots={{
                  textField: () => null,
                }}
              />
              <IconButton onClick={onNextDay}>
                <StyledChevron icon={faChevronCircleRight} />
              </IconButton>
            </StyledDateSelector>
          </StyledDateGrid>
          <StyledGridItem item xs={0} sm={4}>
            <StyledAddBookingButton primary round startIcon={<Icon icon={faPlus} />} onClick={onAddBookingClick}>
              Add Booking
            </StyledAddBookingButton>
          </StyledGridItem>
        </StyledToolbar>
        <StyledPaper sx={{ height: calendarHeight }}>
          <CustomAllotmentContext.Provider value={{ allotmentSizes, setAllotmentSizes }}>
            <CustomAllotment>
              <StyledAllotmentPanel>
                <VehicleSearchContext.Provider value={{ vehicleSearchString, setVehicleSearchString }}>
                  <CalendarFragment
                    sx={{
                      borderRadius: !!operationActiveVehicleId ? `${pxToRem(20)} 0 0 ${pxToRem(20)}` : pxToRem(20),
                      border: 'none',
                    }}
                    currentDay={currentDate.format('YYYY-MM-DD')}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    onVehicleClick={onActiveVehicleChange}
                    onBookingClick={onEditBookingClick}
                    setCurrentPage={setCurrentPage}
                    setRowsPerPage={setRowsPerPage}
                  />
                </VehicleSearchContext.Provider>
              </StyledAllotmentPanel>
              {isTablet && operationActiveVehicleId && (
                <Allotment.Pane minSize={600}>
                  <StyledAllotmentPanel>
                    <VehicleInfoFragment onEditBookingClick={onEditBookingClick} currentDate={currentDate} />
                  </StyledAllotmentPanel>
                </Allotment.Pane>
              )}
            </CustomAllotment>
          </CustomAllotmentContext.Provider>
        </StyledPaper>

        <StyledAddBookingMobileButton primary round startIcon={<Icon icon={faPlus} />} onClick={onAddBookingClick}>
          Add Booking
        </StyledAddBookingMobileButton>

        <Modal title={'Add Booking'} open={isAddBookingFormOpen} onClose={() => { setAddBookingFormOpen(false) }} disabledBackdropClose>
          <BookingForm
            isEditForm={false}
            currentDate={currentDate}
            onFormSubmitSuccess={() => { setAddBookingFormOpen(false) }}
          />
        </Modal>
        <Modal title={'Update Booking'}
          open={isEditBookingFormOpen && !!selectedBookingId}
          onClose={() => {
            setEditBookingFormOpen(false)
            setTimeout(() => {
              setSelectedBookingId(null)
            }, 250)
          }}
          disabledBackdropClose
        >
          <BookingForm
            isEditForm
            bookingId={selectedBookingId ? selectedBookingId : ''}
            currentDate={currentDate}
            isReadOnly={false}
            onFormSubmitSuccess={() => { setSelectedBookingId(null) }}
          />
        </Modal>
        {!isTablet && (
          <Modal
            fullWidth
            open={!!operationActiveVehicleId}
            onClose={() => {
              dispatch(setOperationActiveVehicleId(null))
            }}
          >
            <VehicleInfoFragment onEditBookingClick={onEditBookingClick} currentDate={currentDate} />
          </Modal>
        )}
      </StyledContainer>
    </AnimatedPage>
  )
}

const StyledContainer = styled('div')`
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding: ${(props) => props.theme.typography.pxToRem(24)} ${(props) => props.theme.typography.pxToRem(36)};
  }
`