/* eslint-disable eqeqeq */

import { keyframes, styled } from '@mui/material/styles'

export const backgroundLightBoxOpen = keyframes`
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
`

export const backgroundLightBoxClose = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`


export const anchorBottomEnter = () => keyframes`
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`

export const anchorBottomExit = () => keyframes`
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
`

export const anchorLeftEnter = () => keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100vw, 0, 0);
    transform: translate3d(-100vw, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`

export const anchorRightEnter = () => keyframes`
  0% {
    -webkit-transform: translate3d(100vw, 0, 0);
    transform: translate3d(100vw, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`

export const anchorLeftExit = ($transform?: string) => keyframes`
  0% {
    -webkit-transform: ${$transform ?? 'translate3d(0, 0, 0)'};
    transform: ${$transform ?? 'translate3d(0, 0, 0)'};
  }

  100% {
    -webkit-transform: translate3d(-100vw, 0, 0);
    transform: translate3d(-100vw, 0, 0);
  }
`

export const anchorRightExit = ($transform?: string) => keyframes`
  0% {
    -webkit-transform: ${$transform ?? 'translate3d(0, 0, 0)'};
    transform: ${$transform ?? 'translate3d(0, 0, 0)'};
  }

  100% {
    -webkit-transform: translate3d(100vw, 0, 0);
    transform: translate3d(100vw, 0, 0);
  }
`

export const anchorLeftOut = keyframes`
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40% {
    -webkit-transform: translate3d(-10vw, 0, 0);
    transform: translate3d(-10vw, 0, 0);
  }
`

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mobileBrowserMenuEnterRecord: any = {
  '-1': anchorBottomEnter,
  0: anchorBottomEnter,
  1: anchorRightEnter,
  2: anchorRightEnter,
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mobileBrowserMenuExitRecord: any = {
  '-1': anchorBottomEnter,
  0: anchorBottomExit,
  1: anchorRightExit,
  2: anchorRightExit,
}

const getAnimationAnchorType = (isGlobalOpen: boolean, isOpen: boolean, level: number, $transform?: string) => {
  if (!isGlobalOpen) {
    return anchorBottomExit()
  }

  return isOpen ? mobileBrowserMenuEnterRecord[level]($transform) : mobileBrowserMenuExitRecord[level]($transform)
}

export const StyledListContainer = styled('ul')`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const StyledSliderNavigation = styled('div') <{ $isGlobalOpen: boolean; $isOpen: boolean; $isAnimateOut: boolean; $transform?: string; $level: number; $animationTime: number }>`
  display: block;
  position: fixed;
  top: ${props => (props.$level == -1) ? props.theme.typography.pxToRem(74) : props.theme.typography.pxToRem(128)};
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100dvh - ${props => (props.$level == -1) ? props.theme.typography.pxToRem(74) : props.theme.typography.pxToRem(128)});
  background: ${props => props.theme.palette.background.default};
  overflow-y: scroll !important;
  overscroll-behavior: none;
  transition: transform ${props => props.$animationTime - 100}ms ease-in-out;
  border-radius: ${props => props.$level == -1 ? props.theme.typography.pxToRem(16) : 0} ${props => props.$level == -1 ? props.theme.typography.pxToRem(16) : 0} 0 0;
  z-index: ${props => props.$level === -1 ? props.theme.zIndex.appBar + 299 : (props.theme.zIndex.appBar + 300) + props.$level * 100};
  animation: ${props => getAnimationAnchorType(props.$isGlobalOpen, props.$isOpen, props.$level, props.$transform)} ${props => props.$animationTime}ms ${props => props.$level == 0 ? 'cubic-bezier(0.18, 0.78, 0.43, 1.1)' : 'ease-in-out'};
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledModalBackground = styled('div') <{ $isGlobalOpen: boolean }>`
  position: fixed;
  z-index: ${props => props.theme.zIndex.appBar};
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  animation: ${props => props.$isGlobalOpen ? backgroundLightBoxOpen : backgroundLightBoxClose} 270ms ease-in-out;
`
