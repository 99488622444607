import { ButtonBase, Tooltip, Typography, styled, useTheme } from '@mui/material'
import { ComponentProps, MouseEventHandler } from 'react'
import { formatDateAndTime } from '../../libs/formatDateAndTime'
import { Booking } from './CalendarBookingRow'
import { useCalendarContext } from './CalenderContext'

export type CalendarBookingProps = Omit<ComponentProps<typeof ButtonBase>, 'onClick'> & {
  booking: Booking
  onClick?: () => void
  isOverlapped?: boolean // Use to shift the booking button down since it overlapped with a previous bookings
}

export const bookingColorMapping: Record<Booking['status'], { outline: string, background: string }> = {
  'success': {
    outline: '#78D1C8',
    background: '#D8F1EF',
  },
  'warning': {
    outline: '#E65100',
    background: '#F2BEA2',
  },
  'error': {
    outline: '#B55E5E',
    background: '#f5c9c9',
  },
  'cancelled': {
    outline: '#5E5E5E',
    background: '#CCCCCC',
  },
}

export const CalendarBooking: React.FC<CalendarBookingProps> = ({ booking, onClick, isOverlapped, ...props }) => {
  const theme = useTheme()
  const { currentDay, DAY_WIDTH } = useCalendarContext()
  const HOUR_WIDTH = DAY_WIDTH / 24
  const startFormatDateTime = formatDateAndTime(booking.startDate, booking.startHour, booking.startMinute)
  const endFormatDateTime = formatDateAndTime(booking.endDate, booking.endHour, booking.endMinute)
  const startFormatTime = formatDateAndTime(booking.startDate, booking.startHour, booking.startMinute, true)
  const endFormatTime = formatDateAndTime(booking.endDate, booking.endHour, booking.endMinute, true)

  let width = 0
  let left = 0
  let borderRadius = '0'
  const RADIUS = theme.typography.pxToRem(8)
  let displayTime = `${startFormatDateTime} - ${endFormatDateTime}`

  if (booking.startDate === booking.endDate) {
    // The booking starts and ends on the same day
    width = HOUR_WIDTH * (booking.endHour - booking.startHour) + (HOUR_WIDTH / 60 * (booking.endMinute - booking.startMinute))
    left = (HOUR_WIDTH / 60 * booking.startMinute)
    borderRadius = `${RADIUS} ${RADIUS} ${RADIUS} ${RADIUS}`
    displayTime = `${startFormatTime} - ${endFormatTime}`
  } else if (booking.startDate < currentDay && booking.endDate === currentDay) {
    // The bookings starts on a previous day and ends on the current day
    width = HOUR_WIDTH * booking.endHour + (HOUR_WIDTH / 60 * booking.endMinute)
    borderRadius = `0 ${RADIUS} ${RADIUS} 0`
    displayTime = `${startFormatDateTime} - ${endFormatTime}`
  } else if (booking.startDate === currentDay && booking.endDate > currentDay) {
    // The booking starts on the same day and ends on a future day
    left = (HOUR_WIDTH / 60 * booking.startMinute)
    width = DAY_WIDTH - left - HOUR_WIDTH * (booking.startHour)
    borderRadius = `${RADIUS} 0 0 ${RADIUS}`
    displayTime = `${startFormatTime} - ${endFormatDateTime}`
  } else if (booking.startDate < currentDay && booking.endDate > currentDay) {
    // The booking starts on previous day and ends on a futre day
    width = DAY_WIDTH
    left = 0
  }

  const tooltipMessage = booking.status === 'cancelled' ?
    (<>Member: {booking.customerName}<br />Status: This booking was cancelled</>) :
    (<>Member: {booking.customerName}<br />Date: {startFormatDateTime} - {endFormatDateTime}<br /></>)

  const onBookingClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (onClick) onClick()
  }

  return (
    <Tooltip
      title={tooltipMessage}
    >
      <StyledCalendarBooking
        {...props}
        disableRipple
        disabled={!onClick}
        $width={width}
        $left={left}
        $borderRadius={borderRadius}
        $isOverlapped={isOverlapped}
        $status={booking.status}
        onClick={onClick && onBookingClick}
      >
        <StyledDisplayTime>
          {displayTime}
        </StyledDisplayTime>
      </StyledCalendarBooking>
    </Tooltip>
  )
}

const StyledCalendarBooking = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== '$width' && prop !== '$left' && prop !== '$borderRadius' && prop !== '$isOverlapped' && prop !== '$status',
}) <{ $width: number, $left: number, $borderRadius: string, $isOverlapped?: boolean, $status?: Booking['status'] }>`
  display: flex;
  position: absolute;
  justify-content: flex-start;
  padding: ${props => props.theme.typography.pxToRem(8)};
  top: ${props => props.theme.typography.pxToRem(props.$isOverlapped ? 22 : 6)};
  left: ${props => props.theme.typography.pxToRem(props.$left)};
  z-index: 3;
  background-color: ${props => bookingColorMapping[props.$status || 'success']?.background};
  width: ${props => props.theme.typography.pxToRem(props.$width)};
  height: ${props => props.theme.typography.pxToRem(36)};
  border-radius: ${props => props.$borderRadius};
  overflow: hidden;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:focus {
    box-shadow: 0 0 0 2px ${(props) => props.theme.palette.background.default}, 0 0 0 4px ${props => bookingColorMapping[props.$status || 'success']?.outline};
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.1);
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
`

const StyledDisplayTime = styled(Typography)`
  white-space: nowrap;
  color: white;
  font-size: ${props => props.theme.typography.pxToRem(12)};
  font-weight: 500;
  color: ${props => props.theme.palette.text.primary};
`