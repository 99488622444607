import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { CustomerOrgUnit } from 'src/types/Customer'


type SetupPayload = {
  CUSTOMER_ID: string
}

export const setupCustomerOrgUnits = createAsyncThunk('customers/setupOrgUnits', async ({ CUSTOMER_ID }: SetupPayload) => {
  // Retrieve Customers
  const customers = await CarshareApiService.post<CustomerOrgUnit>('getCustomerOrgUnits', {
    CUSTOMER_ID,
  })

  if (customers?.results) {
    return customers.results
  }

  return []
})