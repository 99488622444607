import { Alert as MuiAlert, AlertProps as MuiAlertProps, styled } from '@mui/material'

import React from 'react'
import { useAlertContext } from './AlertProvider'


export type AlertProps = Omit<MuiAlertProps, 'variant'> & {
  //
}

// es-lint-disable-next-line @typescript-eslint/no-unused-vars
export const Alert: React.FC<AlertProps> = ({ ...props }) => {
  const { successMessage, setSuccessMessage, errorMessage, setErrorMessage, infoMessage, setInfoMessage } = useAlertContext()

  const onSuccessMessageClose = () => {
    setSuccessMessage(null)
  }

  const onInfoMessageClose = () => {
    setInfoMessage(null)
  }

  const onErrorMessageClose = () => {
    setErrorMessage(null)
  }

  return (
    <>
      {successMessage && (
        <StyledAlert severity="success" {...props} onClose={onSuccessMessageClose}>
          {successMessage}
        </StyledAlert>
      )}
      {errorMessage && (
        <StyledAlert severity="error" {...props} onClose={onErrorMessageClose}>
          {errorMessage}
        </StyledAlert>
      )}
      {infoMessage && (
        <StyledAlert severity="info" {...props} onClose={onInfoMessageClose}>
          {infoMessage}
        </StyledAlert>
      )}
    </>
  )
}

const StyledAlert = styled(MuiAlert)`
  ${(props) => props.theme.breakpoints.up('sm')} {
    margin: ${props => props.theme.typography.pxToRem(16)} 0;
    border-radius: ${props => props.theme.typography.pxToRem(8)};
  }
`