/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Grid } from '@mui/material'
import { ComponentProps, useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { TextField } from 'src/components/Form/TextField'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { StyledForm, StyledFormControl, StyledSubmitButton } from 'src/fragments/StyledForm'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { attributesSelector } from 'src/store/adapters/attributes'
import { addMultipleAttributes } from 'src/store/reducers/attributes'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { Attribute } from 'src/types/Attribute'

export type AttributeFormProps = ComponentProps<typeof StyledForm> & {
  onFormSubmitSuccess?: () => void
  isEditForm?: boolean
  editable?: boolean
  attributeId?: string
}

export const AttributeForm: React.FC<AttributeFormProps> = ({ onFormSubmitSuccess, isEditForm, editable, attributeId, ...props }) => {
  const [code, setCode] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [order, setOrder] = useState<number | null>(0)

  const selectedAttribute = useAppSelector(state => attributesSelector.selectById(state, attributeId || ''))

  const { loading, complete, errorMessage, request } = useApiRequest()
  const { setSuccessMessage } = useAlertContext()
  const dispatch = useAppDispatch()

  // Form submission handler
  const submitHandler = () => {
    const payload = {
      attribute_code: code,
      attribute_name: name,
      attribute_order: order,
      skipOrgUnit: true,
    }

    if (isEditForm) {
      const editPayload = {
        ...payload,
        ATTRIBUTE_ID: attributeId,
        skipOrgUnit: true,
      }

      return CarshareApiService.post<Attribute>('editAttribute', editPayload)
    }

    return CarshareApiService.post<Attribute>('addAttribute', payload)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    request(submitHandler, (attr) => {
      dispatch(addMultipleAttributes(attr))
    })
  }

  // Form initialization
  useEffect(() => {
    if (!isEditForm || !selectedAttribute) return

    setName(selectedAttribute.attribute_name || '')
    setCode(selectedAttribute.attribute_code || '')
    setOrder(selectedAttribute.attribute_order || null)
  }, [isEditForm, selectedAttribute, attributeId])

  // Form submission success effect
  useEffect(() => {
    if (errorMessage) return

    if (complete && onFormSubmitSuccess) {
      onFormSubmitSuccess()
    }
  }, [errorMessage, complete, onFormSubmitSuccess])

  useEffect(() => {
    if (complete) {
      setSuccessMessage(`Attribute ${name} was ${isEditForm ? 'updated' : 'created'} successfully.`)
    }
  }, [complete])

  return (
    <StyledForm onSubmit={onSubmit} $isEditable={editable} {...props}>
      <Grid container spacing={2} justifyContent={'center'} flexDirection={'column'}>
        <Grid item>
          {errorMessage && (
            <Alert severity={'error'} icon={false}>
              {errorMessage}
            </Alert>
          )}
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Attribute code</TextFieldLabel>
            <TextField
              id="attribute-code"
              aria-label="Attribute code"
              disabled={!editable}
              value={code}
              required
              onChange={(e) => {
                setCode(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Attribute name</TextFieldLabel>
            <TextField
              id="attribute-name"
              aria-label="Attribute name"
              disabled={!editable}
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Attribute order</TextFieldLabel>
            <TextField
              type="number"
              id="attribute-order"
              aria-label="Attribute order"
              disabled={!editable}
              value={order}
              required
              onChange={(e) => {
                setOrder(parseInt(e.target.value))
              }}
            />
          </StyledFormControl>
        </Grid>

        {editable && <Grid item>
          <StyledSubmitButton
            primary
            fullWidth
            type="submit"
            disabled={loading}
          >
            {isEditForm ? 'Edit' : 'Add'} Attribute
          </StyledSubmitButton>
        </Grid>}
      </Grid>
    </StyledForm >
  )
}
