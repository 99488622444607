import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { InputAdornment, styled } from '@mui/material'
import { ComponentProps } from 'react'
import { TextField } from '../Form/TextField'
import { Icon } from '../Icon/Icon'
import { useManagementTableContext } from './ManagementTableContext'

export type SearchBarProps = ComponentProps<typeof TextField> & {
  //
}

export const SearchBar: React.FC<SearchBarProps> = ({ ...props }) => {
  const { searchValue, setSearchValue } = useManagementTableContext()

  return setSearchValue ? (
    <StyledSearchBar
      round
      onChange={(e) => setSearchValue(e.target.value)}
      value={searchValue}
      placeholder={'Search...'}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <StyledIcon icon={faSearch} />
          </InputAdornment>
        ),
      }}
      {...props}
    >
    </StyledSearchBar>
  ) : null
}

const StyledIcon = styled(Icon)`
  font-size: ${(props) => props.theme.typography.pxToRem(18)};
  padding-left: ${(props) => props.theme.typography.pxToRem(14)};
`

const StyledSearchBar = styled(TextField)`
  min-width: 0;
  .Mui-focused .MuiSvgIcon-root {
    color: ${(props) => props.theme.palette.text.primary};
  }
`