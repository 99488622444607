import { debounce, useMediaQuery, useTheme } from '@mui/material'
import { useEffect } from 'react'
import { useMapContext } from '../Map/MapContext'
import { getClusterIcon } from './getClusterIcon'
import { MarkerDataType } from './models'


export type MapClusterProps = Omit<H.clustering.Provider.Options, 'clusteringOptions'> & {
  children?: React.ReactNode
  data: MarkerDataType[]

  // Allow custom popup bubble
  createDomBubble?: (data: MarkerDataType[]) => HTMLElement;
}

export const MapCluster: React.FC<MapClusterProps> = ({ data, createDomBubble, ...options }) => {
  const { map, ui } = useMapContext()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // Show Tooltip for cluster or noise point
  const showToolTip = (position: H.geo.Point, dataPoints: MarkerDataType[]) => {
    if (!createDomBubble) return

    const bubble = new H.ui.InfoBubble(position, {
      content: createDomBubble(dataPoints),
    })

    ui.getBubbles().forEach((bub) => {
      bub.close()
    })

    ui.addBubble(bubble)

    // Workaround for updating bubble position
    setTimeout(() => {
      const popupWindows = document.querySelectorAll('.H_ib_body')

      popupWindows.forEach(body => {
        if (body.querySelector('.noisePoint')) {
          (body as HTMLElement).style.bottom = '68px'
        } else {
          const clusterWeight = dataPoints.length
          const clusterRadius = Math.max(Math.min(clusterWeight * 5, 65), 20)
          const height = (body as HTMLElement).offsetHeight;

          (body as HTMLElement).style.bottom = -Math.min(height, 200) + 'px';
          (body as HTMLElement).style.right = `-${70 + clusterRadius}px`
        }

        (body as HTMLElement).style.opacity = '1'
      })
    }, 10)
  }

  // Setup Clusters and Noise Markers
  useEffect(() => {
    if (!map || !data || data.length === 0) return

    const dataPoints = data.map(item => new H.clustering.DataPoint(item.position.lat, item.position.lng, 1, item))
    const clusteredDataProvider = new H.clustering.Provider(dataPoints, {
      clusteringOptions: {
        eps: 32,
        minWeight: 2,
      },
      theme: {
        getClusterPresentation: (cluster: H.clustering.ICluster) => {
          // Get datapoints from the given cluster
          let isErrorType = false
          let isWarningType = false

          cluster.forEachDataPoint(dataPoint => {
            const pointType = dataPoint.getData().type || 'success'

            if (pointType === 'error') {
              isErrorType = true
            }

            if (pointType === 'warning') {
              isWarningType = true
            }
          })

          const clusterType = isErrorType ? 'error' : isWarningType ? 'warning' : 'success'

          // Use cluster weight to change the icon size
          const weight = cluster.getWeight()
          const radius = Math.max(Math.min(weight * 5, 55), 20)
          const svgString = getClusterIcon(weight, radius, clusterType)
          const clusterIcon = new H.map.DomIcon(svgString)

          const clusterMarker = new H.map.DomMarker(cluster.getPosition(), {
            icon: clusterIcon,

            // Set min/max zoom with values from the cluster, otherwise
            // clusters will be shown at all zoom levels
            min: cluster.getMinZoom(),
            max: cluster.getMaxZoom(),
            data: cluster,
          })

          return clusterMarker
        },
        getNoisePresentation: (noisePoint) => {
          const markerData = noisePoint.getData() as MarkerDataType
          const position = noisePoint.getPosition()
          let markerUrl = '/svgs/connect-green-map-icon.svg'

          if (markerData.type && markerData.type === 'warning') {
            markerUrl = '/svgs/connect-yellow-map-icon.svg'
          } else if (markerData.type && markerData.type === 'error') {
            markerUrl = '/svgs/connect-red-map-icon.svg'
          }

          const iconMarker: H.map.Icon = new H.map.Icon(markerUrl)

          const noiseMarker = new H.map.Marker(position, {
            icon: iconMarker,
            min: noisePoint.getMinZoom(),
            data: noisePoint,
          })

          return noiseMarker
        },
      },
      ...options,
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onMarkerTap = (event: any) => {
      const marker = event.target
      const point = marker.getData()
      const isCluster = point.isCluster()

      if (isCluster) {
        // Tapped a Cluster
        const position = point.getPosition()
        const clusterData: MarkerDataType[] = []

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        point.forEachDataPoint((dataPoint: any) => {
          clusterData.push(dataPoint.getData())
        })

        // Show Tooltip for cluster
        showToolTip(position, clusterData)
      } else {
        // Tapped a Noise Point
        const position = point.getPosition()
        const noiseData = point.getData()

        // Show Tooltip for noise point
        showToolTip(position, [noiseData])
      }
    }

    clusteredDataProvider.addEventListener('tap', onMarkerTap)

    const clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider)

    map.addLayer(clusteringLayer)

    return () => {
      map.removeLayer(clusteringLayer)
      clusteringLayer.dispose()
      clusteredDataProvider.removeEventListener('tap', onMarkerTap)
      clusteredDataProvider.dispose()
    }
  }, [map, data])


  // Adjust Map Bounds to fit all markers
  const adjustBoundsDebounced = debounce((isInstant?: boolean) => {
    if (!map || !data || data.length === 0) return

    let minLat = data[0].position.lat
    let maxLat = data[0].position.lat
    let minLng = data[0].position.lng
    let maxLng = data[0].position.lng

    data.map((point) => {
      minLat = Math.min(minLat, point.position.lat)
      maxLat = Math.max(maxLat, point.position.lat)
      minLng = Math.min(minLng, point.position.lng)
      maxLng = Math.max(maxLng, point.position.lng)
    })

    const boundingBox = new H.geo.Rect(minLat, minLng, maxLat, maxLng)
    const isSinglePoint = minLat === maxLat && minLng === maxLng
    const singlePointZoomLevel = isSinglePoint ? 14 : undefined

    map.getViewPort().setPadding(75, 25, 25, 25)
    map.getViewModel().setLookAtData({
      bounds: boundingBox,
      zoom: data.length === 1 ? 14 : singlePointZoomLevel,
    }, isInstant ? false : 1.4)
  }, 150)

  useEffect(() => {
    if (map && data && data.length > 0) {
      adjustBoundsDebounced(!isMobile)

      return () => {
        adjustBoundsDebounced.clear()
      }
    }
  }, [data, map, isMobile])

  return null
}

