import { faBuilding, faCar, faUserGear } from '@fortawesome/pro-solid-svg-icons'
import { Grid } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { AnimatedPage } from 'src/components/AnimatedPage/AnimatedPage'
import { SubNavItem } from 'src/components/SubNavSidebar/SubNavItem'
import { StyledAlert, StyledContainer, StyledSubNavSidebar } from './StyledManagement'

export type ManagementProps = {
  //
}

export const Management: React.FC<ManagementProps> = () => {
  return (
    <Grid container flexDirection={'row'} wrap={'nowrap'}>
      <Grid item>
        <StyledSubNavSidebar>
          <SubNavItem
            title={'Customers'}
            href="/dashboard/management/customers"
            description="Customers are associated with one or more org units."
            icon={faUserGear} />
          <SubNavItem
            title={'Locations'}
            href="/dashboard/management/locations/"
            description="Locations are depots where vehicles may be picked up or dropped off from."
            icon={faBuilding} />
          <SubNavItem
            title={'Vehicles'}
            href="/dashboard/management/vehicles/"
            description="Vehicles are associated with an org unit and may be moved between an org unit's locations."
            icon={faCar} />
        </StyledSubNavSidebar>
      </Grid>
      <Grid item sx={{ flexGrow: 1 }}>
        <StyledContainer>
          <StyledAlert />
          <AnimatedPage>
            <Outlet />
          </AnimatedPage>
        </StyledContainer>
      </Grid>
    </Grid>
  )
}

