import { Grid, Typography, styled } from '@mui/material'
import { Button } from 'src/components/Button/Button'
import { Icon } from 'src/components/Icon/Icon'
import { Paper } from 'src/components/Paper/Paper'

export const StyledToolbar = styled(Grid)`
  height: ${props => props.theme.typography.pxToRem(48)};
  margin-top: ${props => props.theme.typography.pxToRem(16)};
  margin-bottom: ${props => props.theme.typography.pxToRem(16)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-top: 0;
    margin-bottom: ${props => props.theme.typography.pxToRem(16)};
  }
`

export const StyledPaper = styled(Paper)`
  width: 100%;
  height: 100%;
  border-top: 1px solid ${props => props.theme.palette.divider};

  ${props => props.theme.breakpoints.up('sm')} {
    border-top: unset;
  }
`

export const StyledDateSelector = styled('div')`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

export const StyledChevron = styled(Icon)`
  font-size: ${props => props.theme.typography.pxToRem(24)};
`

export const StyledDateSelectorButton = styled(Button)`
  margin: 0 ${props => props.theme.typography.pxToRem(16)};

  ${props => props.theme.breakpoints.up('md')} {
    margin: 0 ${props => props.theme.typography.pxToRem(24)};
  }
`

export const StyledDate = styled(Typography)`
  font-weight: 600;
  font-size: ${props => props.theme.typography.pxToRem(20)};
  min-width: ${props => props.theme.typography.pxToRem(130)};
  margin-right: ${props => props.theme.typography.pxToRem(6)};
  text-align: center;

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(28)};
    margin-right: ${props => props.theme.typography.pxToRem(-4)};
    min-width: ${props => props.theme.typography.pxToRem(200)};
  }
`

export const StyledCalIcon = styled(Icon)`
  font-size: ${props => props.theme.typography.pxToRem(18)};
  margin-bottom: ${props => props.theme.typography.pxToRem(2)};

  ${props => props.theme.breakpoints.up('md')} {
    font-size: ${props => props.theme.typography.pxToRem(22)};
  }
`

export const StyledAddBookingButton = styled(Button)`
  float: right;
  display: none;

  ${props => props.theme.breakpoints.up('sm')} {
    display: inline-flex;
  }
`

export const StyledAddBookingMobileButton = styled(Button)`
  position: fixed;
  display: inline-flex;
  z-index: 10;
  right: ${props => props.theme.typography.pxToRem(24)};
  bottom: ${props => props.theme.typography.pxToRem(32)};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
 
  @media all and (display-mode: standalone) {
    margin-bottom: ${(props) => props.theme.typography.pxToRem(36)};
  }
  
  ${props => props.theme.breakpoints.up('sm')} {
    display: none;
  }
`

export const StyledGridItem = styled(Grid)`
  display: none;

  ${props => props.theme.breakpoints.up('sm')} {
    display: unset;
  }
`

export const StyledDateGrid = styled(Grid)`
  display: flex;
  justify-content: center;
`

export const StyledAllotmentPanel = styled('div')`
  height: 100%;
`

export const StyledHideMobile = styled('div')`
  display: none;

  ${props => props.theme.breakpoints.up('sm')} {
    display: unset;
  }
`
