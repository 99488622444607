
import React, { useEffect, useMemo, useState } from 'react'

import { createPortal } from 'react-dom'
import { MAX_NAVITEM_PER_LEVEL, MAX_NUM_SUBLEVEL, MobileSliderContext, MobileSliderContextType } from './MobileSliderContext'
import { StyledListContainer, StyledModalBackground, StyledSliderNavigation } from './StyledNavigator'

export type MobileSliderWrapperProps = {
  children: React.ReactNode
  portal: string
  isOpen: boolean
  onClose: () => void
}

export const MobileSliderWrapper: React.FC<MobileSliderWrapperProps> = ({ isOpen, onClose, children, portal = '#browser-menu' }) => {
  const [menuState, setMenuState] = useState<boolean[]>(Array.from<boolean>({ length: 1 + MAX_NUM_SUBLEVEL * MAX_NAVITEM_PER_LEVEL }).fill(false))
  const [menuStack, setMenuStack] = useState<[number, number][]>([])
  const [portalElement, setPortalElement] = useState<Element | null>(null)
  const [shouldRender, setShouldRender] = useState(isOpen)

  // get portal
  useEffect(() => {
    const portalEl = document.querySelector(portal)

    setPortalElement(portalEl)
  }, [setPortalElement, portal])

  const mobileMenuContext: MobileSliderContextType = useMemo(() => ({
    portal: portalElement,
    menuState,
    menuStack,
    setMenuStack,
    setMenuState,
    getItemIndex: (level: number, idx: number) => (MAX_NAVITEM_PER_LEVEL * Math.max(0, (level - 1)) + idx),
    setMenuOpen: (level: number, idx: number) => {
      const newMenuState = [...menuState]

      newMenuState[MAX_NAVITEM_PER_LEVEL * level + idx] = true
      setMenuState(newMenuState)

      if (menuStack[menuStack.length - 1]?.[0] === level && menuStack[menuStack.length - 1]?.[1] === idx) {
        return
      }

      setMenuStack(prev => [
        ...prev,
        [level, idx],
      ])
    },
    setMenuClose: (level: number, idx: number) => {
      const newMenuState = [...menuState]

      newMenuState[MAX_NAVITEM_PER_LEVEL * Math.max(0, (level - 1)) + idx] = false
      setMenuState(newMenuState)
      setMenuStack(prev => prev.slice(0, -1))
    },
  }), [menuState, portal, portalElement])

  // Allow for animation when opening and closing before completely unmounting
  useEffect(() => {
    if (isOpen) {
      setShouldRender(isOpen)
    } else {
      const animateTimeout = setTimeout(() => {
        setShouldRender(isOpen)
      }, 130)

      return () => {
        clearTimeout(animateTimeout)
      }
    }
  }, [isOpen])


  // Reset menu state when initial open or global close
  useEffect(() => {
    if (isOpen) {
      mobileMenuContext.setMenuOpen(0, 0)
    } else {
      setMenuState(Array.from<boolean>({ length: 1 + MAX_NUM_SUBLEVEL * MAX_NAVITEM_PER_LEVEL }).fill(false))
      setMenuStack([])
    }
  }, [isOpen])

  const navigationSlider = (
    <StyledSliderNavigation
      $isGlobalOpen={isOpen}
      $isOpen={isOpen}
      $isAnimateOut={false}
      $level={-1}
      $animationTime={140}
      data-scroll-lock-scrollable
    >
      <StyledListContainer>
        {children}
      </StyledListContainer>
    </StyledSliderNavigation>
  )

  return (
    <>
      <MobileSliderContext.Provider value={mobileMenuContext}>
        {shouldRender && portalElement && (
          createPortal(navigationSlider, portalElement)
        )}
      </MobileSliderContext.Provider>
      {isOpen && <StyledModalBackground onClick={onClose} $isGlobalOpen={isOpen} />}
    </>
  )
}
