/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Checkbox, FormControlLabel, Grid } from '@mui/material'
import { ComponentProps, useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { StyledForm, StyledAccountSubmitButton } from 'src/fragments/StyledForm'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupAccount } from 'src/store/thunks/setupAccount'

export type NotificationFormProps = ComponentProps<typeof StyledForm> & {
  onFormSubmitSuccess?: () => void
}

export const NotificationForm: React.FC<NotificationFormProps> = ({ onFormSubmitSuccess, ...props }) => {
  const [preferenceLateCheckIns, setPreferenceLateCheckIns] = useState<boolean>(false)
  const [preferenceLateCheckOuts, setPreferenceLateCheckOuts] = useState<boolean>(false)
  const [preferenceCancellations, setPreferenceCancellations] = useState<boolean>(false)
  const [preferenceAccidents, setPreferenceAccidents] = useState<boolean>(false)

  const user = useAppSelector((state) => state.user)

  const { loading, complete, errorMessage, request } = useApiRequest()
  const { setSuccessMessage } = useAlertContext()
  const dispatch = useAppDispatch()

  const submitHandler = () => {
    const payload = {
      preference_notifications_late_check_ins: preferenceLateCheckIns ? '1' : '0',
      preference_notifications_late_check_outs: preferenceLateCheckOuts ? '1' : '0',
      preference_notifications_cancellations: preferenceCancellations ? '1' : '0',
      preference_notifications_accidents: preferenceAccidents ? '1' : '0',
    }

    return CarshareApiService.post('updateTeamMemberPreferences', payload)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    request(submitHandler)
  }

  useEffect(() => {
    if (user && user.preference) {
      setPreferenceLateCheckIns(user.preference.preference_notifications_late_check_ins === '1')
      setPreferenceLateCheckOuts(user.preference.preference_notifications_late_check_outs === '1')
      setPreferenceCancellations(user.preference.preference_notifications_cancellations === '1')
      setPreferenceAccidents(user.preference.preference_notifications_accidents === '1')
    }
  }, [])

  useEffect(() => {
    if (errorMessage) return

    if (complete && onFormSubmitSuccess) {
      onFormSubmitSuccess()
    }
  }, [errorMessage, complete, onFormSubmitSuccess])

  useEffect(() => {
    if (complete) {
      setSuccessMessage('Notification settings were updated successfully.')
      dispatch(setupAccount())
    }
  }, [complete])

  return (
    <StyledForm onSubmit={onSubmit} $isEditable={false} {...props}>
      <Grid container spacing={2} justifyContent={'center'} flexDirection={'column'}>
        <Grid item>
          {errorMessage && (
            <Alert severity={'error'} icon={false}>
              {errorMessage}
            </Alert>
          )}
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Checkbox checked={preferenceLateCheckIns} onChange={(event) => { setPreferenceLateCheckIns(event.target.checked) }} />}
            label="Show notifications for late check-ins"
            labelPlacement="end"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Checkbox checked={preferenceLateCheckOuts} onChange={(event) => { setPreferenceLateCheckOuts(event.target.checked) }} />}
            label="Show notifications for late check-outs"
            labelPlacement="end"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Checkbox checked={preferenceCancellations} onChange={(event) => { setPreferenceCancellations(event.target.checked) }} />}
            label="Show notifications for cancellations"
            labelPlacement="end"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Checkbox checked={preferenceAccidents} onChange={(event) => { setPreferenceAccidents(event.target.checked) }} />}
            label="Show notifications for accidents"
            labelPlacement="end"
          />
        </Grid>
        <Grid item>
          <StyledAccountSubmitButton
            primary
            type="submit"
            disabled={loading}
          >
            Update Notifications
          </StyledAccountSubmitButton>
        </Grid>
      </Grid>
    </StyledForm >
  )
}
