import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { Grid, MenuList, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import { ComponentProps } from 'react'
import { Button } from '../Button/Button'
import { Icon } from '../Icon/Icon'
import { useManagementTableContext } from './ManagementTableContext'
import { SearchBar } from './SearchBar'

export type ManagementTableProps = Omit<ComponentProps<typeof Grid>, 'container'> & {
  title: string
  subtitle?: string
  children: React.ReactNode
  onAddClick?: () => void
}

export const ManagementTable: React.FC<ManagementTableProps> = ({ title, subtitle, onAddClick, children, ...props }) => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'))
  const { setSearchValue } = useManagementTableContext()

  return (
    <Grid container flexDirection={'column'} justifyContent={'flex-start'} wrap={'nowrap'} {...props}>
      <Grid item>
        <TableNameTypography variant="h1">
          {title}
        </TableNameTypography>
        {subtitle && <TableItemCountTypography variant="body2">
          {subtitle}
        </TableItemCountTypography>}
      </Grid>
      <StyledSearchbarGrid item>
        <Grid container flexDirection={'row'} spacing={2} wrap="nowrap">
          {setSearchValue && <Grid item flexGrow={1}>
            <SearchBar />
          </Grid>}
          {onAddClick && <Grid item>
            <Button primary round startIcon={<Icon icon={faPlus} />} onClick={onAddClick}>
              Add {isTablet && title}
            </Button>
          </Grid>}
        </Grid>
      </StyledSearchbarGrid>
      <Grid item>
        <MenuList>
          {children}
        </MenuList>
      </Grid>
    </Grid>
  )
}

const TableNameTypography = styled(Typography)`
  font-weight: 500;
  font-size: ${(props) => props.theme.typography.pxToRem(20)};
`

const TableItemCountTypography = styled(Typography)`
  margin-top: ${(props) => props.theme.typography.pxToRem(4)};
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
  color: ${(props) => props.theme.palette.text.secondary};
`

const StyledSearchbarGrid = styled(Grid)`
  margin-top: ${(props) => props.theme.typography.pxToRem(16)};
`