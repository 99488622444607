import { useMediaQuery, useTheme } from '@mui/material'
import { Allotment } from 'allotment'
import { useEffect, useState } from 'react'
import { CustomAllotment } from 'src/components/CustomAllotment/CustomAllotment'
import { ManagementTableContext } from 'src/components/ManagementTable/ManagementTableContext'
import { TableMenuItem } from 'src/components/ManagementTable/TableMenuItem'
import { Modal } from 'src/components/Modal/Modal'
import { AttributesDetailsFragment } from 'src/fragments/Settings/AttributesDetailsFragment/AttributesDetailsFragment'
import { AttributeForm } from 'src/fragments/Settings/AttributesForm/AttributesForm'
import { attributesSelector } from 'src/store/adapters/attributes'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupAttributes } from 'src/store/thunks/setupAttributes'
import { Attribute } from 'src/types/Attribute'
import { StyledAllotmentPanel, StyledManagementTable, StyledPaper } from './StyledSettings'

export type SettingsAttributesProps = {
  //
}

export const SettingsAttributes: React.FC<SettingsAttributesProps> = () => {
  const [selectedId, setSelectedId] = useState<string | null>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const [isAddAttributeFormOpen, setAddAttributeFormOpen] = useState(false)
  const attributes = useAppSelector(state => attributesSelector.selectAll(state))
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'))
  const dispatch = useAppDispatch()

  const tableContextValue = {
    selectedId,
    setSelectedId,
    searchValue,
    setSearchValue,
  }

  const searchFilter = (attribute: Attribute) => {
    const searchTerm = searchValue.toLowerCase()

    return attribute.attribute_name.toLowerCase().includes(searchTerm) || attribute.attribute_code.toLowerCase().includes(searchTerm)
  }

  useEffect(() => {
    dispatch(setupAttributes())
  }, [])

  return (
    <StyledPaper>
      <ManagementTableContext.Provider value={tableContextValue}>
        <CustomAllotment>
          <Allotment.Pane minSize={350}>
            <StyledAllotmentPanel >
              <StyledManagementTable
                title={'Attributes'}
                subtitle={`${attributes.length} attributes`}
                onAddClick={() => { setAddAttributeFormOpen(true) }}
              >
                {attributes.filter(searchFilter).map((attr) => (
                  <TableMenuItem
                    active={selectedId === attr.ATTRIBUTE_ID}
                    key={attr.ATTRIBUTE_ID}
                    onClick={() => {
                      setSelectedId((prevId) => prevId === attr.ATTRIBUTE_ID ? null : attr.ATTRIBUTE_ID)
                    }}
                  >
                    {attr.attribute_name}
                  </TableMenuItem>
                ))}
              </StyledManagementTable>
            </StyledAllotmentPanel>
          </Allotment.Pane>
          {isTablet && selectedId && (
            <Allotment.Pane minSize={350}>
              <StyledAllotmentPanel>
                <AttributesDetailsFragment />
              </StyledAllotmentPanel>
            </Allotment.Pane>
          )}
        </CustomAllotment>
        <Modal title={'Add Attribute'} open={isAddAttributeFormOpen} onClose={() => { setAddAttributeFormOpen(false) }} disabledBackdropClose>
          <AttributeForm editable isEditForm={false} onFormSubmitSuccess={() => { setAddAttributeFormOpen(false) }} />
        </Modal>
        {!isTablet && (
          <Modal
            fullWidth
            open={!!selectedId}
          >
            <AttributesDetailsFragment />
          </Modal>
        )}
      </ManagementTableContext.Provider>
    </StyledPaper>
  )
}

