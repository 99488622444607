export const generateHourArray = () => {
  const times = []

  for (let i = 0; i < 24; i++) {
    let time12HourFormat
    const time24HourFormat = `${i.toString().padStart(2, '0')}`

    if (i === 0) {
      time12HourFormat = '12 AM'
    } else if (i < 12) {
      time12HourFormat = `${i} AM`
    } else if (i === 12) {
      time12HourFormat = '12 PM'
    } else {
      time12HourFormat = `${i - 12} PM`
    }

    times.push({ time12HourFormat, time24HourFormat })
  }

  return times
}

export const to12HourFormat = (hour: number | string) => {
  let time12HourFormat

  hour = +hour

  if (hour === 0) {
    time12HourFormat = '12 AM'
  } else if (hour < 12) {
    time12HourFormat = `${hour} AM`
  } else if (hour === 12) {
    time12HourFormat = '12 PM'
  } else {
    time12HourFormat = `${hour - 12} PM`
  }

  return time12HourFormat
}
