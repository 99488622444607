import { combineReducers, createAction } from '@reduxjs/toolkit'
import { reducer as attributes, initialState as attributesInitialState } from './attributes'
import { reducer as bookings, initialState as bookingsInitialState } from './bookings'
import { reducer as customers, initialState as customersInitialState } from './customers'
import { reducer as locations, initialState as locationsInitialState } from './locations'
import { reducer as members, initialState as membersInitialState } from './members'
import { reducer as orgUnits, initialState as orgUnitsInitalState } from './orgUnits'
import { reducer as passengers, initialState as passengersInitialState } from './passengers'
import { reducer as settings, initialState as settingsInitialState } from './settings'
import { reducer as setup, initialState as setupInitalState } from './setup'
import { reducer as thirdParties, initialState as thirdPartiesInitialState } from './thirdParties'
import { reducer as user, initialState as userInitialState } from './user'
import { reducer as vehicles, initialState as vehiclesInitialState } from './vehicles'


export const appReducer = combineReducers({
  setup,
  user,
  settings,
  bookings,
  vehicles,
  orgUnits,
  customers,
  locations,
  members,
  thirdParties,
  attributes,
  passengers,
})

export const RESET_STATE = 'RESET_STATE'

export const rootReducer: typeof appReducer = (state, action) => {
  if (action.type === RESET_STATE) {
    return appReducer({
      setup: setupInitalState,
      user: userInitialState,
      settings: settingsInitialState,
      bookings: bookingsInitialState,
      vehicles: vehiclesInitialState,
      orgUnits: orgUnitsInitalState,
      customers: customersInitialState,
      locations: locationsInitialState,
      members: membersInitialState,
      thirdParties: thirdPartiesInitialState,
      attributes: attributesInitialState,
      passengers: passengersInitialState,
    }, action)
  }

  return appReducer(state, action)
}

export const resetState = createAction(RESET_STATE)

export default rootReducer
