import { faClose } from '@fortawesome/pro-regular-svg-icons'
import { Box, Grid, Tab, Tabs, Typography, styled } from '@mui/material'
import { Dayjs } from 'dayjs'
import { useState } from 'react'
import { Icon } from 'src/components/Icon/Icon'
import { IconButton } from 'src/components/IconButton/IconButton'
import { TabPanel } from 'src/components/Tabs/TabPanel'
import { vehiclesSelectors } from 'src/store/adapters/vehicles'
import { setOperationActiveVehicleId } from 'src/store/reducers/settings'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { VehicleBookingTableFragment } from './VehicleBookingTableFragment'
import { VehicleHistoryFragment } from './VehicleHistoryFragment'
import { VehicleSummaryFragment } from './VehicleSummaryFragment'


type VehicleInfoFragmentProps = {
  onEditBookingClick?: (id: string) => void
  currentDate: Dayjs
}

export const VehicleInfoFragment: React.FC<VehicleInfoFragmentProps> = ({ onEditBookingClick, currentDate }) => {
  const operationActiveVehicleId = useAppSelector((state) => state.settings.operationActiveVehicleId) || ''
  const activeVehicle = useAppSelector((state) => vehiclesSelectors.selectById(state, operationActiveVehicleId))
  const customerType = useAppSelector(state => state.user.ui_type) || ''
  const isWaipa = customerType === 'WAIPA'

  const [activeTab, setActiveTab] = useState(1)
  const dispatch = useAppDispatch()

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  const onCloseRightPanel = () => {
    dispatch(setOperationActiveVehicleId(null))
  }

  return (
    <Container>
      <Grid container justifyContent={'space-between'}>
        <Grid item sx={{ maxWidth: '85%' }}>
          <TitleContainer>
            {activeVehicle && (
              <>
                <StyledHeaderTypography variant="h6">
                  {`${activeVehicle.make_name} ${activeVehicle.model_name} `}
                </StyledHeaderTypography>
                <StyledVehicleRego>
                  &bull; {activeVehicle.vehicle_rego_number}
                </StyledVehicleRego>
              </>
            )}
          </TitleContainer>
        </Grid>
        <Grid item>
          <StyledCloseButton onClick={onCloseRightPanel}>
            <Icon icon={faClose} />
          </StyledCloseButton>
        </Grid>
      </Grid>
      <StyledTabsContainer>
        <StyledTabs value={activeTab} onChange={handleChange} aria-label="vehicle tabs">
          {!isWaipa && <StyledTab value={0} disableRipple label="Summary" />}
          <StyledTab value={1} disableRipple label="Schedule" />
          {!isWaipa && <StyledTab value={3} disableRipple label="History" />}
        </StyledTabs>
      </StyledTabsContainer>
      {!isWaipa && <TabPanel value={activeTab} index={0}>
        <VehicleSummaryFragment />
      </TabPanel>}
      <TabPanel value={activeTab} index={1}>
        <VehicleBookingTableFragment onEditBookingClick={onEditBookingClick} currentDate={currentDate} />
      </TabPanel>
      {!isWaipa && <TabPanel value={activeTab} index={3}>
        <VehicleHistoryFragment currentDate={currentDate} />
      </TabPanel>}
    </Container>
  )
}


const StyledTabsContainer = styled(Box)`
  width: calc(100% + ${props => props.theme.typography.pxToRem(32)});
  margin-top: ${props => props.theme.typography.pxToRem(16)};
  margin-left: ${props => props.theme.typography.pxToRem(-16)};
  margin-right: ${props => props.theme.typography.pxToRem(-16)};
`

const Container = styled(Grid)`
  height: 100%;
  padding: ${props => props.theme.typography.pxToRem(16)};
`

const TitleContainer = styled('span')`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-left: ${props => props.theme.typography.pxToRem(-16)};
  margin-right: ${props => props.theme.typography.pxToRem(-16)};
  wrap: nowrap;

  ${props => props.theme.breakpoints.up('sm')} {
    margin-left: unset;
    margin-right: unset;
  }
`

const StyledHeaderTypography = styled(Typography)`
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props => props.theme.breakpoints.up('sm')} {
    width: unset;
  }
`

const StyledVehicleRego = styled(Typography)`
  margin-left: ${props => props.theme.typography.pxToRem(4)};
  font-weight: 400;
  font-size: ${props => props.theme.typography.pxToRem(14)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledTabs = styled(Tabs)`
  margin-left: ${props => props.theme.typography.pxToRem(-20)};
  margin-right: ${props => props.theme.typography.pxToRem(-20)};
  border-bottom: 1px solid ${props => props.theme.palette.divider};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-left: unset;
    margin-right: unset;
  }
  
  .MuiTabs-indicator {
    width: ${props => props.theme.typography.pxToRem(32)};
  }

  .MuiButtonBase-root.MuiTab-root {
    max-width: unset;
  }
`

const StyledTab = styled(Tab)`
  width: calc(100%/3);
  font-weight: 400;
  font-size: ${props => props.theme.typography.pxToRem(16)};
  text-transform: none;
`

const StyledCloseButton = styled(IconButton)`
  margin-top: ${props => props.theme.typography.pxToRem(-12)};
  margin-right: ${props => props.theme.typography.pxToRem(-12)};
`
