import { IconButton as MuiIconButton, Tooltip, styled } from '@mui/material'
import { ComponentProps } from 'react'

export type IconButtonProps = ComponentProps<typeof MuiIconButton> & {
  primary?: boolean
  tooltipTitle?: string
}

export const IconButton: React.FC<IconButtonProps> = ({ primary, tooltipTitle, ...props }) => {
  return (
    <Tooltip title={tooltipTitle || ''}>
      <StyledIconButton
        $primary={primary}
        disableRipple
        {...props}
      />
    </Tooltip>
  )
}

const StyledIconButton = styled(MuiIconButton, {
  shouldForwardProp: (prop) => prop !== '$primary',
}) <{ $primary: boolean | undefined }>`
  color: ${(props) => props.theme.palette.text.primary};
  width: ${(props) => props.theme.typography.pxToRem(48)};
  height: ${(props) => props.theme.typography.pxToRem(48)};
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  
  &:hover {
    background-color: ${(props) => props.theme.palette.custom.button.textButtonHoverBackground};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${(props) => props.theme.palette.background.default}, 0 0 0 4px ${(props) => props.theme.palette.custom.button.focusedOutline};
  }

  ${(props) => props.$primary && `
    color: ${props.theme.palette.custom.button.primaryText};
    background-color: ${props.theme.palette.custom.button.primaryBackground};

    &:hover {
      background-color: ${props.theme.palette.custom.button.primaryBackgroundHover};
    }
  `}
`