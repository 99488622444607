import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Passenger } from 'src/types/Passenger'
import { passengersAdapter } from '../adapters/passengers'

export const initialState = passengersAdapter.getInitialState()

export const { reducer, actions } = createSlice({
  name: 'passengers',
  initialState,
  reducers: {
    addMultiplePassengers: (state, action: PayloadAction<Passenger[]>) => {
      passengersAdapter.upsertMany(state, action.payload)
    },
    replaceAllPassengers: (state, action: PayloadAction<Passenger[]>) => {
      passengersAdapter.setAll(state, action.payload)
    },

  },
})

export const { addMultiplePassengers, replaceAllPassengers } = actions