import { ThemeOptions, createTheme, css } from '@mui/material'
import deepmerge from 'deepmerge'

const fontSize = 16
const htmlFontSize = 16

// White Label themes are created based on environment variables
export const WHITE_LABEL_THEME = process.env.REACT_APP_WHITE_LABEL_THEME as WhiteLabelTheme

export const whiteLabelThemeRecord = {
  'orcoda': 'OrcodaTheme',
  'koala': 'KoalaTheme',
}

export type WhiteLabelTheme = keyof typeof whiteLabelThemeRecord

// Generate the application theme based on the white label theme
export const pxToRem = (size: number) => `${size / htmlFontSize}rem`

export const getDesignTokens = (whiteLabelThemeOptions: ThemeOptions) => {
  return (deepmerge({
    layout: {
      navSidebarWidth: pxToRem(260),
      navSidebarCollapsedWidth: pxToRem(64),
      navSidebarTransitionSpeed: 300,
      navHeaderMobileHeight: pxToRem(56),
      navHeaderHeight: pxToRem(64),
      subNavSidebarWidth: pxToRem(260),
      notificationSidebarWidth: pxToRem(480),
      scrollbarWidth: '16px',
    },
    typography: {
      fontSize,
      htmlFontSize,
      pxToRem: (size: number) => pxToRem(size),
      spacing: (factor: number) => pxToRem(factor * 10),
      h1: {
        fontSize: pxToRem(32),
        fontWeight: 700,
        letterSpacing: '0.03em',
      },
      body1: {
        fontSize: pxToRem(16),
        fontWeight: 300,
        letterSpacing: '0.03em',
      },
      body2: {
        fontSize: pxToRem(16),
        fontWeight: 400,
        letterSpacing: '0.03em',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1200,
        xl: 1536,
      },
    },
  }, whiteLabelThemeOptions))
}

const theme = createTheme()

export const globalComponentOverwrite = {
  MuiContainer: {
    styleOverrides: {
      root: {
        paddingLeft: `${pxToRem(16)} !important`,
        paddingRight: `${pxToRem(16)} !important`,
      },
      maxWidthXs: {
        [theme.breakpoints.up('xs')]: {
          maxWidth: pxToRem(444 + (20 * 2)),
        },
      },
      maxWidthSm: {
        [theme.breakpoints.up('sm')]: { // >=600px & <900px Before 900, change all menus into hamburger and re-order the items
          maxWidth: '100vw',
        },
      },
    },
  },
  TableHead: {
    styleOverrides: {
      root: {
        fontWeight: 600,
        fontSize: pxToRem(16),
      },
    },
  },
}

export const scrollbarStyle = css`
  @media screen and (min-width: 768px) {
    &::-webkit-scrollbar {
      width: 16px;
      height: 16px;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border: none;
    }

    &:hover::-webkit-scrollbar-thumb {
      background: rgba(0,0,0,0.2);
      border-radius: 10px;
      border: 6px solid transparent;
      background-clip: padding-box;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0,0,0,0.3);
      border: 4px solid transparent;
      background-clip: padding-box;
    }
  }
`