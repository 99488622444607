import { Button as BaseButton } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledButton = styled(BaseButton, {
  shouldForwardProp: (prop) => prop !== '$round',
}) <{ $round?: boolean }>`
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.04rem;
  color: ${(props) => props.theme.palette.custom.button.primaryText};
  background-color: ${(props) => props.theme.palette.custom.button.primaryBackground};
  border-radius: ${(props) => props.$round ? '1000px' : props.theme.typography.pxToRem(8)};
  padding: ${(props) => props.theme.typography.pxToRem(14)};
  height: ${(props) => props.theme.typography.pxToRem(48)};

  &:hover {
    color: ${(props) => props.theme.palette.custom.button.primaryTextHover};
    background-color: ${(props) => props.theme.palette.custom.button.primaryBackgroundHover};
  }

  ${(props) => props.variant === 'text' && `
    color: unset;
    background-color: unset;

    &:hover {
      color: unset;
      background-color: ${props.theme.palette.custom.button.textButtonHoverBackground};
    }
  `}

  ${(props) => props.variant === 'outlined' && `
    color: unset;
    background-color: unset;
    box-shadow: ${props.theme.palette.custom.formFieldBoxShadow};

    &:hover {
      color: unset;
      background-color: ${props.theme.palette.custom.button.textButtonHoverBackground};
    }
  `}
  
  &:focus {
    box-shadow: 0 0 0 2px ${(props) => props.theme.palette.background.default}, 0 0 0 4px ${(props) => props.theme.palette.custom.button.focusedOutline};
  }
  
  &:disabled {
    opacity: 0.6;
    color: ${(props) => props.theme.palette.text.disabled};
  }
`
