import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Attribute } from 'src/types/Attribute'
import { replaceAllAttributes } from '../reducers/attributes'

export const setupAttributes = createAsyncThunk('attributes/setup', async (_, { dispatch }) => {
  const attributes = await CarshareApiService.get<Attribute>('getAttributes')

  if (attributes.results) {
    dispatch(replaceAllAttributes(attributes.results))
  }

  return attributes.results
})