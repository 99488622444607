/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react'

export type ManagementTableContextType = {
  selectedId: string | null
  setSelectedId: (id: string | null) => void
  searchValue?: string
  setSearchValue?: (value: string) => void
}

export const ManagementTableContext = createContext<ManagementTableContextType>({
  selectedId: null,
  setSelectedId: () => { },
})

export const useManagementTableContext = () => {
  const context = useContext(ManagementTableContext)

  if (context === undefined) {
    throw new Error(
      'useManagementTableContext must be used within a ManagementTableContext.Provider',
    )
  }

  return context
}
