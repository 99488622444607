import { useMediaQuery, useTheme } from '@mui/material'
import { Allotment } from 'allotment'
import { ComponentProps, Fragment, useEffect, useRef } from 'react'
import { useCustomAllotmentContext } from './CustomAllotmentContext'

export type CustomAllotmentProps = ComponentProps<typeof Allotment> & {
  children?: React.ReactNode
}

export const CustomAllotment: React.FC<CustomAllotmentProps> = ({ children, onDragEnd, ...props }) => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.up('md'))
  const allotmentRef = useRef<HTMLDivElement>(null)

  const { setAllotmentSizes } = useCustomAllotmentContext()

  const onResized = (sizes: number[]) => {
    if (onDragEnd) {
      onDragEnd(sizes)
    }

    setAllotmentSizes(sizes)
  }

  // Set initial allotment sizes
  useEffect(() => {
    if (allotmentRef && allotmentRef.current) {
      const fullWidth = allotmentRef.current.offsetWidth || 0

      setAllotmentSizes([fullWidth / 2, fullWidth / 2])
    }
  }, [])

  return isTablet ? (
    <div ref={allotmentRef} style={{ width: '100%', height: '100%' }}>
      <Allotment onDragEnd={onResized} {...props}>
        {children}
      </Allotment>
    </div>
  ) : (
    <Fragment>
      {children}
    </Fragment>
  )
}