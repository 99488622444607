/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { AutoComplete, AutocompleteOption } from 'src/components/Autocomplete/Autocomplete'
import { Button } from 'src/components/Button/Button'
import { DatePicker } from 'src/components/DatePicker/DatePicker'
import { Dropdown } from 'src/components/Dropdown/Dropdown'
import { DropdownItem } from 'src/components/Dropdown/DropdownItem'
import { TextField } from 'src/components/Form/TextField'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { Modal } from 'src/components/Modal/Modal'
import { MultiselectDropdown, MultiselectDropdownOption } from 'src/components/MultiselectDropdown/MultiselectDropdown'
import { MultiselectDropdownItem } from 'src/components/MultiselectDropdown/MultiselectDropdownItem'
import { FrequencyType, RecurrenceContext, RecurrenceType, getInitialRecurrence } from 'src/components/Recurrence/RecurrenceContext'
import { RecurrenceSelector } from 'src/components/Recurrence/RecurrenceSelector'
import { StyledForm, StyledFormControl, StyledSubmitButton } from 'src/fragments/StyledForm'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { generateHourArray, to12HourFormat } from 'src/libs/generateTime'
import { bookingsSelectors } from 'src/store/adapters/bookings'
import { customersSelectors } from 'src/store/adapters/customers'
import { passengersSelectors } from 'src/store/adapters/passengers'
import { vehiclesSelectors } from 'src/store/adapters/vehicles'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupBookings } from 'src/store/thunks/setupBookings'
import { pxToRem } from 'src/styles/themes'
import { Passenger } from 'src/types/Passenger'
import { Select } from 'src/types/Select'
import { VehicleDetail } from 'src/types/Vehicle'

export type BookingFormProps = {
  bookingId?: string
  isEditForm: boolean
  isReadOnly?: boolean
  onFormSubmitSuccess?: () => void
  currentDate: Dayjs
}

type Option = AutocompleteOption & {
  location_id?: string | null
}

export const BookingForm: React.FC<BookingFormProps> = ({ bookingId, isEditForm, isReadOnly, currentDate, onFormSubmitSuccess }) => {
  const customerType = useAppSelector(state => state.user.ui_type) || ''
  const isWaipa = customerType === 'WAIPA'

  // default pickup date time.
  const defaultMin = Math.round(dayjs().minute() / 15) * 15
  const defaultPickupDate = dayjs().startOf('hour').add(defaultMin, 'minute')
  const defaultDropoffDate = defaultPickupDate.add(1, 'hour')

  const activeOrgUnit = useAppSelector(state => state.settings.activeOrgUnit)
  const { operationActiveVehicleId } = useAppSelector(state => state.settings)
  const booking = useAppSelector((state) => bookingsSelectors.selectById(state, bookingId || ''))
  const vehicles = useAppSelector((state) => vehiclesSelectors.selectAll(state))
  const customers = useAppSelector((state) => customersSelectors.selectAll(state))
  const passengers = useAppSelector((state) => passengersSelectors.selectAll(state))
  const [memberId, setMemberId] = useState<string | null>(null)
  const [vehicleId, setVehicleId] = useState<string | null | undefined>(operationActiveVehicleId)
  const [pickupLocation, setPickupLocation] = useState<string>('')
  const [dropoffLocation, setDropoffLocation] = useState<string>('')
  const [pickupDate, setPickupDate] = useState<string | null>(defaultPickupDate.format('YYYY-MM-DD'))
  const [pickupHour, setPickupHour] = useState<string>(defaultPickupDate.format('HH'))
  const [pickupMinute, setPickupMinute] = useState<string>(defaultPickupDate.format('mm'))
  const [dropoffDate, setDropoffDate] = useState<string | null>(defaultDropoffDate.format('YYYY-MM-DD'))
  const [dropoffHour, setDropoffHour] = useState<string>(defaultDropoffDate.format('HH'))
  const [dropoffMinute, setDropoffMinute] = useState<string>(defaultDropoffDate.format('mm'))
  const [selectedPassengers, setSelectedPassengers] = useState<MultiselectDropdownOption[]>([])
  const [bookingNotes, setBookingNotes] = useState<string | null>(null)
  const [bookingStatus, setBookingStatus] = useState<string>('')
  const [estimatedKm, setEstimatedKm] = useState<string>('')
  const [destination, setDestination] = useState<string | null>(null)
  const [isBookingAutocomplete, setBookingAutocomplete] = useState<boolean>(true)
  const [isEditWithRecurrence, setEditWithRecurrence] = useState<boolean | null>(null)
  const [recurrenceRule, setRecurrenceRule] = useState<RecurrenceType>(getInitialRecurrence())
  const [isUnderOneDay, setIsUnderOneDay] = useState<boolean>(false)
  const [isConfirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState<boolean>(false)
  const [isRecurrenceCancelAll, setRecurrenceCancelAll] = useState<boolean>(false)

  const [isVehicleOptionsLoading, setVehicleOptionsLoading] = useState<boolean>(true)
  const [vehicleOptions, setVehicleOptions] = useState<Option[]>([])
  const [pickupOptions, setPickupOptions] = useState<Select['tuples']>([])
  const [dropoffOptions, setDropoffOptions] = useState<Select['tuples']>([])
  const [destinationOptions, setDestinationOptions] = useState<Select['tuples']>([])

  const { loading, complete, errorMessage, request } = useApiRequest()
  const dispatch = useAppDispatch()
  const { setSuccessMessage } = useAlertContext()

  const isBookingRecurrence = booking ? booking?.recurrence_type.toLowerCase() !== 'none' : false
  const isBookingCancelled = !!booking?.booking_cancelled && booking?.booking_cancelled !== '0000-00-00 00:00:00'
  const isBookingStarted = !!booking?.booking_started && booking?.booking_started !== '0000-00-00 00:00:00'

  isReadOnly = isReadOnly || (isEditForm && isBookingCancelled)

  // Select Options Setup
  useEffect(() => {
    CarshareApiService.get<Select>('getSelects', {
      kinds: 'select-location-pickup-id:carshare_org_units_locations,select-location-dropoff-id:carshare_org_units_locations,carshare_destinations',
      filter: activeOrgUnit?.ORG_UNIT_ID,
    }).then((response) => {
      const pickupLocations = response?.results?.find(result => result.name === 'select-location-pickup-id:carshare_org_units_locations')?.tuples || []
      const dropoffLocations = response?.results?.find(result => result.name === 'select-location-dropoff-id:carshare_org_units_locations')?.tuples || []
      const carshareDestinations = response?.results?.find(result => result.name === 'carshare_destinations')?.tuples || []

      pickupLocations.unshift({ name: '', text: 'None' })
      dropoffLocations.unshift({ name: '', text: 'None' })
      carshareDestinations.unshift({ name: '', text: 'None' })

      setPickupOptions(pickupLocations)
      setDropoffOptions(dropoffLocations)
      setDestinationOptions(carshareDestinations)

      setPickupLocation((prev) => prev ? prev : (pickupLocations?.[0]?.name || ''))
      setDropoffLocation((prev) => prev ? prev : (dropoffLocations?.[0]?.name || ''))
      setDestination((prev) => prev ? prev : (carshareDestinations?.[0]?.name || ''))
    })
  }, [activeOrgUnit])

  // Updating recurrence rules based on user's input
  useEffect(() => {
    const underOneDayCheck = !pickupDate || !dropoffDate || (pickupDate === dropoffDate)

    setIsUnderOneDay(underOneDayCheck)
  }, [pickupDate, dropoffDate])

  // Vehicle select options
  useEffect(() => {
    setVehicleOptionsLoading(true)

    if (activeOrgUnit?.org_unit_name === 'All' && memberId) {
      CarshareApiService.post<VehicleDetail>('getVehicles', {
        ORG_UNIT_ID: activeOrgUnit?.ORG_UNIT_ID,
        CUSTOMER_ID: memberId,
      }).then((vehicleResponse) => {
        if (vehicleResponse.results) {
          const vehicleList: Option[] = vehicleResponse.results.reduce((unique: Option[], vehicle) => {
            const label = vehicle.make_name + ' ' + vehicle.model_name + ' ' + vehicle.vehicle_rego_number
            const id = vehicle.VEHICLE_ID
            const location_id = vehicle.LOCATION_ID

            if (unique.findIndex(option => option.id === id) === -1) {
              unique.push({ label, id, location_id })
            }

            return unique
          }, [])

          setVehicleOptions(vehicleList)
          setVehicleOptionsLoading(false)
        }
      })
    } else {
      const vehiclesList: Option[] = vehicles.reduce((unique: Option[], vehicle) => {
        const label = vehicle.make_name + ' ' + vehicle.model_name + ' ' + vehicle.vehicle_rego_number
        const id = vehicle.VEHICLE_ID
        const location_id = vehicle.LOCATION_ID

        if (unique.findIndex(option => option.id === id) === -1) {
          unique.push({ label, id, location_id })
        }

        return unique
      }, [])

      setVehicleOptions(vehiclesList)
      setVehicleOptionsLoading(false)
    }
  }, [activeOrgUnit, memberId])

  const onMemberChange = async (_: any, value: any) => {
    if (!value) {
      setMemberId(null)

      return
    }

    setMemberId(value.id)
    setVehicleOptionsLoading(true)

    const vehicleResponse = await CarshareApiService.post<VehicleDetail>('getVehicles', {
      ORG_UNIT_ID: activeOrgUnit?.ORG_UNIT_ID,
      CUSTOMER_ID: value.id,
    })

    if (vehicleResponse.results) {
      const vehicleList: Option[] = vehicleResponse.results.map(vehicle => ({
        label: vehicle.make_name + ' ' + vehicle.model_name + ' ' + vehicle.vehicle_rego_number,
        id: vehicle.VEHICLE_ID,
        location_id: vehicle.LOCATION_ID,
      }))

      // Reset vehicle id if the selected vehicle is not in the list
      if (!vehicleList.find(vehicle => vehicle.id === vehicleId)) {
        setVehicleId('')
      }

      setVehicleOptions(vehicleList)
    }

    setVehicleOptionsLoading(false)
  }

  const customerOptions: Option[] = customers.map(customer => ({
    label: customer.customer_name,
    id: customer.CUSTOMER_ID,
  }))

  const getBookingStatusLabel = (currentStatus: string) => {
    if (currentStatus === 'Late pickup') {
      return 'Booked (Late pickup)'
    }

    if (currentStatus === 'Late return') {
      return 'In Use (Late return)'
    }

    return currentStatus
  }

  const getBookingStatusOptions = (currentStatus: string) => {
    const bookingStatusOptions = ['Booked', 'In Use', 'Completed on-time', 'Completed late']

    if (currentStatus === 'Late pickup') {
      return bookingStatusOptions.map((status, index) => ({
        status: status,
        label: status === 'Booked' ? 'Booked (Late pickup)' : status,
        disabled: index === 0,
      }))
    }

    if (currentStatus === 'Late return') {
      return bookingStatusOptions.map((status, index) => ({
        status: status,
        label: status === 'In Use' ? 'In Use (Late return)' : status,
        disabled: index === 1,
      }))
    }

    if (currentStatus === 'Completed on-time') {
      return bookingStatusOptions.map((status, index) => ({
        status: status,
        label: status,
        disabled: index === 2,
      }))
    }

    if (currentStatus === 'Completed late') {
      return bookingStatusOptions.map((status, index) => ({
        status: status,
        label: status,
        disabled: index === 3,
      }))
    }

    return bookingStatusOptions.map((status) => ({
      status: status,
      label: status,
      disabled: false,
    }))
  }

  const hourOptions = generateHourArray()

  const minutesOptions = ['00', '15', '30', '45']

  const submitHandler = () => {
    const payload = {
      CUSTOMER_ID: memberId,
      VEHICLE_ID: vehicleId,
      LOCATION_PICKUP_ID: pickupLocation,
      LOCATION_DROPOFF_ID: dropoffLocation,
      booking_date_pickup: pickupDate,
      booking_time_pickup: pickupHour + ':' + pickupMinute + ':00',
      booking_time_pickup_minutes: pickupMinute,
      booking_date_dropoff: dropoffDate,
      booking_time_dropoff: dropoffHour + ':' + dropoffMinute + ':00',
      booking_time_dropoff_minutes: dropoffMinute,
      booking_estimated_kms: estimatedKm,
      booking_destination: destination,
      passenger_guids: selectedPassengers.map((item) => item.name).join(','),
      booking_notes: bookingNotes,
      booking_autocomplete: isBookingAutocomplete ? '1' : '0',
      recurrence_type: isUnderOneDay ? recurrenceRule.frequency : FrequencyType.None,
      recurrence_every: recurrenceRule.numberOfRepetitions,
      recurrence_until: recurrenceRule.endDate?.format('YYYY-MM-DD'),
    }

    if (isEditForm) {
      const editPayload = {
        ...payload,
        BOOKING_ID: bookingId,
        status: bookingStatus,
        recurrence_update_all: isEditWithRecurrence ? '1' : '0',
      }

      return CarshareApiService.post('editBookings', editPayload)
    }

    return CarshareApiService.post('addBookings', payload)
  }

  const cancelBookingHandler = () => {
    return CarshareApiService.post('cancelBooking', {
      CUSTOMER_ID: memberId,
      BOOKING_ID: bookingId,
      recurrence_cancel_all: isRecurrenceCancelAll ? '1' : '0',
    })
  }

  const onSelectPassenger = (val: MultiselectDropdownOption) => {
    const index = selectedPassengers.findIndex(item => item.name == val.name)

    if (index > -1) {
      const newSelectedItems = [...selectedPassengers]

      newSelectedItems.splice(index, 1)
      setSelectedPassengers(newSelectedItems)
    } else {
      setSelectedPassengers([...selectedPassengers, val])
    }
  }

  const getInValidDropoff = () => {
    if (!pickupDate || !dropoffDate) return false

    // Check for dropoff hour and minute as well
    const tempPickupDate = new Date(pickupDate)

    tempPickupDate.setHours(+pickupHour)
    tempPickupDate.setMinutes(+pickupMinute)

    const tempDropoffDate = new Date(dropoffDate)

    tempDropoffDate.setHours(+dropoffHour)
    tempDropoffDate.setMinutes(+dropoffMinute)

    return tempDropoffDate <= tempPickupDate
  }
  const isInValidDropoff = getInValidDropoff()

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    request(submitHandler)
  }

  const onCancelBooking = () => {
    request(cancelBookingHandler)
  }

  // Initialize edit form data
  useEffect(() => {
    if (!isEditForm || !booking) return

    setMemberId(booking.CUSTOMER_ID)
    setVehicleId(booking.VEHICLE_ID)
    setPickupDate(booking.booking_date_pickup)
    setPickupHour(new Date(booking.booking_dt_pickup).getHours().toString())
    setPickupMinute(new Date(booking.booking_dt_pickup).getMinutes().toString().padStart(2, '0'))
    setPickupLocation(booking.LOCATION_PICKUP_ID)
    setDropoffDate(booking.booking_date_dropoff)
    setDropoffHour(new Date(booking.booking_dt_dropoff).getHours().toString())
    setDropoffMinute(new Date(booking.booking_dt_dropoff).getMinutes().toString().padStart(2, '0'))
    setDropoffLocation(booking.LOCATION_DROPOFF_ID)
    setRecurrenceRule((prev) => {
      return {
        ...prev,
        startDate: dayjs(booking?.booking_date_pickup),
        frequency: (booking?.recurrence_type.toLowerCase() as FrequencyType) || FrequencyType.None,
        numberOfRepetitions: booking?.recurrence_every ? booking?.recurrence_every : 1,
        endDate: booking?.recurrence_until ? dayjs(booking?.recurrence_until) : null,
      }
    })
    setBookingNotes(booking.booking_notes)
    setBookingStatus(booking.status)
    setDestination(booking.booking_destination)
    setBookingAutocomplete(booking.booking_autocomplete === '1')
    setEstimatedKm(booking?.booking_estimated_kms || '')

    const passengerGuids = booking.passenger_guids.split(',')
    const psgs = passengerGuids
      .map((psgGuid) => passengers.find((psg) => psg.passenger_guid === psgGuid) || null)
      .filter(item => item !== null) as Passenger[]

    setSelectedPassengers(psgs.map((item) => ({
      name: item.passenger_guid,
      label: item.passenger_name,
    })))
  }, [isEditForm, booking])

  useEffect(() => {
    if (errorMessage) {
      const node = document.querySelector('#__modal_main')

      node?.scrollTo({
        top: 0,
        behavior: 'smooth',
      })

      return
    }

    if (complete && onFormSubmitSuccess) {
      onFormSubmitSuccess()
    }
  }, [errorMessage, complete, onFormSubmitSuccess])

  useEffect(() => {
    if (complete) {
      setSuccessMessage(`Booking for ${vehicleOptions.find((item) => item.id === vehicleId)?.label} was ${isEditForm ? 'updated' : 'created'} successfully.`)

      if (pickupDate) {
        dispatch(setupBookings({ currentDate: dayjs(pickupDate) }))
      }

      if (dropoffDate !== pickupDate) {
        dispatch(setupBookings({ currentDate: dayjs(dropoffDate) }))
      }

      if (currentDate.format('YYYY-MM-DD') !== pickupDate && currentDate.format('YYYY-MM-DD') !== dropoffDate) {
        dispatch(setupBookings({ currentDate }))
      }
    }
  }, [complete])

  return (!isReadOnly && isEditForm && isBookingRecurrence && isEditWithRecurrence === null) ? (
    <>
      <Typography variant="body1">Would you like to update only this booking, or all future bookings?</Typography>
      <Grid container sx={{ marginTop: pxToRem(4) }} rowSpacing={2}>
        <Grid item xs={12}>
          <Button
            outlined
            fullWidth
            onClick={() => {
              setEditWithRecurrence(false)
            }}
          >
            Update current booking
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            outlined
            fullWidth
            onClick={() => {
              setEditWithRecurrence(true)
            }}
          >
            Update current and future bookings
          </Button>
        </Grid>
      </Grid>
    </>
  ) : (
    <StyledForm $isEditable onSubmit={onSubmit}>
      <Grid container spacing={2} justifyContent={'center'} flexDirection={'column'}>
        <Grid item>
          {errorMessage && (
            <Alert id="__form_alert_error" severity={'error'} icon={false}>
              {errorMessage}
            </Alert>
          )}
        </Grid>
        {isReadOnly && <Grid item>
          <Alert severity={'info'} icon={false}>
            {isBookingCancelled ? `This booking was cancelled on ${dayjs(booking?.booking_cancelled).format('MMMM D, YYYY')}.` : 'Currently in read only mode.'}
          </Alert>
        </Grid>}
        <Grid item>
          <StyledFormControl required={!isEditForm}>
            <TextFieldLabel>Member</TextFieldLabel>
            <AutoComplete
              id="member-selector"
              autoComplete={false}
              options={customerOptions}
              placeholder="Select member"
              disabled={isReadOnly || isEditForm}
              defaultValue={customerOptions.find(option => option.id === booking?.CUSTOMER_ID)}
              isOptionEqualToValue={(option, value) => {
                return (option as Option).id === (value as Option).id
              }}
              onChange={onMemberChange}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Vehicle Booked</TextFieldLabel>
            {isVehicleOptionsLoading ? (
              <TextField
                id="vehicle-selector"
                disabled
                value="Loading..."
              />
            ) : (
              <AutoComplete
                id={'vehicle-selector'}
                options={vehicleOptions}
                disabled={isReadOnly || isEditForm}
                value={vehicleOptions.find(option => option.id === vehicleId)}
                placeholder="Select vehicle"
                isOptionEqualToValue={(option: any, value: any) => {
                  if (!value) return false
                  
                  return option.id === value.id
                }}
                onChange={(_, value: any) => {
                  if (!value) {
                    setVehicleId('')

                    return
                  }

                  setVehicleId(value.id)

                  if (value.location_id) {
                    setPickupLocation(value.location_id)
                    setDropoffLocation(value.location_id)
                  }
                }}
              />
            )}
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Pickup Location</TextFieldLabel>
            <Dropdown
              value={pickupOptions.find(option => option.name === pickupLocation)?.text || ''}
              onSelectItem={(value) => { setPickupLocation(value) }}
              disabled={isReadOnly}
            >
              {pickupOptions.map((option, idx) =>
                <DropdownItem key={idx} data-value={option.name}>{option.text}</DropdownItem>,
              )}
            </Dropdown>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Dropoff Location</TextFieldLabel>
            <Dropdown
              value={dropoffOptions.find(option => option.name === dropoffLocation)?.text || ''}
              onSelectItem={(value) => { setDropoffLocation(value) }}
              disabled={isReadOnly}
            >
              {dropoffOptions.map((option, idx) =>
                <DropdownItem key={idx} data-value={option.name}>{option.text}</DropdownItem>,
              )}
            </Dropdown>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <Grid container spacing={2} flexDirection={'row'} justifyContent={'space-between'}>
            <Grid item xs={12} sm={4}>
              <StyledFormControl required>
                <TextFieldLabel>Pickup Date</TextFieldLabel>
                <DatePicker
                  value={dayjs(pickupDate)}
                  format="DD/MM/YYYY"
                  disabled={isReadOnly}
                  onChange={(date) => {
                    if (date && (date as Dayjs)?.isValid()) {
                      setPickupDate((date as Dayjs).format('YYYY-MM-DD'))
                      
                      if (dayjs(dropoffDate).isBefore(date as Dayjs)) {
                        setDropoffDate((date as Dayjs).format('YYYY-MM-DD'))
                      }
                      
                      setRecurrenceRule((prev) => {
                        return {
                          ...prev,
                          startDate: date as Dayjs,
                        }
                      })
                    }
                  }}
                  onAccept={(date) => {
                    if (date) {
                      setPickupDate((date as Dayjs).format('YYYY-MM-DD'))
                    }
                  }}
                />
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledFormControl required>
                <TextFieldLabel>Pickup Hour</TextFieldLabel>
                <Dropdown
                  value={to12HourFormat(pickupHour)}
                  onSelectItem={(value) => { setPickupHour(value) }}
                  disabled={isReadOnly}
                >
                  {hourOptions.map(hour => {
                    return (
                      <DropdownItem key={hour.time24HourFormat} data-value={hour.time24HourFormat}>{hour.time12HourFormat}</DropdownItem>
                    )
                  })}
                </Dropdown>
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledFormControl required>
                <TextFieldLabel>Pickup Minutes</TextFieldLabel>
                <Dropdown
                  value={pickupMinute || 'Pickup Minutes'}
                  onSelectItem={(value) => { setPickupMinute(value) }}
                  disabled={isReadOnly}
                >
                  {minutesOptions.map(minutes => {
                    return (
                      <DropdownItem key={minutes} data-value={minutes}>{minutes}</DropdownItem>
                    )
                  })}
                </Dropdown>
              </StyledFormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} flexDirection={'row'} justifyContent={'space-between'}>
            <Grid item xs={12} sm={4}>
              <StyledFormControl required
                error={isInValidDropoff}
              >
                <TextFieldLabel>Dropoff Date</TextFieldLabel>
                <DatePicker
                  value={dayjs(dropoffDate)}
                  format="DD/MM/YYYY"
                  disabled={isReadOnly}
                  onChange={(date) => {
                    if (date && (date as Dayjs)?.isValid()) {
                      setDropoffDate((date as Dayjs).format('YYYY-MM-DD'))
                    }
                  }}
                  onAccept={(date) => {
                    if (date) {
                      setDropoffDate((date as Dayjs).format('YYYY-MM-DD'))
                    }
                  }}
                />
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledFormControl
                error={isInValidDropoff}
                required
              >
                <TextFieldLabel>Dropoff Hour</TextFieldLabel>
                <Dropdown
                  value={to12HourFormat(dropoffHour) || 'Dropoff Hour'}
                  onSelectItem={(value) => { setDropoffHour(value) }}
                  error={isInValidDropoff}
                  disabled={isReadOnly}
                >
                  {hourOptions.map(hour => {
                    return (
                      <DropdownItem key={hour.time24HourFormat} data-value={hour.time24HourFormat}>{hour.time12HourFormat}</DropdownItem>
                    )
                  })}
                </Dropdown>
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledFormControl
                error={isInValidDropoff}
                required
              >
                <TextFieldLabel>Dropoff Minutes</TextFieldLabel>
                <Dropdown
                  value={dropoffMinute || 'Dropoff Minutes'}
                  onSelectItem={(value) => { setDropoffMinute(value) }}
                  error={isInValidDropoff}
                  disabled={isReadOnly}
                >
                  {minutesOptions.map(minutes => {
                    return (
                      <DropdownItem key={minutes} data-value={minutes}>{minutes}</DropdownItem>
                    )
                  })}
                </Dropdown>
              </StyledFormControl>
            </Grid>
          </Grid>
        </Grid>
        {(!isEditForm || isEditWithRecurrence) && isUnderOneDay && <Grid item>
          <RecurrenceContext.Provider value={{ recurrence: recurrenceRule, setRecurrence: setRecurrenceRule }}>
            <StyledFormControl>
              <RecurrenceSelector
                isEditForm={isEditForm}
              />
            </StyledFormControl>
          </RecurrenceContext.Provider>
        </Grid>}
        {isEditForm && <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Booking Status</TextFieldLabel>
            <Dropdown
              value={getBookingStatusLabel(bookingStatus || '')}
              onSelectItem={(value) => { setBookingStatus(value) }}
              disabled={isReadOnly}
            >
              {getBookingStatusOptions(booking?.status || '').map(({ status, label, disabled }) => {
                return (
                  <DropdownItem
                    key={status}
                    data-value={status}
                    disabled={disabled}
                  >
                    {label}
                  </DropdownItem>
                )
              })}
            </Dropdown>
          </StyledFormControl>
        </Grid>}
        {!isWaipa && <Grid item>
          <TextFieldLabel>Estimated kilometres</TextFieldLabel>
          <TextField
            type="number"
            value={estimatedKm}
            onChange={(e) => {
              setEstimatedKm(e.target.value)
            }}
            InputProps={{
              inputProps: { min: 0 },
            }}
            disabled={isReadOnly}
          />
        </Grid>}
        {isWaipa && <Grid item>
          <StyledFormControl>
            <TextFieldLabel>Destination</TextFieldLabel>
            <Dropdown
              value={destinationOptions.find(option => option.name === destination)?.text || ''}
              onSelectItem={(value) => { setDestination(value) }}
              disabled={isReadOnly}
            >
              {destinationOptions.map(option => {
                return (
                  <DropdownItem key={option.name} data-value={option.name}>{option.text}</DropdownItem>
                )
              })}
            </Dropdown>
          </StyledFormControl>
        </Grid>}
        <Grid item xs={12}>
          <StyledFormControl>
            <TextFieldLabel>Passengers</TextFieldLabel>
            <MultiselectDropdown
              values={selectedPassengers}
              onSelectItem={onSelectPassenger}
              disabled={isReadOnly}
            >
              {passengers.map((passenger) => (
                <MultiselectDropdownItem
                  key={passenger.passenger_guid}
                  value={{
                    name: passenger.passenger_guid,
                    label: passenger.passenger_name,
                  }}
                >
                  {passenger.passenger_name}
                </MultiselectDropdownItem>
              ))}
            </MultiselectDropdown>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel>Booking Notes</TextFieldLabel>
            <TextField
              onChange={(event) => {
                setBookingNotes(event.target.value)
              }}
              disabled={isReadOnly}
              defaultValue={booking?.booking_notes}
              multiline
              rows={3}
            />
          </StyledFormControl>
        </Grid>
        {isWaipa && <Grid item>
          <FormControlLabel
            disabled={isReadOnly}
            control={<Checkbox checked={isBookingAutocomplete} onChange={(ev) => { setBookingAutocomplete(ev.target.checked) }} />}
            label="Auto start and complete booking based on vehicle location"
          />
        </Grid>}
        <Grid item>
          <Button
            sx={{ marginTop: pxToRem(8), marginBottom: pxToRem(isEditForm ? 0 : 16) }}
            primary
            fullWidth
            type="submit"
            disabled={loading || isReadOnly || isInValidDropoff || !pickupDate || !dropoffDate || !memberId || !vehicleId || !pickupLocation || !dropoffLocation}
          >
            {isEditForm ? 'Update' : 'Add'} Booking
          </Button>
          {!isReadOnly && isEditForm && <StyledSubmitButton
            outlined
            fullWidth
            disabled={loading || isBookingStarted}
            onClick={() => { setConfirmCancelDialogOpen(true) }}
          >
            Cancel Booking
          </StyledSubmitButton>}
        </Grid>
      </Grid>
      <Modal portal={'#modal'} title={'Cancel Booking'} open={isConfirmCancelDialogOpen} onClose={() => { setConfirmCancelDialogOpen(false) }}>
        {isBookingRecurrence ? (
          <FormControlLabel
            control={<Checkbox checked={isRecurrenceCancelAll} onChange={(ev) => { setRecurrenceCancelAll(ev.target.checked) }} />}
            label="Cancel all upcoming bookings in the series"
          />
        ) : (
          <Typography variant="body1">Confirm cancel booking</Typography>
        )}
        <Grid container justifyContent={'flex-end'} sx={{ marginTop: pxToRem(16) }} spacing={1}>
          <Grid item>
            <Button primary onClick={onCancelBooking}>Cancel Booking</Button>
          </Grid>
        </Grid>
      </Modal>
    </StyledForm >
  )
}
