import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Location } from 'src/types/Location'
import { locationsAdapter } from '../adapters/locations'
import { setupLocationFacilities } from '../thunks/setupLocationFacilities'

export const initialState = locationsAdapter.getInitialState()

export const { reducer, actions } = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    addMultipleLocations: (state, action: PayloadAction<Location[]>) => {
      locationsAdapter.upsertMany(state, action.payload)
    },
    replaceAllLocations: (state, action: PayloadAction<Location[]>) => {
      locationsAdapter.setAll(state, action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setupLocationFacilities.fulfilled, (state, action) => {
      if (action.payload) {
        const locationFacilities = action.payload.results ? action.payload.results.map((facility) => ({
          LOCATION_FACILITY_ID: facility.LOCATION_FACILITY_ID,
          location_facility_name: facility.location_facility_name,
        })) : []

        locationsAdapter.updateOne(state, {
          id: action.payload.LOCATION_ID,
          changes: {
            location_facilities: locationFacilities,
          },
        })
      }
    })
  },
})

export const { addMultipleLocations, replaceAllLocations } = actions