import { DependencyList, useEffect } from 'react'

export const useVisibilityChange = (callback: (isVisible: boolean) => void, params: DependencyList) => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      callback(!document.hidden)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [callback, ...params])
}