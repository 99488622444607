import { styled } from '@mui/material'
import React, { ComponentProps } from 'react'

export type VehicleSummaryListProps = ComponentProps<typeof StyledList> & {
  children: React.ReactNode
}

export const VehicleSummaryList: React.FC<VehicleSummaryListProps> = ({ children, ...props }) => {
  return (
    <StyledList {...props}>
      {children}
    </StyledList>
  )
}

const StyledList = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`