import { styled } from '@mui/material'
import { ComponentProps, useEffect, useMemo, useState } from 'react'
import { scrollbarStyle } from '../../styles/themes'
import { CalendarContext, CalendarContextType } from './CalenderContext'
import { PaginationBar, PaginationBarProps } from './PaginationBar'

export type OperationsCalendarProps = ComponentProps<typeof StyledContainer> & {
  children: React.ReactNode
  currentDay: string
  dayWidth?: number
  onVehicleClick?: (id: string | null) => void
  selectedId?: string
  paginatiionProps?: PaginationBarProps
}

export const OperationsCalendar: React.FC<OperationsCalendarProps> = ({ children, dayWidth, currentDay, onVehicleClick, selectedId, paginatiionProps, ...props }) => {
  const [hoveredVehicleId, setHoveredVehicleId] = useState<string | null>(null)
  const [selectedVehicleId, setSelectedVehicleId] = useState<string | null>(null)
  const [vehicleSearchString, setVehicleSearchString] = useState<string>('')

  const onVehicleSelectHandler = (id: string | null) => {
    setSelectedVehicleId(id)

    if (onVehicleClick) {
      onVehicleClick(id)
    }
  }

  // Clear selection if selectedId is null from parent
  useEffect(() => {
    if (!selectedId) {
      setSelectedVehicleId(null)
      setHoveredVehicleId(null)
    }
  }, [selectedId])

  const defaultContext: CalendarContextType = useMemo(() => ({
    DAY_WIDTH: dayWidth || 1536,
    currentDay,
    selectedVehicleId: selectedVehicleId,
    setSelectedVehicleId: onVehicleSelectHandler,
    hoveredVehicleId,
    setHoveredVehicleId,
    vehicleSearchString,
    setVehicleSearchString,
  }), [currentDay, hoveredVehicleId, setHoveredVehicleId])

  return (
    <CalendarContext.Provider value={defaultContext}>
      <StyledContainer {...props} id={'operationsCalendar'}>
        <StyledCalendarContainer>
          {children}
        </StyledCalendarContainer>
      </StyledContainer>
      {paginatiionProps && <PaginationBar {...paginatiionProps} />}
    </CalendarContext.Provider>
  )
}

const StyledContainer = styled('div')`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: ${props => props.theme.palette.background.paper};
  border-radius: 0 !important;
  overflow: auto;

  ${props => props.theme.breakpoints.up('sm')} {
    border-radius: ${props => props.theme.typography.pxToRem(20)} !important;
    overflow: visible;
  }
`

const StyledCalendarContainer = styled('div')`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow-x: auto;

  ${props => props.theme.breakpoints.up('sm')} {
    overflow: auto;
    ${scrollbarStyle}
  }
`
