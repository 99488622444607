import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { LocationFacility } from 'src/types/Location'


type SetupPayload = {
  LOCATION_ID: string
}

export const setupLocationFacilities = createAsyncThunk('locations/setupLocationFacilities', async ({ LOCATION_ID }: SetupPayload) => {
  const facilities = await CarshareApiService.post<LocationFacility>('getLocationFacility', {
    LOCATION_ID,
  })

  return {
    LOCATION_ID: LOCATION_ID,
    results: facilities.results,
  }
})