import { useTheme } from '@mui/material'
import { useCallback, useEffect, useMemo } from 'react'
import { createRoot } from 'react-dom/client'
import { useMapContext } from 'src/components/Map/MapContext'
import { MapCluster } from 'src/components/MapMarker/MapCluster'
import { MapVehicleToolTip } from 'src/components/MapMarker/MapVehicleTooltip'
import { MarkerDataType } from 'src/components/MapMarker/models'
import { useDashboardContext } from 'src/pages/Dashboard/DashboardContext'
import { vehiclesSelectors } from 'src/store/adapters/vehicles'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupBookingById } from 'src/store/thunks/setupBookingById'
import { Booking } from 'src/types/Booking'
import { Vehicle } from 'src/types/Vehicle'


export type MapMarkersProps = {
  vehicles: (Partial<Vehicle> & Partial<Booking>)[]
}

const BookingStatusMap: Record<string, 'success' | 'warning' | 'error'> = {
  '': 'success',
  'Booked': 'success',
  'In Use': 'success',
  'Cancelled': 'error',
  'Completed on-time': 'success',
  'Completed late': 'warning',
  'Late pickup': 'error',
  'Late return': 'warning',
}

export const DashboardMapMarkers: React.FC<MapMarkersProps> = ({ vehicles }) => {
  const { map, ui } = useMapContext()
  const isNavOpen = useAppSelector((state) => state.settings.navSidebarOpen)
  const allVehicles = useAppSelector(state => vehiclesSelectors.selectAll(state))
  const dispatch = useAppDispatch()
  const { setSelectedBookingId } = useDashboardContext()
  const theme = useTheme()

  const clearBubbles = useCallback(() => {
    if (ui) {
      const bubbles = ui.getBubbles()

      bubbles.forEach((bubble) => {
        bubble.close()
      })
    }
  }, [ui])

  // Clear pop up bubbles when the list of vehicles change.
  useEffect(() => {
    clearBubbles()
  }, [ui, vehicles])

  // Reize Heremap whenever the nav sidebar is opened or closed.
  useEffect(() => {
    if (map && ui) {
      const resizeTimeout = setTimeout(() => {
        map.getViewPort().setPadding(75, 25, 25, 25)
        map?.getViewPort().resize()
      }, theme.layout.navSidebarTransitionSpeed + 50)

      return () => {
        clearTimeout(resizeTimeout)
      }
    }
  }, [map, ui, isNavOpen])

  // Create a pop up bubble for each marker when it is clicked based on the marker `data` props
  const createDomBubble = useCallback((data: MarkerDataType[]) => {
    const bubbleDiv = document.createElement('div')
    const bubbleDivRoot = createRoot(bubbleDiv)

    const onCardCtaClick = (cardData: MarkerDataType) => {
      if (cardData.BOOKING_ID) {
        dispatch(setupBookingById({ BOOKING_ID: cardData.BOOKING_ID })).then(() => {
          setSelectedBookingId(cardData.BOOKING_ID)
          clearBubbles()
        })
      }
    }

    bubbleDivRoot.render(
      <MapVehicleToolTip data={data} onCardCtaClick={onCardCtaClick} />,
    )

    return bubbleDiv
  }, [setSelectedBookingId, clearBubbles])

  const data = useMemo(() => vehicles.slice(1).map(({ VEHICLE_ID, BOOKING_ID, position_lat, position_lng, status, vehicle_rego_number, ...vehiclelInfo }, idx) => {
    if (!position_lat || !position_lng) return null

    // Additional Vehicle Info retrieved from store.
    const additionalVehicleInfo = allVehicles.find((item) => item.VEHICLE_ID === VEHICLE_ID)
    const bookingStatusType = BookingStatusMap[status || 'success']

    return {
      id: idx,
      position: { lat: parseFloat(position_lat), lng: parseFloat(position_lng) },
      BOOKING_ID: BOOKING_ID || '',
      VEHICLE_ID: VEHICLE_ID || '',
      file_image_src_data: additionalVehicleInfo?.file_image_src_data || '',
      status: status,
      type: bookingStatusType,
      vehicleName: vehicle_rego_number || '',
      ...vehiclelInfo,
    } as MarkerDataType
  }).filter((item): item is MarkerDataType => item !== null).filter((item) => item.vehicle_rego_number !== ''), [vehicles, allVehicles])

  return (
    <MapCluster data={data} createDomBubble={createDomBubble} />
  )
}
