import { Grid, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import { Allotment } from 'allotment'
import { useEffect, useState } from 'react'
import { CustomAllotment } from 'src/components/CustomAllotment/CustomAllotment'
import { ManagementTableContext } from 'src/components/ManagementTable/ManagementTableContext'
import { TableMenuItem } from 'src/components/ManagementTable/TableMenuItem'
import { Modal } from 'src/components/Modal/Modal'
import { VehicleDetailsFragment, VehicleForm } from 'src/fragments/Management'
import { vehiclesSelectors } from 'src/store/adapters/vehicles'
import { useAppSelector } from 'src/store/store'
import { Vehicle } from 'src/types/Vehicle'
import { StyledAllotmentPanel, StyledManagementTable, StyledPaper } from './StyledManagement'

export type ManagementVehiclesProps = {
  //
}

export const ManagementVehicles: React.FC<ManagementVehiclesProps> = () => {
  const [selectedId, setSelectedId] = useState<string | null>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const activeOrgUnit = useAppSelector((state) => state.settings.activeOrgUnit)
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'))

  const [isAddVehicleFormOpen, setAddVehicleFormOpen] = useState(false)
  const vehicles = useAppSelector((state) => vehiclesSelectors.selectAll(state))

  const tableContextValue = {
    selectedId,
    setSelectedId,
    searchValue,
    setSearchValue,
  }

  const vehicleSearchFilter = (vehicle: Vehicle) => {
    const searchTerm = searchValue.toLowerCase()

    return (
      vehicle.VEHICLE_ID.toLowerCase().includes(searchTerm) ||
      vehicle.vehicle_rego_number.toLowerCase().includes(searchTerm) ||
      vehicle.model_name.toLowerCase().includes(searchTerm) ||
      vehicle.make_name.toLowerCase().includes(searchTerm)
    )
  }

  // Reset selected customer when org unit changes
  useEffect(() => {
    setSelectedId(null)
  }, [activeOrgUnit])

  return (
    <StyledPaper>
      <ManagementTableContext.Provider value={tableContextValue}>
        <CustomAllotment>
          <Allotment.Pane minSize={350}>
            <StyledAllotmentPanel>
              <StyledManagementTable
                title={'Vehicles'}
                subtitle={`${vehicles.length} vehicles`}
                onAddClick={() => { setAddVehicleFormOpen(true) }}
              >
                {vehicles.filter(vehicleSearchFilter).map(({ VEHICLE_ID, vehicle_identifier, model_name, make_name, file_image_src_data, vehicle_rego_number }) => (
                  <TableMenuItem
                    active={selectedId === VEHICLE_ID}
                    key={VEHICLE_ID}
                    onClick={() => {
                      setSelectedId((prevId) => prevId === VEHICLE_ID ? null : VEHICLE_ID)
                    }}
                  >
                    <StyledDetails container flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                      {file_image_src_data && <StyledImageContainer item>
                        <StyledImage src={file_image_src_data && file_image_src_data.substring(4, file_image_src_data.length - 1)} />
                      </StyledImageContainer>}
                      <Grid item>
                        <StyledTitle variant="body1">
                          {make_name} {model_name}
                        </StyledTitle>
                        <StyledRegoId variant="body1">
                          {vehicle_rego_number} {vehicle_rego_number && vehicle_identifier && '-'} {vehicle_identifier}
                        </StyledRegoId>
                      </Grid>
                    </StyledDetails>
                  </TableMenuItem>
                ))}
              </StyledManagementTable>
            </StyledAllotmentPanel>
          </Allotment.Pane>
          {isTablet && selectedId && (
            <Allotment.Pane minSize={350}>
              <StyledAllotmentPanel>
                <VehicleDetailsFragment />
              </StyledAllotmentPanel>
            </Allotment.Pane>
          )}
        </CustomAllotment>
        <Modal title={'Add Vehicle'} open={isAddVehicleFormOpen} onClose={() => { setAddVehicleFormOpen(false) }} disabledBackdropClose>
          <VehicleForm
            isEditForm={false}
            editable
            onFormSubmitSuccess={() => { setAddVehicleFormOpen(false) }}
          />
        </Modal>
        {!isTablet && (
          <Modal
            fullWidth
            open={!!selectedId}
          >
            <VehicleDetailsFragment />
          </Modal>
        )}
      </ManagementTableContext.Provider>
    </StyledPaper>
  )
}

const StyledDetails = styled(Grid)`
  position: relative;
  height: 100%;
`

const StyledImageContainer = styled(Grid)`
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: ${props => props.theme.typography.pxToRem(8)};
`

const StyledImage = styled('img')`
  max-height: 100%;
  width: ${props => props.theme.typography.pxToRem(60)};
  background-color: ${props => props.theme.palette.background.paper};
`

const StyledTitle = styled(Typography)`
  font-weight: 600;
`

const StyledRegoId = styled(Typography)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
`
