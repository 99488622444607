import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { OrgUnit } from 'src/types/OrgUnit'

type Settings = {
  navSidebarOpen: boolean
  activeOrgUnit?: OrgUnit
  operationActiveVehicleId?: string | null
}

export const initialState: Settings = {
  navSidebarOpen: false,
  activeOrgUnit: undefined,
  operationActiveVehicleId: null,
}

export const { reducer, actions } = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setNavSidebarOpen: (state, action: PayloadAction<Settings['navSidebarOpen']>) => {
      state.navSidebarOpen = action.payload
    },
    setActiveOrgUnit: (state, action: PayloadAction<Settings['activeOrgUnit']>) => {
      state.activeOrgUnit = action.payload
    },
    setOperationActiveVehicleId: (state, action: PayloadAction<Settings['operationActiveVehicleId']>) => {
      state.operationActiveVehicleId = action.payload
    },
  },
})

export const { setNavSidebarOpen, setActiveOrgUnit, setOperationActiveVehicleId } = actions