import { faPowerOff } from '@fortawesome/pro-regular-svg-icons'
import { Alert, Grid, Typography, styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { VISApiService } from 'src/apis/VISApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { Button } from 'src/components/Button/Button'
import { Icon } from 'src/components/Icon/Icon'
import { Modal } from 'src/components/Modal/Modal'
import { VehicleSummary } from 'src/components/VehicleSummary/VehicleSummary'
import { vehiclesSelectors } from 'src/store/adapters/vehicles'
import { useAppSelector } from 'src/store/store'
import { pxToRem, scrollbarStyle } from 'src/styles/themes'
import { ConfirmationForm } from './ConfirmationForm'

export type VehicleSummaryFragmentProps = {
  //
}

export const VehicleSummaryFragment: React.FC<VehicleSummaryFragmentProps> = () => {
  const customerType = useAppSelector(state => state.user.ui_type) || ''
  const isWaipa = customerType === 'WAIPA'

  const operationActiveVehicleId = useAppSelector((state) => state.settings.operationActiveVehicleId) || ''
  const activeVehicle = useAppSelector((state) => vehiclesSelectors.selectById(state, operationActiveVehicleId))
  const [isConfirmMobilisedOpen, setConfirmMobilisedOpen] = useState<boolean>(false)
  const [isConfirmImmobilisedOpen, setConfirmImmobilisedOpen] = useState<boolean>(false)
  const [vehicleControlToken, setVehicleControlToken] = useState<string | null>(null)
  const [vehicleGuid, setVehicleGuid] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(false) // For initial summary load
  const { setInfoMessage, setErrorMessage } = useAlertContext()

  // Retreive vehicle control token
  useEffect(() => {
    if (!activeVehicle) return

    setLoading(true)
    CarshareApiService.get<{ x_api_key: string, vehicle_guid: string }>('control', {
      VEHICLE_ID: activeVehicle?.VEHICLE_ID || '',
    }).then((response) => {
      if (response.message) {
        setErrorMessage(response.message)
      }

      setVehicleControlToken(response.results?.[0]?.x_api_key)
      setVehicleGuid(response.results?.[0]?.vehicle_guid)
      setLoading(false)
      setLoaded(true)
    }).catch((error) => {
      setErrorMessage(error.message)
      setLoading(false)
      setLoaded(true)
    })
  }, [activeVehicle])

  const onMobilised = () => {
    if (!vehicleControlToken || !vehicleGuid) {
      setErrorMessage('Failed to retrieve the control token for this vehicle. Verify that you have permission.')

      return
    }

    setLoading(true)

    VISApiService.post<{ message: string }>('action', {
      Action: 'enable',
      Lat: 0,
      Lng: 0,
      VehicleGuid: vehicleGuid,
    }, {
      'X-Api-Key': vehicleControlToken,
    }).then((response) => {
      setConfirmMobilisedOpen(false)
      setInfoMessage(response.message)
      setLoading(false)
    }).catch((error) => {
      setErrorMessage(error.message)
      setLoading(false)
    })
  }

  const onImmobilised = () => {
    if (!vehicleControlToken || !vehicleGuid) {
      setErrorMessage('Failed to retrieve the control token for this vehicle. Verify that you have permission.')

      return
    }

    setLoading(true)

    VISApiService.post<{ message: string }>('action', {
      Action: 'disable',
      Lat: 0,
      Lng: 0,
      VehicleGuid: vehicleGuid,
    }, {
      'X-Api-Key': vehicleControlToken,
    }).then((response) => {
      setConfirmImmobilisedOpen(false)
      setInfoMessage(response.message)
      setLoading(false)
    }).catch((error) => {
      setErrorMessage(error.message)
      setLoading(false)
    })
  }

  const onLock = () => {
    if (!vehicleControlToken || !vehicleGuid) {
      setErrorMessage('Failed to retrieve the control token for this vehicle. Verify that you have permission.')

      return
    }

    setLoading(true)

    VISApiService.post<{ message: string }>('action', {
      Action: 'Lock',
      Lat: 0,
      Lng: 0,
      VehicleGuid: vehicleGuid,
    }, {
      'X-Api-Key': vehicleControlToken,
    }).then((response) => {
      setConfirmMobilisedOpen(false)
      setInfoMessage(response.message)
      setLoading(false)
    }).catch((error) => {
      setErrorMessage(error.message)
      setLoading(false)
    })
  }

  const onUnlock = () => {
    if (!vehicleControlToken || !vehicleGuid) {
      setErrorMessage('Failed to retrieve the control token for this vehicle. Verify that you have permission.')

      return
    }

    setLoading(true)

    VISApiService.post<{ message: string }>('action', {
      Action: 'Unlock',
      Lat: 0,
      Lng: 0,
      VehicleGuid: vehicleGuid,
    }, {
      'X-Api-Key': vehicleControlToken,
    }).then((response) => {
      setConfirmImmobilisedOpen(false)
      setInfoMessage(response.message)
      setLoading(false)
    }).catch((error) => {
      setErrorMessage(`Failed to reach the VIS server. ${error.message}`)
      setLoading(false)
    })
  }


  return (
    <StyledContainer>
      {(loaded && (!vehicleControlToken || !vehicleGuid)) && (
        <StyledAlert severity={'info'} icon={false}>
          Failed to retrieve the control token for this vehicle.
        </StyledAlert>
      )}
      <StyledVehicleSummaryFragment
        vehicleImage={''}
        onLockClick={onLock}
        onUnlockClick={onUnlock}
        loading={!activeVehicle || loading}
        disableAction={!vehicleGuid || !vehicleControlToken}
        disabled={!vehicleGuid || !vehicleControlToken || isWaipa}
      >
        <VehicleSummary list sx={{ paddingLeft: pxToRem(8), paddingRight: pxToRem(4) }}>
          <VehicleSummary item>
            <Typography variant="body1">
              Fuel Range
            </Typography>
            <Typography variant="body1">
              N/A
            </Typography>
          </VehicleSummary>
          <VehicleSummary item>
            <Typography variant="body1">
              Odometer
            </Typography>
            <Typography variant="body1">
              N/A
            </Typography>
          </VehicleSummary>
          <VehicleSummary item>
            <Typography variant="body1">
              Battery
            </Typography>
            <Typography variant="body1">
              N/A
            </Typography>
          </VehicleSummary>
        </VehicleSummary>
        <Grid container flexDirection={'row'} spacing={2} justifyContent={'center'} sx={{ marginTop: pxToRem(8) }}>
          <Grid item xs={6} sm={6}>
            <Button
              fullWidth
              primary
              disabled={!vehicleGuid || !vehicleControlToken}
              startIcon={
                <Icon icon={faPowerOff} />
              }
              onClick={() => setConfirmMobilisedOpen(true)}
            >
              Mobilise
            </Button>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button
              fullWidth
              outlined
              disabled={!vehicleGuid || !vehicleControlToken}
              startIcon={
                <Icon icon={faPowerOff} />
              }
              onClick={() => setConfirmImmobilisedOpen(true)}
            >
              Immobilise
            </Button>
          </Grid>
        </Grid>
        <Modal title={'Mobilise Confirmation'} open={isConfirmMobilisedOpen} onClose={() => { setConfirmMobilisedOpen(false) }} disabledBackdropClose>
          <ConfirmationForm
            action="mobilise vehicle"
            confirmationTerm={activeVehicle?.vehicle_rego_number || ''}
            onFormSubmitSuccess={onMobilised}
          />
        </Modal>
        <Modal title={'Immobilise Confirmation'} open={isConfirmImmobilisedOpen} onClose={() => { setConfirmImmobilisedOpen(false) }} disabledBackdropClose>
          <ConfirmationForm
            action="immobilise vehicle"
            confirmationTerm={activeVehicle?.vehicle_rego_number || ''}
            onFormSubmitSuccess={onImmobilised}
          />
        </Modal>
      </StyledVehicleSummaryFragment>
    </StyledContainer>
  )
}

const StyledContainer = styled('div')`
  height: calc(100dvh - ${props => props.theme.typography.pxToRem(127)});
  overflow: auto;
  ${scrollbarStyle}
  margin-left: ${props => props.theme.typography.pxToRem(-36)};
  margin-right: ${props => props.theme.typography.pxToRem(-36)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-left: ${props => props.theme.typography.pxToRem(-16)};
    margin-right: ${props => props.theme.typography.pxToRem(-16)};
    min-height: calc(${props => props.theme.typography.pxToRem(62 * 11 + 69 - 115)});
    max-height: calc(80vh - ${props => props.theme.typography.pxToRem(116)});
    height: 100%;
  }
`

const StyledAlert = styled(Alert)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: ${props => props.theme.typography.pxToRem(16)};
    margin-left: ${props => props.theme.typography.pxToRem(16)};
    margin-right: ${props => props.theme.typography.pxToRem(16)};
  }
`

const StyledVehicleSummaryFragment = styled(VehicleSummary)`
  height: 100%;
  width: 100%;
  margin-bottom: ${props => props.theme.typography.pxToRem(-26)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-bottom: 0;
    width: 100%;

    > div {
      border-radius: 0 0 ${props => props.theme.typography.pxToRem(20)} 0;
    }
  }
`