import { styled } from '@mui/material'
import { motion } from 'framer-motion'
import { ComponentProps } from 'react'

export type AnimatedPageProps = ComponentProps<typeof motion.div> & {
  children: React.ReactNode
}

const animations = {
  initial: { opacity: 0, y: 0 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 0 },
}

export const AnimatedPage: React.FC<AnimatedPageProps> = ({ children, ...props }) => {
  return (
    <StyledMotionDiv
      {...props}
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.2 }}
    >
      {children}
    </StyledMotionDiv>
  )
}

const StyledMotionDiv = styled(motion.div)`
  ${(props) => props.theme.breakpoints.up('md')} {
    height: 100%;
  }
`