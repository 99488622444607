/* eslint-disable @typescript-eslint/no-explicit-any*/
import { MenuItem, Popover, Typography, styled } from '@mui/material'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { scrollbarStyle } from 'src/styles/themes'
import { MapVehicleCard } from './MapVehicleCard'
import { MarkerDataType } from './models'

export type MapVehicleToolTipProps = {
  data: MarkerDataType[]
  onCardCtaClick?: (data: MarkerDataType) => void
}

export const MapVehicleToolTip: React.FC<MapVehicleToolTipProps> = ({ data, onCardCtaClick }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null)
  const [dataPoint, setDataPoint] = useState<MarkerDataType | null>(data.length === 1 ? data[0] : null)
  const [isPopoverOpen, setPopoverOpen] = useState(false)

  const vehicleNumber = data.length

  function handleClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>, dataItem: MarkerDataType) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget)
      setDataPoint(dataItem)
    }

    setPopoverOpen(true)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const popoverEnter = () => {
    setPopoverOpen(true)
  }

  const handleClose = () => {
    setPopoverOpen(false)
  }

  const getVehicleCard = () => {
    if (!dataPoint) return null

    const imageSrc = dataPoint.file_image_src_data && dataPoint.file_image_src_data.substring(4, dataPoint.file_image_src_data.length - 1) || ''
    const pickupDate = dataPoint.booking_date_pickup || ''
    const pickupTime = dataPoint.booking_time_pickup || ''
    const pickupDateTime = dayjs(`${pickupDate} ${pickupTime}`)
    const pickupDateTimeString = pickupDateTime.format('MMM D, YYYY h:mm A')

    const dropoffDate = dataPoint.booking_date_dropoff || ''
    const dropoffTime = dataPoint.booking_time_dropoff || ''
    const dropoffDateTime = dayjs(`${dropoffDate} ${dropoffTime}`)
    const dropoffDateTimeString = dropoffDateTime.format('MMM D, YYYY h:mm A')

    const startedDateTimeString = dataPoint?.booking_dt_started && dataPoint?.booking_dt_started !== '0000-00-00 00:00:00' && dayjs(dataPoint.booking_dt_started).format('MMM D, YYYY h:mm A')
    const completedDateTimeString = dataPoint?.booking_dt_completed && dataPoint?.booking_dt_completed !== '0000-00-00 00:00:00' && dayjs(dataPoint.booking_dt_completed).format('MMM D, YYYY h:mm A')

    const isIdle = !dataPoint.BOOKING_ID
    const title = isIdle ? (
      <>Vehicle <strong>{dataPoint.vehicleName || ''}</strong> is currently idle.</>
    ) : (
      <strong>{dataPoint.vehicleName || ''} Booking</strong>
    )

    return (
      <MapVehicleCard
        imgSrcUrl={imageSrc}
        title={title}
        isIdle={isIdle}
        description={isIdle ? {} : {
          startDate: pickupDateTimeString,
          endDate: dropoffDateTimeString,
          bookingStartedDate: startedDateTimeString,
          bookingCompletedDate: completedDateTimeString,
          customerName: dataPoint.customer_name,
          customerPhone: dataPoint.customer_phone,
          pickupLocation: dataPoint.location_pickup_name,
          dropoffLocation: dataPoint.location_dropoff_name,
          bookingStatus: dataPoint.status,
        }}
        onCtaClick={() => {
          handleClose()

          if (onCardCtaClick) {
            onCardCtaClick(dataPoint)
          }
        }}
      />
    )
  }

  return (vehicleNumber === 1) ? (
    <StyledSingleVehicleCard className="noisePoint">
      {getVehicleCard()}
    </StyledSingleVehicleCard>
  ) : (
    <div className="cluster">
      <StyledPopupList
        id="Vehicle List Menu"
      >
        <Typography variant="body1" sx={{ fontWeight: 500, width: '100%', textAlign: 'center', marginBottom: '4px' }}>
          Vehicles
        </Typography>
        {data.map((dataItem, index) => {
          return (dataItem.vehicleName !== '') && (
            <StyledMenuItem
              key={index}
              $type={dataItem.type}
              aria-owns="mouse-over-popover"
              aria-haspopup="true"
              disableRipple
              disableTouchRipple
              onClick={(event: any) => handleClick(event, dataItem)}
              onMouseOver={(event: any) => handleClick(event, dataItem)}
              onMouseLeave={handleClose}
            >
              {dataItem.vehicleName}
            </StyledMenuItem>
          )
        })}
      </StyledPopupList>
      <StyledPopover
        id="mouse-over-popover"
        open={isPopoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onMouseEnter={popoverEnter}
        onMouseLeave={handleClose}
      >
        <StyledPopoverContent>
          {dataPoint && getVehicleCard()}
        </StyledPopoverContent>
      </StyledPopover>
    </div>
  )
}

const StyledPopoverContent = styled('div')`
  padding: ${props => props.theme.typography.pxToRem(16)};
`

const StyledPopupList = styled('ul')`
  max-height: ${props => props.theme.typography.pxToRem(200)};
  overflow-y: auto;
  ${scrollbarStyle}
`

const StyledSingleVehicleCard = styled('div')`
  padding: ${props => props.theme.typography.pxToRem(16)};
`

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== '$type',
}) <{ $type?: string }>`
  ${props => props.$type === 'warning' && `
    color: ${props.theme.palette.warning.dark};
  `}

  ${props => props.$type === 'error' && `
    color: ${props.theme.palette.error.dark};
  `}
`

const StyledPopover = styled(Popover)`
  pointer-events: none;

  .MuiPopover-paper {
    pointer-events: auto; 
  }
`