import { createEntityAdapter } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { Booking } from 'src/types/Booking'
import { RootState } from '../store'

export const bookingsAdapter = createEntityAdapter<Booking>({
  selectId: (booking) => booking.BOOKING_ID,
  sortComparer: (a, b) => {
    const dateA = dayjs(a.booking_dt_pickup, 'YYYY-MM-DD HH:mm:ss')
    const dateB = dayjs(b.booking_dt_pickup, 'YYYY-MM-DD HH:mm:ss')

    return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0
  },
})

export const bookingsSelectors = bookingsAdapter.getSelectors<RootState>(
  (state) => state.bookings,
)
