import { faClose } from '@fortawesome/pro-regular-svg-icons'
import { faInfoCircle, faPen } from '@fortawesome/pro-solid-svg-icons'
import { CircularProgress, Grid, ListItemText, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Icon } from 'src/components/Icon/Icon'
import { useManagementTableContext } from 'src/components/ManagementTable/ManagementTableContext'
import { Modal } from 'src/components/Modal/Modal'
import { OutlinedMenuItem } from 'src/components/OutlinedMenuList/OutlinedMenuItem'
import { OutlinedMenuList } from 'src/components/OutlinedMenuList/OutlinedMenuList'
import { useAppDispatch } from 'src/store/store'
import { setupCustomerOrgUnits } from 'src/store/thunks/setupCustomerOrgUnits'
import { pxToRem } from 'src/styles/themes'
import { Customer } from 'src/types/Customer'
import { Manager } from 'src/types/Manager'
import { StyledCloseButton, StyledContainer, StyledEditButton, StyledEditIcon, StyledInfoIcon } from '../../Management/StyledDetailsFragment'
import { OrgUnitForm } from '../OrgUnitForm/OrgUnitForm'


type OrgUnitDetailsFragmentProps = {
  //
}

export const OrgUnitDetailsFragment: React.FC<OrgUnitDetailsFragmentProps> = () => {
  const [isEditOrgUnitFormOpen, setEditOrgUnitFormOpen] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const [members, setMembers] = useState<Customer[] | undefined>()
  const [managers, setManagers] = useState<Manager[] | undefined>()
  const { selectedId, setSelectedId } = useManagementTableContext()

  useEffect(() => {
    if (selectedId) {
      dispatch(setupCustomerOrgUnits({ CUSTOMER_ID: selectedId }))
    }
  }, [selectedId])

  const getMembersHandler = async () => {
    const response = await CarshareApiService.get<Customer>('getOrgUnitMembers', { ORG_UNIT_ID: selectedId })

    setMembers(response.results)
  }

  const getManagerHandler = async () => {
    const response = await CarshareApiService.get<Manager>('getOrgUnitManagers', { ORG_UNIT_ID: selectedId })

    setManagers(response.results)
  }

  useEffect(() => {
    getMembersHandler()
    getManagerHandler()
  }, [selectedId])

  return (
    <StyledContainer>
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <StyledEditButton text round onClick={() => { setEditOrgUnitFormOpen(true) }}>
            <Typography variant="h6">Details</Typography>
            <StyledEditIcon icon={faPen} />
          </StyledEditButton>
        </Grid>
        <Grid item>
          <StyledCloseButton onClick={() => setSelectedId(null)}>
            <Icon icon={faClose} />
          </StyledCloseButton>
        </Grid>
      </Grid>
      <OrgUnitForm isEditForm={true} orgUnitId={selectedId || ''} editable={false} />

      <Grid container sx={{ marginTop: pxToRem(24) }} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
        <Grid item>
          <Typography variant="h6">Managers</Typography>
        </Grid>
        <Tooltip title="Go to Settings > Team Members to update a manager's org unit.">
          <Grid item>
            <StyledInfoIcon icon={faInfoCircle} />
          </Grid>
        </Tooltip>
      </Grid>

      <OutlinedMenuList>
        {managers && managers?.map(manager => {
          return (
            <OutlinedMenuItem key={manager.MANAGER_ID}>
              <ListItemText>
                {manager.team_member_name}
              </ListItemText>
            </OutlinedMenuItem>
          )
        })}
        {!managers && (
          <OutlinedMenuItem disabled>
            <ListItemText>
              <CircularProgress color="secondary" size={32} />
            </ListItemText>
          </OutlinedMenuItem>
        )}
        {(managers?.length === 0) && (
          <OutlinedMenuItem disabled>
            <ListItemText>
              No manager found.
            </ListItemText>
          </OutlinedMenuItem>
        )}
      </OutlinedMenuList>

      <Grid container sx={{ marginTop: pxToRem(24) }} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
        <Grid item>
          <Typography variant="h6">Users</Typography>
        </Grid>
        <Tooltip title="Go to Management > Customers to update a customer's org unit.">
          <Grid item>
            <StyledInfoIcon icon={faInfoCircle} />
          </Grid>
        </Tooltip>
      </Grid>

      <OutlinedMenuList>
        {members && members?.map(member => {
          return (
            <OutlinedMenuItem key={member.CUSTOMER_ID}>
              <ListItemText>
                {member.customer_name}
              </ListItemText>
            </OutlinedMenuItem>
          )
        })}
        {!members && (
          <OutlinedMenuItem disabled>
            <ListItemText>
              <CircularProgress color="secondary" size={32} />
            </ListItemText>
          </OutlinedMenuItem>
        )}
        {members?.length === 0 && (
          <OutlinedMenuItem disabled>
            <ListItemText>
              No member found.
            </ListItemText>
          </OutlinedMenuItem>
        )}
      </OutlinedMenuList>
      <Modal title={'Edit Org Unit Details'} open={isEditOrgUnitFormOpen} onClose={() => { setEditOrgUnitFormOpen(false) }} disabledBackdropClose>
        <OrgUnitForm
          isEditForm={true}
          orgUnitId={selectedId || ''}
          editable={true}
          onFormSubmitSuccess={() => { setEditOrgUnitFormOpen(false) }}
        />
      </Modal>
    </StyledContainer>
  )
}
