import { faEye } from '@fortawesome/pro-regular-svg-icons'
import { ButtonBase, Grid, styled } from '@mui/material'
import React, { ComponentProps, useRef } from 'react'
import { Icon } from '../Icon/Icon'
import { useQuillContext } from './QuillContext'

export type QuillToolbarProps = ComponentProps<typeof StyledToolbar> & {
  //
}

export const QuillToolbar: React.FC<QuillToolbarProps> = ({ ...props }) => {
  const { htmlContent, togglePreview } = useQuillContext()
  const toolbarRef = useRef<HTMLDivElement>(null)

  return (
    <StyledToolbar ref={toolbarRef} id="toolbar" {...props}> 
      <Grid container justifyContent={'space-between'}>
        <Grid item>

          <select className="ql-size" defaultValue={''}>
            <option></option>
            <option value="large"></option>
            <option value="huge"></option>
          </select>
          
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
          <button className="ql-strike"></button>

          <button className="ql-link"></button>

          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>

          <button className="ql-indent" value="-1"></button>
          <button className="ql-indent" value="+1"></button>

        </Grid>
        <Grid item>
          <StyledPreviewButton disableRipple onClick={togglePreview} disabled={!htmlContent}>
            <StyledIcon icon={faEye } />
            <p>
              Preview
            </p>
          </StyledPreviewButton>
        </Grid>
      </Grid>

    </StyledToolbar>
  )
}

const StyledToolbar = styled('div')`
  font-family: 'Inter', 'Roboto', sans-serif !important;
`

const StyledPreviewButton = styled(ButtonBase)`
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
  float: right;
  display: flex !important;
  flex-direction: row;
  width: auto !important;

  &.Mui-disabled {
    color: ${(props) => props.theme.palette.text.disabled};
  }
  
`

const StyledIcon = styled(Icon)`
  font-size: ${(props) => props.theme.typography.pxToRem(18)};
  padding-right: ${(props) => props.theme.typography.pxToRem(4)};
`
