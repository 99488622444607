import axios, { AxiosInstance, AxiosResponse } from 'axios'
import axiosRetry from 'axios-retry'
import { store } from 'src/store/store'
import { VISResources } from './VISResources'

export type VISResourcesEndpoint = keyof typeof VISResources

export type VISAPIResponse<TResult> = {
  status: string
  message: string
  results: TResult[]
}

const orcodaVISClient = axios.create({
  baseURL: process.env.REACT_APP_VIS_API_HOST,
})

axiosRetry(orcodaVISClient, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000
  },
  // Optionally you can define conditions to determine whether to retry or not
  retryCondition: (error) => {
    // Only retry for specific conditions, for example when we get a network error
    return error.response === undefined
  },
})

orcodaVISClient.interceptors.request.use(config => {
  const state = store.getState()
  const sessionId = state.user.sessionid

  config.headers['X-Session-ID'] = sessionId

  // Add a session Id in the payload
  if (config.method === 'post' && config.data) {
    if (!config.data.SessionId) {
      config.data.SessionId = sessionId
    }
  }

  return config
}, error => {
  return Promise.reject(error)
})

class OrcodaVISApiService {
  private instance: AxiosInstance

  public resources = VISResources

  constructor() {
    this.instance = orcodaVISClient
  }

  async get<TResult>(endpoint: VISResourcesEndpoint, params?: object, headers?: object): Promise<TResult> {
    const response: AxiosResponse<TResult> = await this.instance.get(this.resources[endpoint], { params, headers })

    return response.data
  }

  async post<TResult>(endpoint: VISResourcesEndpoint, data?: object, headers?: object): Promise<TResult> {
    const response: AxiosResponse<TResult> = await this.instance.post(this.resources[endpoint], data, {
      headers,
    })

    return response.data
  }
}

export const VISApiService = new OrcodaVISApiService()
