import { Grid, styled } from '@mui/material'
import { Button } from 'src/components/Button/Button'
import { Icon } from 'src/components/Icon/Icon'
import { IconButton } from 'src/components/IconButton/IconButton'

export const StyledContainer = styled(Grid)`
  width: 100%;
  padding: ${props => props.theme.typography.pxToRem(16)} 0;
  margin-bottom: ${props => props.theme.typography.pxToRem(92)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding: ${props => props.theme.typography.pxToRem(16)};
    margin-bottom: unset;
  }
`

export const StyledEditButton = styled(Button)`
  margin-left: ${props => props.theme.typography.pxToRem(-14)};
  margin-top: ${props => props.theme.typography.pxToRem(-14)};
`

export const StyledEditIcon = styled(Icon)`
  font-size: ${props => props.theme.typography.pxToRem(16)};
  margin-left: ${props => props.theme.typography.pxToRem(8)};
`

export const StyledAddButton = styled(Button)`
  margin-right: ${props => props.theme.typography.pxToRem(-14)};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  font-weight: 500;

  .MuiSvgIcon-root {
    font-size: ${props => props.theme.typography.pxToRem(16)};
  }
`

export const StyledCloseButton = styled(IconButton)`
  margin-top: ${props => props.theme.typography.pxToRem(-12)};
  margin-right: ${props => props.theme.typography.pxToRem(-12)};
`

export const StyledInfoIcon = styled(Icon)`
  padding: 0;
  margin-left: ${props => props.theme.typography.pxToRem(8)};
  margin-top: ${props => props.theme.typography.pxToRem(4)};
  font-size: ${props => props.theme.typography.pxToRem(16)};
  color: ${props => props.theme.palette.text.primary};
  cursor: help;
`