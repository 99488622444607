/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Checkbox, FormControlLabel, Grid } from '@mui/material'
import { ComponentProps, useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { HereMapApiService } from 'src/apis/HereMapApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { AutoComplete } from 'src/components/Autocomplete/Autocomplete'
import { Dropdown } from 'src/components/Dropdown/Dropdown'
import { DropdownItem } from 'src/components/Dropdown/DropdownItem'
import { TextField } from 'src/components/Form/TextField'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { StyledForm, StyledFormControl, StyledSubmitButton } from 'src/fragments/StyledForm'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { locationsSelectors } from 'src/store/adapters/locations'
import { orgUnitsSelectors } from 'src/store/adapters/orgUnits'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupLocations } from 'src/store/thunks/setupLocations'
import { Select } from 'src/types/Select'
import { throttle } from 'throttle-debounce'

export type EditLocationFormProps = ComponentProps<typeof StyledForm> & {
  locationId: string
  onFormSubmitSuccess?: () => void
  editable?: boolean
}

export const EditLocationForm: React.FC<EditLocationFormProps> = ({ editable, locationId, onFormSubmitSuccess, ...props }) => {
  const activeLocation = useAppSelector(state => locationsSelectors.selectById(state, locationId))
  const orgUnits = useAppSelector((state) => orgUnitsSelectors.selectAll(state)).slice(1)
  const [orgUnitId, setOrgUnitId] = useState<string>(activeLocation?.ORG_UNIT_ID || '')
  const [name, setName] = useState<string>(activeLocation?.location_name || '')
  const [phoneNumber, setPhoneNumber] = useState<string>(activeLocation?.location_phone || '')
  const [latitude, setLatitude] = useState<string>(activeLocation?.location_lat || '')
  const [longitude, setLongitude] = useState<string>(activeLocation?.location_lng || '')
  const [timezone, setTimezone] = useState<string>(activeLocation?.location_timezone || '')
  const [address, setAddress] = useState<string>(activeLocation?.location_address || '')
  const [addressOptions, setAddressOptions] = useState<any[]>([])
  const [timezoneOptions, setTimezoneOptions] = useState<Select['tuples']>([])
  const [isLocationShared, setIsLocationShared] = useState<boolean>(false)

  const { loading, complete, errorMessage, request } = useApiRequest()
  const { setSuccessMessage } = useAlertContext()
  const dispatch = useAppDispatch()

  const orgUnitOptions = orgUnits.map(orgUnit => ({
    label: orgUnit.org_unit_name,
    orgUnitId: orgUnit.ORG_UNIT_ID,
  }))

  useEffect(() => {
    CarshareApiService.get<Select>('getSelects', {
      kinds: 'common_timezones',
      skipOrgUnit: true,
    }).then((response) => {
      const timezones = response.results?.find(result => result.name === 'common_timezones')?.tuples || []

      setTimezoneOptions(timezones)
    })
  }, [])


  useEffect(() => {
    if (!activeLocation) return

    setOrgUnitId(activeLocation.ORG_UNIT_ID)
    setName(activeLocation.location_name)
    setPhoneNumber(activeLocation.location_phone)
    setLatitude(activeLocation.location_lat)
    setLongitude(activeLocation.location_lng)
    setTimezone(activeLocation.location_timezone)
    setAddress(activeLocation.location_address)
    setIsLocationShared(activeLocation.location_shared === '1')
  }, [activeLocation])

  const createLocationHandler = () => {
    const payload = {
      ORG_UNIT_ID: orgUnitId,
      LOCATION_ID: locationId,
      location_name: name,
      location_address: address,
      location_phone: phoneNumber,
      location_timezone: timezone,
      location_lat: latitude,
      location_lng: longitude,
      location_shared: isLocationShared ? '1' : '0',
    }

    return CarshareApiService.post('editLocation', payload)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    request(createLocationHandler)
  }

  // Address Autocomplete component
  const throttledHereMapFetch = throttle(500, async (value) => {
    const results = await HereMapApiService.get('geocode', {
      q: value,
      in: 'countryCode:AUS,NZL',
    })

    setAddressOptions(results.items)
  })

  const onAddressChanged = (_: any, value: any) => {
    if (value && value.length > 3 && editable) {
      throttledHereMapFetch(value)
    }

    setAddress(value)
  }

  const onAddressSelected = (_: any, value: any) => {
    if (value) {
      setAddress(value.title)
      setLatitude(value.position.lat)
      setLongitude(value.position.lng)
    }
  }

  useEffect(() => {
    if (errorMessage) return

    if (complete && onFormSubmitSuccess) {
      onFormSubmitSuccess()
    }
  }, [errorMessage, complete, onFormSubmitSuccess])

  useEffect(() => {
    if (complete) {
      setSuccessMessage(`Location ${name} was updated successfully.`)
      dispatch(setupLocations({}))
    }
  }, [complete])

  return (
    <StyledForm $isEditable={editable} onSubmit={onSubmit} {...props}>
      <Grid container spacing={2} justifyContent={'center'} flexDirection={'column'} wrap={'nowrap'}>
        <Grid item>
          {errorMessage && (
            <Alert severity={'error'} icon={false}>
              {errorMessage}
            </Alert>
          )}
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Org Unit</TextFieldLabel>
            <Dropdown
              disabled={!editable}
              value={orgUnitOptions.find(orgUnit => orgUnit.orgUnitId === orgUnitId)?.label || ''}
              onSelectItem={(value) => { setOrgUnitId(value) }}
            >
              {orgUnitOptions.map(orgUnit => {
                return (
                  <DropdownItem key={orgUnit.orgUnitId} data-value={orgUnit.orgUnitId}>{orgUnit.label}</DropdownItem>
                )
              })}
            </Dropdown>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Name</TextFieldLabel>
            <TextField
              id="name-textfield"
              disabled={!editable}
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Address</TextFieldLabel>
            <AutoComplete
              id="address-selector"
              disabled={!editable}
              value={{ title: address }}
              placeholder="Search address"
              filterOptions={(x) => x}
              options={addressOptions}
              getOptionLabel={(option: any) => option.title}
              onInputChange={onAddressChanged}
              onChange={onAddressSelected}
              isOptionEqualToValue={(option: any, value: any) => {
                return option.title === value.title
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel>Phone</TextFieldLabel>
            <TextField
              type="tel"
              id="phone-textfield"
              value={phoneNumber}
              disabled={!editable}
              onChange={(e) => {
                setPhoneNumber(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Timezone</TextFieldLabel>
            <Dropdown
              value={timezoneOptions.find(option => option.name === timezone)?.text || ''}
              disabled={!editable}
              onSelectItem={(value) => { setTimezone(value) }}
            >
              {timezoneOptions.map(tz => {
                return (
                  <DropdownItem key={tz.name} data-value={tz.name}>{tz.text}</DropdownItem>
                )
              })}
            </Dropdown>
          </StyledFormControl>
        </Grid>
        {editable &&
          <>
            <Grid item>
              <StyledFormControl>
                <TextFieldLabel disabled>Latitude</TextFieldLabel>
                <TextField
                  type="text"
                  value={latitude}
                  disabled
                  id="latitude-textfield"
                  onChange={(e) => {
                    setLatitude(e.target.value)
                  }}
                />
              </StyledFormControl>
            </Grid>
            <Grid item>
              <StyledFormControl>
                <TextFieldLabel disabled>Longitude</TextFieldLabel>
                <TextField
                  type="text"
                  value={longitude}
                  disabled
                  id="longitude-textfield"
                  onChange={(e) => {
                    setLongitude(e.target.value)
                  }}
                />
              </StyledFormControl>
            </Grid>
            <Grid item>
              <FormControlLabel
                disabled={!editable}
                control={<Checkbox checked={isLocationShared} onChange={(ev) => { setIsLocationShared(ev.target.checked) }} />}
                label="Share location between all org units"
              />
            </Grid>
            <Grid item>
              <StyledSubmitButton
                primary
                fullWidth
                type="submit"
                disabled={loading || !name || !address || !timezone || !orgUnitId}
              >
                Update Location
              </StyledSubmitButton>
            </Grid>
          </>
        }
      </Grid>
    </StyledForm >
  )
}
