/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext, useContext, useState } from 'react'

export type AlertContextType = {
  successMessage: string | null,
  errorMessage: string | null,
  infoMessage: string | null,
  setSuccessMessage: (message: string | null) => void,
  setErrorMessage: (message: string | null) => void,
  setInfoMessage: (message: string | null) => void,
}

export const AlertContext = createContext<AlertContextType>({
  successMessage: null,
  errorMessage: null,
  infoMessage: null,
  setSuccessMessage: () => { },
  setErrorMessage: () => { },
  setInfoMessage: () => { },
})

export const useAlertContext = () => {
  const context = useContext(AlertContext)

  if (context === undefined) {
    throw new Error(
      'useAlertContext must be used within a AlertContextProvider',
    )
  }

  return context
}

export const useAlertState = () => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [infoMessage, setInfoMessage] = useState<string | null>(null)

  return {
    successMessage,
    errorMessage,
    infoMessage,
    setSuccessMessage,
    setErrorMessage,
    setInfoMessage,
  }
}
