/* eslint-disable @typescript-eslint/no-empty-function */
import dayjs, { Dayjs } from 'dayjs'
import { createContext, useContext } from 'react'

export enum FrequencyType {
  None = 'none',
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Fortnightly = 'fortnightly',
}

export type RecurrenceType = {
  startDate: Dayjs
  frequency: FrequencyType
  numberOfRepetitions: number
  endDate: Dayjs | null
}

export type RecurrenceDay = {
  key: number
  title: string
  symbol: string
}

export type Option = {
  key: string
  title: string
}

export type RecurrenceContextType = {
  recurrence: RecurrenceType
  setRecurrence: React.Dispatch<React.SetStateAction<RecurrenceType>>
}

export const getInitialRecurrence = () => ({
  startDate: dayjs(),
  endDate: dayjs(),
  frequency: FrequencyType.None,
  numberOfRepetitions: 1,
})

const contextInitValue: RecurrenceContextType = {
  recurrence: {
    startDate: dayjs(),
    endDate: dayjs(),
    frequency: FrequencyType.None,
    numberOfRepetitions: 1,
  } as RecurrenceType,
  setRecurrence: () => { },
}

export const RecurrenceContext = createContext<RecurrenceContextType>(contextInitValue)

export const useRecurrenceContext = () => {
  const context = useContext(RecurrenceContext)

  if (context === undefined) {
    throw new Error(
      'useRecurrenceContext must be used within a RecurrenceContext.Provider',
    )
  }

  return context
}