/* eslint-disable @typescript-eslint/no-explicit-any */
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { Alert, Box, Grid, Tooltip } from '@mui/material'
import { ComponentProps, useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { Dropdown, StyledIcon } from 'src/components/Dropdown/Dropdown'
import { DropdownItem } from 'src/components/Dropdown/DropdownItem'
import { TextField } from 'src/components/Form/TextField'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { StyledForm, StyledFormControl, StyledSubmitButton } from 'src/fragments/StyledForm'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { thirdPartiesSelectors } from 'src/store/adapters/thirdParties'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupThirdParties } from 'src/store/thunks/setupThirdParties'
import { Select } from 'src/types/Select'

export type ThirdPartyFormProps = ComponentProps<typeof StyledForm> & {
  isEditForm?: boolean,
  onFormSubmitSuccess?: () => void
  editable?: boolean
  thirdPartyId?: string
}

export const ThirdPartyForm: React.FC<ThirdPartyFormProps> = ({ onFormSubmitSuccess, isEditForm, editable, thirdPartyId, ...props }) => {
  const [name, setName] = useState<string>('')
  const [manufacturer, setManufacturer] = useState<string>('')
  const [manufacturerOptions, setManufacturerOptions] = useState<Select['tuples']>([])
  const [oauthClientId, setOauthClientId] = useState<string>('')
  const [oauthUsername, setOauthUserName] = useState<string>('')
  const [oauthPassword, setOauthPassword] = useState<string>('')
  const selectedThirdPaty = useAppSelector(state => thirdPartiesSelectors.selectById(state, thirdPartyId || ''))

  const { loading, complete, errorMessage, request } = useApiRequest()
  const { setSuccessMessage } = useAlertContext()
  const dispatch = useAppDispatch()

  const submitHandler = () => {
    const payload = {
      THIRD_PARTY_ID: thirdPartyId,
      third_party_name: name,
      MAKE_ID: manufacturer,
      third_party_encrypted_client_id: oauthClientId,
      third_party_encrypted_username: oauthUsername,
      third_party_encrypted_password: oauthPassword,
    }

    if (isEditForm) {
      const editPayload = {
        ...payload,
        THIRD_PARTY_ID: thirdPartyId,
      }

      return CarshareApiService.post('editThirdParty', editPayload)
    }

    return CarshareApiService.post('addThirdParty', payload)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    request(submitHandler)
  }

  useEffect(() => {
    CarshareApiService.get<Select>('getSelects', {
      kinds: 'makes',
    }).then((response) => {
      const makes = response.results.find(result => result.name === 'makes')?.tuples || []

      setManufacturerOptions(makes)
    })
  }, [])

  useEffect(() => {
    if (!isEditForm || !selectedThirdPaty) return

    setName(selectedThirdPaty?.third_party_name)
    setManufacturer(selectedThirdPaty?.MAKE_ID)

    if (!editable) {
      // Only set these fields when the form is view only mode
      setOauthClientId(selectedThirdPaty?.third_party_encrypted_client_id)
      setOauthUserName(selectedThirdPaty?.third_party_encrypted_username)
      setOauthPassword(selectedThirdPaty?.third_party_encrypted_password)
    }
  }, [isEditForm, selectedThirdPaty])

  useEffect(() => {
    if (errorMessage) return

    if (complete && onFormSubmitSuccess) {
      onFormSubmitSuccess()
    }
  }, [errorMessage, complete, onFormSubmitSuccess])

  useEffect(() => {
    if (complete) {
      setSuccessMessage(`Third Party ${name} was ${isEditForm ? 'updated' : 'created'} successfully.`)
      dispatch(setupThirdParties())
    }
  }, [complete])

  return (
    <StyledForm onSubmit={onSubmit} $isEditable={editable} {...props}>
      <Grid container spacing={2} justifyContent={'center'} flexDirection={'column'}>
        <Grid item>
          {errorMessage && (
            <Alert severity={'error'} icon={false}>
              {errorMessage}
            </Alert>
          )}
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Third Party Name</TextFieldLabel>
            <TextField
              id="first-name-textfield"
              required
              disabled={!editable}
              value={name || ''}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Manufacturer</TextFieldLabel>
            <Dropdown
              value={manufacturerOptions.length === 0 ? 'Loading...' : manufacturerOptions.find(option => option.name === manufacturer)?.text || ''}
              onSelectItem={(value) => { setManufacturer(value) }}
              disabled={!editable}
            >
              {manufacturerOptions.map(option => {
                return (
                  <DropdownItem key={option.name} data-value={option.name}>{option.text}</DropdownItem>
                )
              })}
            </Dropdown>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>

            <TextFieldLabel>
              Oauth client ID {!editable && '(Encrypted)'}
              {editable && <Tooltip placement="bottom-start" title="The Client ID is provided by the third party platform. This field will be encryped once submit.">
                <Box sx={{ display: 'inline-block' }}>
                  <StyledIcon icon={faInfoCircle} />
                </Box>
              </Tooltip>}
            </TextFieldLabel>

            <TextField
              type="text"
              value={oauthClientId || ''}
              disabled={!editable}
              placeholder="Oauth client ID"
              onChange={(e) => {
                setOauthClientId(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel>
              Oauth username {!editable && '(Encrypted)'}

              {editable && <Tooltip placement="bottom-start" title="The Client username is provided by the third party platform. This field will be encryped once submit.">
                <Box sx={{ display: 'inline-block' }}>
                  <StyledIcon icon={faInfoCircle} />
                </Box>
              </Tooltip>}
            </TextFieldLabel>
            <TextField
              type="text"
              value={oauthUsername || ''}
              disabled={!editable}
              placeholder="Oauth username"
              onChange={(e) => {
                setOauthUserName(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel>
              Oauth password {!editable && '(Encrypted)'}

              {editable && <Tooltip placement="bottom-start" title="The Client password is provided by the third party platform. This field will be encryped once submit.">
                <Box sx={{ display: 'inline-block' }}>
                  <StyledIcon icon={faInfoCircle} />
                </Box>
              </Tooltip>}
            </TextFieldLabel>
            <TextField
              type="text"
              value={oauthPassword || ''}
              disabled={!editable}
              placeholder="Oauth password"
              onChange={(e) => {
                setOauthPassword(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        {editable && <Grid item>
          <StyledSubmitButton
            primary
            fullWidth
            type="submit"
            disabled={loading}
          >
            {isEditForm ? 'Edit' : 'Add'} Third Party
          </StyledSubmitButton>
        </Grid>}
      </Grid>
    </StyledForm >
  )
}
