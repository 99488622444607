import { MenuItem, styled } from '@mui/material'
import React, { ComponentProps, useCallback, useContext } from 'react'
import { DropdownContext } from './DropdownContext'


export type DropdownItemProps = Omit<ComponentProps<typeof StyledDropdownItem>, 'disabled'> & {
  children: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: (event: any) => void
  disabled?: boolean
}

export const DropdownItem: React.FC<DropdownItemProps> = ({ children, disabled, onSelect, ...props }) => {
  const dropdownContext = useContext(DropdownContext)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDropdownClick = useCallback((event: any) => {
    event.preventDefault()
    event.stopPropagation()

    if (onSelect) {
      onSelect(event)
    }

    if (dropdownContext.onSelectItem) {
      dropdownContext.onSelectItem(event.target?.getAttribute('data-value'))
    }

    if (dropdownContext.setDropdownOpen) {
      dropdownContext.setDropdownOpen(false)
    }
  }, [dropdownContext, onSelect])

  return (
    <StyledDropdownItem
      disableRipple
      disableTouchRipple
      disableGutters
      onClick={onDropdownClick}
      $disabled={disabled}
      {...props}
    >
      {children}
    </StyledDropdownItem>
  )
}

export const StyledDropdownItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== '$disabled',
}) <{ $disabled?: boolean }>`
  padding: 0;
  margin: 0;
  z-index: 2;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${props => props.theme.palette.text.primary};
  padding: 0 ${(props) => props.theme.typography.pxToRem(16)};

  ${props => props.theme.breakpoints.up('sm')} {
    padding: ${props => props.theme.typography.pxToRem(8)} ${props => props.theme.typography.pxToRem(16)};
  }
  
  &:hover {
    background-color: transparent;
  }
  
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: ${props => props.theme.palette.custom.button.textButtonHoverBackground};
    opacity: 0;
    transition: opacity 0.2s ease;

    ${props => props.theme.breakpoints.up('sm')} {
      top: ${props => props.theme.typography.pxToRem(-8)};
      bottom: ${props => props.theme.typography.pxToRem(-8)};
    }
  }

  &:hover {
    background-color: none !important;

    &:after {
      opacity: 1;
    }
  }

  ${(props) => props.$disabled && `
    pointer-events: none;
    
    &:after {
      opacity: 1;
      background-color: ${props.theme.palette.action.disabledBackground};
    }
  `}
`
