/* eslint-disable @typescript-eslint/no-empty-function */

import Quill from 'quill'
import { createContext, useContext } from 'react'

export type QuillContextType = {
  quill: Quill | null
  showPreview?: boolean
  togglePreview?: () => void
  htmlContent: string
}

export const QuillContext = createContext<QuillContextType>({
  quill: null,
  htmlContent: '',
})

export const useQuillContext = () => {
  const context = useContext(QuillContext)

  if (context === undefined) {
    throw new Error(
      'useQuillContext must be used within a QuillContext.Provider',
    )
  }

  return context
}
