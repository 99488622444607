/* eslint-disable @typescript-eslint/no-empty-function */

import React, { createContext } from 'react'

export const MAX_NUM_SUBLEVEL = 2
export const MAX_NAVITEM_PER_LEVEL = 10

export type MobileSliderContextType = {
  portal: Element | null
  menuState: boolean[]
  menuStack: [number, number][]
  setMenuStack : React.Dispatch<React.SetStateAction<[number, number][]>>
  setMenuState: React.Dispatch<React.SetStateAction<boolean[]>>
  getItemIndex: (level: number, idx: number) => number
  setMenuOpen: (level: number, idx: number) => void | null
  setMenuClose: (level: number, idx: number) => void | null
}

export const MobileSliderContext = createContext<MobileSliderContextType>({
  portal: null,
  menuState: [],
  menuStack: [],
  setMenuStack: () => { },
  setMenuState: () => { },
  getItemIndex: (level = 0, idx = 0) => (MAX_NAVITEM_PER_LEVEL * Math.max(0, (level - 1)) + idx),
  setMenuOpen: () => { },
  setMenuClose: () => { },
})

