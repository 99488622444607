import { faEye } from '@fortawesome/pro-regular-svg-icons'
import { Alert, FormControl, InputAdornment, styled } from '@mui/material'
import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-use'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Button } from 'src/components/Button/Button'
import { TextField } from 'src/components/Form/TextField'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { IconButton } from 'src/components/IconButton/IconButton'
import { Link } from 'src/components/Link/Link'
import { LoginCard } from 'src/components/LoginCard/LoginCard'
import { StyledEyeIcon } from 'src/fragments/StyledForm'
import { RequestHandler, useApiRequest } from 'src/hooks/useApiRequest'
import { useAppDispatch } from 'src/store/store'
import { pxToRem } from 'src/styles/themes'
import { User } from 'src/types/User'

export type ChangePasswordPageProps = {
  //
}

export const ChangePasswordPage: React.FC<ChangePasswordPageProps> = () => {
  const [newPassword, setNewPassword] = useState('')
  const [newPassword2, setNewPassword2] = useState('')
  const [isNewPasswordHidden, setNewPasswordHidden] = useState(true)
  const [isNewPassword2Hidden, setNewPassword2Hidden] = useState(true)
  const [error, setError] = useState('')
  const location = useLocation()
  const navigate = useNavigate()

  const { loading, complete, errorMessage, request } = useApiRequest()
  const dispatch = useAppDispatch()

  const onChangePassword = useCallback(async () => {
    const queryParams = new URLSearchParams(location.search)
    const token = queryParams.get('token')

    const changePasswordHandler: RequestHandler<User> = () => (
      CarshareApiService.post('changePassword', {
        token: token,
        password: newPassword,
        password2: newPassword2,
      })
    )

    await request<User>(changePasswordHandler)
  }, [newPassword, newPassword2, dispatch, request])

  useEffect(() => {
    if (complete) {
      navigate('/')
    }
  }, [complete])

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (newPassword !== newPassword2) {
      setError('Passwords do not match')
    } else {
      setError('')
      onChangePassword()
    }
  }

  return (
    <StyledLoginPage>
      <LoginCard>
        {!complete && !errorMessage && !error && (
          <StyledAlert severity={'info'} icon={false}>
            Enter your new password below.
          </StyledAlert>
        )}
        {complete && (
          <StyledAlert severity={'info'} icon={false}>
            Your password has been successfully reset. You can now <a>log in</a> with your new password.
          </StyledAlert>
        )}
        {(errorMessage || error) && (
          <StyledAlert severity={'error'} icon={false}>
            {error}
          </StyledAlert>
        )}
        <StyledForm onSubmit={onSubmit} >
          <FormControl required error={!!errorMessage}>
            <TextFieldLabel required>New Password</TextFieldLabel>
            <TextField
              required
              value={newPassword || ''}
              type={isNewPasswordHidden ? 'password' : 'text'}
              autoComplete="new-password"
              aria-label="New Password"
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => { setNewPasswordHidden((prev) => !prev) }}
                    >
                      <StyledEyeIcon icon={faEye} />
                    </IconButton>
                  </InputAdornment>,
              }}
              onChange={(e) => {
                setNewPassword(e.target.value)
              }}
            />
            <TextFieldLabel required sx={{ marginTop: pxToRem(16) }}>Confirm New Password</TextFieldLabel>
            <TextField
              required
              value={newPassword2 || ''}
              type={isNewPassword2Hidden ? 'password' : 'text'}
              autoComplete="new-password-2"
              aria-label="New Password 2"
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => { setNewPassword2Hidden((prev) => !prev) }}
                    >
                      <StyledEyeIcon icon={faEye} />
                    </IconButton>
                  </InputAdornment>,
              }}
              onChange={(e) => {
                setNewPassword2(e.target.value)
              }}
            />
          </FormControl>
          <StyledSubmitButton
            primary
            fullWidth
            type="submit"
            disabled={loading}
          >
            Submit
          </StyledSubmitButton>
          <StyledLink href="/">
            Back to login
          </StyledLink>
        </StyledForm>
      </LoginCard>
    </StyledLoginPage>
  )
}

const StyledLoginPage = styled('main')`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.palette.background.default};
`

const StyledForm = styled('form')`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const StyledLink = styled(Link)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
  margin-top: ${props => props.theme.typography.pxToRem(16)};
  text-align: right;
`

const StyledSubmitButton = styled(Button)`
  margin-top: ${props => props.theme.typography.pxToRem(16)};

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => props.theme.typography.pxToRem(28)};
  }
`

const StyledAlert = styled(Alert)`
  align-self: stretch;
  margin-bottom: ${props => props.theme.typography.pxToRem(16)};
`
