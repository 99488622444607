import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Attribute } from 'src/types/Attribute'
import { attributesAdapter } from '../adapters/attributes'

export const initialState = attributesAdapter.getInitialState()

export const { reducer, actions } = createSlice({
  name: 'attributes',
  initialState,
  reducers: {
    addMultipleAttributes: (state, action: PayloadAction<Attribute[]>) => {
      attributesAdapter.upsertMany(state, action.payload)
    },
    replaceAllAttributes: (state, action: PayloadAction<Attribute[]>) => {
      attributesAdapter.setAll(state, action.payload)
    },
    removeAttributes: (state, action: PayloadAction<Attribute[]>) => {
      attributesAdapter.removeMany(state, action.payload.map(attr => attr.ATTRIBUTE_ID))
    },
  },
})

export const { addMultipleAttributes, replaceAllAttributes, removeAttributes } = actions