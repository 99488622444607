import { Typography } from '@mui/material'
import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { RequestHandler, useApiRequest } from 'src/hooks/useApiRequest'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { logoutUser } from 'src/store/thunks/logoutUser'
import { User } from 'src/types/User'

export type LoginRouteProps = {
  children: React.ReactNode
}

export const LoginRoute: React.FC<LoginRouteProps> = ({ children }) => {
  const user = useAppSelector(state => state.user)
  const { loading, complete, errorMessage, request } = useApiRequest()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (user.sessionid !== null) {
      const sessionHandler: RequestHandler<User> = () => (
        CarshareApiService.post('session', {
          sid: user.sessionid,
        })
      )

      request(sessionHandler)
    }
  }, [user, request])

  useEffect(() => {
    if (!loading && errorMessage) {
      dispatch(logoutUser())
    }
  }, [loading, errorMessage])

  return (
    <>
      {loading && <Typography variant="body1">Loading...</Typography>}
      {(user.sessionid === null || !!errorMessage) && children}
      {complete && <Navigate to="/dashboard" />}
    </>
  )
}