import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { formatDay } from 'src/libs/formatDateAndTime'
import { Customer } from 'src/types/Customer'
import { replaceAllCustomers } from '../reducers/customers'


type SetupPayload = {
  ORG_UNIT_ID?: string
}

export const setupCustomers = createAsyncThunk('customers/setup', async ({ ORG_UNIT_ID }: SetupPayload, { dispatch }) => {
  // Retrieve Customers
  const customers = await CarshareApiService.post<Customer>('getCustomers', {
    date: formatDay(new Date()),
    limit: 10000,
    ORG_UNIT_ID,
  })

  if (customers.results) {
    dispatch(replaceAllCustomers(customers.results))
  }
})