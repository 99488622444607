import { CircularProgress, styled } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { AlertContext, useAlertState } from 'src/components/Alert/AlertProvider'
import { withSimpleBoundary } from 'src/components/ErrorBoundary/ErrorBoundary'
import { Modal } from 'src/components/Modal/Modal'
import { NotificationSidebarContext } from 'src/components/NotificationSidebar/NotificationSidebarContext'
import { useNotificationWorker } from 'src/hooks/useNotificationWorker'
import { useAppSelector } from 'src/store/store'
import { BookingForm } from '../Operations'
import { ProtectedRoute } from '../Route/ProtectedRoute'
import { HeaderFragment } from './HeaderFragment'
import { LayoutContext } from './LayoutContext'
import { NavigationFragment } from './NavigationFragment'
import { NotificationSidebarFragment } from './NotificationSidebarFragment'
import { StyledMain } from './StyledLayout'

export type LayoutProps = {
  //
}

const Main = withSimpleBoundary(StyledMain)

export const Layout: React.FC<LayoutProps> = () => {
  const [isNotificationSidebarOpen, setNotificationSidebarOpen] = useState(false)
  const [notificationCount, setNotificationCount] = useState(0)
  const [selectedBookingId, setSelectedBookingId] = useState<string | null>(null)
  const isNavOpen = useAppSelector((state) => state.settings.navSidebarOpen)
  const { loading, loaded } = useAppSelector((state) => state.setup)

  const alertState = useAlertState()
  const notificationSidebarContext = {
    isNotificationSidebarOpen,
    setNotificationSidebarOpen,
    notificationCount,
    setNotificationCount,
  }

  // Register notification worker to pull notifications in the background
  const _ = useNotificationWorker()

  return (
    <LayoutContext.Provider value={{ selectedBookingId, setSelectedBookingId }}>
      <NotificationSidebarContext.Provider value={notificationSidebarContext}>
        <HeaderFragment />
        <NavigationFragment />
        <Main $isNavOpen={isNavOpen}>
          <ProtectedRoute key={window.location.pathname}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <AlertContext.Provider value={alertState}>
                <Outlet />
              </AlertContext.Provider>
            </LocalizationProvider>
          </ProtectedRoute>
        </Main>
        <div id="session-expired-dialog" />
        <div id="modal" />
        <NotificationSidebarFragment />
        <Modal title={'Update Booking'} open={!!selectedBookingId} onClose={() => { setSelectedBookingId(null) }}>
          <BookingForm
            isEditForm
            isReadOnly
            bookingId={selectedBookingId ? selectedBookingId : ''}
            currentDate={dayjs()}
            onFormSubmitSuccess={() => { setSelectedBookingId(null) }}
          />
        </Modal>

        {loading && !loaded &&
          <StyledLoadingOverlay>
            <CircularProgress />
          </StyledLoadingOverlay>
        }
      </NotificationSidebarContext.Provider>
    </LayoutContext.Provider>
  )
}

const StyledLoadingOverlay = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`
