import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { ThirdParty } from 'src/types/ThirdParty'
import { addMultipleThirdParties, removeAllThirdParties } from '../reducers/thirdParties'

export const setupThirdParties = createAsyncThunk('thirdParties/setup', async (_, { dispatch }) => {
  const thirdParties = await CarshareApiService.get<ThirdParty>('getThirdParties')

  if (thirdParties.results) {
    dispatch(removeAllThirdParties())
    dispatch(addMultipleThirdParties(thirdParties.results))
  }

  return thirdParties.results
})