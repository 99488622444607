import { createSlice } from '@reduxjs/toolkit'
import { setupApp } from '../thunks/setupApp'

export const initialState = {
  loading: false,
  loaded: false,
}

export const { reducer, actions } = createSlice({
  name: 'setup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setupApp.pending, (state) => {
        state.loading = true
        state.loaded = false
      })
      .addCase(setupApp.fulfilled, (state) => {
        state.loading = false
        state.loaded = true
      })
  },
})
