import { Grid, styled } from '@mui/material'
import { scrollbarStyle } from '../../styles/themes'
import { useCalendarContext } from './CalenderContext'
import { CurrentTimeline } from './CurrentTimeline'

export type CalendarBookingListProps = {
  children: React.ReactNode
}

export const CalendarBookingList: React.FC<CalendarBookingListProps> = ({ children }) => {
  const { DAY_WIDTH } = useCalendarContext()

  return (
    <StyledCalendarBookingList id="calendarBookingListColumn">
      <StyledCalendarTimeLine container justifyContent={'flex-start'} $dayWidth={DAY_WIDTH}>
        {Array.from({ length: 24 }).map((_, idx) => (
          <StyledCalendarTimeLineItem item key={idx} data-hour={String(idx).padStart(2, '0')} />
        ))}
      </StyledCalendarTimeLine>
      {children}
      <CurrentTimeline />
    </StyledCalendarBookingList >
  )
}

export const StyledCalendarBookingList = styled('div')`
  position: relative;
  display: flex;
  height: calc(100% + ${props => props.theme.typography.pxToRem(62)});
  flex-direction: column;

  ${props => props.theme.breakpoints.up('sm')} {
    overflow: auto;
    height: calc(100% - 52px);
    ${scrollbarStyle}
  }
`

export const StyledCalendarTimeLine = styled(Grid, {
  shouldForwardProp: (prop) => prop !== '$dayWidth',
}) <{ $dayWidth: number }>`
  width: ${props => props.theme.typography.pxToRem(props.$dayWidth)};
  background-color: ${props => props.theme.palette.background.paper};
  z-index: 1;
`

export const StyledCalendarTimeLineItem = styled(Grid)`
  width: calc(100% / 24);
  height: ${props => props.theme.typography.pxToRem(62)};
  position: relative;
  border-bottom: 1px solid ${props => props.theme.palette.divider};

  &:after {
    position: absolute;
    margin-left: ${props => props.theme.typography.pxToRem(-20)};
    margin-top: ${props => props.theme.typography.pxToRem(24)};
    font-family: ${props => props.theme.typography.fontFamily};
    font-size: ${props => props.theme.typography.pxToRem(14)};
    font-weight: 300;
    color: ${props => props.theme.palette.text.secondary};
    content: attr(data-hour) ":00";
  }

  &:before {
    content: "";
    position: absolute;
    top: 75%;
    bottom: 0;
    right: 0;
    width: 1px;
    background-color: ${props => props.theme.palette.divider};
  }

  &:first-of-type {
    &:after {
      display: none;
    }
  }
`
