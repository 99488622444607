import { styled } from '@mui/material'
import { Alert } from 'src/components/Alert/Alert'
import { IconButton } from 'src/components/IconButton/IconButton'
import { ManagementTable } from 'src/components/ManagementTable/ManagementTable'
import { Paper } from 'src/components/Paper/Paper'
import { SubNavSidebar } from 'src/components/SubNavSidebar/SubNavSidebar'
import { scrollbarStyle } from 'src/styles/themes'

export const StyledAlert = styled(Alert)`
  ${(props) => props.theme.breakpoints.up('sm')} {
    margin-top: 0;
    margin-bottom: ${(props) => props.theme.typography.pxToRem(24)};
  }
`

export const StyledContainer = styled('div')`
  padding: 0;

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding: ${(props) => props.theme.typography.pxToRem(24)};
    height: calc(100vh - ${props => props.theme.layout.navHeaderHeight} - ${props => props.theme.typography.pxToRem(48)});
    overflow: auto;
    
    /* Offset scrollbar */
    ${scrollbarStyle};
    padding-right: calc(${props => props.theme.typography.pxToRem(24)} - ${props => props.theme.layout.scrollbarWidth});
  }
`

export const StyledSubNavSidebar = styled(SubNavSidebar)`
  ${(props) => props.theme.breakpoints.up('sm')} {
    display: block;
    height: 100%;
    min-height: calc(100vh - ${props => props.theme.layout.navHeaderHeight});
  }
`

export const StyledPaper = styled(Paper)`
  width: 100%;
  margin-bottom: ${props => props.theme.typography.pxToRem(200)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    min-height: calc(100vh - ${props => props.theme.layout.navHeaderHeight} - ${props => props.theme.typography.pxToRem(48)});
  }
  
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-bottom: unset;
    height: calc(100vh - ${props => props.theme.layout.navHeaderHeight} - ${props => props.theme.typography.pxToRem(48)});
  }
`

export const StyledAllotmentPanel = styled('div')`
  height: 100%;
  width: 100%;
  overflow: auto;
  ${scrollbarStyle};
`

export const StyledManagementTable = styled(ManagementTable)`
  padding: ${props => props.theme.typography.pxToRem(16)};
`

export const StyledCloseButton = styled(IconButton)`
  position: absolute;
  top: ${props => props.theme.typography.pxToRem(4)};
  right: ${props => props.theme.typography.pxToRem(4)};
  z-index: 1;
`