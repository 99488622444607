import { ButtonBase, ClickAwayListener, Grid, InputAdornment, MenuItem, MenuList, Popper, styled, Typography } from '@mui/material'
import React, { ChangeEventHandler, Children, isValidElement, KeyboardEventHandler, MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react'

import { faChevronDown, faSearch } from '@fortawesome/pro-regular-svg-icons'
import { scrollbarStyle } from '../../styles/themes'
import { TextField } from '../Form/TextField'
import { Icon } from '../Icon/Icon'
import { ItemTag } from './ItemTag'
import { DropdownContext, DropdownContextType } from './MultiselectDropdownContext'

export type MultiselectDropdownOption = {
  name: string
  label: string
}

export type MultiselectDropdownProps = {
  children: React.ReactNode
  values: MultiselectDropdownOption[]
  onSelectItem: (value: MultiselectDropdownOption) => void
  error?: boolean
  disabled?: boolean
}

export const MultiselectDropdown: React.FC<MultiselectDropdownProps> = ({ children, values, error, disabled, onSelectItem, ...props }) => {
  const menuListRef = useRef<HTMLUListElement>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [open, setOpen] = useState(false)
  const [width, setWidth] = useState(0)
  const [searchText, setSearchText] = useState('')

  // Handle Popper Opens
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    event.preventDefault()
    
    setAnchorEl(event.currentTarget)
    setOpen((prevOpen) => !prevOpen)
  }

  const onKeyDown: KeyboardEventHandler<HTMLButtonElement> = (event) => {
    if (event.key === 'ArrowDown') {
      setAnchorEl(event.currentTarget)
      setOpen(true)

      if (open && menuListRef) {
        menuListRef?.current?.focus()
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClose = (event: any) => {
    event.stopPropagation()
    event.preventDefault()
    
    setSearchText('')
    setTimeout(() => {
      setOpen(false)
    }, 100)
  }

  // Handle Search
  const handleSearch: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    event.stopPropagation()

    setSearchText(event.target.value)
  }

  const filteredChildren = useMemo(() => {
    return Children.toArray(children).filter((child) => {
      if (isValidElement(child) && child.props.value) {
        const textContent = child.props.children?.toString() || ''
        const dataValue = child.props.value as MultiselectDropdownOption

        return textContent.includes(searchText) || textContent.toLowerCase().includes(searchText) || dataValue.name.includes(searchText)
      }

      return false
    })
  }, [children, searchText])

  const defaultContext: DropdownContextType = useMemo(() => ({
    open,
    values,
    onSelectItem,
    setDropdownOpen: setOpen,
  }), [open, values])

  useEffect(() => {
    if (anchorEl) {
      setWidth(anchorEl.offsetWidth)
    }
  }, [anchorEl])

  const selectedTags = useMemo(() => {
    return values.map((val) => {
      return (
        <ItemTag
          key={val.name}
          value={val}
        >
          {val.label}
        </ItemTag>
      )
    })
  }, [values])

  return (
    <DropdownContext.Provider value={defaultContext}>
      <StyledButton as="div" disableRipple onClick={handleClick} onKeyDown={onKeyDown} $open={open} $error={error} disabled={disabled} {...props}>
        <StyledTitleContainer container justifyContent={'space-between'} wrap={'nowrap'}>
          <StyledTagsContainer item xs={10}>
            {selectedTags}
          </StyledTagsContainer>
          <Grid item>
            <StyledIcon icon={faChevronDown} />
          </Grid>
        </StyledTitleContainer>
      </StyledButton>
      <StyledPopper
        $width={width}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        disablePortal
      >
        <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown" touchEvent="onTouchStart">
          <StyledMenuList ref={menuListRef}>
            <MenuItem
              disableRipple
              disableTouchRipple
              disableGutters
              style={{ backgroundColor: 'transparent' }}
              onClickCapture={e => { e.stopPropagation(); e.preventDefault() }}
              onKeyDown={e => e.stopPropagation()}
            >
              <StyledTextField
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <StyledIcon icon={faSearch} />
                    </InputAdornment>
                  ),
                }}
              />
            </MenuItem>
            {filteredChildren}
          </StyledMenuList>
        </ClickAwayListener>
      </StyledPopper>
    </DropdownContext.Provider >
  )
}


export const StyledButton = styled(ButtonBase, { shouldForwardProp: (prop) => prop !== '$open' && prop !== '$error' && prop !== '$disabled' }) <{ $open: boolean, $error?: boolean, $disabled?: boolean }>`
  border-radius: ${(props) => props.theme.typography.pxToRem(8)};
  padding: 0 ${(props) => props.theme.typography.pxToRem(16)} 0 ${(props) => props.theme.typography.pxToRem(8)};
  min-height: ${(props) => props.theme.typography.pxToRem(48)};
  background-color: ${(props) => props.theme.palette.background.paper};
  border: 1px solid ${(props) => props.$open ? props.theme.palette.text.primary : props.theme.palette.custom.formFieldBorder};
  box-shadow: ${(props) => props.theme.palette.custom.formFieldBoxShadow};
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;

  ${props => props.$disabled && `
    background-color: ${props.theme.palette.background.default};
    border-color: ${props.theme.palette.divider};
    box-shadow: none;
    opacity: 0.5;
  `}


  ${(props) => props.$open && `
    &:after {
      opacity: 1;
    }
  `}

  ${(props) => props.$error && `
    border-color: ${props.theme.palette.error.main};

    &:after {
      opacity: 0;
    }
  `}
`

const StyledTextField = styled(TextField)`
  margin: ${(props) => props.theme.typography.pxToRem(4)} ${(props) => props.theme.typography.pxToRem(8)};
  width: 100%;
  
  .MuiOutlinedInput-root {
    padding: 0;
  }

  .Mui-focused fieldset {
    border-color: ${(props) => props.theme.palette.text.primary};
    box-shadow: none;
  }

  input {
    padding-left: 0;
  }
`

export const StyledTagsContainer = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  overflow: auto;

  ${scrollbarStyle};
`

export const StyledPopper = styled(Popper, { shouldForwardProp: (prop) => prop !== '$width' }) <{ $width: number }>`
  width: ${(props) => props.$width}px;
  z-index: ${(props) => props.theme.zIndex.appBar + 110};
  background-color: ${(props) => props.theme.palette.background.paper};
  border-radius: ${(props) => props.theme.typography.pxToRem(8)};
`

export const StyledDropdownContainer = styled('div')`
  display: inline-block;
`

export const StyledIcon = styled(Icon)`
  color: ${props => props.theme.palette.text.primary};
  margin-left: ${props => props.theme.typography.pxToRem(8)};
  font-size: ${props => props.theme.typography.pxToRem(14)};
`

export const StyledTitleContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  text-overflow: ellipsis;
`

export const StyledDropdownValue = styled(Typography)`
  color: ${props => props.theme.palette.text.primary};
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledMenuList = styled(MenuList)`
  display: flex;
  flex-direction: column;
  border-radius: ${props => props.theme.typography.pxToRem(8)};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  gap: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: auto;
  ${scrollbarStyle};
  max-height: 260px;

  li:last-of-type {
    &:after {
      border-radius: 0 0 ${props => props.theme.typography.pxToRem(8)} ${props => props.theme.typography.pxToRem(8)};
    }
  }
  
  ${props => props.theme.breakpoints.up('sm')} {
    gap: ${props => props.theme.typography.pxToRem(16)};
  }
`
