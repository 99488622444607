import React, { useEffect } from 'react'

interface PreloadImagesProps {
  imageUrls: string[]
}

const PreloadImages: React.FC<PreloadImagesProps> = ({ imageUrls }) => {
  // Preload images
  useEffect(() => {
    const loadImage = (url: string) => {
      return new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image()

        img.src = url
        img.onload = () => resolve(img)
        img.onerror = (err) => reject(err)
      })
    }

    if (imageUrls && imageUrls.length) {
      imageUrls.forEach(url => {
        loadImage(url).catch(err => console.error(`Error preloading image: ${url}`, err))
      })
    }
  }, [imageUrls])

  return null
}

export default PreloadImages