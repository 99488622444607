import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { styled } from '@mui/material/styles'
import React, { useContext } from 'react'

import { Button } from '../Button/Button'
import { Icon } from '../Icon/Icon'
import { MobileSliderContext } from './MobileSliderContext'

export type MobileSliderButtonProps = {
  level: number
  idx: number
  children: React.ReactNode
}

export const MobileSliderButton: React.FC<MobileSliderButtonProps> = ({ level, idx, children, ...props }) => {
  const menuContext = useContext(MobileSliderContext)
  const onClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    menuContext.setMenuOpen(level ?? 0, (idx ?? 0) + 1)
  }

  return (
    <StyledMobileSliderButton
      onClick={onClick}
      fullWidth
      outlined
      {...props}
    >
      {children}
      <StyledCheveronRight icon={faChevronRight} />
    </StyledMobileSliderButton>
  )
}

const StyledCheveronRight = styled(Icon)`
  color: ${props => props.theme.palette.text.primary};
  font-size: ${props => props.theme.typography.pxToRem(14)};
`

const StyledMobileSliderButton = styled(Button)`
  color: ${props => props.theme.palette.text.primary};
  border: 1px solid ${(props) => props.theme.palette.custom.formFieldBorder};
  font-size: ${props => props.theme.typography.pxToRem(16)};
  justify-content: space-between;
  font-weight: 300;
  text-transform: none;
`