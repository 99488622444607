import { useMediaQuery, useTheme } from '@mui/material'
import { Allotment } from 'allotment'
import { useEffect, useState } from 'react'
import { CustomAllotment } from 'src/components/CustomAllotment/CustomAllotment'
import { ManagementTableContext } from 'src/components/ManagementTable/ManagementTableContext'
import { TableMenuItem } from 'src/components/ManagementTable/TableMenuItem'
import { Modal } from 'src/components/Modal/Modal'
import { AddLocationForm, LocationDetailsFragment } from 'src/fragments/Management'
import { locationsSelectors } from 'src/store/adapters/locations'
import { useAppSelector } from 'src/store/store'
import { Location } from 'src/types/Location'
import { StyledAllotmentPanel, StyledManagementTable, StyledPaper } from './StyledManagement'

export type ManagementLocationsProps = {
  //
}

export const ManagementLocations: React.FC<ManagementLocationsProps> = () => {
  const [selectedId, setSelectedId] = useState<string | null>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const [isAddLocationFormOpen, setAddLocationFormOpen] = useState(false)
  const activeOrgUnit = useAppSelector((state) => state.settings.activeOrgUnit)
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'))
  const locations = useAppSelector((state) => locationsSelectors.selectAll(state))

  const tableContextValue = {
    selectedId,
    setSelectedId,
    searchValue,
    setSearchValue,
  }

  const locationSearchFilter = (location: Location) => {
    const searchTerm = searchValue.toLowerCase()

    return (
      location.location_name.toLowerCase().includes(searchTerm) ||
      location.location_address.includes(searchTerm) ||
      location.location_timezone.toLowerCase().includes(searchTerm)
    )
  }

  // Reset selected customer when org unit changes
  useEffect(() => {
    setSelectedId(null)
  }, [activeOrgUnit])

  return (
    <StyledPaper>
      <ManagementTableContext.Provider value={tableContextValue}>
        <CustomAllotment>
          <Allotment.Pane minSize={350}>
            <StyledAllotmentPanel>
              <StyledManagementTable
                title={'Locations'}
                subtitle={`${locations.length} Locations`}
                onAddClick={() => { setAddLocationFormOpen(true) }}
              >
                {locations.filter(locationSearchFilter).map((location) => (
                  <TableMenuItem
                    active={selectedId === location.LOCATION_ID}
                    key={location.LOCATION_ID}
                    onClick={() => {
                      setSelectedId((prevId) => prevId === location.LOCATION_ID ? null : location.LOCATION_ID)
                    }}
                  >
                    {location.location_name}
                  </TableMenuItem>
                ))}
              </StyledManagementTable>
            </StyledAllotmentPanel>
          </Allotment.Pane>
          {isTablet && selectedId && (
            <Allotment.Pane minSize={350}>
              <StyledAllotmentPanel>
                <LocationDetailsFragment />
              </StyledAllotmentPanel>
            </Allotment.Pane>
          )}
        </CustomAllotment>
        <Modal title={'Add Location'} open={isAddLocationFormOpen} onClose={() => { setAddLocationFormOpen(false) }} disabledBackdropClose>
          <AddLocationForm
            onFormSubmitSuccess={() => { setAddLocationFormOpen(false) }}
          />
        </Modal>
        {!isTablet && (
          <Modal
            fullWidth
            open={!!selectedId}
          >
            <LocationDetailsFragment />
          </Modal>
        )}
      </ManagementTableContext.Provider>
    </StyledPaper>
  )
}
