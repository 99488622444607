/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { Dropdown } from 'src/components/Dropdown/Dropdown'
import { DropdownItem } from 'src/components/Dropdown/DropdownItem'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { StyledForm, StyledFormControl, StyledSubmitButton } from 'src/fragments/StyledForm'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { customersSelectors } from 'src/store/adapters/customers'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupCustomerAttributes } from 'src/store/thunks/setupCustomerAttributes'
import { Select } from 'src/types/Select'

export type AddCustomerAttributeFormProps = {
  customerId: string
  onFormSubmitSuccess?: () => void
}

export const AddCustomerAttributeForm: React.FC<AddCustomerAttributeFormProps> = ({ customerId, onFormSubmitSuccess }) => {
  const activeCustomer = useAppSelector(state => customersSelectors.selectById(state, customerId))
  const [attributeId, setAttributeId] = useState<string>('')
  const [attributeOptions, setAttributeOptions] = useState<Select['tuples']>([])
  const { loading, complete, errorMessage, request } = useApiRequest()
  const { setSuccessMessage } = useAlertContext()
  const dispatch = useAppDispatch()

  useEffect(() => {
    CarshareApiService.get<Select>('getSelects', {
      kinds: 'attributes_for_customer',
    }).then((response) => {
      const attributes = response.results?.find(result => result.name === 'attributes_for_customer')?.tuples || [{ name: '1', text: 'VIP' }]

      setAttributeOptions(attributes)
    })
  }, [])

  const submitHandler = () => {
    const payload = {
      CUSTOMER_ID: customerId,
      ATTRIBUTE_ID: attributeId,
    }

    return CarshareApiService.post('addCustomerAttribute', payload)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    request(submitHandler)
  }

  useEffect(() => {
    if (errorMessage) return

    if (complete && onFormSubmitSuccess) {
      onFormSubmitSuccess()
    }
  }, [errorMessage, complete, onFormSubmitSuccess])

  useEffect(() => {
    if (complete) {
      setSuccessMessage(`Customer ${activeCustomer?.customer_first_name} ${activeCustomer?.customer_last_name}'s attributes were updated successfully.`)
      dispatch(setupCustomerAttributes({ CUSTOMER_ID: customerId }))
    }
  }, [complete])

  return (
    <StyledForm $isEditable onSubmit={onSubmit} >
      <Grid container spacing={2} justifyContent={'center'} flexDirection={'column'}>
        <Grid item>
          {errorMessage && (
            <Alert severity={'error'} icon={false}>
              {errorMessage}
            </Alert>
          )}
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Attribute</TextFieldLabel>
            <Dropdown
              value={attributeOptions.find(option => option.name === attributeId)?.text || ''}
              onSelectItem={(value) => { setAttributeId(value) }}
            >
              {attributeOptions.map(option => {
                return (
                  <DropdownItem key={option.name} data-value={option.name}>{option.text}</DropdownItem>
                )
              })}
            </Dropdown>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledSubmitButton
            primary
            fullWidth
            type="submit"
            disabled={loading}
          >
            Add Attribute
          </StyledSubmitButton>
        </Grid>
      </Grid>
    </StyledForm >
  )
}
