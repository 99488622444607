import { useMediaQuery, useTheme } from '@mui/material'
import { Allotment } from 'allotment'
import { useState } from 'react'
import { DownloadableReports } from 'src/apis/CarshareResources'
import { CustomAllotment } from 'src/components/CustomAllotment/CustomAllotment'
import { CustomAllotmentContext } from 'src/components/CustomAllotment/CustomAllotmentContext'
import { TableMenuItem } from 'src/components/ManagementTable/TableMenuItem'
import { Modal } from 'src/components/Modal/Modal'
import { ReportDetailFragment } from 'src/fragments/Reports/ReportDetailFragment'
import { StyledAllotmentPanel, StyledManagementTable, StyledPaper } from './StyledReports'
// @ts-ignore

export type ReportsDownloadsProps = {
  //
}

export const ReportsDownloads: React.FC<ReportsDownloadsProps> = () => {
  const [allotmentSizes, setAllotmentSizes] = useState<number[]>([0, 0])
  const [activeReport, setActiveReport] = useState<DownloadableReports | null>(null)

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <StyledPaper>
      <CustomAllotmentContext.Provider value={{ allotmentSizes, setAllotmentSizes }}>
        <CustomAllotment>
          <StyledAllotmentPanel>
            <StyledManagementTable
              title={'Downloadable Reports'}
            >
              <TableMenuItem
                actionText="Download"
                onClick={() => setActiveReport('getCustomerReport')}
              >
                Customer Report
              </TableMenuItem>
              <TableMenuItem
                actionText="Download"
                onClick={() => setActiveReport('getVehicleReport')}
              >
                Vehicle Report
              </TableMenuItem>
              <TableMenuItem
                actionText="Download"
                onClick={() => setActiveReport('getVehicleUtilisationReport')}
              >
                Vehicle Utilisation Report
              </TableMenuItem>
              <TableMenuItem
                actionText="Download"
                onClick={() => setActiveReport('getBookingReport')}
              >
                Booking Report
              </TableMenuItem>
            </StyledManagementTable>
          </StyledAllotmentPanel>
          {isTablet && activeReport && (
            <Allotment.Pane minSize={600}>
              <StyledAllotmentPanel>
                <ReportDetailFragment onCloseRightPanel={() => { setActiveReport(null) }} activeReport={activeReport} />
              </StyledAllotmentPanel>
            </Allotment.Pane>
          )}
        </CustomAllotment>

        {!isTablet && activeReport && (
          <Modal
            fullWidth
            open={!!activeReport}
            onClose={() => { setActiveReport(null) }}
          >
            <ReportDetailFragment onCloseRightPanel={() => { setActiveReport(null) }} activeReport={activeReport} />
          </Modal>
        )}
      </CustomAllotmentContext.Provider>
    </StyledPaper>

  )
}
