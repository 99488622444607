import { Grid, styled } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useCalendarContext } from './CalenderContext'

export type Booking = {
  id: string
  startDate: string
  startHour: number
  startMinute: number
  endDate: string
  endHour: number
  endMinute: number
  status: 'success' | 'warning' | 'error' | 'cancelled'
  customerName: string
}

export type CalendarBookingRowProps = {
  bookings?: Booking[] | null
  children: (args: { bookingsForThisHour: Booking[] }) => React.ReactNode;
  vehicleId: string
}

export const CalendarBookingRow: React.FC<CalendarBookingRowProps> = ({ bookings, vehicleId, children }) => {
  const { DAY_WIDTH, currentDay, hoveredVehicleId, setHoveredVehicleId, selectedVehicleId, setSelectedVehicleId } = useCalendarContext()
  const rowRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (rowRef && rowRef.current) {
      rowRef.current.addEventListener('mouseover', () => {
        setHoveredVehicleId(vehicleId)
      })

      rowRef.current.addEventListener('mouseout', () => {
        setHoveredVehicleId('')
      })
    }
  }, [vehicleId, rowRef, rowRef.current])

  const onRowClick: React.MouseEventHandler<HTMLDivElement> = (evt) => {
    evt.stopPropagation()

    if (selectedVehicleId === vehicleId) {
      setSelectedVehicleId('')
    } else {
      setSelectedVehicleId(vehicleId)
    }
  }

  return (
    <CalendarBookingRowGrid
      container
      ref={rowRef}
      $active={hoveredVehicleId === vehicleId || selectedVehicleId == vehicleId}
      justifyContent={'flex-start'}
      onClick={onRowClick}
      $dayWidth={DAY_WIDTH}
    >
      {Array.from({ length: 24 }).map((_, idx) => (
        <CalendarBookingRowGridItem item key={idx}>
          {bookings && children({
            bookingsForThisHour: bookings.filter(booking => isBookingInThisHour(booking, currentDay, idx)),
          })}
        </CalendarBookingRowGridItem>
      ))}
    </CalendarBookingRowGrid>
  )
}

const isBookingInThisHour = (booking: Booking, currentDay: string, hour: number): boolean => {
  const startsWithinCurrentHour = booking.startDate === currentDay && booking.startHour === hour
  const startsTodayFromPreviousDays = booking.startDate < currentDay && booking.endDate >= currentDay && hour === 0

  return startsWithinCurrentHour || startsTodayFromPreviousDays
}


const CalendarBookingRowGrid = styled(Grid, { shouldForwardProp: (prop) => prop !== '$active' && prop !== '$dayWidth' }) <{ $active: boolean, $dayWidth: number }>`
  width: ${props => props.theme.typography.pxToRem(props.$dayWidth)};
  height: ${props => props.theme.typography.pxToRem(62)};
  background-color: ${props => props.$active ? props.theme.palette.primary.main : props.theme.palette.background.paper};
  border-bottom: 1px solid ${props => props.theme.palette.divider};
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  > div {
    border-right: 1px solid ${props => props.theme.palette.divider};
  }
`

const CalendarBookingRowGridItem = styled(Grid)`
  width: calc(100% / 24);
  height: 100%;
  position: relative;
`
