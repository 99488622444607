import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Button } from '../Button/Button'


export const ModalHeaderContainer = styled('header')`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.palette.primary.contrastText};
  background-color: ${props => props.theme.palette.custom.sidebar};
  justify-content: space-between;
  align-items: center;
  border-radius: ${props => props.theme.typography.pxToRem(12)} ${props => props.theme.typography.pxToRem(12)} 0 0;
  padding: ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(20)};

  ${(props) => props.theme.breakpoints.up('xs')} {
    padding: ${props => props.theme.typography.pxToRem(12)} ${props => props.theme.typography.pxToRem(24)};
  }
`

export const ModalTitle = styled(Typography)`

`

export const ModalHeaderActionsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.typography.pxToRem(12)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    gap: 0;
    margin-top: ${props => props.theme.typography.pxToRem(48)};
  }
`

export const ModalCloseText = styled(Button)`
  font-weight: 400;
  margin-right: ${props => props.theme.typography.pxToRem(-14)};
  height: ${props => props.theme.typography.pxToRem(36)};

  &:hover {
    background-color: ${props => props.theme.palette.custom.linkColor};
  }
`
