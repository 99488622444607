import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { Button, Grid, Typography, styled } from '@mui/material'
import React from 'react'
import { pxToRem } from '../../styles/themes'
import { Icon } from '../Icon/Icon'

export type MapVehicleCardProps = {
  imgSrcUrl?: string
  title?: React.ReactNode
  description?: {
    startDate?: string
    endDate?: string
    bookingStartedDate?: string
    bookingCompletedDate?: string
    customerName?: string
    customerPhone?: string
    pickupLocation?: string
    dropoffLocation?: string
    bookingStatus?: string
  }
  onCtaClick?: () => void
  isIdle?: boolean
  children?: React.ReactNode
}

export const MapVehicleCard: React.FC<MapVehicleCardProps> = ({ imgSrcUrl, title, description, onCtaClick, isIdle, children }) => {
  return (
    <Grid container sx={{ width: pxToRem(280) }} spacing={pxToRem(8)} justifyContent={'center'} alignItems={'flex-start'}>
      {imgSrcUrl && <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
        <StyledImage src={imgSrcUrl} />
      </Grid>}
      <Grid item xs={12}>
        <StyledTitle variant="body1">
          {title}
        </StyledTitle>
        {!isIdle && (
          <>
            <StyledDescriptionTable>
              <tbody>
                {description?.startDate && <tr>
                  <th>Scheduled pickup</th>
                  <th>{description?.startDate}</th>
                </tr>}
                {description?.endDate && <tr>
                  <th>Scheduled dropoff</th>
                  <th>{description?.endDate}</th>
                </tr>}
                {description?.bookingStartedDate && <tr>
                  <th>Started on</th>
                  <th>{description?.bookingStartedDate}</th>
                </tr>}
                {description?.bookingCompletedDate && <tr>
                  <th>Completed on</th>
                  <th>{description?.bookingCompletedDate}</th>
                </tr>}
                {description?.customerName && <tr>
                  <th>Customer Name</th>
                  <th>{description?.customerName}</th>
                </tr>}
                {description?.customerPhone && <tr>
                  <th>Customer Phone</th>
                  <th>{description?.customerPhone}</th>
                </tr>}
                {description?.pickupLocation && <tr>
                  <th>Pickup Location</th>
                  <th>{description?.pickupLocation}</th>
                </tr>}
                {description?.dropoffLocation && <tr>
                  <th>Dropoff Location</th>
                  <th>{description?.dropoffLocation}</th>
                </tr>}
                {description?.dropoffLocation && <tr>
                  <th>Booking Status</th>
                  <th>{description?.bookingStatus}</th>
                </tr>}
              </tbody>
            </StyledDescriptionTable>

            {children}
            <StyledCta
              fullWidth
              disableRipple
              disabled={isIdle}
              onClick={onCtaClick}
              endIcon={<Icon icon={faArrowRight} />}
            >
              Go to booking
            </StyledCta>
          </>
        )}
      </Grid>
    </Grid>
  )
}

const StyledImage = styled('img')`
  max-height: 100%;
  width: 50%;
  background-color: ${props => props.theme.palette.background.paper};
  margin-right: ${props => props.theme.typography.pxToRem(8)};
`

const StyledTitle = styled(Typography)`
  font-weight: 400;
`

const StyledDescriptionTable = styled('table')`
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  margin-top: ${props => props.theme.typography.pxToRem(4)};
  width: 100%;
  
  th {
    font-weight: 400;
    text-align: left;
  }
`

const StyledCta = styled(Button)`
  min-width: ${(props) => props.theme.typography.pxToRem(140)};
  background-color: #262839;
  color: #fff;
  border-radius: ${(props) => props.theme.typography.pxToRem(8)};
  margin-top: ${(props) => props.theme.typography.pxToRem(12)};
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
  font-weight: 400;
  text-transform: capitalize;
  
  .MuiButton-endIcon {
    margin-left: ${(props) => props.theme.typography.pxToRem(-16)};
    opacity: 0;
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, margin-left 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    .MuiSvgIcon-root {
      font-size: ${(props) => props.theme.typography.pxToRem(16)};
    }
  }

  &.Mui-disabled {
    background-color: rgba(38, 40, 57, 0.6);
    color: rgba(255, 255, 255, 0.8);
  }

  &:hover {
    background-color: #78D1C8;
    color: #262839;
    
    .MuiButton-endIcon {
      opacity: 1;
      margin-left: ${(props) => props.theme.typography.pxToRem(6)};
    }
  }
`
