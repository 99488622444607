/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react'

export type CalendarContextType = {
  DAY_WIDTH: number
  currentDay: string
  selectedVehicleId: string | null
  setSelectedVehicleId: (id: string | null) => void
  hoveredVehicleId: string | null
  setHoveredVehicleId: (id: string | null) => void
}

export const CalendarContext = createContext<CalendarContextType>({
  DAY_WIDTH: 1536,
  currentDay: '',
  selectedVehicleId: null,
  setSelectedVehicleId: () => { },
  hoveredVehicleId: null,
  setHoveredVehicleId: () => { },
})

export const useCalendarContext = () => {
  const context = useContext(CalendarContext)

  if (context === undefined) {
    throw new Error(
      'useCalendarContext must be used within a CalendarContext.Provider',
    )
  }

  return context
}