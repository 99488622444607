import { Typography, styled } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Map } from 'src/components/Map/Map'
import { Paper } from 'src/components/Paper/Paper'
import { useAppSelector } from 'src/store/store'
import { Booking } from 'src/types/Booking'
import { Vehicle } from 'src/types/Vehicle'
import { DashboardMapMarkers } from './MapMarkers'


export type MapFragmentProps = {
  //
}

export const MapFragment: React.FC<MapFragmentProps> = () => {
  const activeOrgUnit = useAppSelector(state => state.settings.activeOrgUnit)
  const [vehicles, setVehicles] = useState<(Partial<Vehicle> & Partial<Booking>)[] | null>(null)

  const getVehicleInfoHandler = useCallback(async () => {
    if (activeOrgUnit) {
      const response = await CarshareApiService.get<Partial<Vehicle> & Partial<Booking>>('getVehiclesLastInfo', {
        ORG_UNIT_ID: activeOrgUnit.ORG_UNIT_ID,
      })

      if (response.results) {
        setVehicles(response.results || [])
      }
    }
  }, [activeOrgUnit])

  useEffect(() => {
    getVehicleInfoHandler()
  }, [activeOrgUnit])

  return (
    <Paper>
      {vehicles ? (
        <StyledDashboardMap
          mapProps={{
            mapOptions: {
              center: { lat: parseFloat(vehicles?.[0]?.position_lat_avg || '-37.810246'), lng: parseFloat(vehicles?.[0]?.position_lng_avg || '144.962768') },
              zoom: 14,
            },
          }}
        >
          <DashboardMapMarkers vehicles={vehicles} />
        </StyledDashboardMap>
      ) : (
        <StyledLoadingContainer>
          <Typography variant="body1">
            Loading...
          </Typography>
        </StyledLoadingContainer>
      )}
    </Paper>
  )
}

const StyledLoadingContainer = styled('div')`
  height: 100vh;
  position: fixed;
  top: 0;

  ${props => props.theme.breakpoints.up('sm')} {
    height: ${props => props.theme.typography.pxToRem(400)};
    position: unset;
    border-radius: ${props => props.theme.typography.pxToRem(20)};
    
    > div {
      border-radius: ${props => props.theme.typography.pxToRem(20)};
    }
  }
`

const StyledDashboardMap = styled(Map)`
  position: fixed;
  top: ${props => props.theme.layout.navHeaderMobileHeight};
  height: calc(100dvh - ${props => props.theme.layout.navHeaderMobileHeight});

  ${props => props.theme.breakpoints.up('sm')} {
    position: unset;
    top: unset;
    height: ${props => props.theme.typography.pxToRem(400)};
    border-radius: ${props => props.theme.typography.pxToRem(20)};
    
    > div {
      border-radius: ${props => props.theme.typography.pxToRem(20)};
    }
  }
`
