import React from 'react'

import { VehicleSummaryList, VehicleSummaryListProps } from './VehicleSummaryList'
import { VehicleSummaryListItem, VehicleSummaryListItemProps } from './VehicleSummaryListItem'
import { VehicleSummaryWrapper, VehicleSummaryWrapperProps } from './VehicleSummaryWrapper'

export type VehicleSummaryProps =
  | ({ wrapper?: true; list?: false; item?: false; } & VehicleSummaryWrapperProps)
  | ({ wrapper?: false; list: true; item?: false; } & VehicleSummaryListProps)
  | ({ wrapper?: false; list?: false; item: true; } & VehicleSummaryListItemProps)


export const VehicleSummary: React.FC<VehicleSummaryProps> = ({ wrapper, list, item, ...props }) => {
  if (list) {
    return <VehicleSummaryList {...(props as VehicleSummaryListProps)} />
  } else if (item) {
    return <VehicleSummaryListItem {...(props as VehicleSummaryListItemProps)} />
  }

  return <VehicleSummaryWrapper {...(props as VehicleSummaryWrapperProps)} />
}
