import { faAdd, faClose, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { faPen } from '@fortawesome/pro-solid-svg-icons'
import { CircularProgress, Grid, ListItemText, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Button } from 'src/components/Button/Button'
import { Icon } from 'src/components/Icon/Icon'
import { useManagementTableContext } from 'src/components/ManagementTable/ManagementTableContext'
import { Modal } from 'src/components/Modal/Modal'
import { OutlinedMenuItem } from 'src/components/OutlinedMenuList/OutlinedMenuItem'
import { OutlinedMenuList } from 'src/components/OutlinedMenuList/OutlinedMenuList'
import { pxToRem } from 'src/styles/themes'
import { ManagerOrgUnit } from 'src/types/Manager'
import { StyledAddButton, StyledCloseButton, StyledContainer, StyledEditButton, StyledEditIcon } from '../StyledDetailsFragment'
import { AddTeamMemberOrgUnitForm } from '../TeamMemberForm/AddTeamMemberOrgUnitForm'
import { TeamMemberForm } from '../TeamMemberForm/TeamMemberForm'


type TeamMemberDetailsFragmentProps = {
  //
}

export const TeamMemberDetailsFragment: React.FC<TeamMemberDetailsFragmentProps> = () => {
  const [isEditTeamMemberFormOpen, setEditTeamMemberFormOpen] = useState<boolean>(false)
  const [isEditOrgUnitFormOpen, setEditOrgUnitFormOpen] = useState<boolean>(false)
  const [isConfirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState<boolean>(false)
  const { selectedId, setSelectedId } = useManagementTableContext()
  const [orgUnits, setOrgUnits] = useState<ManagerOrgUnit[]>()
  const [selectedOrgUnit, setSelectedOrgUnit] = useState<string>('')

  const getOrgUnitHandler = useCallback(async () => {
    const response = await CarshareApiService.get<ManagerOrgUnit>('getTeamMemberOrgUnits', { TEAM_MEMBER_ID: selectedId })

    setOrgUnits(response.results)
  }, [selectedId, setOrgUnits])

  const onAddOrgUnitSuccess = useCallback(() => {
    getOrgUnitHandler()
    setEditOrgUnitFormOpen(false)
  }, [getOrgUnitHandler, setEditOrgUnitFormOpen])

  const onConfirmDeleteOrgUnit = (orgUnitId: string) => {
    setSelectedOrgUnit(orgUnitId)
    setConfirmDeleteDialogOpen(true)
  }

  const removeOrgUnitHandler = async () => {
    const toRemoveOrgUnit = orgUnits?.find(orgUnit => orgUnit.ORG_UNIT_ID === selectedOrgUnit)

    const payload = {
      TEAM_MEMBER_ID: toRemoveOrgUnit?.TEAM_MEMBER_ID,
      ORG_UNIT_ID: toRemoveOrgUnit?.ORG_UNIT_ID,
      MANAGER_ID: toRemoveOrgUnit?.MANAGER_ID,
    }

    return CarshareApiService.post('removeTeamMemberOrgUnit', payload).then(() => {
      getOrgUnitHandler()
      setConfirmDeleteDialogOpen(false)
    })
  }

  useEffect(() => {
    getOrgUnitHandler()
  }, [selectedId])

  return (
    <StyledContainer>
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <StyledEditButton text round onClick={() => { setEditTeamMemberFormOpen(true) }}>
            <Typography variant="h6">Details</Typography>
            <StyledEditIcon icon={faPen} />
          </StyledEditButton>
        </Grid>
        <Grid item>
          <StyledCloseButton onClick={() => setSelectedId(null)}>
            <Icon icon={faClose} />
          </StyledCloseButton>
        </Grid>
      </Grid>
      <TeamMemberForm isEditForm={true} editable={false} teamMemberId={selectedId || ''} />

      <Grid container sx={{ marginTop: pxToRem(24) }} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Typography variant="h6">Org Units</Typography>
        </Grid>
        <Grid item>
          <StyledAddButton text round startIcon={<Icon icon={faAdd} />} onClick={() => { setEditOrgUnitFormOpen(true) }}>
            Add Org Unit
          </StyledAddButton>
        </Grid>
      </Grid>

      <OutlinedMenuList>
        {orgUnits && orgUnits.map(orgUnit => {
          return (
            <OutlinedMenuItem key={orgUnit.ORG_UNIT_ID} onClick={() => onConfirmDeleteOrgUnit(orgUnit.ORG_UNIT_ID)}>
              <ListItemText>
                {orgUnit.org_unit_name}
              </ListItemText>
              <Icon icon={faTrash} />
            </OutlinedMenuItem>
          )
        })}
        {!orgUnits && (
          <OutlinedMenuItem disabled>
            <ListItemText>
              <CircularProgress color="secondary" size={32} />
            </ListItemText>
          </OutlinedMenuItem>
        )}
        {orgUnits?.length === 0 && (
          <OutlinedMenuItem disabled>
            <ListItemText>
              This member is not a part of any org units.
            </ListItemText>
          </OutlinedMenuItem>
        )}
      </OutlinedMenuList>

      <Modal title={'Edit Team Member Details'} open={isEditTeamMemberFormOpen} onClose={() => { setEditTeamMemberFormOpen(false) }} disabledBackdropClose>
        <TeamMemberForm teamMemberId={selectedId || ''} isEditForm editable onFormSubmitSuccess={() => setEditTeamMemberFormOpen(false)} />
      </Modal>

      <Modal title={'Add Org Unit'} open={isEditOrgUnitFormOpen} onClose={() => { setEditOrgUnitFormOpen(false) }} disabledBackdropClose>
        <AddTeamMemberOrgUnitForm teamMemberId={selectedId || ''} onFormSubmitSuccess={onAddOrgUnitSuccess} />
      </Modal>

      <Modal title={'Delete Confirmation'} open={isConfirmDeleteDialogOpen} onClose={() => { setConfirmDeleteDialogOpen(false) }} disabledBackdropClose>
        <Typography variant="body1">Are you sure you want to remove this team member from that org unit?</Typography>
        <Grid container justifyContent={'flex-end'} sx={{ marginTop: pxToRem(16) }} spacing={1}>
          <Grid item>
            <Button text onClick={() => { setConfirmDeleteDialogOpen(false) }}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button primary onClick={removeOrgUnitHandler}>Confirm</Button>
          </Grid>
        </Grid>
      </Modal>
    </StyledContainer>
  )
}
