import { useMediaQuery, useTheme } from '@mui/material'
import { Allotment } from 'allotment'
import { useState } from 'react'
import { CustomAllotment } from 'src/components/CustomAllotment/CustomAllotment'
import { ManagementTableContext } from 'src/components/ManagementTable/ManagementTableContext'
import { TableMenuItem } from 'src/components/ManagementTable/TableMenuItem'
import { Modal } from 'src/components/Modal/Modal'
import { ContentDetailsFragment } from 'src/fragments/Settings/ContentDetailsFragment/ContentDetailsFragment'
import { StyledAllotmentPanel, StyledManagementTable, StyledPaper } from './StyledSettings'

export type SettingsContentsProps = {
  //
}

export const SettingsContents: React.FC<SettingsContentsProps> = () => {
  const [selectedId, setSelectedId] = useState<string | null>(null)
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'))

  const tableContextValue = {
    selectedId,
    setSelectedId,
  }


  return (
    <StyledPaper>
      <ManagementTableContext.Provider value={tableContextValue}>
        <CustomAllotment>
          <Allotment.Pane minSize={350}>
            <StyledAllotmentPanel >
              <StyledManagementTable
                title={'Contents'}
                subtitle={'Manage the contents of the web app'}
              >
                <TableMenuItem
                  active={selectedId === 'Onboarding'}
                  key={'Onboarding'}
                  onClick={() => {
                    setSelectedId((prev) => (prev === 'Onboarding' ? null : 'Onboarding'))
                  }}
                >
                  Onboarding Page
                </TableMenuItem>
              </StyledManagementTable>
            </StyledAllotmentPanel>
          </Allotment.Pane>
          {isTablet && selectedId && (
            <Allotment.Pane minSize={350}>
              <StyledAllotmentPanel>
                <ContentDetailsFragment />
              </StyledAllotmentPanel>
            </Allotment.Pane>
          )}
        </CustomAllotment>
        {!isTablet && (
          <Modal
            fullWidth
            open={!!selectedId}
          >
            <ContentDetailsFragment />
          </Modal>
        )}
      </ManagementTableContext.Provider>
    </StyledPaper>
  )
}
