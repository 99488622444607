import { styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { useCalendarContext } from './CalenderContext'

const calculateTimelinePosition = (dayWidth: number): number => {
  const hourWidth = dayWidth / 24
  const now = new Date()
  const hour = now.getHours()
  const minute = now.getMinutes()

  return hour * hourWidth + (minute / 60) * hourWidth
}

export const CurrentTimeline: React.FC = () => {
  const { currentDay, DAY_WIDTH } = useCalendarContext()
  const [position, setPosition] = useState(calculateTimelinePosition(DAY_WIDTH))
  const [visibility, setVisibility] = useState('hidden')

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPosition(calculateTimelinePosition(DAY_WIDTH))
    }, 60 * 1000)

    setPosition(calculateTimelinePosition(DAY_WIDTH))

    const now = new Date()
    const currentDate = new Date(currentDay)

    setVisibility(now.toDateString() === currentDate.toDateString() ? 'visible' : 'hidden')

    return () => {
      clearInterval(intervalId)
    }
  }, [DAY_WIDTH, currentDay, setPosition])

  return (
    <StyledCurrentTimeline $position={position} $visibility={visibility} />
  )
}

export const StyledCurrentTimeline = styled('div', {
  shouldForwardProp: (prop) => prop !== '$position' && prop !== '$visibility',
}) <{ $position: number, $visibility: string }>`
  position: absolute;
  top: ${props => props.theme.typography.pxToRem(48)};
  bottom: 0;
  left: 0;
  width: 1px;
  background-color: #f54C4C;
  z-index: 3;
  visibility: ${props => props.$visibility};
  transform: translateX(${props => props.theme.typography.pxToRem(props.$position)});
  transition: transform 2s ease-in-out;
`
