import { createEntityAdapter } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { Manager } from 'src/types/Manager'

export const membersAdapter = createEntityAdapter<Manager>({
  selectId: (member) => member.TEAM_MEMBER_ID,
})

export const membersSelectors = membersAdapter.getSelectors<RootState>(
  (state) => state.members,
)
