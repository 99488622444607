import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Passenger } from 'src/types/Passenger'
import { replaceAllPassengers } from '../reducers/passengers'

export const setupPassengers = createAsyncThunk('passengers/setup', async (_, { dispatch }) => {
  const passengers = await CarshareApiService.get<Passenger>('getPassengers', {})

  if (passengers.results) {
    dispatch(replaceAllPassengers(passengers.results))
  }
})