/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext, useContext } from 'react'

export type MapContextType = {
  ui: H.ui.UI
  map: H.Map
  platform: H.service.Platform
  markerGroup?: H.map.Group
  clusterLayer: H.map.layer.ObjectLayer | null;
  setMarkerGroup: (group: H.map.Group) => void
}

export const MapContext = createContext<MapContextType>(null as never)

export const useMapContext = () => {
  const context = useContext(MapContext)

  if (context === undefined) {
    throw new Error(
      'useMapContext must be used within a MapContextProvider',
    )
  }

  return context
}
