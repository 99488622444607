import { createAsyncThunk } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import { CarshareApiService } from 'src/apis/CarshareApiService'


export const logoutUser = createAsyncThunk('auth/logoutUser', async (_, { dispatch }) => {
  CarshareApiService.post('logout')

  // Purge Redux Persist Storage State
  dispatch({
    type: PURGE,
    key: 'user',
  })
})