import { faAdd, faClose, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { faPen } from '@fortawesome/pro-solid-svg-icons'
import { CircularProgress, Grid, ListItemText, Typography, styled } from '@mui/material'
import { useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Button } from 'src/components/Button/Button'
import { Icon } from 'src/components/Icon/Icon'
import { useManagementTableContext } from 'src/components/ManagementTable/ManagementTableContext'
import { Modal } from 'src/components/Modal/Modal'
import { OutlinedMenuItem } from 'src/components/OutlinedMenuList/OutlinedMenuItem'
import { OutlinedMenuList } from 'src/components/OutlinedMenuList/OutlinedMenuList'
import { locationsSelectors } from 'src/store/adapters/locations'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupLocationFacilities } from 'src/store/thunks/setupLocationFacilities'
import { pxToRem } from 'src/styles/themes'
import { LocationFacility } from 'src/types/Location'
import { AddLocationFacilityForm } from '../LocationForm/AddLocationFacilityForm'
import { EditLocationForm } from '../LocationForm/EditLocationForm'
import { StyledAddButton, StyledCloseButton, StyledContainer, StyledEditButton, StyledEditIcon } from '../StyledDetailsFragment'


type LocationDetailsFragmentProps = {
  //
}

export const LocationDetailsFragment: React.FC<LocationDetailsFragmentProps> = () => {
  const [isEditLocationFormOpen, setEditLocationFormOpen] = useState<boolean>(false)
  const [isAddLocationFacilityFormOpen, setAddLocationFacilityFormOpen] = useState<boolean>(false)
  const [selectedFacility, setSelectedFacility] = useState<LocationFacility>()
  const [isConfirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState<boolean>(false)
  const { selectedId, setSelectedId } = useManagementTableContext()
  const [memoSelectedId, setMemoSelectedId] = useState<string | null>(null)
  const activeLocation = useAppSelector(state => locationsSelectors.selectById(state, memoSelectedId || ''))
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (selectedId) {
      dispatch(setupLocationFacilities({ LOCATION_ID: selectedId }))

      // Use to prevent flickering redux state data when closing details modal on mobile.
      setMemoSelectedId(selectedId)
    }
  }, [selectedId])

  const onConfirmDeleteFacility = (facility: LocationFacility) => {
    setSelectedFacility({
      LOCATION_FACILITY_ID: facility.LOCATION_FACILITY_ID,
      location_facility_name: facility.location_facility_name,
    })

    setConfirmDeleteDialogOpen(true)
  }

  const removeFacilityHandler = () => {
    CarshareApiService.post('removeLocationFacility', {
      ...selectedFacility,
      LOCATION_ID: selectedId || '',
    }).then(() => {
      dispatch(setupLocationFacilities({ LOCATION_ID: selectedId || '' }))
    }).then(() => {
      setConfirmDeleteDialogOpen(false)
    })
  }

  return (
    <StyledContainer>
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <StyledEditButton text round onClick={() => { setEditLocationFormOpen(true) }}>
            <Typography variant="h6">Details</Typography>
            <StyledEditIcon icon={faPen} />
          </StyledEditButton>
        </Grid>
        <Grid item>
          <StyledCloseButton onClick={() => setSelectedId(null)}>
            <Icon icon={faClose} />
          </StyledCloseButton>
        </Grid>
      </Grid>
      <EditLocationForm locationId={selectedId || ''} editable={false} />

      <Grid container sx={{ marginTop: pxToRem(24) }} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} wrap={'nowrap'}>
        <Grid item>
          <Typography variant="h6">Facilities</Typography>
        </Grid>
        <Grid item>
          <StyledAddButton text round startIcon={<Icon icon={faAdd} />} onClick={() => { setAddLocationFacilityFormOpen(true) }}>
            Add Facility
          </StyledAddButton>
        </Grid>
      </Grid>

      <OutlinedMenuList>
        {activeLocation?.location_facilities?.map(facility => {
          return (
            <OutlinedMenuItem key={facility.LOCATION_FACILITY_ID} onClick={() => { onConfirmDeleteFacility(facility) }}>
              <ListItemText>
                {facility.location_facility_name}
              </ListItemText>
              <Icon icon={faTrash} />
            </OutlinedMenuItem>
          )
        })}
        {!activeLocation?.location_facilities && (
          <OutlinedMenuItem disabled>
            <ListItemText>
              <CircularProgress color="secondary" size={32} />
            </ListItemText>
          </OutlinedMenuItem>
        )}
        {activeLocation?.location_facilities?.length === 0 && (
          <OutlinedMenuItem disabled>
            <ListItemText>
              No facility found.
            </ListItemText>
          </OutlinedMenuItem>
        )}
      </OutlinedMenuList>

      <Modal title={'Edit Location Details'} open={isEditLocationFormOpen} onClose={() => { setEditLocationFormOpen(false) }} disabledBackdropClose>
        <StyledEditLocationForm
          locationId={selectedId || ''}
          editable={true}
          onFormSubmitSuccess={() => { setEditLocationFormOpen(false) }}
        />
      </Modal>
      <Modal title={'Add Location Facility'} open={isAddLocationFacilityFormOpen} onClose={() => { setAddLocationFacilityFormOpen(false) }} disabledBackdropClose>
        <AddLocationFacilityForm
          locationId={selectedId || ''}
          onFormSubmitSuccess={() => { setAddLocationFacilityFormOpen(false) }}
        />
      </Modal>
      <Modal title={'Delete Confirmation'} open={isConfirmDeleteDialogOpen} onClose={() => { setConfirmDeleteDialogOpen(false) }} disabledBackdropClose>
        <Typography variant="body1">Are you sure you want to remove facility <strong>{selectedFacility?.location_facility_name}</strong> from {activeLocation?.location_name}?</Typography>
        <Grid container justifyContent={'flex-end'} sx={{ marginTop: pxToRem(16) }} spacing={1}>
          <Grid item>
            <Button text onClick={() => { setConfirmDeleteDialogOpen(false) }}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button primary onClick={() => removeFacilityHandler()}>Confirm</Button>
          </Grid>
        </Grid>
      </Modal>
    </StyledContainer>
  )
}


const StyledEditLocationForm = styled(EditLocationForm)`
  width: 100%;

  ${props => props.theme.breakpoints.up('sm')} {
    width: 70vw;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    width: 35vw;
  }
`