import { createEntityAdapter } from '@reduxjs/toolkit'
import { OrgUnit } from 'src/types/OrgUnit'
import { RootState } from '../store'

export const orgUnitsAdapter = createEntityAdapter<OrgUnit>({
  selectId: (orgUnit) => orgUnit.ORG_UNIT_ID,
})

export const orgUnitsSelectors = orgUnitsAdapter.getSelectors<RootState>(
  (state) => state.orgUnits,
)
