import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { OrgUnit } from 'src/types/OrgUnit'
import { addMultipleOrgUnits } from '../reducers/orgUnits'

export const setupOrgUnits = createAsyncThunk('orgUnits/setup', async (_, { dispatch }) => {
  const orgUnits = await CarshareApiService.get<OrgUnit>('retrieveOrgUnits')

  if (orgUnits.results) {
    dispatch(addMultipleOrgUnits(orgUnits.results))
  }

  return orgUnits.results
})