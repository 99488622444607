import { createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Booking } from 'src/types/Booking'
import { addMultipleBookings } from '../reducers/bookings'

type SetupPayload = {
  ORG_UNIT_ID?: string
  currentDate: dayjs.Dayjs
  page?: number
  pageCnt?: number
}

export const setupBookings = createAsyncThunk('bookings/setup', async ({ ORG_UNIT_ID, currentDate }: SetupPayload, { dispatch }) => {
  // Retrieve Bookings
  const bookings = await CarshareApiService.post<Booking>('getBookingsByDate', {
    limit: 10000,
    ORG_UNIT_ID,
    date: dayjs(currentDate).format('YYYY-MM-DD'),
  })

  if (bookings.results) {
    dispatch(addMultipleBookings(bookings.results))
  }
})