import { styled } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import dayjs, { Dayjs } from 'dayjs'
import { useCustomAllotmentContext } from 'src/components/CustomAllotment/CustomAllotmentContext'
import { bookingsSelectors } from 'src/store/adapters/bookings'
import { useAppSelector } from 'src/store/store'
import { scrollbarStyle } from 'src/styles/themes'

export type VehicleBookingTableFragmentProps = {
  onEditBookingClick?: (id: string) => void
  currentDate: Dayjs
}

export const VehicleBookingTableFragment: React.FC<VehicleBookingTableFragmentProps> = ({ onEditBookingClick, currentDate, ...props }) => {
  const operationActiveVehicleId = useAppSelector((state) => state.settings.operationActiveVehicleId) || ''
  const bookings = useAppSelector((state) => bookingsSelectors.selectAll(state)).filter((booking) => {
    return booking.VEHICLE_ID === operationActiveVehicleId
  }).filter((booking) => {
    // check for booking pickup and dropoff date is equal to currentDate
    const pickupDate = dayjs(booking.booking_date_pickup).format('YYYY-MM-DD')
    const dropoffDate = dayjs(booking.booking_date_dropoff).format('YYYY-MM-DD')
    const currentDateFormatted = dayjs(currentDate).format('YYYY-MM-DD')

    return pickupDate === currentDateFormatted || dropoffDate === currentDateFormatted || (pickupDate < currentDateFormatted && dropoffDate > currentDateFormatted)
  })
  const { allotmentSizes } = useCustomAllotmentContext()

  const numColumns = 5
  const columnWidth = Math.min(Math.max(200, Math.floor(allotmentSizes[1] || 1 / numColumns)), 300)

  const columns: GridColDef[] = [
    {
      field: 'pickup',
      headerName: 'Pickup date',
      type: 'dateTime',
      valueFormatter: params => {
        return dayjs(params.value).format('MMM D, YYYY h:mm A')
      },
      disableColumnMenu: true,
      sortable: false,
      minWidth: columnWidth,
    },
    {
      field: 'dropoff',
      headerName: 'Dropoff date',
      type: 'dateTime',
      valueFormatter: params => {
        return dayjs(params.value).format('MMM D, YYYY h:mm A')
      },
      sortable: false,
      minWidth: columnWidth,
    },
    { field: 'name', headerName: 'Full name', description: 'Customer name', disableColumnMenu: true, sortable: false, minWidth: columnWidth },
    // { field: 'km', headerName: 'Estimated kms', type: 'string', disableColumnMenu: true, sortable: false, minWidth: columnWidth },
    { field: 'status', headerName: 'Status', type: 'string', disableColumnMenu: true, sortable: false, minWidth: columnWidth },
  ]

  const rows = bookings.map((booking, idx) => {
    const pickupDate = new Date(booking.booking_date_pickup)

    pickupDate.setHours(+booking.booking_time_pickup_hour)
    pickupDate.setMinutes(+booking.booking_time_pickup_minutes)

    const dropoffDate = new Date(booking.booking_date_dropoff)

    dropoffDate.setHours(+booking.booking_time_dropoff_hour)
    dropoffDate.setMinutes(+booking.booking_time_dropoff_minutes)

    const isCancelled = booking.booking_cancelled && booking.booking_cancelled !== '0000-00-00 00:00:00'

    return {
      id: idx,
      BOOKING_ID: booking.BOOKING_ID,
      pickup: pickupDate,
      dropoff: dropoffDate,
      name: booking.customer_name,
      // km: 'TBC',
      status: isCancelled ? `(Cancelled) ${booking.status}` : booking.status,
    }
  })

  return (
    <StyledDataGrid
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnSelector
      onRowClick={(params) => {
        if (!onEditBookingClick) return

        onEditBookingClick(params.row.BOOKING_ID)
      }}
      initialState={{
        pagination: {
          paginationModel: { page: 0 },
        },
      }}
      {...props}
    />
  )
}

const StyledDataGrid = styled(DataGrid)`
  margin-left: ${props => props.theme.typography.pxToRem(-36)};
  margin-right: ${props => props.theme.typography.pxToRem(-36)};
  height: calc(100dvh - ${props => props.theme.typography.pxToRem(150)});
  font-size: ${props => props.theme.typography.pxToRem(14)};
  overflow: auto;
  ${scrollbarStyle}

  & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus {
    outline: none
  }

  & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus {
    outline: none
  }

  & .MuiDataGrid-columnHeaders {
    .MuiDataGrid-columnHeader:first-of-type {
      padding-left: ${props => props.theme.typography.pxToRem(20)};
    }
  }

  & .MuiDataGrid-row {
    > .MuiDataGrid-cell:first-of-type {
      padding-left: ${props => props.theme.typography.pxToRem(20)};
    }
  }

  ${props => props.theme.breakpoints.up('sm')} {
    margin-left: ${props => props.theme.typography.pxToRem(-16)};
    margin-right: ${props => props.theme.typography.pxToRem(-16)};
    border: none;
    min-height: calc(${props => props.theme.typography.pxToRem(62 * 11 + 69 - 115)});
    height: 100%;

    & .MuiDataGrid-columnHeaders {
      .MuiDataGrid-columnHeader:first-of-type {
        padding-left: ${props => props.theme.typography.pxToRem(16)};
      }
    }

    & .MuiDataGrid-row {
      > .MuiDataGrid-cell:first-of-type {
        padding-left: ${props => props.theme.typography.pxToRem(16)};
      }
    }
  }

  & .MuiTablePagination-toolbar {
    > p {
      font-size: ${props => props.theme.typography.pxToRem(14)};
    }
  }

  & .MuiDataGrid-row {
    cursor: pointer;
    
    &:hover {
      background-color: ${props => props.theme.palette.action.hover};
    }
  }

  & .MuiDataGrid-virtualScroller {
    ${scrollbarStyle}
  }

  & .MuiDataGrid-overlay {
    background-color: ${props => props.theme.palette.background.paper};
  }
`
