import { Link as MuiLink, styled } from '@mui/material'
import { ComponentProps } from 'react'

const StyledLink = styled(MuiLink)`
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
  color: ${(props) => props.theme.palette.text.primary};

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.palette.custom.linkColor};
  }
`

export type LinkdProps = Omit<ComponentProps<typeof StyledLink>, 'variant'> & {
  //
}

export const Link: React.FC<LinkdProps> = ({ ...props }) => (
  <StyledLink
    {...props}
    variant="body1"
  />
)
