import { IconLookup } from '@fortawesome/fontawesome-common-types'
import { styled } from '@mui/material'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Icon } from '../Icon/Icon'

export type DesktopNavItemProps = {
  children: React.ReactNode
  href: string
  icon?: IconLookup
  onClick?: () => void
}

export const DesktopNavItem: React.FC<DesktopNavItemProps> = ({ href, icon, onClick, children }) => {
  const location = useLocation()
  const locationPaths = location.pathname.split('/')
  const hrefPaths = href.split('/')
  const isEqual = locationPaths[1] === hrefPaths[1] && locationPaths[2] === hrefPaths[2]

  return (
    <NavItem onClick={onClick} to={href} $active={isEqual}>
      {icon && <StyledNavIcon icon={icon} />}
      {children}
    </NavItem>
  )
}

export const StyledNavIcon = styled(Icon)`
  font-size: ${(props) => props.theme.typography.pxToRem(18)};
  width: ${(props) => props.theme.typography.pxToRem(48)};
`

export const NavItem = styled(Link, {
  shouldForwardProp: (prop) => prop !== '$active',
}) <{ $active?: boolean }>`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-weight: 400;
  font-size: ${(props) => props.theme.typography.pxToRem(16)};
  letter-spacing: 0.03em;
  display: flex;
  align-items: center;
  height: ${(props) => props.theme.typography.pxToRem(48)};
  text-decoration: none;
  color: ${(props) => props.theme.palette.custom.button.primaryText};
  border-radius: ${(props) => props.theme.typography.pxToRem(8)};
  margin: ${(props) => props.theme.typography.pxToRem(4)} ${(props) => props.theme.typography.pxToRem(8)};
  overflow: hidden;
  
  ${(props) => props.theme.breakpoints.up('sm')} {
    &:hover {
      background-color: ${(props) => props.theme.palette.custom.button.textButtonHoverBackground};
    }
  }

  ${(props) => props.$active && `
    background-image: ${props.theme.palette.custom.navLinkGradient};
  `}
`
