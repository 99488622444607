import { Paper as MuiPaper, styled } from '@mui/material'

export const Paper: React.FC<React.ComponentProps<typeof MuiPaper>> = (props) => {
  return (<StyledPaper elevation={0} {...props}></StyledPaper>)
}

export const StyledPaper = styled(MuiPaper)`
  padding: 0;
  box-shadow: 0;
  height: 100%;

  ${(props) => props.theme.breakpoints.up('sm')} {
    border-radius: ${props => props.theme.typography.pxToRem(20)};
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    border-top: unset;
  }
`