import { faClose } from '@fortawesome/pro-regular-svg-icons'
import { Grid, Typography, styled } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { ComponentProps, useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { DownloadableReports } from 'src/apis/CarshareResources'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { Button } from 'src/components/Button/Button'
import { DatePicker } from 'src/components/DatePicker/DatePicker'
import { Dropdown } from 'src/components/Dropdown/Dropdown'
import { DropdownItem } from 'src/components/Dropdown/DropdownItem'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { Icon } from 'src/components/Icon/Icon'
import { IconButton } from 'src/components/IconButton/IconButton'
import { customersSelectors } from 'src/store/adapters/customers'
import { locationsSelectors } from 'src/store/adapters/locations'
import { orgUnitsSelectors } from 'src/store/adapters/orgUnits'
import { vehiclesSelectors } from 'src/store/adapters/vehicles'
import { useAppSelector } from 'src/store/store'
import { pxToRem } from 'src/styles/themes'
import { StyledFormControl } from '../StyledForm'


type ReportDetailFragmentProps = ComponentProps<typeof Container> & {
  activeReport: DownloadableReports
  onCloseRightPanel: () => void
  //
}

type ReportParams = {
  title: string
  fileName: string
  enabledFilters?: {
    startDate: boolean
    endDate: boolean
    customerId: boolean
    vehicleId: boolean
    locationId: boolean
    orgUnitId: boolean
  }
}

type DropdownOption = {
  name: string | null
  text: string
}

const activeReportMappingInfo: Record<DownloadableReports, ReportParams> = {
  getCustomerReport: {
    title: 'Customer Report',
    fileName: 'customer_report',
  },
  getVehicleReport: {
    title: 'Vehicle Report',
    fileName: 'vehicle_report',
  },
  getVehicleUtilisationReport: {
    title: 'Vehicle Utilisation Report',
    fileName: 'vehicle_utilisation_report',
    enabledFilters: {
      startDate: true,
      endDate: true,
      customerId: false,
      vehicleId: true,
      locationId: true,
      orgUnitId: true,
    },
  },
  getBookingReport: {
    title: 'Booking Report',
    fileName: 'booking_report',
    enabledFilters: {
      startDate: true,
      endDate: true,
      customerId: true,
      vehicleId: true,
      locationId: true,
      orgUnitId: true,
    },
  },
}

export const ReportDetailFragment: React.FC<ReportDetailFragmentProps> = ({ activeReport, onCloseRightPanel, ...props }) => {
  const orgUnits = useAppSelector(state => orgUnitsSelectors.selectAll(state))
  const locations = useAppSelector(state => locationsSelectors.selectAll(state))
  const customers = useAppSelector(state => customersSelectors.selectAll(state))
  const vehicles = useAppSelector(state => vehiclesSelectors.selectAll(state))

  const [selectedOrgUnit, setSelectedOrgUnit] = useState<string | null>(orgUnits[0]?.ORG_UNIT_ID || null)
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null)
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null)
  const [selectedVehicle, setSelectedVehicle] = useState<string | null>(null)

  const [startDate, setStartDate] = useState<string | null>(dayjs().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState<string | null>(dayjs().format('YYYY-MM-DD'))
  const [loading, setLoading] = useState(false)
  const { setInfoMessage, setErrorMessage } = useAlertContext()

  const { enabledFilters, title } = activeReportMappingInfo[activeReport]

  // Map the org units, locations, customers and vehicles to dropdown options
  const orgUnitOptions: DropdownOption[] = orgUnits.map(orgUnit => ({
    name: orgUnit.ORG_UNIT_ID,
    text: orgUnit.org_unit_name,
  }))

  const locationOptions: DropdownOption[] = locations.filter(location => {
    if (selectedOrgUnit === '0' || selectedOrgUnit === null) return true

    if (location.ORG_UNIT_ID === selectedOrgUnit) return true
    
    return false
  }).map(location => ({
    name: location.LOCATION_ID,
    text: location.location_name,
  }))

  locationOptions.unshift({ name: null, text: 'All Locations' })

  const customerOptions: DropdownOption[] = customers.map(customer => ({
    name: customer.CUSTOMER_ID,
    text: customer.customer_name,
  }))

  customerOptions.unshift({ name: null, text: 'All Customers' })

  const vehicleOptions: DropdownOption[] = vehicles.filter(vehicle => {
    if (selectedOrgUnit === '0' || selectedOrgUnit === null) return true

    if (vehicle.ORG_UNIT_ID === selectedOrgUnit) return true
    
    return false
  }).map(vehicle => ({
    name: vehicle.VEHICLE_ID,
    text: `${vehicle.make_name} ${vehicle.model_name} (${vehicle.vehicle_rego_number})`,
  }))

  vehicleOptions.unshift({ name: null, text: 'All Vehicles' })

  // Reset Vehicle Selection if the selected vehicle no longer in the list
  useEffect(() => {
    if (!vehicleOptions.find(option => option.name === selectedVehicle)) {
      setSelectedVehicle(vehicleOptions[0]?.name || null)
    }
  }, [vehicleOptions, selectedVehicle])

  // Reset Location Selection if the selected vehicle no longer in the list
  useEffect(() => {
    if (!locationOptions.find(option => option.name === selectedLocation)) {
      setSelectedVehicle(locationOptions[0]?.name || null)
    }
  }, [locationOptions, selectedLocation])

  // Open a new tab to download the csv reports
  const onDownloadReport = async (reportType: DownloadableReports) => {
    let params = {}

    setLoading(true)

    if (enabledFilters) {
      params = {
        ...(enabledFilters.startDate && startDate !== null ? { date_start: startDate } : {}),
        ...(enabledFilters.endDate && endDate !== null ? { date_end: endDate } : {}),
        ...(enabledFilters.orgUnitId && selectedOrgUnit !== null ? { ORG_UNIT_ID: selectedOrgUnit } : {}),
        ...(enabledFilters.locationId && selectedLocation !== null ? { LOCATION_ID: selectedLocation } : {}),
        ...(enabledFilters.customerId && selectedCustomer !== null ? { CUSTOMER_ID: selectedCustomer } : {}),
        ...(enabledFilters.vehicleId && selectedVehicle !== null ? { VEHICLE_ID: selectedVehicle } : {}),
      }
    }

    const blob = await CarshareApiService.downloadFile(reportType, params)

    if (blob.size === 0) {
      setInfoMessage('No data available for the selected filters')
    } else if (blob) {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')

      link.href = url
      link.setAttribute('download', activeReportMappingInfo[reportType].fileName + '.csv')
      document.body.appendChild(link)
      link.click()
    } else {
      setErrorMessage('Failed to download report')
    }

    setLoading(false)
  }

  return (
    <Container {...props}>
      <Grid container justifyContent={'space-between'}>
        <Grid item sx={{ maxWidth: '85%' }}>
          <TitleContainer>
            {activeReport && (
              <>
                <StyledHeaderTypography variant="h6">
                  {title}
                </StyledHeaderTypography>
              </>
            )}
          </TitleContainer>
        </Grid>
        <Grid item>
          <StyledCloseButton onClick={onCloseRightPanel}>
            <Icon icon={faClose} />
          </StyledCloseButton>
        </Grid>
      </Grid>
      <StyledContent>
        {enabledFilters && <Grid container direction={'column'} gap={pxToRem(16)}>
          {enabledFilters.orgUnitId && <Grid item>
            <StyledFormControl>
              <TextFieldLabel>Org Unit</TextFieldLabel>
              <Dropdown
                value={orgUnitOptions.find(option => option.name === selectedOrgUnit)?.text || ''}
                onSelectItem={(value) => { setSelectedOrgUnit(value) }}
              >
                {orgUnitOptions.map((option, idx) =>
                  <DropdownItem key={idx} data-value={option.name}>{option.text}</DropdownItem>,
                )}
              </Dropdown>
            </StyledFormControl>
          </Grid>}
          {enabledFilters.startDate && <Grid item>
            <StyledFormControl>
              <TextFieldLabel>Start Date</TextFieldLabel>
              <DatePicker
                value={dayjs(startDate)}
                format="DD/MM/YYYY"
                onChange={(date) => {
                  if (date && (date as Dayjs)?.isValid()) {
                    setStartDate((date as Dayjs).format('YYYY-MM-DD'))

                    if (dayjs(endDate).isBefore(date as Dayjs)) {
                      setEndDate((date as Dayjs).format('YYYY-MM-DD'))
                    }
                  }
                }}
                onAccept={(date) => {
                  if (date) {
                    setStartDate((date as Dayjs).format('YYYY-MM-DD'))
                  }
                }}
              />
            </StyledFormControl>
          </Grid>}
          {enabledFilters.endDate && <Grid item>
            <StyledFormControl>
              <TextFieldLabel>End Date</TextFieldLabel>
              <DatePicker
                value={dayjs(endDate)}
                format="DD/MM/YYYY"
                onChange={(date) => {
                  if (date && (date as Dayjs)?.isValid()) {
                    setEndDate((date as Dayjs).format('YYYY-MM-DD'))
                  }
                }}
                onAccept={(date) => {
                  if (date) {
                    setEndDate((date as Dayjs).format('YYYY-MM-DD'))
                  }
                }}
              />
            </StyledFormControl>
          </Grid>}
          {enabledFilters.locationId && <Grid item>
            <StyledFormControl>
              <TextFieldLabel>Location</TextFieldLabel>
              <Dropdown
                value={locationOptions.find(option => option.name === selectedLocation)?.text || ''}
                onSelectItem={(value) => { setSelectedLocation(value) }}
              >
                {locationOptions.map((option, idx) =>
                  <DropdownItem key={idx} data-value={option.name}>{option.text}</DropdownItem>,
                )}
              </Dropdown>
            </StyledFormControl>
          </Grid>}
          {enabledFilters.vehicleId && <Grid item>
            <StyledFormControl>
              <TextFieldLabel>Vehicle</TextFieldLabel>
              <Dropdown
                value={vehicleOptions.find(option => option.name === selectedVehicle)?.text || ''}
                onSelectItem={(value) => { setSelectedVehicle(value) }}
              >
                {vehicleOptions.map((option, idx) =>
                  <DropdownItem key={idx} data-value={option.name}>{option.text}</DropdownItem>,
                )}
              </Dropdown>
            </StyledFormControl>
          </Grid>}
          {enabledFilters.customerId && <Grid item>
            <StyledFormControl>
              <TextFieldLabel>Customer</TextFieldLabel>
              <Dropdown
                value={customerOptions.find(option => option.name === selectedCustomer)?.text || ''}
                onSelectItem={(value) => { setSelectedCustomer(value) }}
              >
                {customerOptions.map((option, idx) =>
                  <DropdownItem key={idx} data-value={option.name}>{option.text}</DropdownItem>,
                )}
              </Dropdown>
            </StyledFormControl>
          </Grid>}
        </Grid>}
        <Button
          primary
          onClick={() => onDownloadReport(activeReport)}
          fullWidth
          disabled={loading}
          sx={{ marginTop: pxToRem(24) }}
        >
          Download {title}
        </Button>
      </StyledContent>
    </Container >
  )
}

const Container = styled(Grid)`
  height: 100%;
  padding: ${props => props.theme.typography.pxToRem(16)};
`

const TitleContainer = styled('span')`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-left: ${props => props.theme.typography.pxToRem(-16)};
  margin-right: ${props => props.theme.typography.pxToRem(-16)};
  wrap: nowrap;

  ${props => props.theme.breakpoints.up('sm')} {
    margin-left: unset;
    margin-right: unset;
  }
`

const StyledHeaderTypography = styled(Typography)`
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props => props.theme.breakpoints.up('sm')} {
    width: unset;
  }
`

const StyledCloseButton = styled(IconButton)`
  margin-top: ${props => props.theme.typography.pxToRem(-12)};
  margin-right: ${props => props.theme.typography.pxToRem(-12)};
`


const StyledContent = styled('div')`
  margin-top: ${props => props.theme.typography.pxToRem(16)};
`
