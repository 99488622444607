import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { InputAdornment, MenuItem, Autocomplete as MuiAutoComplete, Paper, Popper, autocompleteClasses, styled } from '@mui/material'
import { ComponentProps } from 'react'
import { TextField } from '../Form/TextField'
import { Icon } from '../Icon/Icon'

export type AutocompleteProps = Omit<ComponentProps<typeof MuiAutoComplete>, 'sx' | 'renderOption' | 'renderInput'> &
{
  errorMessage?: string
  placeholder?: string
}

export type AutocompleteOption = {
  id: string
  label: string
}

export const AutoComplete: React.FC<AutocompleteProps> = ({ errorMessage, placeholder, ...props }) => (
  <>
    <StyledAutocomplete
      {...props}
      PaperComponent={StyledPaper}
      PopperComponent={StyledPopper}
      disablePortal
      renderInput={(params) => {
        return <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            placeholder: placeholder,
            endAdornment: (
              <InputAdornment position="end">
                <StyledDropdownIcon icon={faChevronDown} />
              </InputAdornment>
            ),
          }}
        />
      }}
      renderOption={(optionProps, option, state, ownerState) => {
        return (
          <StyledDropdownItem
            {...optionProps}
            key={(option as AutocompleteOption).id}
          >
            {ownerState.getOptionLabel(option)}
          </StyledDropdownItem>
        )
      }}
    />
  </>
)

export const StyledDropdownIcon = styled(Icon)`
  color: ${props => props.theme.palette.text.primary};
  margin-right: ${props => props.theme.typography.pxToRem(16)};
  font-size: ${props => props.theme.typography.pxToRem(14)};
`

export const StyledPopper = styled(Popper)`
  z-index: ${(props) => props.theme.zIndex.appBar + 110};
  background-color: ${(props) => props.theme.palette.background.paper};
  border-radius: ${(props) => props.theme.typography.pxToRem(8)};
`

const StyledPaper = styled(Paper)`
  box-shadow: none;
  border-radius: ${(props) => props.theme.typography.pxToRem(8)};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  margin: 0 !important;
  padding: 0 !important;

  &.MuiAutocomplete-listbox {
    margin: 0 !important;
    padding: 0 !important;
  }
`

const StyledAutocomplete = styled(MuiAutoComplete)`
  .MuiInputBase-root {
    padding: 0 !important;

    .MuiOutlinedInput-input {
      padding: ${(props) => props.theme.typography.pxToRem(12.5)} ${(props) => props.theme.typography.pxToRem(12)};
    }
  }
`

export const StyledDropdownItem = styled(MenuItem)`
  padding: 0;
  margin: 0;
  z-index: 2;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${props => props.theme.palette.text.primary};

  &.${autocompleteClasses.option} {
    padding: ${(props) => props.theme.typography.pxToRem(8)} ${(props) => props.theme.typography.pxToRem(16)};
    transition: opacity 0.2s ease;
  
    ${props => props.theme.breakpoints.up('sm')} {
      padding: ${props => props.theme.typography.pxToRem(16)} ${props => props.theme.typography.pxToRem(16)};
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: ${props => props.theme.palette.custom.button.textButtonHoverBackground};
      opacity: 0;
      transition: opacity 0.2s ease;
    }

    &:hover {
      background-color: none !important;

      &:after {
        opacity: 1;
      }
    }
  }
`
