import React from 'react'

import { MobileSliderButton, MobileSliderButtonProps } from './MobileSliderButton'
import { MobileSliderDropdownItem, MobileSliderDropdownItemProps } from './MobileSliderDropdownItem'
import { MobileSliderNavigation, MobileSliderNavigationProps } from './MobileSliderNavigation'
import { MobileSliderWrapper, MobileSliderWrapperProps } from './MobileSliderWrapper'

export type MobileSliderProps =
  | ({ wrapper: true; navigation?: false; button?: false; menuItem?: false } & MobileSliderWrapperProps)
  | ({ wrapper?: false; navigation: true; button?: false; menuItem?: false } & MobileSliderNavigationProps)
  | ({ wrapper?: false; navigation?: false; button: true; menuItem?: false } & MobileSliderButtonProps)
  | ({ wrapper?: false; navigation?: false; button?: false; menuItem: true } & MobileSliderButtonProps)
  

export const MobileSlider: React.FC<MobileSliderProps> = ({ wrapper, navigation, button, menuItem, ...props }) => {
  if (navigation) {
    return <MobileSliderNavigation {...(props as MobileSliderNavigationProps)} />
  }

  if (button) {
    return <MobileSliderButton {...(props as MobileSliderButtonProps)} />
  }

  if (menuItem) {
    return <MobileSliderDropdownItem {...(props as MobileSliderDropdownItemProps)} />
  }

  return <MobileSliderWrapper {...(props as MobileSliderWrapperProps)} />
}
