
// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { useSwipe } from 'src/hooks/useSwipe'
import { MobileSliderContext } from './MobileSliderContext'
import { StyledListContainer, StyledSliderNavigation } from './StyledNavigator'

export type MobileSliderNavigationProps = {
  level: number
  idx: number
  animationDuration?: number
  children: React.ReactNode
}

export const MobileSliderNavigation: React.FC<MobileSliderNavigationProps> = ({ level, idx, children, animationDuration = 150, ...props }) => {
  const [isAnimateOut, setIsAnimateOut] = useState(false)
  const [transform, setTransform] = useState('translate3d(0, 0, 0)')
  const menuContext = useContext(MobileSliderContext)
  const currentIndex = menuContext.getItemIndex(level, idx)
  const isGlobalOpen = menuContext.menuState[0]
  const isOpen = menuContext.menuState[currentIndex]
  const { portal } = menuContext

  const [shouldRender, setShouldRender] = useState(isOpen)
  const [activeElement, setActiveElement] = useState<HTMLElement>()
  
  //Swipe to close
  const swipeHandlers = useSwipe({
    onSwipedLeft: () => {
    },
    onSwipedRight: () => {
      if (level !== 0 && idx !== 0) {
        menuContext.setMenuClose(level, idx)
      }
    },
    onSwipeMove: (distance) => {
      if (level <= 0 || distance >= 0) {
        setTransform('translate3d(0, 0, 0)')

        return
      }

      const newTransform = `translate3d(${-distance}px, 0, 0)`

      setTransform(newTransform)
    },
  })

  useEffect(() => {
    if (menuContext.menuStack.length <= 1) {
      setIsAnimateOut(false)

      return
    }

    const [lastLevel, lastIdx] = menuContext.menuStack[menuContext.menuStack.length - 2]

    if (lastLevel === level && lastIdx === idx) {
      setIsAnimateOut(true)
    }
  }, [menuContext.menuStack])

  // Allow for animation when opening and closing before completely unmounting
  useEffect(() => {
    if (isOpen) {
      setActiveElement(document.activeElement as HTMLElement)
      setShouldRender(isOpen)
    } else {
      const animateTimeout = setTimeout(() => {
        setTransform('translate3d(0, 0, 0)')
        setShouldRender(isOpen)
        activeElement?.focus()
      }, animationDuration - 20)

      return () => {
        clearTimeout(animateTimeout)
      }
    }
  }, [isOpen, animationDuration, activeElement])

  if (!shouldRender) {
    return null
  }

  const navigationSlider = (
    <StyledSliderNavigation
      {...props}
      {...swipeHandlers}
      $isGlobalOpen={isGlobalOpen}
      $isOpen={isOpen}
      $isAnimateOut={isAnimateOut}
      $transform={transform}
      $level={level}
      $animationTime={animationDuration}
      data-scroll-lock-scrollable
      style={{ transform: isAnimateOut ? 'translate3d(-20vw, 0, 0)' : transform }}
    >
      <StyledListContainer>
        {children}
      </StyledListContainer>
    </StyledSliderNavigation>
  )

  return portal ? (
    createPortal(navigationSlider, portal)
  ) : (
    navigationSlider
  )
}
