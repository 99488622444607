import { TouchEvent, useCallback, useState } from 'react'

interface SwipeInput {
  onSwipedLeft: (distance: number) => void
  onSwipedRight: (distance: number) => void
  onSwipeMove: (distance: number) => void
}

interface SwipeOutput {
  onTouchStart: (e: TouchEvent) => void
  onTouchMove: (e: TouchEvent) => void
  onTouchEnd: () => void
}

export const useSwipe = (input: SwipeInput): SwipeOutput => {
  const [touchStartY, setTouchStartY] = useState(0)
  const [touchEndY, setTouchEndY] = useState(0)
  const [touchStartX, setTouchStartX] = useState(0)
  const [touchEndX, setTouchEndX] = useState(0)

  const minSwipeDistance = 50
  const maxVerticalTolerance = 50

  const onTouchStart = (e: TouchEvent) => {
    setTouchEndX(0)
    setTouchEndY(0)
    setTouchStartX(e.targetTouches[0].clientX)
    setTouchStartY(e.targetTouches[0].clientY)
  }

  const onTouchMove = (e: TouchEvent) => {
    const currentTouchX = e.targetTouches[0].clientX
    const currentTouchY = e.targetTouches[0].clientY

    setTouchEndX(currentTouchX)
    setTouchEndY(e.targetTouches[0].clientY)

    const distanceX = touchStartX - currentTouchX
    const distanceY = touchStartY - currentTouchY

    if (Math.abs(distanceX) > 20 && Math.abs(distanceY) <= maxVerticalTolerance) {
      e.stopPropagation()
      input.onSwipeMove(distanceX)
    }
  }

  const onTouchEnd = useCallback(() => {
    if (!touchStartX || !touchEndX) return

    const distanceX = touchStartX - touchEndX
    const distanceY = touchStartY - touchEndY
    const isLeftSwipe = distanceX > minSwipeDistance && Math.abs(distanceY) <= maxVerticalTolerance
    const isRightSwipe = distanceX < -minSwipeDistance && Math.abs(distanceY) <= maxVerticalTolerance

    if (isLeftSwipe) {
      input.onSwipedLeft(distanceX)
    } else if (isRightSwipe) {
      input.onSwipedRight(distanceX)
    } else {
      input.onSwipeMove(0)
    }
  }, [touchStartX, touchEndX, touchStartY, touchEndY, input])

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  }
}