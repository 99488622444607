import { TablePagination, styled, useMediaQuery, useTheme } from '@mui/material'
import { ComponentProps } from 'react'

export type PaginationBarProps = ComponentProps<typeof TablePagination> & {
  //
}

export const PaginationBar: React.FC<PaginationBarProps> = ({ count, page, onPageChange, rowsPerPage, onRowsPerPageChange }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const rowsPerPageOptions = [10, 25, 50, 100]
  const filteredRowsPerPageOptions = rowsPerPageOptions.filter((option) => option < count)

  return (
    <StyledContainer>
      <TablePagination
        component="div"
        count={count}
        rowsPerPageOptions={isMobile ? [] : filteredRowsPerPageOptions}
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled('div')`
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  ${props => props.theme.breakpoints.up('sm')} {
    position: absolute;
    border-radius: 0 0 ${props => props.theme.typography.pxToRem(20)} ${props => props.theme.typography.pxToRem(20)};

    p {
      font-size: ${props => props.theme.typography.pxToRem(14)};
    }
  }
`
