/* eslint-disable @typescript-eslint/no-explicit-any */
import { faEye } from '@fortawesome/pro-solid-svg-icons'
import { Alert, Box, Grid, InputAdornment, Typography } from '@mui/material'
import { ComponentProps, useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { TextField } from 'src/components/Form/TextField'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { IconButton } from 'src/components/IconButton/IconButton'
import { StyledAccountSubmitButton, StyledEyeIcon, StyledForm, StyledFormControl } from 'src/fragments/StyledForm'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { useAppDispatch } from 'src/store/store'
import { setupAccount } from 'src/store/thunks/setupAccount'
import { pxToRem } from 'src/styles/themes'

export type ChangePasswordFormProps = ComponentProps<typeof StyledForm> & {
  onFormSubmitSuccess?: () => void
}

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ onFormSubmitSuccess, ...props }) => {
  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [isCurrentPasswordHidden, setCurrentPasswordHidden] = useState(true)
  const [isNewPasswordHidden, setNewPasswordHidden] = useState(true)

  const { loading, complete, errorMessage, request } = useApiRequest()
  const { setSuccessMessage } = useAlertContext()
  const dispatch = useAppDispatch()

  const toggleCurrentPasswordHidden = () => {
    setCurrentPasswordHidden((prev) => !prev)
  }

  const toggleNewPasswordHidden = () => {
    setNewPasswordHidden((prev) => !prev)
  }

  const submitHandler = () => {
    const payload = {
      old_password: currentPassword,
      new_password: newPassword,
    }

    return CarshareApiService.post('updateAccountPassword', payload)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    request(submitHandler)
  }

  useEffect(() => {
    if (errorMessage) return

    if (complete && onFormSubmitSuccess) {
      onFormSubmitSuccess()
    }
  }, [errorMessage, complete, onFormSubmitSuccess])

  useEffect(() => {
    if (complete) {
      setSuccessMessage('Password was updated successfully.')
      dispatch(setupAccount())
    }
  }, [complete])

  return (
    <StyledForm onSubmit={onSubmit} $isEditable={false} {...props}>
      <Grid container spacing={2} justifyContent={'center'} flexDirection={'column'}>
        <Grid item>
          {errorMessage && (
            <Alert severity={'error'} icon={false}>
              {errorMessage}
            </Alert>
          )}
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Old Password</TextFieldLabel>
            <TextField
              required
              value={currentPassword || ''}
              type={isCurrentPasswordHidden ? 'password' : 'text'}
              aria-label="Current Password"
              autoComplete="current-password"
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleCurrentPasswordHidden}
                    >
                      <StyledEyeIcon icon={faEye} />
                    </IconButton>
                  </InputAdornment>,
              }}
              onChange={(e) => {
                setCurrentPassword(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>New Password</TextFieldLabel>
            <TextField
              required
              value={newPassword || ''}
              type={isNewPasswordHidden ? 'password' : 'text'}
              autoComplete="new-password"
              aria-label="New Password"
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleNewPasswordHidden}
                    >
                      <StyledEyeIcon icon={faEye} />
                    </IconButton>
                  </InputAdornment>,
              }}
              onChange={(e) => {
                setNewPassword(e.target.value)
              }}
            />
          </StyledFormControl>
          <Box sx={{ marginTop: pxToRem(4) }}>
            <Typography variant={'caption'} sx={{ color: 'text.disabled' }}>
              Minimum 8 characters. Must included numbers, letters & special characters.
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <StyledAccountSubmitButton
            primary
            type="submit"
            disabled={loading}
          >
            Update Password
          </StyledAccountSubmitButton>
        </Grid>
      </Grid>
    </StyledForm >
  )
}
