import { faCloudArrowUp } from '@fortawesome/pro-regular-svg-icons'
import { Typography, styled } from '@mui/material'
import { ComponentProps } from 'react'
import { Button } from '../Button/Button'
import { Icon } from '../Icon/Icon'


export type ImageInputFieldProps = Omit<ComponentProps<'input'>, 'variant'> & {
  error?: string | null
  disabled?: boolean
}

export const ImageInputField: React.FC<ImageInputFieldProps> = ({ children, error, disabled, ...props }) => {
  return (
    <>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        {...props}
      />

      <label htmlFor="raised-button-file">
        <Button
          outlined
          component="span"
          startIcon={
            <Icon icon={faCloudArrowUp} />
          }
          disabled={disabled}
          fullWidth
        >
          {children}
        </Button>
      </label>
      {error && (
        <StyledErrorMessage variant="body1">
          {error}
        </StyledErrorMessage>
      )}
    </>
  )
}


const StyledErrorMessage = styled(Typography)`
  color: ${(props) => props.theme.palette.error.main};
  font-size: ${(props) => props.theme.typography.pxToRem(14)};
  margin-top: ${(props) => props.theme.typography.pxToRem(4)};
`
