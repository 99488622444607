/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { Dropdown } from 'src/components/Dropdown/Dropdown'
import { DropdownItem } from 'src/components/Dropdown/DropdownItem'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { StyledForm, StyledFormControl, StyledSubmitButton } from 'src/fragments/StyledForm'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { membersSelectors } from 'src/store/adapters/members'
import { orgUnitsSelectors } from 'src/store/adapters/orgUnits'
import { useAppSelector } from 'src/store/store'

export type AddTeamMemberOrgUnitFormProps = {
  teamMemberId: string
  onFormSubmitSuccess?: () => void
}

export const AddTeamMemberOrgUnitForm: React.FC<AddTeamMemberOrgUnitFormProps> = ({ teamMemberId, onFormSubmitSuccess }) => {
  const activeMember = useAppSelector(state => membersSelectors.selectById(state, teamMemberId))
  const orgUnits = useAppSelector((state) => orgUnitsSelectors.selectAll(state)).slice(1)
  const activeOrgUnit = useAppSelector(state => state.settings.activeOrgUnit)
  const [orgUnitId, setOrgUnitId] = useState<string>(activeOrgUnit?.ORG_UNIT_ID || '')
  const { loading, complete, errorMessage, request } = useApiRequest()
  const { setSuccessMessage } = useAlertContext()

  const orgUnitOptions = orgUnits.map(orgUnit => ({
    label: orgUnit.org_unit_name,
    orgUnitId: orgUnit.ORG_UNIT_ID,
  }))

  const submitHandler = () => {
    const payload = {
      TEAM_MEMBER_ID: teamMemberId,
      ORG_UNIT_ID: orgUnitId,
    }

    return CarshareApiService.post('addTeamMemberOrgUnit', payload)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    request(submitHandler)
  }

  useEffect(() => {
    if (errorMessage) return

    if (complete && onFormSubmitSuccess) {
      onFormSubmitSuccess()
    }
  }, [errorMessage, complete, onFormSubmitSuccess])

  useEffect(() => {
    if (complete) {
      setSuccessMessage(`Team Member ${activeMember?.team_member_name}'s org units were updated successfully.`)
    }
  }, [complete])

  return (
    <StyledForm $isEditable={true} onSubmit={onSubmit} >
      <Grid container spacing={2} justifyContent={'center'} flexDirection={'column'}>
        <Grid item>
          {errorMessage && (
            <Alert severity={'error'} icon={false}>
              {errorMessage}
            </Alert>
          )}
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Org Unit</TextFieldLabel>
            <Dropdown
              value={orgUnitOptions.find(orgUnit => orgUnit.orgUnitId === orgUnitId)?.label || ''}
              onSelectItem={(value) => { setOrgUnitId(value) }}
            >
              {orgUnitOptions.map(orgUnit => {
                return (
                  <DropdownItem key={orgUnit.orgUnitId} data-value={orgUnit.orgUnitId}>{orgUnit.label}</DropdownItem>
                )
              })}
            </Dropdown>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledSubmitButton
            primary
            fullWidth
            type="submit"
            disabled={loading}
          >
            Add Org Unit
          </StyledSubmitButton>
        </Grid>
      </Grid>
    </StyledForm >
  )
}
