import { Alert, styled } from '@mui/material'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { RequestHandler, useApiRequest } from 'src/hooks/useApiRequest'
import { useVisibilityChange } from 'src/hooks/useVisibilityChange'
import { resetState } from 'src/store/reducers/rootReducer'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { logoutUser } from 'src/store/thunks/logoutUser'
import { User } from 'src/types/User'
import { Button } from '../../components/Button/Button'
import { Modal } from '../../components/Modal/Modal'

export type ProtectedRouteProps = {
  children: React.ReactNode
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const user = useAppSelector(state => state.user)
  const location = useLocation()
  const dispatch = useAppDispatch()

  // errorMessage is used to detect session timeout
  const { errorMessage, request } = useApiRequest()
  const navigate = useNavigate()

  // Session check every time route change
  useEffect(() => {
    if (user.sessionid !== null && request) {
      const sessionHandler: RequestHandler<User> = () => (
        CarshareApiService.post('session', {
          sid: user.sessionid,
        })
      )

      request(sessionHandler)
    }
  }, [user, request])

  // Session check on tab/visibility change
  useVisibilityChange(() => {
    if (user.sessionid !== null && request) {
      const sessionHandler: RequestHandler<User> = () => (
        CarshareApiService.post('session', {
          sid: user.sessionid,
        })
      )

      request(sessionHandler)
    }
  }, [user, request])

  // automatically redirect to login page if sessionId is unfound
  useEffect(() => {
    if (user.sessionid === null) {
      navigate('/', {
        replace: true,
        state: { from: location },
      })
    }
  }, [user])

  const logout = () => {
    dispatch(logoutUser()).then(() => {
      dispatch(resetState())
    })
    navigate('/', {
      replace: true,
      state: { from: location },
    })
  }

  return (
    <>
      {user.sessionid !== null && children}
      {(!!errorMessage) && (
        <Modal
          open={true}
          portal="#session-expired-dialog"
          lock={true}
          title={'Your session has expired'}
        >
          <SyledModalContent>
            <Alert severity={'info'} icon={false}>
              Sign in again to continue using the application.
            </Alert>
            <StyledButton
              primary
              fullWidth
              onClick={logout}
            >
              Sign back in
            </StyledButton>
          </SyledModalContent>
        </Modal>
      )}
    </>
  )
}

const SyledModalContent = styled('div')`
  ${(props) => props.theme.breakpoints.up('sm')} {
    min-width: ${(props) => props.theme.typography.pxToRem(360)};
  }
`

const StyledButton = styled(Button)`
  margin-top: ${(props) => props.theme.typography.pxToRem(20)};
`