import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Manager } from 'src/types/Manager'
import { replaceAllMembers } from '../reducers/members'

export const setupMembers = createAsyncThunk('members/setup', async (_, { dispatch }) => {
  const members = await CarshareApiService.get<Manager>('getTeamMembers')

  if (members.results) {
    dispatch(replaceAllMembers(members.results))
  }

  return members.results
})