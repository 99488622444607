import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { CustomerAttribute } from 'src/types/Customer'


type SetupPayload = {
  CUSTOMER_ID: string
}

export const setupCustomerAttributes = createAsyncThunk('customers/setupAttributes', async ({ CUSTOMER_ID }: SetupPayload) => {
  // Retrieve Customers
  const customers = await CarshareApiService.post<CustomerAttribute>('getCustomerAttributes', {
    CUSTOMER_ID,
  })

  return {
    CUSTOMER_ID: CUSTOMER_ID,
    results: customers.results,
  }
})