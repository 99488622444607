/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react'

export type CustomAllotmentContextType = {
  allotmentSizes: number[]
  setAllotmentSizes: (sizes: number[]) => void
}

export const CustomAllotmentContext = createContext<CustomAllotmentContextType>({
  allotmentSizes: [],
  setAllotmentSizes: () => { },
})

export const useCustomAllotmentContext = () => {
  const context = useContext(CustomAllotmentContext)

  if (context === undefined) {
    throw new Error(
      'useCustomAllotmentContext must be used within a CustomAllotmentContext.Provider',
    )
  }

  return context
}
