import { Typography, styled } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Paper } from 'src/components/Paper/Paper'
import { useDashboardContext } from 'src/pages/Dashboard/DashboardContext'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupBookingById } from 'src/store/thunks/setupBookingById'
import { scrollbarStyle } from 'src/styles/themes'
import { Booking } from 'src/types/Booking'

export type BookingsFragmentProps = {
  //
}

export const BookingsFragment: React.FC<BookingsFragmentProps> = ({ ...props }) => {
  const activeOrgUnit = useAppSelector((state) => state.settings.activeOrgUnit)
  const { setSelectedBookingId } = useDashboardContext()
  const [bookings, setBookings] = useState<(Booking & { vehicle: string, user: string })[]>([])
  const dispatch = useAppDispatch()

  useEffect(() => {
    CarshareApiService.get<Booking & { vehicle: string, user: string }>('getDayBookings', {
      ORG_UNIT_ID: activeOrgUnit?.ORG_UNIT_ID,
    }).then((response) => {
      setBookings(response.results || [])
    })
  }, [activeOrgUnit])

  const columns: GridColDef[] = [
    {
      field: 'vehicle',
      headerName: 'Vehicle',
      disableColumnMenu: true,
      sortable: false,
      width: 200,
    },
    {
      field: 'pickup',
      headerName: 'Pickup date',
      type: 'dateTime',
      valueFormatter: params => {
        return dayjs(params.value).format('MMM D, YYYY h:mm A')
      },
      disableColumnMenu: true,
      sortable: false,
      width: 200,
    },
    {
      field: 'dropoff',
      headerName: 'Dropoff date',
      type: 'dateTime',
      valueFormatter: params => {
        return dayjs(params.value).format('MMM D, YYYY h:mm A')
      },
      disableColumnMenu: true,
      sortable: false,
      width: 200,
    },
    { field: 'name', headerName: 'Full name', description: 'Customer name', disableColumnMenu: true, sortable: false, width: 160 },
    { field: 'status', headerName: 'Status', type: 'string', disableColumnMenu: true, sortable: false, width: 160 },
  ]

  const rows = bookings.map((booking, idx) => {
    const pickupDate = new Date(booking.booking_date_pickup)

    pickupDate.setHours(+booking.booking_time_pickup_hour)
    pickupDate.setMinutes(+booking.booking_time_pickup_minutes)

    const dropoffDate = new Date(booking.booking_date_dropoff)

    dropoffDate.setHours(+booking.booking_time_dropoff_hour)
    dropoffDate.setMinutes(+booking.booking_time_dropoff_minutes)

    return {
      id: idx,
      BOOKING_ID: booking.BOOKING_ID,
      vehicle: booking.vehicle + '\n' + booking.vehicle_rego_number,
      pickup: pickupDate,
      dropoff: dropoffDate,
      name: booking.user,
      status: booking.status,
    }
  })

  return (
    <Paper>
      <StyledTitleTypography variant="body1">
        Upcoming bookings
      </StyledTitleTypography>
      <StyledDescriptionTypography variant="body1">
        Today
      </StyledDescriptionTypography>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        disableColumnSelector
        disableColumnFilter
        onRowClick={(params) => {
          if (!setSelectedBookingId) return
          dispatch(setupBookingById({ BOOKING_ID: params.row.BOOKING_ID }))

          setSelectedBookingId(params.row.BOOKING_ID)
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0 },
          },
        }}
        {...props}
      />
    </Paper>
  )
}

export const StyledTitleTypography = styled(Typography)`
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  padding-top: ${({ theme }) => theme.typography.pxToRem(16)};
  padding-left: ${({ theme }) => theme.typography.pxToRem(16)};
`

export const StyledDescriptionTypography = styled(Typography)`
  font-weight: 600;
  padding-left: ${({ theme }) => theme.typography.pxToRem(16)};
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  color: ${({ theme }) => theme.palette.text.secondary};
`

const StyledDataGrid = styled(DataGrid)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
  background-color: ${props => props.theme.palette.background.paper};
  border-radius: ${props => props.theme.typography.pxToRem(20)};
  overflow: auto;
  ${scrollbarStyle}

  & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus {
    outline: none
  }

  & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus {
    outline: none
  }

  & .MuiDataGrid-columnHeaders {
    .MuiDataGrid-columnHeader:first-of-type {
      padding-left: ${props => props.theme.typography.pxToRem(20)};
    }
  }

  & .MuiDataGrid-row {
    > .MuiDataGrid-cell:first-of-type {
      padding-left: ${props => props.theme.typography.pxToRem(20)};
    }
  }

  ${props => props.theme.breakpoints.up('sm')} {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    height: ${props => props.theme.typography.pxToRem(385)};
    border: none;

    & .MuiDataGrid-columnHeaders {
      .MuiDataGrid-columnHeader:first-of-type {
        padding-left: ${props => props.theme.typography.pxToRem(16)};
      }
    }

    & .MuiDataGrid-row {
      > .MuiDataGrid-cell:first-of-type {
        padding-left: ${props => props.theme.typography.pxToRem(16)};
      }
    }
  }

  & .MuiTablePagination-toolbar {
    > p {
      font-size: ${props => props.theme.typography.pxToRem(14)};
    }
  }

  & .MuiDataGrid-row {
    cursor: pointer;
    
    &:hover {
      background-color: ${props => props.theme.palette.action.hover};
    }
  }

  & .MuiDataGrid-virtualScroller {
    ${scrollbarStyle}
  }

  & .MuiDataGrid-overlay {
    background-color: ${props => props.theme.palette.background.paper};
  }
`
