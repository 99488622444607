/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react'

export type VehicleSearchContextType = {
  vehicleSearchString: string
  setVehicleSearchString: (searchString: string) => void
}

export const VehicleSearchContext = createContext<VehicleSearchContextType>({
  vehicleSearchString: '',
  setVehicleSearchString: () => { },
})

export const useVehicleSearchContext = () => {
  const context = useContext(VehicleSearchContext)

  if (context === undefined) {
    throw new Error(
      'useVehicleSearchContext must be used within a VehicleSearchContext.Provider',
    )
  }

  return context
}