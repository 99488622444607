/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Box, Grid, Switch, Tooltip } from '@mui/material'
import { ComponentProps, useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { Dropdown } from 'src/components/Dropdown/Dropdown'
import { DropdownItem } from 'src/components/Dropdown/DropdownItem'
import { TextField } from 'src/components/Form/TextField'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { StyledForm, StyledFormControl, StyledSubmitButton } from 'src/fragments/StyledForm'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { orgUnitsSelectors } from 'src/store/adapters/orgUnits'
import { vehiclesSelectors } from 'src/store/adapters/vehicles'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupVehicles } from 'src/store/thunks/setupVehicles'
import { Select } from 'src/types/Select'

export type VehicleFormProps = ComponentProps<typeof StyledForm> & {
  onFormSubmitSuccess?: () => void
  vehicleId?: string
  isEditForm?: boolean
  editable?: boolean
}

export const VehicleForm: React.FC<VehicleFormProps> = ({ onFormSubmitSuccess, isEditForm, vehicleId, editable, ...props }) => {
  const customerType = useAppSelector(state => state.user.ui_type) || ''
  const isWaipa = customerType === 'WAIPA'

  const { loading, complete, errorMessage, request } = useApiRequest()
  const { setSuccessMessage } = useAlertContext()
  const dispatch = useAppDispatch()
  const activeVehicle = useAppSelector(state => vehiclesSelectors.selectById(state, vehicleId || ''))

  const orgUnits = useAppSelector((state) => orgUnitsSelectors.selectAll(state)).slice(1)
  const activeOrgUnit = useAppSelector(state => state.settings.activeOrgUnit)
  const [orgUnitId, setOrgUnitId] = useState<string>(activeOrgUnit?.ORG_UNIT_ID || '')
  const [locationId, setLocationId] = useState<string>('')
  const [modelId, setModelId] = useState<string>('')
  const [vehicleIdentifier, setVehicleIdentifier] = useState<string>('')
  const [vehicleFuel, setVehicleFuel] = useState<string>('PETROL')
  const [vehicleTransmission, setVehicleTransmission] = useState<string>('AUTO')
  const [vehicleYear, setVehicleYear] = useState<string>('')
  const [vehicleRegoNumber, setVehicleRegoNumber] = useState<string>('')
  const [vehicleRegoExpiry, setVehicleRegoExpiry] = useState<string>('')
  const [vehicleInstructions, setVehicleInstructions] = useState<string>('')
  const [vehicleDetails, setVehicleDetails] = useState<string>('')
  const [vehicleRangeKm, setVehicleRangeKm] = useState<string>('')
  const [vehicleNrSeats, setVehicleNrSeats] = useState<string>('')
  const [vehicleNrLuggage, setVehicleNrLuggage] = useState<string>('')
  const [isColorCodeEnabled, setColorCodeEnabled] = useState<boolean>(false)
  const [vehicleColourCode, setVehicleColourCode] = useState<string>('#050df0')
  const [vehicleEngineSerialNumber, setVehicleEngineSerialNumber] = useState<string>('')
  const [vehicleIdenficationNumber, setVehicleIdentificationNumber] = useState<string>('')
  const [thirdPartyId, setThirdPartyId] = useState<string>('')
  const [vehicleMobileId, setVehicleMobileId] = useState<string>('')
  const [isVehicleHidden, setVehicleHidden] = useState<boolean>(false)

  const [modelOptions, setModelOptions] = useState<Select['tuples']>([])
  const [thirdPartyOptions, setThirdPartyOptions] = useState<Select['tuples']>([])
  const [locationOptions, setLocationOptions] = useState<Select['tuples']>([])

  const transmissionOptions: Select['tuples'] = [{ name: 'AUTO', text: 'Automatic' }, { name: 'MANUAL', text: 'Manual' }]
  const fuelOptions: Select['tuples'] = [{ name: 'PETROL', text: 'Petrol' }, { name: 'DIESEL', text: 'Diesel' }, { name: 'EV', text: 'Electric' }]

  // Setup Form initial Value
  useEffect(() => {
    if (isEditForm && activeVehicle) {
      setOrgUnitId(activeVehicle.ORG_UNIT_ID)
      setLocationId(activeVehicle.LOCATION_ID)
      setModelId(activeVehicle.MODEL_ID)
      setVehicleIdentifier(activeVehicle.vehicle_identifier)
      setVehicleFuel(activeVehicle.vehicle_fuel)
      setVehicleTransmission(activeVehicle.vehicle_transmission)
      setVehicleYear(activeVehicle.vehicle_year)
      setVehicleRegoNumber(activeVehicle.vehicle_rego_number)
      setVehicleRegoExpiry(activeVehicle.vehicle_rego_expiry)
      setVehicleInstructions(activeVehicle.vehicle_instructions)
      setVehicleDetails(activeVehicle.vehicle_details)
      setVehicleRangeKm(activeVehicle.vehicle_range_km)
      setVehicleNrSeats(activeVehicle.vehicle_nr_seats)
      setVehicleNrLuggage(activeVehicle.vehicle_nr_luggage)
      setColorCodeEnabled(activeVehicle.vehicle_colour_code !== '')
      setVehicleColourCode(activeVehicle.vehicle_colour_code)
      setVehicleEngineSerialNumber(activeVehicle.vehicle_engine_serial_number)
      setVehicleIdentificationNumber(activeVehicle.vehicle_identification_number)
      setThirdPartyId(activeVehicle.THIRD_PARTY_ID)
      setVehicleMobileId(activeVehicle.vehicle_mobile_id)
      setVehicleHidden(activeVehicle.vehicle_hidden === '1')
    }
  }, [isEditForm, activeVehicle])

  const scrollTop = () => {
    const node = document.querySelector('#__modal_main')

    node?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  // Select Options Setup
  useEffect(() => {
    CarshareApiService.get<Select>('getSelects', {
      kinds: 'makes_models,third_parties,carshare_attributes',
    }).then((response) => {
      const makeModels = response.results.find(result => result.name === 'makes_models')?.tuples || []
      const thirdParties = response.results.find(result => result.name === 'third_parties')?.tuples || []

      thirdParties.unshift({ name: '', text: 'None' })
      makeModels.unshift({ name: '', text: 'None' })

      setModelOptions(makeModels)
      setThirdPartyOptions(thirdParties)

      setModelId((prev) => prev ? prev : (makeModels?.[0].name || ''))
    })
  }, [])

  useEffect(() => {
    CarshareApiService.get<Select>('getSelects', {
      kinds: 'select-location-id:carshare_org_units_locations',
      filter: orgUnitId,
    }).then((response) => {
      const locations = response.results.find(result => result.name === 'select-location-id:carshare_org_units_locations')?.tuples || []

      locations.unshift({ name: '', text: 'None' })

      setLocationOptions(locations)
      setLocationId((prev) => prev ? prev : locations?.[0]?.name || '')
    })
  }, [orgUnitId])

  const orgUnitOptions = orgUnits.map(orgUnit => ({
    label: orgUnit.org_unit_name,
    orgUnitId: orgUnit.ORG_UNIT_ID,
  }))

  const submitHandler = () => {
    const payload = {
      ORG_UNIT_ID: orgUnitId,
      LOCATION_ID: locationId,
      MODEL_ID: modelId,
      vehicle_identifier: vehicleIdentifier,
      vehicle_fuel: vehicleFuel,
      vehicle_transmission: vehicleTransmission,
      vehicle_year: vehicleYear,
      vehicle_rego_number: vehicleRegoNumber,
      vehicle_rego_expiry: vehicleRegoExpiry,
      vehicle_instructions: vehicleInstructions,
      vehicle_engine_serial_number: vehicleEngineSerialNumber,
      vehicle_details: vehicleDetails,
      vehicle_range_km: vehicleRangeKm,
      vehicle_nr_seats: vehicleNrSeats,
      vehicle_nr_luggage: vehicleNrLuggage,
      vehicle_colour_code: isColorCodeEnabled ? vehicleColourCode : '',
      vehicle_identification_number: vehicleIdenficationNumber,
      vehicle_mobile_id: vehicleMobileId,
      THIRD_PARTY_ID: thirdPartyId,
      vehicle_hidden: isVehicleHidden ? '1' : '0',
    }

    if (isEditForm) {
      const editPayload = {
        ...payload,
        VEHICLE_ID: vehicleId,
      }

      return CarshareApiService.post('editVehicle', editPayload)
    }

    return CarshareApiService.post('addVehicle', payload)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    request(submitHandler)
  }

  // Add Form Submission
  useEffect(() => {
    if (errorMessage) {
      scrollTop()
    }

    if (complete && onFormSubmitSuccess) {
      onFormSubmitSuccess()
    }
  }, [errorMessage, complete, onFormSubmitSuccess])

  // Post submit hook
  useEffect(() => {
    if (complete) {
      setSuccessMessage(`Vehicle ${vehicleIdentifier} - ${vehicleRegoNumber} was ${isEditForm ? 'updated' : 'created'} successfully.`)

      dispatch(setupVehicles({}))
    }
  }, [complete])

  return (
    <StyledForm $isEditable={editable} onSubmit={onSubmit} {...props}>
      <Grid container spacing={2} justifyContent={'center'} flexDirection={'column'}>
        <Grid item>
          {errorMessage && (
            <Alert severity={'error'} icon={false}>
              {errorMessage}
            </Alert>
          )}
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Org Unit</TextFieldLabel>
            <Dropdown
              value={orgUnitOptions.find(orgUnit => orgUnit.orgUnitId === orgUnitId)?.label || ''}
              onSelectItem={(value) => { setOrgUnitId(value) }}
              disabled={!editable}
            >
              {orgUnitOptions.map(orgUnit => {
                return (
                  <DropdownItem key={orgUnit.orgUnitId} data-value={orgUnit.orgUnitId}>{orgUnit.label}</DropdownItem>
                )
              })}
            </Dropdown>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Location</TextFieldLabel>
            <Dropdown
              value={locationOptions.length === 0 ? 'Loading...' : locationOptions.find(option => option.name === locationId)?.text || ''}
              onSelectItem={(value) => { setLocationId(value) }}
              disabled={!editable}
            >
              {locationOptions.map(option => {
                return (
                  <DropdownItem key={option.name} data-value={option.name}>{option.text}</DropdownItem>
                )
              })}
            </Dropdown>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Model</TextFieldLabel>
            <Dropdown
              value={modelOptions.length === 0 ? 'Loading...' : modelOptions.find(option => option.name === modelId)?.text || ''}
              onSelectItem={(value) => { setModelId(value) }}
              disabled={!editable}
            >
              {modelOptions.map(option => {
                return (
                  <DropdownItem key={option.name} data-value={option.name}>{option.text}</DropdownItem>
                )
              })}
            </Dropdown>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Identifier</TextFieldLabel>
            <TextField
              id="identifier-textfield"
              required
              value={vehicleIdentifier}
              onChange={(e) => {
                setVehicleIdentifier(e.target.value)
              }}
              disabled={!editable}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required={!isWaipa}>
            <TextFieldLabel>Fuel</TextFieldLabel>
            <Dropdown
              value={fuelOptions.find(option => option.name === vehicleFuel)?.text || ''}
              onSelectItem={(value) => { setVehicleFuel(value) }}
              disabled={!editable}
            >
              {fuelOptions.map(option => {
                return (
                  <DropdownItem key={option.name} data-value={option.name}>{option.text}</DropdownItem>
                )
              })}
            </Dropdown>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required={!isWaipa}>
            <TextFieldLabel>Transmission</TextFieldLabel>
            <Dropdown
              value={transmissionOptions.find(option => option.name === vehicleTransmission)?.text || ''}
              onSelectItem={(value) => { setVehicleTransmission(value) }}
              disabled={!editable}
            >
              {transmissionOptions.map(option => {
                return (
                  <DropdownItem key={option.name} data-value={option.name}>{option.text}</DropdownItem>
                )
              })}
            </Dropdown>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required={!isWaipa}>
            <TextFieldLabel>Year</TextFieldLabel>
            <TextField
              type="number"
              required={!isWaipa}
              value={vehicleYear}
              id="vehicle-year-textfield"
              onChange={(e) => {
                setVehicleYear(e.target.value)
              }}
              disabled={!editable}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Vehicle Rego Number</TextFieldLabel>
            <TextField
              type="text"
              required
              value={vehicleRegoNumber}
              id="vehicle-rego-number-textfield"
              onChange={(e) => {
                setVehicleRegoNumber(e.target.value)
              }}
              disabled={!editable}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required={!isWaipa}>
            <TextFieldLabel>Vehicle Rego Expiry Date</TextFieldLabel>
            <TextField
              type="date"
              required={!isWaipa}
              value={vehicleRegoExpiry}
              id="vehicle-rego-expiry"
              onChange={(event) => {
                setVehicleRegoExpiry(event.target.value)
              }}
              disabled={!editable}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel>VIN</TextFieldLabel>
            <TextField
              type="text"
              value={vehicleIdenficationNumber}
              id="engine-vin-textfield"
              onChange={(e) => {
                setVehicleIdentificationNumber(e.target.value)
              }}
              disabled={!editable}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel>Engine Number</TextFieldLabel>
            <TextField
              type="text"
              value={vehicleEngineSerialNumber}
              id="engine-number-textfield"
              onChange={(e) => {
                setVehicleEngineSerialNumber(e.target.value)
              }}
              disabled={!editable}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel>Instructions</TextFieldLabel>
            <TextField
              type="text"
              value={vehicleInstructions}
              id="instructions-textfield"
              onChange={(e) => {
                setVehicleInstructions(e.target.value)
              }}
              disabled={!editable}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel>Details</TextFieldLabel>
            <TextField
              type="text"
              value={vehicleDetails}
              id="details-textfield"
              onChange={(e) => {
                setVehicleDetails(e.target.value)
              }}
              disabled={!editable}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl >
            <TextFieldLabel>Vehicle Range (in KM)</TextFieldLabel>
            <TextField
              type="number"
              value={vehicleRangeKm}
              id="range-textfield"
              onChange={(e) => {
                setVehicleRangeKm(e.target.value)
              }}
              disabled={!editable}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl >
            <TextFieldLabel>Seats Number</TextFieldLabel>
            <TextField
              type="number"
              value={vehicleNrSeats}
              id="seats-textfield"
              onChange={(e) => {
                setVehicleNrSeats(e.target.value)
              }}
              disabled={!editable}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel>Luggage</TextFieldLabel>
            <TextField
              type="text"
              value={vehicleNrLuggage}
              id="luggage-textfield"
              onChange={(e) => {
                setVehicleNrLuggage(e.target.value)
              }}
              disabled={!editable}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel>Vehicle Mobile ID</TextFieldLabel>
            <TextField
              type="text"
              value={vehicleMobileId}
              onChange={(e) => {
                setVehicleMobileId(e.target.value)
              }}
              disabled={!editable}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel>Third Party</TextFieldLabel>
            <Dropdown
              value={thirdPartyOptions.find(option => option.name === thirdPartyId)?.text || ''}
              onSelectItem={(value) => { setThirdPartyId(value) }}
              disabled={!editable}
            >
              {thirdPartyOptions.map(option => {
                return (
                  <DropdownItem key={option.name} data-value={option.name}>{option.text}</DropdownItem>
                )
              })}
            </Dropdown>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
              <TextFieldLabel>Vehicle Colour Code</TextFieldLabel>
              <Tooltip title="Switch on to select a color">
                <Switch
                  aria-label="Vehicle Colour Code"
                  checked={isColorCodeEnabled}
                  onChange={(e) => { setColorCodeEnabled(e.target.checked) }}
                  disabled={!editable}
                />
              </Tooltip>
            </Box>
            {isColorCodeEnabled && <TextField
              type="color"
              value={vehicleColourCode}
              onChange={(e) => {
                setVehicleColourCode(e.target.value)
              }}
              disabled={!editable}
            />}
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
              <TextFieldLabel>Vehicle Hidden Status</TextFieldLabel>
              <Tooltip title="Switch on to disable this vehicle">
                <Switch
                  aria-label="Vehicle Colour Code"
                  checked={isVehicleHidden}
                  onChange={(e) => { setVehicleHidden(e.target.checked) }}
                  disabled={!editable}
                />
              </Tooltip>
            </Box>
          </StyledFormControl>
        </Grid>
        {editable && (
          <Grid item>
            <StyledSubmitButton
              primary
              fullWidth
              type="submit"
              disabled={loading || !orgUnitId || !locationId || !modelId}
            >
              {isEditForm ? 'Update' : 'Add'} Vehicle
            </StyledSubmitButton>
          </Grid>
        )}
      </Grid>
    </StyledForm >
  )
}
