import { Grid, styled, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { AnimatedPage } from 'src/components/AnimatedPage/AnimatedPage'
import { Modal } from 'src/components/Modal/Modal'
import { StatisticCard } from 'src/components/StatisticCard/StatisticCard'
import { TrendCard } from 'src/components/TrendCard/TrendCard'
import { BookingsFragment, MapFragment } from 'src/fragments/Dashboard'
import { BookingForm } from 'src/fragments/Operations'
import { useAppSelector } from 'src/store/store'
import { DashboardContext } from './DashboardContext'

export type DayStatistics = {
  late_check_ins: string
  vehicles_in_use: string
  total_vehicles: string
  vehicle_utilisation: string
}

export type OnTimeRunningStatistics = {
  ORG_ID: string
  ORG_UNIT_ID: string
  date: string
  ontime: string
  count: string
  percent: string
}

export const Dashboard = () => {
  const [dayStatistics, setDayStatistics] = useState<DayStatistics>()
  const [onTimeRunning, setOnTimeRunning] = useState<OnTimeRunningStatistics[]>([])
  const [selectedBookingId, setSelectedBookingId] = useState<string | null>(null)
  const activeOrgUnit = useAppSelector(state => state.settings.activeOrgUnit)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (activeOrgUnit) {
      // Get Day Statistics
      CarshareApiService.get<DayStatistics>('getDayStats', {
        ORG_UNIT_ID: activeOrgUnit?.ORG_UNIT_ID,
      }).then((response) => {
        if (!response.results) return

        setDayStatistics(response.results?.[0])
      })

      // Get On time running statistics
      CarshareApiService.get<OnTimeRunningStatistics>('getOnTimeRunning', {
        ORG_UNIT_ID: activeOrgUnit?.ORG_UNIT_ID,
      }).then((response) => {
        if (!response.results) return

        const sortedOnTimeRunningStats: OnTimeRunningStatistics[] = response.results.sort((a, b) => a.date.localeCompare(b.date))

        setOnTimeRunning(sortedOnTimeRunningStats)
      })
    }
  }, [activeOrgUnit])

  return (
    <AnimatedPage>
      <DashboardContext.Provider value={{ selectedBookingId, setSelectedBookingId }}>
        <StyledDashboardGrid container justifyContent={'center'} spacing={4}>
          <Grid item xs={12}>
            <MapFragment />
          </Grid>
          <StyledDashboardContentGrid item xs={12} md={6}>
            <Grid container spacing={4}>
              <Grid item xs={6} md={6} sx={{ height: '100%' }}>
                <TrendCard
                  title={'Late Dropoff'}
                  description="Today"
                  number={dayStatistics?.late_check_ins || '0'}
                  numberPercentage={`${(parseInt(dayStatistics?.late_check_ins || '0') / Math.max(parseInt(dayStatistics?.total_vehicles || '1'), 1) * 100).toFixed(2)}%`}
                  trend={'down'}
                />
              </Grid>
              <Grid item xs={6} md={6} sx={{ height: '100%' }}>
                <TrendCard
                  title={'Vehicle Utilization'}
                  description="Today"
                  number={dayStatistics?.vehicles_in_use || '0'}
                  numberPercentage={`${(parseInt(dayStatistics?.vehicle_utilisation || '0') / Math.max(parseInt(dayStatistics?.total_vehicles || '1'), 1) * 100).toFixed(2)}%`}
                  trend={'up'}
                />
              </Grid>
              {onTimeRunning && onTimeRunning.length > 0 && <Grid item xs={12} sx={{ height: '100%' }}>
                <StatisticCard
                  title={'On-Time Running'}
                  description={'% of Vehicles returned on time'}
                  numberPercentage={(onTimeRunning?.[onTimeRunning.length - 1]?.percent || '0') + '%'}
                  day={'Today'}
                  xAxis={[{ scaleType: 'point', data: onTimeRunning.map(item => dayjs(item.date, 'YYYY-MM-DD').format('MMM D, YYYY')) }]}
                  series={[
                    {
                      data: onTimeRunning.map(item => parseInt(item.percent)),
                      area: true,
                    },
                  ]}
                />
              </Grid>}
            </Grid>
          </StyledDashboardContentGrid>
          {!isMobile && (
            <StyledDashboardBookingGrid item xs={12} md={6}>
              <BookingsFragment />
            </StyledDashboardBookingGrid>
          )}
        </StyledDashboardGrid>
        <Modal title={'View Booking'}
          open={!!selectedBookingId}
          onClose={() => {
            setSelectedBookingId(null)
          }}
        >
          <BookingForm
            isEditForm
            bookingId={selectedBookingId ? selectedBookingId : ''}
            currentDate={dayjs()}
            isReadOnly
            onFormSubmitSuccess={() => { setSelectedBookingId(null) }}
          />
        </Modal>

      </DashboardContext.Provider>
    </AnimatedPage >
  )
}

const StyledDashboardGrid = styled(Grid)`
  padding: 0;
  
  ${props => props.theme.breakpoints.up('sm')} {
    height: 100%;
    padding: ${props => props.theme.typography.pxToRem(16)};
    spacing: ${props => props.theme.typography.pxToRem(32)};
  }

  ${props => props.theme.breakpoints.up('md')} {
    padding: ${props => props.theme.typography.pxToRem(32)};
  }
`

const StyledDashboardContentGrid = styled(Grid)`
  display: none;
  
  ${props => props.theme.breakpoints.up('sm')} {
    display: unset;
    position: unset;
    height: 100%;
    border-radius: 0;
    spacing: ${props => props.theme.typography.pxToRem(32)};
  }
`

const StyledDashboardBookingGrid = styled(Grid)`
  display: none;

  ${props => props.theme.breakpoints.up('sm')} {
    display: unset;
  }
`