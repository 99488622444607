import { styled } from '@mui/material'

export const StyledDivider = styled('div')`
  display: block;
  text-align: center;
  white-space: nowrap;
  color: ${(props) => props.theme.palette.text.primary};
  margin-left: ${(props) => props.theme.typography.pxToRem(16)};

  span {
    position: relative;
    overflow: visible;
    display: inline-block;
  }

  span:before,
  span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: ${(props) => props.theme.palette.divider};
  }

  span:before {
    right: 100%;
    margin-right: 15px;
  }

  span:after {
    left: 100%;
    margin-left: 15px;
  }
`