import { useEffect, useState } from 'react'
import { carshareResources } from 'src/apis/CarshareResources'
import { store } from 'src/store/store'
import { Notification as NotificationType } from 'src/types/Notification'

export const useNotificationWorker = () => {
  const [notificationWorker, setNotificationWorker] = useState<Worker | null>(null)
  const state = store.getState()

  const xApiKey = process.env.REACT_APP_CARSHARE_API_KEY
  const sessionId = state.user.sessionid

  useEffect(() => {
    const workerUrl = new URL('../workers/notifications.worker.js', import.meta.url)
    const worker = new Worker(workerUrl)
    const allNotifications: NotificationType[] = []

    // listens for new notifications
    worker.onmessage = (e) => {
      if (e.data.type && e.data.type === 'message_passing/notification_response') {
        const newNotifications = e.data?.payload.filter((notification: NotificationType) => notification.notification_read === '0000-00-00 00:00:00')

        if (!newNotifications) return

        if (!('Notification' in window) || !(Notification.permission === 'granted')) return

        newNotifications.map((notification: NotificationType) => {
          if (!allNotifications.some((item) => item.NOTIFICATION_ID === notification.NOTIFICATION_ID)) {
            new Notification(notification.notification_heading, {
              body: notification.notification_contents,
              icon: '/favicon/ios/256.png',
            })
          }
        })

        allNotifications.push(...newNotifications)
      }
    }

    worker.postMessage({
      type: 'message_passing/notification_payload',
      endpoint: process.env.REACT_APP_CARSHARE_API_HOST + carshareResources.getNotifications,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
        'X-Api-Key': xApiKey,
        'X-Session-ID': sessionId,
      },
    })

    setNotificationWorker(worker)

    return () => {
      worker.terminate()
    }
  }, [sessionId, xApiKey])


  return notificationWorker
}