import { Grid } from '@mui/material'
import { LineChart } from '@mui/x-charts/LineChart'
import { ComponentProps, useEffect, useRef, useState } from 'react'
import { StyledDescriptionTypography, StyledPaper, StyledPercentageTypography, StyledTitleTypography } from './StyledStatisticCard'

export type StatisticCardProps = Pick<ComponentProps<typeof LineChart>, 'xAxis' | 'series'> & {
  title: string
  description: string
  day: 'Today' | 'Week'
  numbers?: number[]
  numberPercentage: string
}

export const StatisticCard: React.FC<StatisticCardProps> = ({ title, description, numberPercentage, day, ...props }) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const [chartWidth, setChartWidth] = useState<number>(300)

  useEffect(() => {
    const updateChartWidth = () => {
      if (chartRef && chartRef.current) {
        setChartWidth(chartRef.current.offsetWidth)
      }
    }

    window.addEventListener('resize', updateChartWidth)

    updateChartWidth()

    return () => {
      window.removeEventListener('resize', updateChartWidth)
    }
  }, [])

  return (
    <StyledPaper>
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <StyledTitleTypography variant="body1">{title}</StyledTitleTypography>
          <StyledDescriptionTypography variant="body1">{description}</StyledDescriptionTypography>
        </Grid>
        <Grid item sx={{ textAlign: 'right' }}>
          <StyledPercentageTypography variant="body1">{numberPercentage}</StyledPercentageTypography>
          <StyledDescriptionTypography variant="body1">{day}</StyledDescriptionTypography>
        </Grid>
      </Grid>
      <div ref={chartRef} style={{ width: 'calc(100% + 130px)', marginLeft: '-64px', marginTop: '-40px' }}>
        <LineChart
          height={205}
          width={chartWidth}
          sx={{
            '& .MuiLineElement-root': {
              stroke: '#626BB2',
            },
            '& .MuiAreaElement-root': {
              fill: '#363C5B',
            },
            '& .MuiChartsAxis-root': {
              display: 'none',
            },
          }}
          {...props}
        />
      </div>
    </StyledPaper>
  )
}
