import { Link, styled, useMediaQuery, useTheme } from '@mui/material'
import { ComponentProps } from 'react'

export type DesktopNavSidebarProps = ComponentProps<'aside'> & {
  children: React.ReactNode
  open: boolean
  logoHref?: string
}

export const DesktopNavSidebar: React.FC<DesktopNavSidebarProps> = ({ children, open, logoHref, ...props }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const logoSrc = isMobile || open ? '/aside-logo.svg' : '/aside-logo-icon.svg'

  return (
    <StyledSidebarNav {...props} $open={open}>
      <StyledLogoContainer href={logoHref}>
        <StyledLogo src={logoSrc} />
      </StyledLogoContainer>
      {children}
    </StyledSidebarNav>
  )
}

export const StyledLogoContainer = styled(Link)`
  margin: 0;
  display: flex;
  position: relative;
`

export const StyledLogo = styled('img')`
  height: ${(props) => props.theme.typography.pxToRem(36)};
  margin: ${(props) => props.theme.typography.pxToRem(16)} auto;
`

export const StyledSidebarNav = styled('aside', {
  shouldForwardProp: (prop) => prop !== '$open',
}) <{ $open?: boolean }>`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: ${(props) => props.theme.zIndex.appBar + 1};
  height: 100vh;
  background-color: ${(props) => props.theme.palette.custom.sidebar};
  overflow: hidden;
  width: ${(props) => props.theme.layout.navSidebarWidth};
  transition: transform ${(props) => props.theme.layout.navSidebarTransitionSpeed}ms ease-in-out;
  transform: translateX(-${(props) => props.theme.layout.navSidebarWidth});
  
  ${(props) => props.$open && `
    transform: translateX(0);
  `}

  ${(props) => props.theme.breakpoints.up('sm')} {
    width: ${(props) => props.theme.layout.navSidebarCollapsedWidth};
    transition: width ${(props) => props.theme.layout.navSidebarTransitionSpeed}ms ease-in-out;
    transform: none;
  
    ${(props) => props.$open && `
      width: ${props.theme.layout.navSidebarWidth};
    `}
  }
`
