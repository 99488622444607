import { IconLookup } from '@fortawesome/fontawesome-common-types'
import { Grid, Tooltip, Typography, styled } from '@mui/material'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Icon } from '../Icon/Icon'

export type SubNavItemProps = {
  href: string
  icon?: IconLookup
  title?: string
  description?: string
}

export const SubNavItem: React.FC<SubNavItemProps> = ({ href, icon, title, description }) => {
  const location = useLocation()

  return (
    <Tooltip title={''} placement="right">
      <StyledNavItem to={href} $active={href === location.pathname || `${href}/` === location.pathname}>
        <StyledGridContainer container wrap="nowrap">
          <StyledIconContainer item>
            {icon && <StyledNavIcon icon={icon} />}
          </StyledIconContainer>
          <StyledDescriptionContainer item>
            <StyledTitle variant={'body1'}>
              {title}
            </StyledTitle>
            <StyledDescription variant={'body1'}>
              {description}
            </StyledDescription>
          </StyledDescriptionContainer>
        </StyledGridContainer>
      </StyledNavItem>
    </Tooltip>
  )
}

const StyledIconContainer = styled(Grid)`
  margin: auto;

  ${(props) => props.theme.breakpoints.up('sm')} {
    margin: unset;
  }
`

const StyledGridContainer = styled(Grid)`
  flex-direction: column;
  text-align: center;

  ${(props) => props.theme.breakpoints.up('sm')} {
    flex-direction: row;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    spacing: ${(props) => props.theme.typography.pxToRem(8)};
  }
`

const StyledDescriptionContainer = styled(Grid)`
  margin: auto;
  
  ${(props) => props.theme.breakpoints.up('sm')} {
    margin: unset;
    display: block;
  }
`

const StyledNavIcon = styled(Icon)`
  font-size: ${(props) => props.theme.typography.pxToRem(20)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    margin-top: ${(props) => props.theme.typography.pxToRem(2)};
    margin-right: ${(props) => props.theme.typography.pxToRem(16)};
    font-size: ${(props) => props.theme.typography.pxToRem(18)};
  }
`

const StyledNavItem = styled(Link, {
  shouldForwardProp: (prop) => prop !== '$active',
}) <{ $active?: boolean }>`
  font-family: ${(props) => props.theme.typography.fontFamily};
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: ${(props) => props.theme.typography.pxToRem(16)};
  letter-spacing: 0.03em;
  display: flex;
  align-items: center;
  min-height: ${(props) => props.theme.typography.pxToRem(32)};
  text-decoration: none;
  padding: ${(props) => props.theme.typography.pxToRem(12)} ${(props) => props.theme.typography.pxToRem(4)};
  width: 100%;
  position: relative;
  transition: color 0.25s ease-in-out;

  ${(props) => props.$active && `
    color: ${props.theme.palette.text.primary};
    background-color: ${props.theme.palette.action.hover};
  `}

  @media all and (display-mode: standalone) {
    padding: ${(props) => props.theme.typography.pxToRem(12)} ${(props) => props.theme.typography.pxToRem(4)} ${(props) => props.theme.typography.pxToRem(28)};
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      background-color: ${(props) => props.theme.palette.primary.main};
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
    }

    ${(props) => props.$active && `
      color: ${props.theme.palette.text.primary};
      background-color: ${props.theme.palette.background.paper};

      &:after {
        opacity: 1;
      }
    `}

    &:hover {
      background-color: ${(props) => props.theme.palette.background.paper};
    }
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding: ${(props) => props.theme.typography.pxToRem(8)} ${(props) => props.theme.typography.pxToRem(16)};
    width: unset;

    &:hover {
      color: ${(props) => props.theme.palette.text.primary};
      background-color: ${(props) => props.theme.palette.action.hover};
    }
  }
`

const StyledTitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.pxToRem(11)};
  font-weight: 500;

  ${(props) => props.theme.breakpoints.up('sm')} {
    font-size: ${(props) => props.theme.typography.pxToRem(14)};
    font-weight: 600;
  }
`

const StyledDescription = styled(Typography)`
  display: none;

  ${(props) => props.theme.breakpoints.up('sm')} {
    display: unset;
    font-weight: 500;
    margin-top: ${(props) => props.theme.typography.pxToRem(2)};
    font-size: ${(props) => props.theme.typography.pxToRem(12)};
    line-height: ${(props) => props.theme.typography.pxToRem(20)};
  }
`