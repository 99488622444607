import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ThirdParty } from 'src/types/ThirdParty'
import { thirdPartiesAdapter } from '../adapters/thirdParties'

export const initialState = thirdPartiesAdapter.getInitialState()

export const { reducer, actions } = createSlice({
  name: 'thirdParties',
  initialState,
  reducers: {
    addMultipleThirdParties: (state, action: PayloadAction<ThirdParty[]>) => {
      thirdPartiesAdapter.upsertMany(state, action.payload)
    },
    removeAllThirdParties: (state) => {
      thirdPartiesAdapter.removeAll(state)
    },
  },
})

export const { removeAllThirdParties, addMultipleThirdParties } = actions