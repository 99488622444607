import { styled } from '@mui/material'
import { DataGrid, GridColDef, GridFilterModel, GridLogicOperator, GridSortModel } from '@mui/x-data-grid'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { useAppSelector } from 'src/store/store'
import { scrollbarStyle } from 'src/styles/themes'
import { TelematicsEvent } from 'src/types/TelematicsEvent'

export type VehicleHistoryFragmentProps = {
  onEditBookingClick?: (id: string) => void
  currentDate: Dayjs
}

export const VehicleHistoryFragment: React.FC<VehicleHistoryFragmentProps> = ({ onEditBookingClick, currentDate, ...props }) => {
  const operationActiveVehicleId = useAppSelector((state) => state.settings.operationActiveVehicleId) || ''
  const [telematicsEvents, setTelematicsEvent] = useState<TelematicsEvent[]>([])

  useEffect(() => {
    CarshareApiService.get<TelematicsEvent>('getVehicleTelematicsEvents', {
      VEHICLE_ID: operationActiveVehicleId,
    }).then((response) => {
      if (!response.results) return

      setTelematicsEvent(response.results)
    })
  }, [operationActiveVehicleId])

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      type: 'string',
      disableColumnMenu: true,
      sortable: false,
      width: 100,
    },
    {
      field: 'eventName',
      headerName: 'Event name',
      type: 'string',
      disableColumnMenu: true,
      sortable: false,
      width: 200,
    },
    {
      field: 'generatedOn',
      headerName: 'Event triggered on',
      type: 'dateTime',
      valueFormatter: params => {
        return dayjs(params.value).format('MMM D, YYYY h:mm A')
      },
      sortable: false,
      width: 200,
    },
  ]

  const filterModel: GridFilterModel = {
    items: [
      {
        field: 'generatedOn',
        operator: 'onOrAfter',
        value: currentDate.startOf('day').format('YYYY-MM-DDTHH:mm'),
      },
    ],
    logicOperator: GridLogicOperator.And,
  }

  const initialSortModel: GridSortModel = [
    {
      field: 'generatedOn',
      sort: 'asc',
    },
  ]

  const rows = telematicsEvents.map((event) => {
    const id = event.VEHICLE_EVENT_ID
    const eventName = event.vehicle_event_name
    const generatedOn = event.vehicle_event_created

    return {
      id: id,
      eventName: eventName,
      generatedOn: new Date(generatedOn),
    }
  })

  return (
    <StyledDataGrid
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnSelector
      onRowClick={(params) => {
        if (!onEditBookingClick) return

        onEditBookingClick(params.row.BOOKING_ID)
      }}
      initialState={{
        pagination: {
          paginationModel: { page: 0 },
        },
        filter: {
          filterModel: filterModel,
        },
        sorting: {
          sortModel: initialSortModel,
        },
      }}
      {...props}
    />
  )
}

const StyledDataGrid = styled(DataGrid)`
  margin-left: ${props => props.theme.typography.pxToRem(-36)};
  margin-right: ${props => props.theme.typography.pxToRem(-36)};
  height: calc(100dvh - ${props => props.theme.typography.pxToRem(150)});
  font-size: ${props => props.theme.typography.pxToRem(14)};
  overflow: auto;
  ${scrollbarStyle}

  & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus {
    outline: none
  }

  & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus {
    outline: none
  }

  & .MuiDataGrid-columnHeaders {
    .MuiDataGrid-columnHeader:first-of-type {
      padding-left: ${props => props.theme.typography.pxToRem(20)};
    }
  }

  & .MuiDataGrid-row {
    > .MuiDataGrid-cell:first-of-type {
      padding-left: ${props => props.theme.typography.pxToRem(20)};
    }
  }

  ${props => props.theme.breakpoints.up('sm')} {
    margin-left: ${props => props.theme.typography.pxToRem(-16)};
    margin-right: ${props => props.theme.typography.pxToRem(-16)};
    border: none;
    min-height: calc(${props => props.theme.typography.pxToRem(62 * 11 + 69 - 115)});
    height: 100%;

    & .MuiDataGrid-columnHeaders {
      .MuiDataGrid-columnHeader:first-of-type {
        padding-left: ${props => props.theme.typography.pxToRem(16)};
      }
    }

    & .MuiDataGrid-row {
      > .MuiDataGrid-cell:first-of-type {
        padding-left: ${props => props.theme.typography.pxToRem(16)};
      }
    }
  }

  & .MuiTablePagination-toolbar {
    > p {
      font-size: ${props => props.theme.typography.pxToRem(14)};
    }
  }

  & .MuiDataGrid-row {
    cursor: pointer;
    
    &:hover {
      background-color: ${props => props.theme.palette.action.hover};
    }
  }

  & .MuiDataGrid-virtualScroller {
    ${scrollbarStyle}
  }

  & .MuiDataGrid-overlay {
    background-color: ${props => props.theme.palette.background.paper};
  }
`
