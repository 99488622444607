import { faFileDownload } from '@fortawesome/pro-regular-svg-icons'
import { Grid } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { AnimatedPage } from 'src/components/AnimatedPage/AnimatedPage'
import { SubNavItem } from 'src/components/SubNavSidebar/SubNavItem'
import { StyledAlert, StyledContainer, StyledSubNavSidebar } from './StyledReports'

export type ReportsProps = {
  //
}

export const Reports: React.FC<ReportsProps> = () => {
  return (
    <Grid container flexDirection={'row'} wrap={'nowrap'}>
      <Grid item>
        <StyledSubNavSidebar>
          <SubNavItem
            title={'Downloads'}
            href="/dashboard/reports/downloads"
            description=""
            icon={faFileDownload} />
        </StyledSubNavSidebar>
      </Grid>
      <Grid item sx={{ flexGrow: 1 }}>
        <StyledContainer id="__reports">
          <StyledAlert />
          <AnimatedPage>
            <Outlet />
          </AnimatedPage>
        </StyledContainer>
      </Grid>
    </Grid>
  )
}
