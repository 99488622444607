import { styled } from '@mui/material'
import React, { ComponentProps, useState } from 'react'
import { HERE_API_KEY } from '../../apis/HereMapApiService'
import { MapContext, MapContextType } from './MapContext'
import { UseMapArgs, useMap } from './useMap'

export type MapProps = ComponentProps<typeof StyledMapContainer> & {
  children?: React.ReactNode
  mapProps?: Partial<UseMapArgs>
}

export const Map: React.FC<MapProps> = ({ children, mapProps, ...props }) => {
  const [mapContainer, setMapContainer] = useState<HTMLDivElement | null>(null)
  const [markerGroup, setMarkerGroup] = useState<H.map.Group | null>(null)

  if (mapProps && !mapProps?.mapOptions) {
    mapProps.mapOptions = {
      center: { lat: -37.810246, lng: 144.962768 },
      zoom: 10,
    }
  }

  if (!mapProps) {
    mapProps = {
      mapOptions: {
        center: { lat: -37.810246, lng: 144.962768 },
        zoom: 10,
      },
    }
  }

  const { ui, map, platform } = useMap({
    apiKey: HERE_API_KEY,
    node: mapContainer,
    ...mapProps,
  })

  return (
    <MapContext.Provider value={{ ui, map, platform, markerGroup, setMarkerGroup } as MapContextType}>
      <StyledMapContainer
        {...props}
        ref={(node) => setMapContainer(node)}
      >
        {map ? children : null}
      </StyledMapContainer>
    </MapContext.Provider>
  )
}

export const StyledMapContainer = styled('div')`
  width: 100%;

  .H_ib_body {
    right: -120px;
    opacity: 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }

  .H_ib_content {
    margin: 0;
  }

  .H_icon {
    display: none;
  }
 
  .H_ib_tail {
    &::after {
      display: none;
      bottom: 68px;
    }
    &::before {
      display: none;
      bottom: 68px;
    }
  }
`
