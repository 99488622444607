/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { Dropdown } from 'src/components/Dropdown/Dropdown'
import { DropdownItem } from 'src/components/Dropdown/DropdownItem'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { StyledForm, StyledFormControl, StyledSubmitButton } from 'src/fragments/StyledForm'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupVehicleAttributes } from 'src/store/thunks/setupVehicleAttributes'
import { Select } from 'src/types/Select'

export type AddVehicleAttributeFormProps = {
  vehicleId: string
  onFormSubmitSuccess?: () => void
}

export const AddVehicleAttributeForm: React.FC<AddVehicleAttributeFormProps> = ({ vehicleId, onFormSubmitSuccess }) => {
  const activeOrgUnit = useAppSelector(state => state.settings.activeOrgUnit)
  const { loading, complete, errorMessage, request } = useApiRequest()
  const { setSuccessMessage } = useAlertContext()
  const dispatch = useAppDispatch()

  const [attributeId, setAttributeId] = useState<string>('')
  const [attributeOptions, setAttributeOptions] = useState<Select['tuples']>([])

  useEffect(() => {
    CarshareApiService.get<Select>('getSelects', {
      kinds: 'carshare_attributes',
    }).then((response) => {
      const attributes = response.results.find(result => result.name === 'carshare_attributes')?.tuples || []

      setAttributeOptions(attributes)
    })
  }, [])

  const createFacilityHandler = () => {
    const payload = {
      ORG_UNIT_ID: activeOrgUnit?.ORG_UNIT_ID,
      VEHICLE_ID: vehicleId,
      ATTRIBUTE_ID: attributeId,
    }

    return CarshareApiService.post('addVehicleAttribute', payload)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    request(createFacilityHandler)
  }

  useEffect(() => {
    if (errorMessage) return

    if (complete && onFormSubmitSuccess) {
      onFormSubmitSuccess()
    }
  }, [errorMessage, complete, onFormSubmitSuccess])

  useEffect(() => {
    if (complete) {
      setSuccessMessage('Attribute was added successfully.')
      dispatch(setupVehicleAttributes({ VEHICLE_ID: vehicleId }))
    }
  }, [complete])

  return (
    <StyledForm $isEditable onSubmit={onSubmit} >
      <Grid container spacing={2} justifyContent={'center'} flexDirection={'column'}>
        <Grid item>
          {errorMessage && (
            <Alert severity={'error'} icon={false}>
              {errorMessage}
            </Alert>
          )}
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Attribute</TextFieldLabel>
            <Dropdown
              value={attributeOptions.find(option => option.name === attributeId)?.text || ''}
              onSelectItem={(value) => { setAttributeId(value) }}
              disablePortal={false}
            >
              {attributeOptions.map(option => {
                return (
                  <DropdownItem key={option.name} data-value={option.name}>{option.text}</DropdownItem>
                )
              })}
            </Dropdown>
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledSubmitButton
            primary
            fullWidth
            type="submit"
            disabled={loading}
          >
            Add Attribute
          </StyledSubmitButton>
        </Grid>
      </Grid>
    </StyledForm >
  )
}
