import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Vehicle } from 'src/types/Vehicle'
import { vehiclesAdapter } from '../adapters/vehicles'
import { setupVehicleAttributes } from '../thunks/setupVehicleAttributes'

export const initialState = vehiclesAdapter.getInitialState()

export const { reducer, actions } = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    addMultipleVehicles: (state, action: PayloadAction<Vehicle[]>) => {
      vehiclesAdapter.upsertMany(state, action.payload)
    },
    removeAllVehicles: (state) => {
      vehiclesAdapter.removeAll(state)
    },
    replaceAllVehicles: (state, action: PayloadAction<Vehicle[]>) => {
      vehiclesAdapter.setAll(state, action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setupVehicleAttributes.fulfilled, (state, action) => {
      if (action.payload) {
        const vehicleAttributes = action.payload.results || []

        vehiclesAdapter.updateOne(state, {
          id: action.payload.VEHICLE_ID,
          changes: {
            vehicle_attributes: vehicleAttributes,
          },
        })
      }
    })
  },
})


export const { addMultipleVehicles, removeAllVehicles, replaceAllVehicles } = actions