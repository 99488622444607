/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Grid } from '@mui/material'
import { useState } from 'react'
import { Button } from 'src/components/Button/Button'
import { TextField } from 'src/components/Form/TextField'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { StyledForm } from 'src/fragments/StyledForm'
import { pxToRem } from 'src/styles/themes'

export type ConfirmationFormProps = {
  action?: string
  confirmationTerm: string
  onFormSubmitSuccess: () => void
}

export const ConfirmationForm: React.FC<ConfirmationFormProps> = ({ action, confirmationTerm, onFormSubmitSuccess }) => {
  const [confirmationValue, setConfirmationValue] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    if (confirmationValue === confirmationTerm) {
      onFormSubmitSuccess()
    } else {
      setErrorMessage('The entered value does not match.')
    }
  }

  return (
    <StyledForm $isEditable onSubmit={onSubmit}>
      <Grid container spacing={2} justifyContent={'center'} flexDirection={'column'}>
        {errorMessage && <Grid item>
          <Alert severity={'error'} icon={false}>
            {errorMessage}
          </Alert>
        </Grid>}
        <Grid item>
          <TextFieldLabel>
            Enter <strong>{confirmationTerm}</strong> below to confirm {action}:
          </TextFieldLabel>
          <TextField
            type="text"
            value={confirmationValue}
            onChange={(e) => {
              setConfirmationValue(e.target.value)
            }}
          />
        </Grid>
        <Grid item>
          <Button
            sx={{ marginTop: pxToRem(8), marginBottom: pxToRem(16) }}
            primary
            fullWidth
            type="submit"
            disabled={confirmationValue !== confirmationTerm}
          >
            Confirm {action}
          </Button>
        </Grid>
      </Grid>
    </StyledForm >
  )
}
