import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { Button, ButtonBase, ClickAwayListener, MenuList, Popper, styled, Typography } from '@mui/material'
import React, { ComponentProps, KeyboardEventHandler, MouseEvent, useEffect, useMemo, useRef, useState } from 'react'


import { scrollbarStyle } from '../../styles/themes'
import { Icon } from '../Icon/Icon'
import { DropdownContext, DropdownContextType } from './DropdownContext'

export type DropdownProps = ComponentProps<typeof Button> & {
  children: React.ReactNode
  value: string
  fullHeight?: boolean
  onSelectItem: (value: string) => void
  disablePortal?: boolean
  error?: boolean
}

export const Dropdown: React.FC<DropdownProps> = ({ children, value, onSelectItem, error, fullHeight, disablePortal = true, ...props }) => {
  const menuListRef = useRef<HTMLUListElement>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [open, setOpen] = useState(false)
  const [width, setWidth] = useState(0)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()

    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const onKeyDown: KeyboardEventHandler<HTMLButtonElement> = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      event.stopPropagation()

      setAnchorEl(event.currentTarget)
      setOpen(true)

      if (open && menuListRef) {
        menuListRef?.current?.focus()
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClose = (event: any) => {
    event.preventDefault()
    event.stopPropagation()

    setTimeout(() => {
      setOpen(false)
    }, 100)
  }

  const defaultContext: DropdownContextType = useMemo(() => ({
    open,
    onSelectItem,
    setDropdownOpen: setOpen,
  }), [open])

  useEffect(() => {
    if (anchorEl) {
      setWidth(anchorEl.offsetWidth)
    }
  }, [anchorEl])

  return (
    <DropdownContext.Provider value={defaultContext}>
      <StyledButton disableRipple disableTouchRipple onClick={handleClick} onKeyDown={onKeyDown} $open={open} $error={error} {...props}>
        <StyledTitleContainer>
          <StyledDropdownValue variant="body1">
            {value}
          </StyledDropdownValue>
          <StyledIcon icon={faChevronDown} />
        </StyledTitleContainer>
      </StyledButton>
      <StyledPopper
        $width={width}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        disablePortal={disablePortal}
      >
        <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown" touchEvent="onTouchStart">
          <StyledMenuList ref={menuListRef} $fullHeight={fullHeight}>
            {children}
          </StyledMenuList>
        </ClickAwayListener>
      </StyledPopper>
    </DropdownContext.Provider>
  )
}

export const StyledButton = styled(ButtonBase, { shouldForwardProp: (prop) => prop !== '$open' && prop !== '$error' }) <{ $open: boolean, $error?: boolean }>`
  border-radius: ${(props) => props.theme.typography.pxToRem(8)};
  padding: ${(props) => props.theme.typography.pxToRem(12)} ${(props) => props.theme.typography.pxToRem(16)};
  height: ${(props) => props.theme.typography.pxToRem(48)};
  background-color: ${(props) => props.theme.palette.background.paper};
  border: 1px solid ${(props) => props.$open ? props.theme.palette.text.primary : props.theme.palette.custom.formFieldBorder};
  box-shadow: ${(props) => props.theme.palette.custom.formFieldBoxShadow};
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.Mui-disabled {
    .MuiTypography-root {
      color: ${(props) => props.theme.palette.text.disabled};
    }
    
    .MuiSvgIcon-root {
      color: ${(props) => props.theme.palette.text.disabled};
    }
    
    box-shadow: none;
  }

  &:focus {
    box-shadow: 0 0 0 2px ${(props) => props.theme.palette.background.default}, 0 0 0 4px ${(props) => props.theme.palette.custom.button.focusedOutline};
  }
  
  &:after {
    content: '';
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    box-shadow: ${(props) => props.theme.palette.custom.formFieldBoxShadow};
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    border-color: ${(props) => props.theme.palette.text.disabled};

    &:after {
      opacity: 1;
    }
  }

  ${(props) => props.$open && `
    &:after {
      opacity: 1;
    }
  `}

  ${(props) => props.$error && `
    border-color: ${props.theme.palette.error.main};

    &:after {
      opacity: 0;
    }
  `}
`

export const StyledPopper = styled(Popper, { shouldForwardProp: (prop) => prop !== '$width' }) <{ $width: number }>`
  width: ${(props) => props.$width}px;
  z-index: ${(props) => props.theme.zIndex.appBar + 110};
  background-color: ${(props) => props.theme.palette.background.paper};
  border-radius: ${(props) => props.theme.typography.pxToRem(8)};
`

export const StyledDropdownContainer = styled('div')`
  display: inline-block;
`

export const StyledIcon = styled(Icon)`
  color: ${props => props.theme.palette.text.primary};
  margin-left: ${props => props.theme.typography.pxToRem(8)};
  font-size: ${props => props.theme.typography.pxToRem(14)};
`

export const StyledTitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

export const StyledDropdownValue = styled(Typography)`
  color: ${props => props.theme.palette.text.primary};
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledMenuList = styled(MenuList, {
  shouldForwardProp: (prop) => prop !== '$fullHeight',
}) <{ $fullHeight?: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: ${props => props.theme.typography.pxToRem(8)};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  gap: 0;
  overflow-y: auto;
  ${props => !props.$fullHeight && `
    max-height: ${props.theme.typography.pxToRem(200)};
  `}
  ${scrollbarStyle};

  li:first-of-type {
    &:after {
      border-radius: ${props => props.theme.typography.pxToRem(8)} ${props => props.theme.typography.pxToRem(8)} 0 0;
    }
  }

  li:last-of-type {
    &:after {
      border-radius: 0 0 ${props => props.theme.typography.pxToRem(8)} ${props => props.theme.typography.pxToRem(8)};
    }
  }
  
  ${props => props.theme.breakpoints.up('sm')} {
    gap: ${props => props.theme.typography.pxToRem(16)};
  }
`
