import { createEntityAdapter } from '@reduxjs/toolkit'
import { Customer } from 'src/types/Customer'
import { RootState } from '../store'

export const customersAdapter = createEntityAdapter<Customer>({
  selectId: (customer) => customer.CUSTOMER_ID,
})

export const customersSelectors = customersAdapter.getSelectors<RootState>(
  (state) => state.customers,
)
