import { faBell } from '@fortawesome/pro-regular-svg-icons'
import { faArrowRightFromBracket, faBars, faCompress, faExpand } from '@fortawesome/pro-solid-svg-icons'
import { Badge, Grid, styled } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon } from 'src/components/Icon/Icon'
import { useNotificationSidebarContext } from 'src/components/NotificationSidebar/NotificationSidebarContext'
import { resetState } from 'src/store/reducers/rootReducer'
import { setNavSidebarOpen } from 'src/store/reducers/settings'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { logoutUser } from 'src/store/thunks/logoutUser'
import { IconButton } from '../../components/IconButton/IconButton'
import { NavHeader } from '../../components/NavHeader/NavHeader'
import { OrganisationDropdownFragment } from './OrganisationDropdownFragment'

export type HeaderFragmentProps = {
  //
}

export const HeaderFragment: React.FC<HeaderFragmentProps> = () => {
  const [isFullscreen, setIsFullscreen] = useState(false)
  const isNavOpen = useAppSelector((state) => state.settings.navSidebarOpen)
  const { notificationCount, setNotificationSidebarOpen } = useNotificationSidebarContext()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const toggleNavOpen = () => {
    dispatch(setNavSidebarOpen(!isNavOpen))
  }

  const onNotificationSidebarOpen = () => {
    setNotificationSidebarOpen(true)

    if ('Notification' in window) {
      Notification.requestPermission()
    }
  }

  // Full Screen toggle
  const onToggleFullScreen = () => {
    if (!isFullscreen) {
      document.body.requestFullscreen()
      setIsFullscreen(true)
    } else {
      document.exitFullscreen()
      setIsFullscreen(false)
    }
  }

  // Logout
  const onLogout = () => {
    dispatch(logoutUser()).then(() => {
      dispatch(resetState())
      navigate('/')
    })
  }

  return (
    <StyledNavHeader $isNavOpen={isNavOpen}>
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <StyledMenuButton onClick={toggleNavOpen}>
            <Icon icon={faBars} sx={{ fontSize: '24px' }} />
          </StyledMenuButton>
          <OrganisationDropdownFragment />
        </Grid>
        <Grid item>
          <StyledExpandButton onClick={onToggleFullScreen} tooltipTitle="Toggle fullscreen">
            <Icon icon={isFullscreen ? faCompress : faExpand} />
          </StyledExpandButton>
          <StyledBellButton onClick={onNotificationSidebarOpen} tooltipTitle="Show notifications">
            <Badge sx={{ fontWeight: 400 }} badgeContent={notificationCount} color="error">
              <Icon icon={faBell} />
            </Badge>
          </StyledBellButton>
          <StyledLogoutButton onClick={onLogout} tooltipTitle="Logout">
            <Icon icon={faArrowRightFromBracket} />
          </StyledLogoutButton>
        </Grid>
      </Grid>
    </StyledNavHeader>
  )
}

const StyledNavHeader = styled(NavHeader, {
  shouldForwardProp: (prop) => prop !== '$isNavOpen',
}) <{ $isNavOpen?: boolean }>`
  left: 0;
  transition: transform ${(props) => props.theme.layout.navSidebarTransitionSpeed}ms ease-in-out, width ${(props) => props.theme.layout.navSidebarTransitionSpeed}ms ease-in-out;

  ${(props) => props.$isNavOpen && `
    transform: translateX(${props.theme.layout.navSidebarWidth});
  `}

  ${(props) => props.theme.breakpoints.up('sm')} {
    width: calc(100% - ${(props) => props.theme.layout.navSidebarCollapsedWidth});
    transform: translateX(${(props) => props.theme.layout.navSidebarCollapsedWidth});

    ${(props) => props.$isNavOpen && `
      width: calc(100% - ${props.theme.layout.navSidebarWidth});
      transform: translateX(${props.theme.layout.navSidebarWidth});
    `}
  }
`

const StyledMenuButton = styled(IconButton)`
  margin-left: ${props => props.theme.typography.pxToRem(-12)};
  margin-right: ${props => props.theme.typography.pxToRem(8)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    margin-right: ${props => props.theme.typography.pxToRem(16)};
  }
`

const StyledExpandButton = styled(IconButton)`
  display: none;
  margin-right: ${props => props.theme.typography.pxToRem(8)};

  ${(props) => props.theme.breakpoints.up('sm')} {
    display: inline-flex;
  }
`

const StyledLogoutButton = styled(IconButton)`
  margin-right: ${props => props.theme.typography.pxToRem(-12)};
`


const StyledBellButton = styled(IconButton)`
  margin-right: ${props => props.theme.typography.pxToRem(8)};
`
