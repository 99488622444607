import dayjs from 'dayjs'

export const formatDateAndTime = (dateStr: string, hour: number, minute: number, isTimeOnly?: boolean): string => {
  const date = dayjs(dateStr, 'YYYY-MM-DD').hour(hour).minute(minute)
  
  if (isTimeOnly) {
    return date.format('hh:mm A')
  }

  return `${date.format('DD/MM/YYYY hh:mm A')}`
}

export const formatDay = (day: Date) => {
  return day.toISOString().split('T')[0]
}


export const createDayjsObject = (date: string, hour: number, minute: number) => {
  return dayjs(`${date}T${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}:00`)
}