import { useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { NotificationCard } from 'src/components/NotificationCard/NotificationCard'
import { NotificationSidebar } from 'src/components/NotificationSidebar/NotificationSidebar'
import { useNotificationSidebarContext } from 'src/components/NotificationSidebar/NotificationSidebarContext'
import { useAppDispatch } from 'src/store/store'
import { setupBookingById } from 'src/store/thunks/setupBookingById'
import { Notification } from 'src/types/Notification'
import { useLayoutContext } from './LayoutContext'

export type NotificationSidebarFragmentProps = {
  //
}

export const NotificationSidebarFragment: React.FC<NotificationSidebarFragmentProps> = () => {
  const { setNotificationCount, isNotificationSidebarOpen, setNotificationSidebarOpen } = useNotificationSidebarContext()
  const { setSelectedBookingId } = useLayoutContext()
  const [notifications, setNotifications] = useState<Notification[]>([])
  const dispatch = useAppDispatch()

  const onSeeBookingClick = (bookingId: string) => {
    dispatch(setupBookingById({ BOOKING_ID: bookingId }))
    setSelectedBookingId(bookingId)
  }

  const onDismissClick = (notificationId: string) => {
    CarshareApiService.post('dismissNotification', { NOTIFICATION_ID: notificationId }).then(() => {
      setNotifications(notifications.filter((notification) =>
        (notification.NOTIFICATION_ID !== notificationId) &&
        (notification.notification_read === '0000-00-00 00:00:00'), //Filter out read notifications
      ))
      setNotificationCount((prev) => Math.max(0, prev - 1))
    })
  }

  useEffect(() => {
    CarshareApiService.get<Notification>('getNotifications').then((response) => {
      if (response.results) {
        setNotifications(response.results.filter((notification) => notification.notification_read === '0000-00-00 00:00:00') || [])
        setNotificationCount(response.results.length)
      }
    })
  }, [])

  return (
    <NotificationSidebar open={isNotificationSidebarOpen} onClose={() => setNotificationSidebarOpen(false)}>
      {notifications.map((notification) => (
        <NotificationCard
          key={notification.NOTIFICATION_ID}
          title={notification.notification_heading}
          time={notification.notification_htime}
          type={'error'}
          onMarkReadClick={() => onDismissClick(notification.NOTIFICATION_ID)}
          onSeeBookingClick={() => onSeeBookingClick(notification.BOOKING_ID)}
        >
          {notification.notification_contents}
        </NotificationCard>
      ))}
    </NotificationSidebar>
  )
}