export const getClusterIcon = (weight: number, radius: number, type: 'success' | 'warning' | 'error') => {
  const radiusOuter = radius
  const radiusInner = radius * 0.75
  const diameter = radius * 2
  const maxScaleFactor = 1.1
  const maxDiameter = diameter * maxScaleFactor
  const center = maxDiameter / 2

  const colorMapping = {
    'success': '#67c879',
    'warning': '#d09746',
    'error': '#c14739',
  }

  return `<div class="cluster-container" style="left: -${center}px; top: -${center}px;">
    <svg xmlns="http://www.w3.org/2000/svg" width="${diameter}" height="${diameter}" viewBox="0 0 ${maxDiameter} ${maxDiameter}" fill="none">
      <circle cx="${center}" cy="${center}" r="${radiusInner}px" fill="${colorMapping[type]}" fill-opacity="0.3">
        <animate attributeName="r" values="${radiusInner};${radiusOuter};${radiusOuter * maxScaleFactor};${radiusOuter}};${radiusOuter}" dur="2s" repeatCount="indefinite" />
        <animate attributeName="fill-opacity" values="0.4;0.25;0;0;0" dur="2s" repeatCount="indefinite" />
      </circle>
      <circle cx="${center}" cy="${center}" r="${radiusInner}px" fill="${colorMapping[type]}"/>
      <text x="${center}" y="${center}" font-family="Tahoma,Comic Sans MS,system-ui" font-weight="600" font-size="16" fill="white" text-anchor="middle" dy=".3em">${weight}</text>
    </svg>
  </div>`
}