import { styled } from '@mui/material/styles'
import React, { ComponentProps, useContext } from 'react'

import { DropdownItem } from '../Dropdown/DropdownItem'
import { MobileSliderContext } from './MobileSliderContext'

export type MobileSliderDropdownItemProps = ComponentProps<typeof DropdownItem> & {
  level: number
  idx: number
  children: React.ReactNode
}

export const MobileSliderDropdownItem: React.FC<MobileSliderDropdownItemProps> = ({ level, idx, children, ...props }) => {
  const menuContext = useContext(MobileSliderContext)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDropdownItemClick = (event: any) => {
    event.stopPropagation()

    if (props.onClick) {
      props.onClick(event)
    }
    
    menuContext.setMenuOpen(level ?? 0, (idx ?? 0) + 1)
  }

  return (
    <StyledDropdownItem
      onSelect={onDropdownItemClick}
      {...props}
    >
      {children}
    </StyledDropdownItem>
  )
}

const StyledDropdownItem = styled(DropdownItem)`
`