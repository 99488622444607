import { styled } from '@mui/material'

export const StyledMain = styled('main', {
  shouldForwardProp: (prop) => prop !== '$isNavOpen',
}) <{ $isNavOpen?: boolean }>`
  position: relative;
  margin-top: ${(props) => props.theme.layout.navHeaderMobileHeight};
  margin-left: 0;
  transition: margin-left ${(props) => props.theme.layout.navSidebarTransitionSpeed}ms ease-in-out,
    width ${(props) => props.theme.layout.navSidebarTransitionSpeed}ms ease-in-out,
    transform ${(props) => props.theme.layout.navSidebarTransitionSpeed}ms ease-in-out;
  height: calc(100dvh - ${(props) => props.theme.layout.navHeaderMobileHeight} - ${(props) => props.theme.typography.pxToRem(16)});
  
  ${(props) => props.$isNavOpen && `
    transform: translateX(${props.theme.layout.navSidebarWidth});
  `}

  ${(props) => props.theme.breakpoints.up('sm')} {
    padding: 0;
    margin-top: ${(props) => props.theme.layout.navHeaderHeight};
    width: calc(100% - ${(props) => props.theme.layout.navSidebarCollapsedWidth});
    transform: translateX(${(props) => props.theme.layout.navSidebarCollapsedWidth});
    height: 100%;
    
    ${(props) => props.$isNavOpen && `
      width: calc(100% - ${props.theme.layout.navSidebarWidth});
      transform: translateX(${props.theme.layout.navSidebarWidth});
    `}
  }
`
