/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext } from 'react'

export type DropdownContextType = {
  open: boolean
  setDropdownOpen?: (open: boolean) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectItem: (event: any) => void
}

export const DropdownContext = createContext<DropdownContextType>({
  open: false,
  onSelectItem: () => { },
})

