import { FormControl, styled } from '@mui/material'
import { Button } from 'src/components/Button/Button'
import { TextField } from 'src/components/Form/TextField'
import { Icon } from 'src/components/Icon/Icon'

export const StyledForm = styled('form', { shouldForwardProp: (prop) => prop !== '$isEditable' }) <{ $isEditable?: boolean }>`
  width: 100%;

  ${props => props.$isEditable && props.theme.breakpoints.up('md')} {
    width: 70vw;
  }

  ${props => props.$isEditable && props.theme.breakpoints.up('lg')} {
    width: 35vw;
  }
`

export const StyledFormControl = styled(FormControl)`
  width: 100%;
`

export const StyledDateTextField = styled(TextField)`
  .MuiInputBase-root {
    box-shadow: ${(props) => props.theme.palette.custom.formFieldBoxShadow};    

    ${props => props.theme.breakpoints.up('sm')} {
      box-shadow: none;
    }
  }
`

export const StyledAccountSubmitButton = styled(Button)`
  margin-top: ${props => props.theme.typography.pxToRem(8)};
  margin-bottom: ${props => props.theme.typography.pxToRem(16)};
  width: 100%;

  ${props => props.theme.breakpoints.up('sm')} {
    width: ${props => props.theme.typography.pxToRem(180)};
  }
`

export const StyledSubmitButton = styled(Button)`
  margin-top: ${props => props.theme.typography.pxToRem(8)};
  margin-bottom: ${props => props.theme.typography.pxToRem(16)};
`

export const StyledIcon = styled(Icon)`
  color: ${props => props.theme.palette.primary.main};
  font-size: ${props => props.theme.typography.pxToRem(14)};
`

export const StyledEyeIcon = styled(Icon)`
  color: ${props => props.theme.palette.primary.main};
  font-size: ${props => props.theme.typography.pxToRem(16)};
`
