import { styled } from '@mui/material'

export type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...props }) => {
  return (
    <StyledTabPanelContainer
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </StyledTabPanelContainer>
  )
}

const StyledTabPanelContainer = styled('div')`
  height: calc(100% - ${props => props.theme.typography.pxToRem(150)});

  ${props => props.theme.breakpoints.up('sm')} {
    height: calc(100% - ${props => props.theme.typography.pxToRem(86)});
  }
`
