import { faAdd, faClose, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { faPen } from '@fortawesome/pro-solid-svg-icons'
import { CircularProgress, Grid, ListItemText, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { Button } from 'src/components/Button/Button'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { Icon } from 'src/components/Icon/Icon'
import { useManagementTableContext } from 'src/components/ManagementTable/ManagementTableContext'
import { Modal } from 'src/components/Modal/Modal'
import { OutlinedMenuItem } from 'src/components/OutlinedMenuList/OutlinedMenuItem'
import { OutlinedMenuList } from 'src/components/OutlinedMenuList/OutlinedMenuList'
import { RequestHandler, useApiRequest } from 'src/hooks/useApiRequest'
import { customersSelectors } from 'src/store/adapters/customers'
import { orgUnitsSelectors } from 'src/store/adapters/orgUnits'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupCustomerAttributes } from 'src/store/thunks/setupCustomerAttributes'
import { setupCustomerOrgUnits } from 'src/store/thunks/setupCustomerOrgUnits'
import { pxToRem } from 'src/styles/themes'
import { CustomerAttribute } from 'src/types/Customer'
import { User } from 'src/types/User'
import { AddCustomerAttributeForm } from '../CustomerForm/AddCustomerAttributeForm'
import { AddCustomerOrgUnitForm } from '../CustomerForm/AddCustomerOrgUnitForm'
import { CustomerForm } from '../CustomerForm/CustomerForm'
import { StyledAddButton, StyledCloseButton, StyledContainer, StyledEditButton, StyledEditIcon } from '../StyledDetailsFragment'


type CustomerDetailsFragmentProps = {
  //
}

type SelectedOrgUnit = {
  ORG_UNIT_ID: string
  MEMBER_ID: string
  CUSTOMER_ID: string
}

export const CustomerDetailsFragment: React.FC<CustomerDetailsFragmentProps> = () => {
  const [isEditCustomerFormOpen, setEditCustomerFormOpen] = useState<boolean>(false)
  const [isAddOrgUnitFormOpen, setAddOrgUnitFormOpen] = useState<boolean>(false)
  const [isAddAttributeFormOpen, setAddAttributeFormOpen] = useState<boolean>(false)
  const [selectedOrgUnit, setSelectedOrgUnit] = useState<SelectedOrgUnit>()
  const [selectedAttribute, setSelectedAttribute] = useState<CustomerAttribute>()
  const [memoSelectedId, setMemoSelectedId] = useState<string | null>(null)
  const { selectedId, setSelectedId } = useManagementTableContext()
  const activeCustomer = useAppSelector(state => customersSelectors.selectById(state, memoSelectedId || ''))
  const orgUnits = useAppSelector(state => orgUnitsSelectors.selectAll(state))
  const [isDeleteOrgUnitDialogOpen, setDeleteOrgUnitDialogOpen] = useState<boolean>(false)
  const [isDeleteAttributeDialogOpen, setDeleteAttributeDialogOpen] = useState<boolean>(false)
  const [isSendPasswordDialogOpen, setSendPasswordDialogOpen] = useState<boolean>(false)
  const { loading, errorMessage, request } = useApiRequest()
  const { setSuccessMessage, setErrorMessage } = useAlertContext()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (selectedId) {
      dispatch(setupCustomerOrgUnits({ CUSTOMER_ID: selectedId }))
      dispatch(setupCustomerAttributes({ CUSTOMER_ID: selectedId }))

      // Use to prevent flickering redux state data when closing customer details modal on mobile.
      setMemoSelectedId(selectedId)
    }
  }, [selectedId])

  const onConfirmDeleteOrgUnit = (orgUnit: SelectedOrgUnit) => {
    setSelectedOrgUnit(orgUnit)

    setDeleteOrgUnitDialogOpen(true)
  }

  const removeOrgUnitHandler = () => {
    CarshareApiService.post('removeCustomerOrgUnit', selectedOrgUnit).then(() => {
      dispatch(setupCustomerOrgUnits({
        CUSTOMER_ID: selectedId || '',
      }))
    }).then(() => {
      setDeleteOrgUnitDialogOpen(false)
    })
  }

  const onConfirmDeleteAttribute = (attribute: CustomerAttribute) => {
    setSelectedAttribute(attribute)

    setDeleteAttributeDialogOpen(true)
  }

  const removeAttributeHandler = () => {
    CarshareApiService.post('removeCustomerAttribute', selectedAttribute).then(() => {
      dispatch(setupCustomerAttributes({ CUSTOMER_ID: selectedId || '' }))
    }).then(() => {
      setDeleteAttributeDialogOpen(false)
    })
  }

  const onSendPasswordResetEmail = () => {
    const handler: RequestHandler<User> = () => (
      CarshareApiService.post('resetPassword', {
        email: activeCustomer?.email,
        domain: process.env.REACT_APP_CUSTOMER_DOMAIN || window.location.host,
        skipOrgUnit: true,
      })
    )

    request<User>(handler, () => {
      setSuccessMessage(`Password Reset Email has been sent to ${activeCustomer?.email}`)
      setSendPasswordDialogOpen(false)
    })
  }

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage(errorMessage)
    }
  }, [errorMessage])

  return (
    <StyledContainer>
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <StyledEditButton text round onClick={() => { setEditCustomerFormOpen(true) }}>
            <Typography variant="h6">Details</Typography>
            <StyledEditIcon icon={faPen} />
          </StyledEditButton>
        </Grid>
        <Grid item>
          <StyledCloseButton onClick={() => setSelectedId(null)}>
            <Icon icon={faClose} />
          </StyledCloseButton>
        </Grid>
      </Grid>
      <CustomerForm isEditForm customerId={selectedId || ''} editable={false} />

      <Grid container sx={{ marginTop: pxToRem(24) }} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Typography variant="h6">Org Units</Typography>
        </Grid>
        <Grid item>
          <StyledAddButton text round startIcon={<Icon icon={faAdd} />} onClick={() => { setAddOrgUnitFormOpen(true) }}>
            Add Org Unit
          </StyledAddButton>
        </Grid>
      </Grid>

      <OutlinedMenuList>
        {activeCustomer?.ORG_UNITS?.map(orgUnit => {
          if (orgUnit.ORG_UNIT_ID === '0') return null

          return (
            <OutlinedMenuItem key={orgUnit.ORG_UNIT_ID} onClick={() => { onConfirmDeleteOrgUnit({ ...orgUnit, CUSTOMER_ID: activeCustomer.CUSTOMER_ID }) }}>
              <ListItemText>
                {orgUnits.find(el => orgUnit.ORG_UNIT_ID === el.ORG_UNIT_ID)?.org_unit_name}
              </ListItemText>
              <Icon icon={faTrash} />
            </OutlinedMenuItem>
          )
        })}
        {!activeCustomer?.ORG_UNITS && (
          <OutlinedMenuItem disabled>
            <ListItemText>
              <CircularProgress color="secondary" size={32} />
            </ListItemText>
          </OutlinedMenuItem>
        )}
        {activeCustomer?.ORG_UNITS?.length === 0 && (
          <OutlinedMenuItem disabled>
            <ListItemText>
              No org unit found.
            </ListItemText>
          </OutlinedMenuItem>
        )}
      </OutlinedMenuList>

      <Grid container sx={{ marginTop: pxToRem(24) }} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Typography variant="h6">Attributes</Typography>
        </Grid>
        <Grid item>
          <StyledAddButton text round startIcon={<Icon icon={faAdd} />} onClick={() => { setAddAttributeFormOpen(true) }}>
            Add Attributes
          </StyledAddButton>
        </Grid>
      </Grid>

      <OutlinedMenuList>
        {activeCustomer && activeCustomer?.ATTRIBUTES?.map(attribute => {
          return (
            <OutlinedMenuItem key={attribute.ATTRIBUTE_ID} onClick={() => { onConfirmDeleteAttribute(attribute) }}>
              <ListItemText>
                {attribute.attribute_name}
              </ListItemText>
              <Icon icon={faTrash} />
            </OutlinedMenuItem>
          )
        })}
        {!activeCustomer?.ATTRIBUTES && (
          <OutlinedMenuItem disabled>
            <CircularProgress color="secondary" size={32} />
          </OutlinedMenuItem>
        )}
        {activeCustomer?.ATTRIBUTES?.length === 0 && (
          <OutlinedMenuItem disabled>
            <ListItemText>
              No attribute found.
            </ListItemText>
          </OutlinedMenuItem>
        )}
      </OutlinedMenuList>

      {activeCustomer && activeCustomer.email && <Grid container sx={{ marginTop: pxToRem(24) }} flexDirection={'column'} justifyContent={'space-between'} >
        <Grid item>
          <Typography variant="h6">Actions</Typography>
        </Grid>
        <Grid item sx={{ marginTop: pxToRem(4) }}>
          <TextFieldLabel>Send password reset email:</TextFieldLabel>
          <Button outlined fullWidth onClick={() => setSendPasswordDialogOpen(true)}>Send email</Button>
        </Grid>
      </Grid>}

      <Modal title={'Edit Customer Details'} open={isEditCustomerFormOpen} onClose={() => { setEditCustomerFormOpen(false) }} disabledBackdropClose>
        <CustomerForm
          customerId={selectedId || ''}
          isEditForm
          editable={true}
          onFormSubmitSuccess={() => { setEditCustomerFormOpen(false) }}
        />
      </Modal>
      <Modal title={'Add Customer Org Unit'} open={isAddOrgUnitFormOpen} onClose={() => { setAddOrgUnitFormOpen(false) }} disabledBackdropClose>
        <AddCustomerOrgUnitForm
          customerId={selectedId || ''}
          onFormSubmitSuccess={() => { setAddOrgUnitFormOpen(false) }}
        />
      </Modal>
      <Modal title={'Add Customer Attribute'} open={isAddAttributeFormOpen} onClose={() => { setAddAttributeFormOpen(false) }} disabledBackdropClose>
        <AddCustomerAttributeForm
          customerId={selectedId || ''}
          onFormSubmitSuccess={() => { setAddAttributeFormOpen(false) }}
        />
      </Modal>

      <Modal title={'Delete Confirmation'} open={isDeleteOrgUnitDialogOpen} onClose={() => { setDeleteOrgUnitDialogOpen(false) }} disabledBackdropClose>
        <Typography variant="body1">Are you sure you want to remove {activeCustomer?.customer_name} from <strong>{orgUnits.find(el => selectedOrgUnit?.ORG_UNIT_ID === el.ORG_UNIT_ID)?.org_unit_name}</strong>?</Typography>
        <Grid container justifyContent={'flex-end'} sx={{ marginTop: pxToRem(16) }} spacing={1}>
          <Grid item>
            <Button text onClick={() => { setDeleteOrgUnitDialogOpen(false) }}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button primary onClick={() => removeOrgUnitHandler()}>Confirm</Button>
          </Grid>
        </Grid>
      </Modal>

      <Modal title={'Delete Confirmation'} open={isDeleteAttributeDialogOpen} onClose={() => { setDeleteAttributeDialogOpen(false) }} disabledBackdropClose>
        <Typography variant="body1">Are you sure you want to remove attribute <strong>{selectedAttribute?.attribute_name}</strong> from {activeCustomer?.customer_name}?</Typography>
        <Grid container justifyContent={'flex-end'} sx={{ marginTop: pxToRem(16) }} spacing={1}>
          <Grid item>
            <Button text onClick={() => { setDeleteAttributeDialogOpen(false) }}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button primary onClick={() => removeAttributeHandler()}>Confirm</Button>
          </Grid>
        </Grid>
      </Modal>

      <Modal title={'Send Email Confirmation'} open={isSendPasswordDialogOpen} onClose={() => { if (!loading) setSendPasswordDialogOpen(false) }} disabledBackdropClose>
        <Typography variant="body1">Confirm send password reset email to <strong>{activeCustomer?.email}</strong>:</Typography>
        <Grid container justifyContent={'flex-end'} sx={{ marginTop: pxToRem(16) }} spacing={1}>
          <Grid item>
            <Button text disabled={loading} onClick={() => { setSendPasswordDialogOpen(false) }}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button primary onClick={() => onSendPasswordResetEmail()}>
              {loading ?
                <CircularProgress color="info" size={32} /> :
                'Confirm'}
            </Button>
          </Grid>
        </Grid>
      </Modal>

    </StyledContainer>
  )
}
