import { Grid, Typography, styled } from '@mui/material'
import { Icon } from '../Icon/Icon'
import { Paper } from '../Paper/Paper'

export const StyledPaper = styled(Paper)`
  height: ${({ theme }) => theme.typography.pxToRem(200)};
  padding: ${props => props.theme.typography.pxToRem(16)};
`

export const StyledTitleTypography = styled(Typography)`
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
`

export const StyledDescriptionTypography = styled(Typography)`
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  color: ${({ theme }) => theme.palette.text.secondary};
`

export const StyledNumberTypography = styled(Typography)`
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.pxToRem(80)};
`

export const StyledTrendGrid = styled(Grid)`
  margin-left: ${({ theme }) => theme.typography.pxToRem(16)};
`

export const StyledTrendIcon = styled(Icon, { shouldForwardProp: (prop) => prop !== '$trend' }) <{ $trend: 'up' | 'down' }>`
  font-size: ${({ theme }) => theme.typography.pxToRem(36)};
  color: ${(props) => props.$trend === 'up' ? props.theme.palette.success.main : props.theme.palette.error.main};
`

export const StyledPercentageTypography = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.palette.text.secondary};
`

