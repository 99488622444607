/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext } from 'react'
import { MultiselectDropdownOption } from './MultiselectDropdown'

export type DropdownContextType = {
  open: boolean
  setDropdownOpen?: (open: boolean) => void
  values: MultiselectDropdownOption[]
  onSelectItem: (value: MultiselectDropdownOption) => void
}

export const DropdownContext = createContext<DropdownContextType>({
  open: false,
  values: [],
  onSelectItem: () => { },
})

