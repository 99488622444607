import H from '@here/maps-api-for-javascript'
import { useEffect, useState } from 'react'
import { throttle } from 'throttle-debounce'
import type { DefaultLayer } from './models/layer'

export type UseMapArgs = {
  apiKey: string
  mapOptions?: H.Map.Options
  layerOptions?: H.service.Platform.DefaultLayersOptions
  localization?: string | H.ui.i18n.Localization
  zoomAlign?: H.ui.LayoutAlignment
  zoomVisible?: boolean
  zoomDisabled?: boolean
  mapSettingsAlign?: H.ui.LayoutAlignment
  mapSettingsVisible?: boolean
  mapSettingsDisabled?: boolean
  scaleBarAlign?: H.ui.LayoutAlignment
  scaleBarVisible?: boolean
  scaleBarDisabled?: boolean
  node: HTMLElement | null
}

export const useMap = ({
  apiKey,
  layerOptions,
  mapOptions,
  localization,
  zoomAlign,
  zoomVisible,
  zoomDisabled,
  mapSettingsAlign,
  mapSettingsVisible,
  mapSettingsDisabled,
  scaleBarAlign,
  scaleBarVisible,
  scaleBarDisabled,
  node,
}: UseMapArgs) => {
  const [ui, setUi] = useState<H.ui.UI>()
  const [map, setMap] = useState<H.Map>()
  const [platform, setPlatform] = useState<H.service.Platform>()

  const options = {
    autoColor: mapOptions?.autoColor || false,
    bounds: mapOptions?.bounds,
    center: mapOptions?.center || { lat: 52.5, lng: 13.4 },
    engineType: mapOptions?.engineType || H.Map.EngineType.WEBGL,
    imprint: mapOptions?.imprint,
    layers: mapOptions?.layers,
    margin: mapOptions?.margin,
    padding: mapOptions?.padding,
    pixelRatio: mapOptions?.pixelRatio || window.devicePixelRatio || 1,
    renderBaseBackground: mapOptions?.renderBaseBackground,
    zoom: mapOptions?.zoom || 10,
  }

  // Create Map when the container node is ready
  useEffect(() => {
    if (node !== null) {
      const mapPlatform = new H.service.Platform({
        apikey: apiKey || '',
      })

      setPlatform(mapPlatform)

      const defaultLayer = mapPlatform.createDefaultLayers({
        ...layerOptions,
      }) as DefaultLayer

      const newMap = new H.Map(
        node,
        defaultLayer.vector.normal.map,
        options,
      )


      const newUi = H.ui.UI.createDefault(newMap, defaultLayer, localization)
      const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(newMap))
      const zoom = newUi.getControl('zoom')
      const mapSettings = newUi.getControl('mapsettings')
      const scaleBar = newUi.getControl('scalebar')

      behavior.disable(H.mapevents.Behavior.Feature.TILT)
      behavior.disable(H.mapevents.Behavior.Feature.HEADING)
      behavior.disable(H.mapevents.Behavior.Feature.FRACTIONAL_ZOOM)
      zoom?.setAlignment(zoomAlign || H.ui.LayoutAlignment.BOTTOM_RIGHT)
      zoom?.setVisibility(zoomVisible ? true : false)
      zoom?.setDisabled(zoomDisabled ? true : false)
      mapSettings?.setAlignment(mapSettingsAlign || H.ui.LayoutAlignment.BOTTOM_RIGHT)
      mapSettings?.setVisibility(mapSettingsVisible ? true : false)
      mapSettings?.setDisabled(mapSettingsDisabled ? true : false)
      scaleBar?.setAlignment(scaleBarAlign || H.ui.LayoutAlignment.BOTTOM_RIGHT)
      scaleBar?.setVisibility(scaleBarVisible ? true : false)
      scaleBar?.setDisabled(scaleBarDisabled ? true : false)

      // Disable all pop up info bubble on outside click
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const onMapTap = throttle(100, (evt: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        newMap.getObjectAt(evt.currentPointer.viewportX, evt.currentPointer.viewportY, (obj: any) => {
          if (!obj) {
            newUi.getBubbles().forEach((bubble) => {
              bubble.close()
            })
          }
        })
      })

      newMap.addEventListener('tap', onMapTap)

      // Resize Map when window resizes
      const resizeMap = () => {
        newMap?.getViewPort()?.resize()
      }

      window.addEventListener('resize', resizeMap)

      // Set Map and UI
      setUi(newUi)
      setMap(newMap)

      return () => {
        behavior.dispose()
        newMap.removeEventListener('tap', onMapTap)
        window.removeEventListener('resize', resizeMap)

        if (newUi) {
          newUi.dispose()
          setUi(undefined)
        }

        if (newMap) {
          setMap(undefined)
          setTimeout(() => newMap.dispose(), 500)
        }
      }
    }
  }, [node])

  return { ui, map, platform }
}