import { faCity, faPuzzlePiece, faScrewdriverWrench, faScroll, faUser, faUserGroup } from '@fortawesome/pro-solid-svg-icons'
import { Grid, useTheme } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { AnimatedPage } from 'src/components/AnimatedPage/AnimatedPage'
import { SubNavItem } from 'src/components/SubNavSidebar/SubNavItem'
import { useAppSelector } from 'src/store/store'
import { StyledAlert, StyledContainer, StyledSubNavSidebar } from './StyledSettings'

export type SettingsProps = {
  //
}

export const Settings: React.FC<SettingsProps> = () => {
  const theme = useTheme()
  const isMobile = theme.breakpoints.down('md')
  const user = useAppSelector(state => state.user)
  const isWaipa = user.ui_type === 'WAIPA'

  return (
    <Grid container flexDirection={'row'} wrap={'nowrap'}>
      <Grid item>
        <StyledSubNavSidebar>
          {user.idtype === 'OWNER' && <SubNavItem
            title={'Org Units'}
            href="/dashboard/settings/org_units"
            description="Org Units contain managers and vehicles."
            icon={faCity} />}
          {user.idtype === 'OWNER' && <SubNavItem
            title={isMobile ? 'Team' : 'Team Members'}
            href="/dashboard/settings/team_members/"
            description="Team Members are managers that manage locations."
            icon={faUserGroup} />}
          {user.idtype === 'OWNER' && <SubNavItem
            title={'Attributes'}
            href="/dashboard/settings/attributes/"
            description="Attributes can be associated with vehicles for filtering."
            icon={faScrewdriverWrench} />}
          <SubNavItem
            title={'Contents'}
            href="/dashboard/settings/contents/"
            description="Editable contents to display on customer web application."
            icon={faScroll} />
          {!isWaipa && user.idtype === 'OWNER' && <SubNavItem
            title={'Third Parties'}
            href="/dashboard/settings/third_parties/"
            description="Third parties represent vehicle manufactures credentials."
            icon={faPuzzlePiece} />}
          <SubNavItem
            title={'Account'}
            href="/dashboard/settings/account/"
            description="View your account settings."
            icon={faUser} />
          {/* <SubNavItem
            title={'Notifications'}
            href='/dashboard/settings/notifications/'
            description="Manage notifications you receive about bookings."
            icon={faBell} /> */}
        </StyledSubNavSidebar>
      </Grid>
      <Grid item sx={{ flexGrow: 1 }}>
        <StyledContainer id="__settings">
          <StyledAlert />
          <AnimatedPage>
            <Outlet />
          </AnimatedPage>
        </StyledContainer>
      </Grid>
    </Grid>
  )
}
