import { faClose } from '@fortawesome/pro-regular-svg-icons'
import { faPen } from '@fortawesome/pro-solid-svg-icons'
import { Alert, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Button } from 'src/components/Button/Button'
import { Icon } from 'src/components/Icon/Icon'
import { useManagementTableContext } from 'src/components/ManagementTable/ManagementTableContext'
import { Modal } from 'src/components/Modal/Modal'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { removeAttributes } from 'src/store/reducers/attributes'
import { useAppDispatch } from 'src/store/store'
import { pxToRem } from 'src/styles/themes'
import { Attribute } from 'src/types/Attribute'
import { AttributeForm } from '../AttributesForm/AttributesForm'
import { StyledCloseButton, StyledContainer, StyledEditButton, StyledEditIcon } from '../StyledDetailsFragment'


type AttributesDetailsFragmentProps = {
  //
}

export const AttributesDetailsFragment: React.FC<AttributesDetailsFragmentProps> = () => {
  const [isEditAttributeFormOpen, setEditAttributeFormOpen] = useState<boolean>(false)
  const [isConfirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState<boolean>(false)
  const { selectedId, setSelectedId } = useManagementTableContext()
  const { loading, errorMessage, request } = useApiRequest()
  const dispatch = useAppDispatch()

  const onRemoveAttribute = () => {
    const removeAttributeHandler = () => {
      const payload = {
        ATTRIBUTE_ID: selectedId,
      }

      return CarshareApiService.post<Attribute>('removeAttribute', payload)
    }

    request(removeAttributeHandler,
      (attr) => {
        setSelectedId(null)
        dispatch(removeAttributes(attr))
        setConfirmDeleteDialogOpen(false)
      },
    )
  }

  return (
    <StyledContainer>
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <StyledEditButton text round onClick={() => { setEditAttributeFormOpen(true) }}>
            <Typography variant="h6">Details</Typography>
            <StyledEditIcon icon={faPen} />
          </StyledEditButton>
        </Grid>
        <Grid item>
          <StyledCloseButton onClick={() => setSelectedId(null)}>
            <Icon icon={faClose} />
          </StyledCloseButton>
        </Grid>
      </Grid>
      <AttributeForm isEditForm={true} editable={false} attributeId={selectedId || ''} />
      <Button outlined fullWidth onClick={() => { setConfirmDeleteDialogOpen(true) }} sx={{ marginTop: pxToRem(24) }}>Remove Attribute</Button>

      <Modal title={'Edit Attribute Details'} open={isEditAttributeFormOpen} onClose={() => { setEditAttributeFormOpen(false) }} disabledBackdropClose>
        <AttributeForm
          isEditForm={true}
          attributeId={selectedId || ''}
          editable={true}
          onFormSubmitSuccess={() => { setEditAttributeFormOpen(false) }}
        />
      </Modal>
      <Modal title={'Delete Confirmation'} open={isConfirmDeleteDialogOpen} onClose={() => { setConfirmDeleteDialogOpen(false) }} disabledBackdropClose>
        {errorMessage && (
          <Alert severity={'error'} icon={false} sx={{ marginBottom: pxToRem(8) }}>
            {errorMessage}
          </Alert>
        )}
        <Typography variant="body1">Are you sure you want to remove this attribute?</Typography>
        <Grid container justifyContent={'flex-end'} sx={{ marginTop: pxToRem(16) }} spacing={1}>
          <Grid item>
            <Button text onClick={() => { setConfirmDeleteDialogOpen(false) }}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button
              primary
              onClick={onRemoveAttribute}
              disabled={loading}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </StyledContainer>
  )
}
