import axios, { AxiosInstance, AxiosResponse } from 'axios'
import axiosRetry from 'axios-retry'
import { store } from 'src/store/store'
import { v4 as uuidv4 } from 'uuid'
import { DownloadableReports, carshareResources } from './CarshareResources'

export type CarshareResourcesEndpoint = keyof typeof carshareResources

export type APIResponse<TResult> = {
  URL: string
  error: string
  failover?: string
  hostname?: string
  message?: string
  status: 'OK' | 'ERROR'
  warning?: string
  target_id?: string
  submit: string
  limit: string
  offset: string
  microsecs: number
  results: TResult[]
}

const orcodaCarshareClient = axios.create({
  baseURL: process.env.REACT_APP_CARSHARE_API_HOST,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    'X-Api-Key': process.env.REACT_APP_CARSHARE_API_KEY,
  },
})

axiosRetry(orcodaCarshareClient, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000
  },
  // Optionally you can define conditions to determine whether to retry or not
  retryCondition: (error) => {
    // Only retry for specific conditions, for example when we get a network error
    return error.response === undefined
  },
})

orcodaCarshareClient.interceptors.request.use(config => {
  const state = store.getState()
  const sessionId = state.user.sessionid
  const orgUnitId = state.settings.activeOrgUnit?.ORG_UNIT_ID

  config.headers['X-Session-ID'] = sessionId

  if (!config.headers['X-Request-ID']) {
    config.headers['X-Request-ID'] = uuidv4()
  }

  if (config.method === 'post' && config.data && config.data.skipOrgUnit) {
    delete config.data.skipOrgUnit

    return config
  }

  if (config.method === 'get' && config.params && config.params.skipOrgUnit) {
    delete config.params.skipOrgUnit

    return config
  }


  if (config.method === 'post' && orgUnitId && config.data) {
    if (!config.data.ORG_UNIT_ID) {
      config.data.ORG_UNIT_ID = orgUnitId
    }
  }

  if (config.method === 'get' && orgUnitId && config.params) {
    if (!config.params.ORG_UNIT_ID) {
      config.params.ORG_UNIT_ID = orgUnitId
    }
  }

  return config
}, error => {
  return Promise.reject(error)
})

class OrcodaCarshareApiService {
  private instance: AxiosInstance

  public resources = carshareResources

  constructor() {
    this.instance = orcodaCarshareClient
  }

  async get<TResult>(endpoint: CarshareResourcesEndpoint, params?: object): Promise<APIResponse<TResult>> {
    const response: AxiosResponse<APIResponse<TResult>> = await this.instance.get(this.resources[endpoint], { params })

    return response.data
  }

  async post<TResult>(endpoint: CarshareResourcesEndpoint, data?: object): Promise<APIResponse<TResult>> {
    const response: AxiosResponse<APIResponse<TResult>> = await this.instance.post(this.resources[endpoint], data)

    return response.data
  }

  async downloadFile(endpoint:DownloadableReports, params?: Record<string, string>) {
    const state = store.getState()
    const sessionId = state.user.sessionid
    const url = new URL(this.resources[endpoint], process.env.REACT_APP_CARSHARE_API_HOST)

    if (sessionId) {
      url.searchParams.append('sid', sessionId)
    }
    
    if (params) {
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    }

    const downloadLink = url.href
    const response = await this.instance.get(downloadLink, { responseType: 'blob' })

    return response.data
  }
}

export const CarshareApiService = new OrcodaCarshareApiService()
