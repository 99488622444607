/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Grid } from '@mui/material'
import { ComponentProps, useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { TextField } from 'src/components/Form/TextField'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { StyledForm, StyledFormControl, StyledSubmitButton } from 'src/fragments/StyledForm'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { membersSelectors } from 'src/store/adapters/members'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupMembers } from 'src/store/thunks/setupMembers'

export type TeamMemberFormProps = ComponentProps<typeof StyledForm> & {
  isEditForm?: boolean,
  onFormSubmitSuccess?: () => void
  editable?: boolean
  teamMemberId?: string
}

export const TeamMemberForm: React.FC<TeamMemberFormProps> = ({ onFormSubmitSuccess, isEditForm, editable, teamMemberId, ...props }) => {
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [externalId, setExternalId] = useState<string>('')
  const selectedTeamMember = useAppSelector(state => membersSelectors.selectById(state, teamMemberId || ''))

  const { loading, complete, errorMessage, request } = useApiRequest()
  const { setSuccessMessage } = useAlertContext()
  const dispatch = useAppDispatch()

  const submitHandler = () => {
    const payload = {
      team_member_first_name: firstName,
      team_member_last_name: lastName,
      team_member_identifier: externalId,
      email: email,
    }

    if (isEditForm) {
      const editPayload = {
        ...payload,
        USER: selectedTeamMember?.USER,
        TEAM_MEMBER_ID: selectedTeamMember?.TEAM_MEMBER_ID,
      }

      return CarshareApiService.post('editTeamMember', editPayload)
    }

    return CarshareApiService.post('addTeamMember', payload)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    request(submitHandler)
  }

  useEffect(() => {
    if (!isEditForm || !selectedTeamMember) return

    setFirstName(selectedTeamMember.team_member_first_name)
    setLastName(selectedTeamMember.team_member_last_name)
    setEmail(selectedTeamMember.email)
    setExternalId(selectedTeamMember.team_member_identifier)
  }, [isEditForm, selectedTeamMember])

  useEffect(() => {
    if (errorMessage) return

    if (complete && onFormSubmitSuccess) {
      onFormSubmitSuccess()
    }
  }, [errorMessage, complete, onFormSubmitSuccess])

  useEffect(() => {
    if (complete) {
      setSuccessMessage(`Team member ${firstName} ${lastName} was ${isEditForm ? 'updated' : 'created'} successfully.`)
      dispatch(setupMembers())
    }
  }, [complete])

  return (
    <StyledForm onSubmit={onSubmit} $isEditable={editable} {...props}>
      <Grid container spacing={2} justifyContent={'center'} flexDirection={'column'}>
        <Grid item>
          {errorMessage && (
            <Alert severity={'error'} icon={false}>
              {errorMessage}
            </Alert>
          )}
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>First Name</TextFieldLabel>
            <TextField
              id="first-name-textfield"
              required
              disabled={!editable}
              value={firstName || ''}
              onChange={(e) => {
                setFirstName(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Last Name</TextFieldLabel>
            <TextField
              id="last-name-textfield"
              required
              disabled={!editable}
              value={lastName || ''}
              onChange={(e) => {
                setLastName(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Email</TextFieldLabel>
            <TextField
              type="email"
              id="email-textfield"
              disabled={!editable}
              value={email || ''}
              required
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel>Identifier</TextFieldLabel>
            <TextField
              type="text"
              value={externalId || ''}
              disabled={!editable}
              placeholder="External ID"
              onChange={(e) => {
                setExternalId(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        {editable && <Grid item>
          <StyledSubmitButton
            primary
            fullWidth
            type="submit"
            disabled={loading}
          >
            {isEditForm ? 'Edit' : 'Add'} Team Member
          </StyledSubmitButton>
        </Grid>}
      </Grid>
    </StyledForm >
  )
}
