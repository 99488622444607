import { Grid, Typography, styled } from '@mui/material'
import { useCallback, useEffect } from 'react'
import { AccountForm, ChangePasswordForm, NotificationForm } from 'src/fragments/Settings'
import { useAppDispatch } from 'src/store/store'
import { setupAccount } from 'src/store/thunks/setupAccount'
import { pxToRem } from 'src/styles/themes'
// @ts-ignore

export type SettingsAccountProps = {
  //
}

export const SettingsAccount: React.FC<SettingsAccountProps> = () => {
  const dispatch = useAppDispatch()

  const scrollTop = useCallback(() => {
    const node = document.querySelector('#__settings')

    node?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])


  const onNotificationSubmitSuccess = () => {
    scrollTop()

    if ('Notification' in window) {
      Notification.requestPermission()
    }
  }

  useEffect(() => {
    dispatch(setupAccount())
  }, [])

  return (
    <StyledContainerGrid container flexDirection={'column'} justifyContent={'flex-start'} >
      <StyledFormGrid item>
        <StyledHeaderTypography variant="h1">
          Profile
        </StyledHeaderTypography>
        <AccountForm onFormSubmitSuccess={scrollTop} />
      </StyledFormGrid>
      <StyledFormGrid item sx={{ marginTop: pxToRem(36) }}>
        <StyledHeaderTypography variant="h1">
          Notifications
        </StyledHeaderTypography>
        <NotificationForm onFormSubmitSuccess={onNotificationSubmitSuccess} />
      </StyledFormGrid>
      <StyledFormGrid item sx={{ marginTop: pxToRem(36), marginBottom: pxToRem(24) }}>
        <StyledHeaderTypography variant="h1">
          Update Password
        </StyledHeaderTypography>
        <ChangePasswordForm onFormSubmitSuccess={scrollTop} />
      </StyledFormGrid>
    </StyledContainerGrid>
  )
}

const StyledContainerGrid = styled(Grid)`
  padding: ${props => props.theme.typography.pxToRem(16)};
  margin-bottom: ${props => props.theme.typography.pxToRem(65)};

  ${props => props.theme.breakpoints.up('sm')} {
    margin-bottom: 0;
    padding: ${props => props.theme.typography.pxToRem(32)} ${props => props.theme.typography.pxToRem(64)};
  }

  > *:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.palette.divider};
  }
`

const StyledFormGrid = styled(Grid)`
`

const StyledHeaderTypography = styled(Typography)`
  font-weight: 500;
  font-size: ${(props) => props.theme.typography.pxToRem(20)};
`
