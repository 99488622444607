import { useMediaQuery, useTheme } from '@mui/material'
import { Allotment } from 'allotment'
import { useEffect, useState } from 'react'
import { CustomAllotment } from 'src/components/CustomAllotment/CustomAllotment'
import { ManagementTableContext } from 'src/components/ManagementTable/ManagementTableContext'
import { TableMenuItem } from 'src/components/ManagementTable/TableMenuItem'
import { Modal } from 'src/components/Modal/Modal'
import { thirdPartiesSelectors } from 'src/store/adapters/thirdParties'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupThirdParties } from 'src/store/thunks/setupThirdParties'
import { ThirdParty } from 'src/types/ThirdParty'
import { StyledAllotmentPanel, StyledManagementTable, StyledPaper } from './StyledSettings'
import { ThirdPartyDetailsFragment, ThirdPartyForm } from 'src/fragments/Settings'

export type SettingsThirdPartiesProps = {
  //
}

export const SettingsThirdParties: React.FC<SettingsThirdPartiesProps> = () => {
  const [selectedId, setSelectedId] = useState<string | null>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const [isAddThirdPartyFormOpen, setAddThirdPartyFormOpen] = useState(false)
  const thirdParties = useAppSelector(state => thirdPartiesSelectors.selectAll(state))
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'))
  const dispatch = useAppDispatch()

  const tableContextValue = {
    selectedId,
    setSelectedId,
    searchValue,
    setSearchValue,
  }

  const searchFilter = (thirdParty: ThirdParty) => {
    const searchTerm = searchValue.toLowerCase()

    return thirdParty.third_party_name.toLowerCase().includes(searchTerm)
  }

  useEffect(() => {
    dispatch(setupThirdParties())
  }, [])

  return (
    <StyledPaper>
      <ManagementTableContext.Provider value={tableContextValue}>
        <CustomAllotment>
          <Allotment.Pane minSize={350}>
            <StyledAllotmentPanel>
              <StyledManagementTable
                title={'Third Parties'}
                subtitle={`${thirdParties.length} third parties`}
                onAddClick={() => { setAddThirdPartyFormOpen(true) }}
              >
                {thirdParties.filter(searchFilter).map((thirdParty) => (
                  <TableMenuItem
                    active={selectedId === thirdParty.THIRD_PARTY_ID}
                    key={thirdParty.THIRD_PARTY_ID}
                    onClick={() => {
                      setSelectedId((prevId) => prevId === thirdParty.THIRD_PARTY_ID ? null : thirdParty.THIRD_PARTY_ID)
                    }}
                  >
                    {thirdParty.third_party_name}
                  </TableMenuItem>
                ))}
              </StyledManagementTable>
            </StyledAllotmentPanel>
          </Allotment.Pane>
          {isTablet && selectedId && (
            <Allotment.Pane minSize={350}>
              <StyledAllotmentPanel>
                <ThirdPartyDetailsFragment />
              </StyledAllotmentPanel>
            </Allotment.Pane>
          )}
        </CustomAllotment>
        <Modal title={'Add Third Party'} open={isAddThirdPartyFormOpen} onClose={() => { setAddThirdPartyFormOpen(false) }} disabledBackdropClose>
          <ThirdPartyForm editable $isEditable onFormSubmitSuccess={() => { setAddThirdPartyFormOpen(false) }} />
        </Modal>
        {!isTablet && (
          <Modal
            fullWidth
            open={!!selectedId}
          >
            <ThirdPartyDetailsFragment />
          </Modal>
        )}
      </ManagementTableContext.Provider>
    </StyledPaper>
  )
}
