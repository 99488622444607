import { createEntityAdapter } from '@reduxjs/toolkit'
import { ThirdParty } from 'src/types/ThirdParty'
import { RootState } from '../store'

export const thirdPartiesAdapter = createEntityAdapter<ThirdParty>({
  selectId: (thirdParty) => thirdParty.THIRD_PARTY_ID,
})

export const thirdPartiesSelectors = thirdPartiesAdapter.getSelectors<RootState>(
  (state) => state.thirdParties,
)
