import { createAsyncThunk } from '@reduxjs/toolkit'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { UserPreference, UserProfile } from 'src/types/User'

export const setupAccount = createAsyncThunk('account/setup', async () => {
  const preference = await CarshareApiService.get<UserPreference>('getTeamMemberPreferences')
  const profile = await CarshareApiService.get<UserProfile>('getAccount')

  return {
    profile: profile?.results?.[0],
    preference: preference?.results?.[0],
  }
})