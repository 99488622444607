/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react'

export type NotificationSidebarContextType = {
  notificationCount: number
  setNotificationCount: React.Dispatch<React.SetStateAction<number>>
  isNotificationSidebarOpen: boolean
  setNotificationSidebarOpen: (open: boolean) => void
}

export const NotificationSidebarContext = createContext<NotificationSidebarContextType>({
  notificationCount: 0,
  setNotificationCount: () => { },
  isNotificationSidebarOpen: false,
  setNotificationSidebarOpen: () => { },
})

export const useNotificationSidebarContext = () => {
  const context = useContext(NotificationSidebarContext)

  if (context === undefined) {
    throw new Error(
      'useNotificationSidebarContext must be used within a NotificationSidebarContext.Provider',
    )
  }

  return context
}
