import { Grid, Typography, styled } from '@mui/material'
import React, { ComponentProps } from 'react'

export type LoginCardProps = Omit<ComponentProps<typeof StyledLoginCard>, 'container'> & {
  children: React.ReactNode
}

export const LoginCard: React.FC<LoginCardProps> = ({ children, ...props }) => {
  return (
    <StyledLoginCard container {...props}>
      <StyledLoginCardLeftGrid item md={6}>
        <StyledWelcomeContainer>
          <StyledWelcomeTypography variant="body1">
            Welcome to
          </StyledWelcomeTypography>
          <StyledNameTypography variant="h1">
            Orcoda Connect
          </StyledNameTypography>
        </StyledWelcomeContainer>
        <StyledVisualImage src="/login-visual.svg" />
      </StyledLoginCardLeftGrid>
      <StyledLoginCardRightGrid item xs={12} md={6}>
        <StyledLoginFormContainer>
          <StyledLoginLogo src="/login-logo.svg" />
          {children}
        </StyledLoginFormContainer>
      </StyledLoginCardRightGrid>
    </StyledLoginCard>
  )
}

export const StyledLoginCard = styled(Grid)`
  margin: auto;
  width: 100%;
  height: 100%;
  
  ${(props) => props.theme.breakpoints.up('sm')} {
    width: ${props => props.theme.typography.pxToRem(868)};
    height: ${props => props.theme.typography.pxToRem(868)};
    max-width: 90svw;
    max-height: 90svh;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    border-radius: ${props => props.theme.typography.pxToRem(16)};
  }
`

export const StyledLoginCardLeftGrid = styled(Grid)`
  display: none;
  border-radius: ${props => props.theme.typography.pxToRem(16)} 0 0 ${props => props.theme.typography.pxToRem(16)};
  background-color: #1F2937;

  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
  }
`

export const StyledWelcomeContainer = styled('div')`
  color: ${props => props.theme.palette.common.white};

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${props => props.theme.typography.pxToRem(32)};
  }
`

export const StyledWelcomeTypography = styled(Typography)`
  font-weight: 700;
  font-size: ${props => props.theme.typography.pxToRem(20)};
`

export const StyledNameTypography = styled(Typography)`
  font-weight: 700;
`

export const StyledVisualImage = styled('img')`
  width: ${props => props.theme.typography.pxToRem(434)};
`

export const StyledLoginCardRightGrid = styled(Grid)`
  border-radius: 0;
  background-color: ${props => props.theme.palette.background.paper};

  ${(props) => props.theme.breakpoints.up('md')} {
    border-radius: 0 ${props => props.theme.typography.pxToRem(16)} ${props => props.theme.typography.pxToRem(16)} 0;
  }
`

export const StyledLoginLogo = styled('img')`
  width: ${props => props.theme.typography.pxToRem(270)};
  margin-bottom: ${props => props.theme.typography.pxToRem(32)};
`

export const StyledLoginFormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.typography.pxToRem(48)} ${props => props.theme.typography.pxToRem(32)};
  max-width: ${props => props.theme.typography.pxToRem(316)};
  margin: auto;

  ${(props) => props.theme.breakpoints.up('sm')} {
    overflow-x: hidden;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${props => props.theme.typography.pxToRem(32)} ${props => props.theme.typography.pxToRem(64)};
  }
`
